import React, { Component } from 'react';

class ChartSell extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }
    load_data(data,stock) {
        if (Object.keys(data).length > 0) {
   
            data['day']=  data['day'].map(date =>    String(date).replace(/_/g, '-'));

            let data_chart = []

            let candlestick_data = {
                x: data['day'],
                close: data['close'],
                high: data['high'],
                low: data['low'],
                open: data['open'],
                type: 'candlestick',
                xaxis: 'x',
                yaxis: 'y',
                name : stock,
            }
            data_chart.push(candlestick_data)

            var layout = {
                title: stock,
                // height: 500,
                hovermode: 'x',

                showlegend: true,
                xaxis: {
                    rangeslider: {
                         visible: false
                     }
                  },
                  hovermode: "x unified",
                  hoverdistance: 10,
                  // margin: {b:75, t:25, l:50, r:25},
                  autosize: true,
                  xaxis: {
                      autorange: true,
                      type: "date",
                      categoryorder: "category ascending",
                      rangeslider: { visible: false },
                      showspikes: true,
                      spikemode: "across",
                      spikesnap: "cursor",
                      spikedash: "dot",
           
                      spikethickness: 2,
                  
                      showexponent: "all",
         
                  },
                  yaxis: {
                      visible: true,
                      showline: true,
                      tickcolor: '#eeee',
                      linecolor: '#eeee',
                      autorange: true,
                      showspikes: true,
                      spikemode: "across",
                      spikesnap: "cursor",
                      spikedash: "dot",
        
                      spikethickness: 2,
          
  
                  },

            };
            var config = { responsive: true }
            global.Plotly.newPlot(this.chart, data_chart, layout, config);
        } else {
            global.Plotly.newPlot(this.chart, []);
        }
        this.setState({
            loading: false
        });
    }

    render() {
        return (
            <div style={{ margin: '10px 0px' }}>
                <div style={{ width: '100%' }} ref={c => this.chart = c}>

                </div>
            </div>
        );
    }
}

export default ChartSell;