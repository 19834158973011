import model from "../model";
import axios from "axios";
import { domain_config } from "../../utilities/Domain";
class Dashboard {

    constructor() {
        // this.url = 'https://d11.f5traders.com/api'

        this.url = domain_config(1)

    }

    getDataInsider(filter) {
        return axios.request({
            url: `${this.url}/db11-endpoint-insider`,
            method: "POST",
            data : {...filter}
        })

            .then(response => {
                response = response['data']
                return response

            })

            .catch((error) => {
                // if (error.response.status == 401) {
                //     window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent(window.location.pathname)}`
                //     return false;
                // }
                console.log(error);
                return false;
            })

       
     
    }






}

export default Dashboard;