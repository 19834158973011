import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import moment from 'moment';

import Banner from '../components/Banner';

import { InputSwitch } from 'primereact/inputswitch';
import Transaction from '../models/admin/Transaction';
import Portfolio from '../models/admin/Portfolio';


class PortfolioView extends Component {

    constructor(props) {

        super(props)
        this.columns = [
            { field: 'expander', header: '', body: true },
            { field: 'index', header: 'Index', body: true },
            { field: 'stock', header: 'Stock', body: true, sort: true, filter: false },
            { field: 'stk', header: 'STK', body: true, sort: false, filter: false },
            { field: 'position', header: 'Position', body: true, sort: true, filter: false },
            { field: 'equityFlow', header: 'Equity Flow', body: true, sort: true, filter: false },
            { field: 'avgCost', header: 'AvgCost', body: true, sort: true, filter: false },
            { field: 'pnl', header: 'Pnl', body: true, sort: true, filter: false },
            { field: 'realized', header: 'Realized', sort: true, body: true, filter: false },
            { field: 'unrealized', header: 'Unrealized', sort: true, body: true },

        ];
        this.state = {
            data: [],
            loading: false,
            filters: {},
            injection: true,
            groupByStock: true,
            fromDay: null,
            endDay: null,

            selectedColumns: [
                { field: 'expander', header: '', body: true },
                { field: 'index', header: 'Index', body: true },
                { field: 'stock', header: 'Stock', body: true, sort: true, filter: false },
                { field: 'stk', header: 'STK', body: true, sort: false, filter: false },
                { field: 'position', header: 'Position', body: true, sort: true, filter: false },
                { field: 'equityFlow', header: 'Equity Flow', body: true, sort: true, filter: false },
                { field: 'avgCost', header: 'AvgCost', body: true, sort: true, filter: false },
                { field: 'pnl', header: 'Pnl', body: true, sort: true, filter: false },
                { field: 'realized', header: 'Realized', sort: true, body: true, filter: false },
                { field: 'unrealized', header: 'Unrealized', sort: true, body: true },
            ],

            groupOptions: [],
            expandedRows: {},
        }

        this.loadLazyTimeout = null;

        this.DayOpt = [
            {
                'name': '1w',
                'day': 1,
                'type': 'weeks'
            },
            {
                'name': '2w',
                'day': 2,
                'type': 'weeks'
            },
            {
                'name': '1m',
                'day': 1,
                'type': 'months'
            },
            {
                'name': '2m',
                'day': 2,
                'type': 'months'
            },
            {
                'name': '3m',
                'day': 3,
                'type': 'months'
            },

            {
                'name': 'All',
            },
        ]


        this.tranModel = new Transaction()

        this.PortfolioModel = new Portfolio()

        this.stock_special = ['MWG', 'VNM', 'VPB','SSI', 'STB', 'TCB', 'VCB', 'VND', 'CTG','HPG','MSB','FRT','FPT']
    }

    componentDidMount() {
        this.getQuerry()
    }

    getQuerry() {
        this.tranModel.query().then(response => {

            let parsed = global.parsed()

            let params = {}
            if (parsed['stk']) {
                params['stk'] = {
                    "col_name": "stk",
                    "operator": "in",
                    "value": parsed['stk']
                }
            }

            if (parsed['group']) {
                params['group'] = {
                    "col_name": "group",
                    "operator": "in",
                    "value": parsed['group']
                }
            }

            this.setState({
                groupOptions: response['categories']['label1_details'],
                filters: params,
                stkValue: parsed['stk'] ? { name: parsed['stk'], code: parsed['stk'] } : null,
                groupValue: parsed['group'] ? parsed['group'] : null,
            }, () => this.getData());
        }).catch((error) => {
            console.log(error);
        })
    }

    getData() {

        clearTimeout(this.loadLazyTimeout);
        this.loadLazyTimeout = setTimeout(() => {
            this.setState({ loading: true });

            let filter = {
                data_filter: Object.values(this.state.filters),
                injection: this.state.injection,
          
            }



            this.PortfolioModel.getData(filter).then(res => {

                if (this.state.groupByStock) {
                    if(Object.keys(res).length == 0){
                        this.setState({
                            loading : false,
                            data : []
                        });
                        return
                    }
                    let groupObj = {}

                    res.map(item => {
                        let group = item['stock']


                        if (groupObj[group]) {
                            groupObj[group]['value'].push(item)

                            Object.keys(item).map(col => {
                                if (col == 'position' || col == 'avgCost' || col == 'pnl' || col == 'realized' || col == 'unrealized' || col == 'equityFlow') {

                                    groupObj[group][col] += Number(item[col])

                                }
                            })
                        } else {
                            groupObj[group] = {
                                ...item, value: [item]
                            }
                        }

                    })
                    
                    this.setState({
                        data: Object.values(groupObj),
                        loading: false,
                        expandedRows: {}
                    });
                    return
                }
                this.setState({
                    data: res,
                    loading: false,
                    expandedRows: {}
                });
            })
        }, 500);





    }

    change(value, col, filterkey, type) {

        var filter = this.state.filters;
        if (type == 'text') {
            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": value.trim()
                }
            }
        } else if (type == 'date') {
            if (value == null) {
                delete filter[filterkey];

            } else {

                
             
                let dateData = []
                dateData.push(moment(value).format("YYYY/MM/DD"))
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dateData,
                }
            }
        } else if (type == 'multi') {
            if (value.length == 0) {
                delete filter[filterkey];
            } else {
                let dataMul = []
                value.map(item => {
                    dataMul.push(item['code'])
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dataMul
                }
            }
        }
        else {
            if (typeof (value) == 'string' && value != '') {

                this.setState({
                    [col]: value,
                });
                return
            }

            if (value == undefined) return

            if (value == '') {
                delete filter[filterkey];
            } else {

                let name = value['name']
                let check = name.indexOf('(Σ:')
                if (check > -1) {
                    name = name.substring(0, check)
                    name = name.trim()
                }
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": name
                }
                value['name'] = name
                value['code'] = name


            }

        }
  
        this.setState({
            [col]: value,
            filters: filter,
        }, () => {this.getData()});


    }

   


    expanderCus(id) {

        let expandedRows = this.state.expandedRows

        if (expandedRows[id]) {
            delete expandedRows[id]
        } else {
            expandedRows[id] = true
        }
        this.setState({
            expandedRows
        });
    }

    representativeTemplate = (data, props) => {

        if (props.field == "index") {

            return (
                <div >
                    <span>{props.rowIndex + 1}</span>
                </div>
            )
        }
        if (props.field == "stock") {

            if(this.stock_special.includes(data[props.field])){
                return (
                    <div >
                        <span>{data[props.field]}*</span>
                    </div>
                )
            }
            return (
                <div >
                    <span>{data[props.field]}</span>
                </div>
            )
        }

        if (props.field == "expander") {

            if (data['value'] && data['value'].length > 1) {
                let icon = this.state.expandedRows[data['stock']] ? 'down' : 'right'

                return (
                    <button className="p-row-toggler p-link" type="button" onClick={() => this.expanderCus(data['stock'])}>
                        <span className={`p-row-toggler-icon pi pi-chevron-${icon}`}></span>
                        <span className="p-ink"></span>
                    </button>
                )
            }


        }

        if (props.field == 'stk'){
           
            if (data['value'] && data['value'].length > 0) {
                return (
                    <div >
                        <b>{data['value'].length}</b>
                    </div>
                )
            }
            // let result = global.formatNumber(Math.round(data[props.field] * 100) / 100)

            return (
                <div >
                    <span>{data[props.field]}</span>
                </div>
            )
        }

        if (props.field == 'avgCost'){
           
            if (data['value'] && data['value'].length > 0) {
      

                let avgCost = data['close'] - data['unrealized'] / data['position']
                let result = global.formatNumber(Math.round(avgCost * 100) / 100)
                return (
                    <div >
                        <span>{result}</span>
                    </div>
                )
            }
            let result = global.formatNumber(Math.round(data[props.field] * 100) / 100)

            return (
                <div >
                    <span>{result}</span>
                </div>
            )
        }

        let col = [ 'pnl', 'position', 'realized', 'unrealized','equityFlow']

        if (col.includes(props.field)) {


            let result = global.formatNumber(Math.round(data[props.field] * 100) / 100)

            return (
                <div >
                    <span>{result}</span>
                </div>
            )
        }







    }

    genDay(opt) {
        if (opt['name'] == 'All') {

    
            var filter = this.state.filters;
            delete filter['fromDay'];
            delete filter['endDay'];
  
            this.setState({
                fromDayValue: null,
                endDayValue: null,
                filters: filter,
            }, () => this.getData());
            return

        }
        let endDay = moment().format('YYYY/MM/DD')
        let startDay = moment().subtract(opt['day'], opt['type']).format('YYYY/MM/DD');



        var filter = this.state.filters;

        filter['fromDay'] = {
            "col_name": 'fromDay',
            "operator": "from",
            "value": [startDay],
        }
        filter['endDay'] = {
            "col_name": 'endDay',
            "operator": "from",
            "value": [endDay],
        }



        this.setState({
            fromDayValue: new Date(startDay),
            endDayValue: new Date(endDay),
            filters: filter

        }, () => this.getData());

    }

    async searchSuggest(col, data) {
        if (data.length == 1) return
        if (data[data.length - 1] == ' ') {
            const collection = document.getElementsByClassName("p-autocomplete-panel p-component p-connected-overlay-enter-done")[0];
            if (collection) {
                return
            }
        }
        data = data.trim()
        let struct = {
            "column": col,
            "data": data
        }
        let result = await this.tranModel.suggestion(struct)
        let out = [];
        for (let index = 0; index < 10; index++) {
            const item = result[index];
            out.push({
                name: item,
                code: item
            })

        }
        this.setState({
            nameSuggest: out,
        });


    }

    clearFilter(col) {


        if (Object.keys(this.state.filters).length == 0) return
        if (col) {

            let value = `${col}Value`
            if (col == 'day') {

                this.setState({
                    'fromDay': null,
                    'endDay': null,
                });
            } else if (col == 'fromDay') {
                this.setState({
                    'fromDay': null,

                });
            } else if (col == 'endDay') {
                this.setState({
                    'endDay': null,

                });
            }
            else {

                this.setState({
                    [value]: ''
                });
            }
            var filter = this.state.filters;
            delete filter[col];
            this.setState({
                filters: filter,
            }, () => this.getData());
            return
        } else {
            Object.keys(this.state.filters).map(item => {
                if (item == 'day') {
                    this.setState({
                        'fromDay': null,
                        'endDay': null,
                    });
                } else if (col == 'fromDay') {
                    this.setState({
                        'fromDay': null,

                    });
                } else if (col == 'endDay') {
                    this.setState({
                        'endDay': null,

                    });
                }
                else {
                    let value = `${item}Value`

                    this.setState({
                        [value]: ''
                    });
                }

            })
            this.setState({
                filters: {},
            }, () => this.getData());
        }


    }

    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }

    AutoKeyEnter(e, data) {

        if (e.key == "Enter") {
            const collection = document.getElementsByClassName("p-autocomplete-panel p-component p-connected-overlay-enter-done")[0];
            collection.style.display = 'none'
            this.change(this.state[`${data}Value`], `${data}Value`, data, 'text')
        }

    }

    rowExpansionTemplate(data) {

        return (
            <div className="sub_table">
                <DataTable value={data.value} responsiveLayout="scroll">
                    <Column style={{ width: '5em' }} />

                    <Column field='index' header='Index' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='stock' header='Stock'  ></Column>
                    <Column field='stk' header='STK' body={(data, props) => this.representativeTemplate(data, props)}   ></Column>
                    <Column field='position' header='Position' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='equityFlow' header='Equity Flow' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='avgCost' header='AvgCost' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='pnl' header='Pnl' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='realized' header='Realized' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='unrealized' header='Unrealized' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>






                </DataTable>
            </div>
        );





    }

    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                <Banner text='Portfolio' icon='pi pi-wallet'></Banner>
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {Object.keys(this.state.filters).length} filter : {
                            Object.keys(this.state.filters).map(item => {
                                let value1 = this.state.filters[item]['value']
                                if (item == 'day') {
                                    value1 = value1.map(item => moment(item).format('DD - MMM'))

                                }
                                let col = item == 'label1_details' ? 'group' : item
                                return (
                                    <b onClick={() => this.clearFilter(item)} style={{ cursor: 'pointer' }}>
                                        <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                        {`     ${this.state.filters[item]['operator']} '${col}' `}    |
                                    </b>)
                            }

                            )
                        }

                        {
                            Object.keys(this.state.filters).length > 0 && <Button onClick={() => this.clearFilter()} style={{ position: 'relative', top: '7px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                        }
                    </div>

                    <div style={{ display: 'flex' }}>

                        <InputSwitch checked={this.state.groupByStock} onChange={(e) => this.setState({ groupByStock: e.value }, () => this.getData())} />
                        <span style={{ marginLeft: '10px', marginRight: '10px' }}> Total</span>

                        <InputSwitch checked={this.state.injection} onChange={(e) => this.setState({ injection: e.value }, () => this.getData())} />
                        <span style={{ marginLeft: '10px' }}> Injection</span>
                    </div>
                </div>
            </div>
        );
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });


        return (

            <div className="p-col-12 p-md-12" style={{ display: 'flex' }}>
                <div className="card widget-table" style={{ width: '80%' }} >

                    {
                        this.state.groupByStock ?
                            <DataTable
                                className="p-datatable-customers"
                                value={this.state.data}
                                dataKey="stock" stripedRows

                                loading={this.state.loading}
                                responsiveLayout="scroll"
                                header={header}

                                expandedRows={this.state.expandedRows}
                                onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                rowExpansionTemplate={(data) => this.rowExpansionTemplate(data)}
                            >
                                {columnComponents}
                            </DataTable> :
                            <DataTable
                                className="p-datatable-customers"
                                value={this.state.data}
                                dataKey="id1123" stripedRows

                                loading={this.state.loading}
                                responsiveLayout="scroll"
                                header={header}
                            >
                                {columnComponents}
                            </DataTable>



                    }

                    {/* <DataTable
                        className="p-datatable-customers"
                        value={this.state.data}
                        dataKey="id1123" stripedRows

                        loading={this.state.loading}
                        responsiveLayout="scroll"
                        header={header}
                    >
                        {columnComponents}
                    </DataTable> */}

                </div>

                <div className="card" style={{ width: '20%', marginLeft: '25px' }}>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT COLUMN</div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} />

                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY STK</div>
                        <div>
                            <AutoComplete
                                value={this.state.stkValue}
                                suggestions={this.state.nameSuggest}
                                completeMethod={(e) => this.searchSuggest('id', e.query)}
                                field="name"
                                onChange={(e) => this.change(e.value, 'stkValue', 'stk', 'auto')}
                                inputStyle={{ width: '100%' }}
                                style={{ width: '100%' }}

                                onKeyUp={(e) => this.AutoKeyEnter(e, 'id')}

                            />

                        </div>
                    </div>


                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY ID</div>
                        <div>
                            <AutoComplete
                                value={this.state.idValue}
                                suggestions={this.state.nameSuggest}
                                completeMethod={(e) => this.searchSuggest('account', e.query)}
                                field="name"
                                onChange={(e) => this.change(e.value, 'idValue', 'id', 'auto')}
                                inputStyle={{ width: '100%' }}
                                style={{ width: '100%' }}

                                onKeyUp={(e) => this.AutoKeyEnter(e, 'account')}
                            />
                        </div>
                    </div>


                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY GROUP</div>
                        <div>
                            <Dropdown style={{ width: '100%' }} options={this.state.groupOptions} value={this.state.groupValue} onChange={(e) => this.change(e.target.value, 'groupValue', 'group', 'text')} className="p-column-filter" showClear />
                        </div>
                    </div>




                    <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>FROM DATE</div>
                        <div>

                            <Calendar style={{ width: '100%' }} dateFormat="dd - M" id="multiple" value={this.state.fromDayValue} onChange={(e) => this.change(e.value, 'fromDayValue', 'fromDay', 'date')}  readOnlyInput showButtonBar />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>TO DATE</div>
                        <div>
                            <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.endDayValue} onChange={(e) => this.change(e.value, 'endDayValue', 'endDay', 'date')}  readOnlyInput showButtonBar />
                        </div>
                    </div>

                    <div style={{ marginTop: '10px' }}>

                        {
                            this.DayOpt.map(item => {

                                return (
                                    <Button key={item['name']} label={item['name']} style={{ color: '#44486D' }} className="p-button-raised p-button-text mr-2" onClick={() => this.genDay(item)} />
                                )
                            })
                        }

                    </div>

                </div>




            </div>
        )
    }
}

export default PortfolioView;