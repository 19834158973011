
import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import Banner from '../Banner';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
class OneStockSellGroup extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            { field: 'expander', header: '', body: true },
            { field: 'index', header: 'Index', body: true },
            { field: 'id', header: 'STK', body: true, filter: false },
            { field: 'name', header: 'Name', body: true, filter: false, style: { maxWidth: '14rem' } },

            
            { field: 'sell', header: 'Sell', body: true , sort : true },
            { field: 'sell_order', header: 'Sell Order', body: true  },
            // { field: 'type', header: 'Type', body : true  },
            { field: 'label', header: 'Label', body: true },

            { field: 'price', header: 'Price', body: true },
            // { field: 'position', header: 'Position', body: true },
            { field: 'indv_position', header: 'Position', body: true },
            { field: 'percentile', header: 'Percentile'  },
            { field: 'group', header: 'Group', body: true },

            { field: 'buy', header: 'Buy', body: true  },
            { field: 'buy_order', header: 'Buy Order', body: true  },
            { field: 'icon', header: '', body: true },
         



        ];
        this.state = {
            data: [],
            loading: false,
            totalRecords: 0,
            first: 0,
            quantity: 25,
            page: 1,
            sortField: 'sell',
            sortOrder: -1,
            filters: {

            },
            selectedColumns: [
                { field: 'expander', header: '', body: true },
            { field: 'index', header: 'Index', body: true },
            { field: 'id', header: 'STK', body: true, filter: false },
            { field: 'name', header: 'Name', body: true, filter: false, style: { maxWidth: '14rem' } },

            
            { field: 'sell', header: 'Sell', body: true , sort : true  },
            { field: 'sell_order', header: 'Sell Order', body: true  },
            { field: 'price', header: 'Price', body: true },
            // { field: 'position', header: 'Position', body: true },
            { field: 'indv_position', header: 'Position', body: true },
            // { field: 'type', header: 'Type', body : true  },
            { field: 'label', header: 'Label', body: true },
            { field: 'group', header: 'Group', body: true },
            { field: 'icon', header: '', body: true },
         




        ],






        }





        this.loadLazyTimeout = null;


    }



    setLoading() {
        this.setState({ loading: true });
    }

    setData(data) {
        let out = []
        let groupObj = {}
        data.map(item => {
            let group = item['group']
            if (group == '') {
                out.push(item)
            } else {

                if (groupObj[group]) {
                    groupObj[group]['value'].push(item)

                    Object.keys(item).map(col => {
                        if (col == 'buy' || col == 'sell' || col == 'buy_order' || col == 'sell_order' || col == 'indv_position') {

                            groupObj[group][col] += Number(item[col])

                        }
                    })
                } else {
                    groupObj[group] = {
                        ...item, value: [item]
                    }
                }
            }
        })

        out = [...Object.values(groupObj), ...out]
    

        let sellFooter = 0
        let sellOrderFooter = 0
        data.map(item => {
            sellFooter += Number(item['sell'])
            sellOrderFooter += Number(item['sell_order'])
        })

        out.sort(function(a, b){return b.sell - a.sell});
        this.setState({
            // data: data,
            data: out,
            loading: false,
            sellFooter,
            sellOrderFooter,
            expandedRows : {}
        });

    }

    onSort(event) {
        this.setState({
            sortField: event.sortField,
            sortOrder: -1
        });

        // this.props.setSort(event.sortField , event.sortOrder )

    }




    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }
    representativeTemplate = (data, props) => {
        if (props.field == "icon") {
            if(data['value'] && data['value'].length > 1){
                return
            }

            return (
                <div  style={{cursor: 'pointer' }}
                    onClick={() => window.open(`${window.location.origin}/#/portfolio?stk=${data['id']}&name=${data['name']}`)}
                >
                    
                    <i className="pi pi-search" style={{ 'fontSize': '1em', 'lineHeight': 0 }}></i>
                </div>
            )
        }
        if (props.field == "id") {
         
            if(data['value'] && data['value'].length > 1){
                let col =[]

                data.value.map(item => {
                    if(!col.includes(item['id'])){
                        col.push(item['id'])
                    }
                })

                return <b>{col.length}</b>
            }
            return (
                <span style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/?id=${data[props.field]}`)}>{data[props.field]}</span>
            )
        }

        if (props.field == "name") {
            if(data['value'] && data['value'].length > 1){
                return <b style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/?group=${data['group']}`)}>{data['group']}</b>
            }
            return (
                <span style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/?name=${data[props.field]}`)}>{data[props.field]}</span>
            )
        }

        if (props.field == "index") {
            return (
                <div style={{display : 'flex' , alignItems : 'center' , justifyContent : 'space-between'}}>
                    <span>{props.rowIndex + 1}</span>
                   {data.type == 'Insider' && <i class="pi pi-star-fill" style={{color : 'orange' }}></i>}
                </div>
            
            )
        }

        let col = ['buy' , 'buy_order','sell','sell_order']

        if (col.includes(props.field)) {
            if(data[props.field] == null) return 'NULL'

            let result =global.formatNumber(data[props.field])
            if(result == 0) result = '-'

            let color
            if(props.field == 'buy'){
                color = 'limegreen'
            }
            if(props.field == 'sell'){
                color = 'orange'
            }

            

            return (
                <span style={result != '-' ? { color : color} : {} }>{result}</span>
            )
           
        }

        if (props.field == "type") {

            if(data['value'] && data['value'].length > 1){
                let col =[]

                data.value.map(item => {
                    if(!col.includes(item['type'])){
                        col.push(item['type'])
                    }
                })

                return <b>{col.length}</b>
            }

            let result = data[props.field]

            if(result == 'Unknown') result = '-'

            return (
                <span >{result}</span>
            )
        }
        if (props.field == "label") {

            if(data['value'] && data['value'].length > 1){
                let col =[]

                data.value.map(item => {
                    if(!col.includes(item['label'])){
                        col.push(item['label'])
                    }
                })

                return <b>{col.length}</b>
            }


            let result = data[props.field]

            if (result == 'Unknown') result = '-'

            return (
                <span >{result}</span>
            )
        }
        if (props.field == "expander") {

            if(data['value'] && data['value'].length > 1){
                let icon = this.state.expandedRows[data['id']] ? 'down' : 'right'


                return (
                    <button class="p-row-toggler p-link" type="button" onClick={() => this.expanderCus(data['id']) }>
                        <span class={`p-row-toggler-icon pi pi-chevron-${icon}`}></span>
                        <span class="p-ink"></span>
                    </button>
                )
            }

           
        }

        if (props.field == "price" || props.field == 'indv_position') {

            if (data[props.field] == null) return ''

            let result = global.formatNumber(Math.floor(data[props.field]))
            return (

             
                <span style={props.field == 'indv_position' & this.props.color_position ? {color : 'blue'} : {} } >

                {result}
            </span>

            )

           
        }

        if(props.field == 'group'){

            var group = data[props.field]
            if(data['value'] && data['value'].length > 1){
                
                return <b  style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/portfolio?group=${group}`)}>{group}</b>
            }

            return (

                

                <span style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/portfolio?group=${group}`)} >

                    {group}
                </span>

            )
        }








    }

    expanderCus(id){

        let expandedRows = this.state.expandedRows

        if(expandedRows[id]){
            delete expandedRows[id]
        }else{
            expandedRows[id] = true
        }
        this.setState({
            expandedRows
        });
    }

    rowExpansionTemplate(data) {


        return (
            <div className="sub_table">
                <DataTable value={data.value} responsiveLayout="scroll">
                    <Column style={{ width: '3em' }} />
                    {/* <Column style={{ width: '13rem' }} field='highlight' body={(data, props) => this.representativeTemplate(data, props)} ></Column> */}
                    <Column field='index' header='Index' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='id' header='STK'  body={(data, props) => this.representativeTemplate(data, props)}   ></Column>
                    <Column field='name' header='Name'  ></Column>
                    <Column field='sell' header='Sell'  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell_order' header='Sell Order'   body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='indv_position' header='Position' body={(data, props) => this.representativeTemplate(data, props)}   ></Column>
                    {/* <Column field='type' header='Type'  ></Column> */}
                    <Column field='label' header='Label'  ></Column>
                    <Column field='icon' header='' body={(data, props) => this.representativeTemplate(data, props)} ></Column>





                </DataTable>
            </div>
        );





    }




    render() {
        const header = (
            <div style={{ textAlign: 'left'  }}>
                 {/* <h1 style={{textAlign : 'center' , background : '#f44f4f' , color : 'white'}}>Top Sell</h1> */}
                 <Banner text='Top Sell Group' icon='pi pi-star' sell={true} ></Banner>
                <MultiSelect value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} style={{ width: '20em' }} />

            </div>
        );
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });
        let footerGroup = (
            <ColumnGroup>
                <Row >
                    <Column colSpan={4}/>
                    <Column footer={global.formatNumber(Math.round(this.state.sellFooter ))} footerStyle={{ color: 'orange' }} />
                    <Column footer={global.formatNumber(Math.round(this.state.sellOrderFooter ))} footerStyle={{ color: 'orange' }} />
                </Row>
            </ColumnGroup>
        );
        return (
            <DataTable
                // rowClassName={(data) => this.rowClass(data)}
                className="p-datatable-customers" value={this.state.data}
                dataKey="id" 
                stripedRows
                emptyMessage="No data."
                lazy loading={this.state.loading}
                sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                onFilter={(event) => this.onFilter(event)}
                // onSort={(event) => this.onSort(event)}
                responsiveLayout="scroll"
                header={header}
                footerColumnGroup={footerGroup}

                expandedRows={this.state.expandedRows}
                onRowToggle={(e) => this.setState({ expandedRows: e.data }, () => console.log(this.state.expandedRows))}
                rowExpansionTemplate={(data) => this.rowExpansionTemplate(data)}

            >

                {columnComponents}
            </DataTable>


        )
    }
}

export default OneStockSellGroup;