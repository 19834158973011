export const domain_config = (version) => {
    let mode = localStorage.getItem('mode');
 
    if(!mode){
        mode = 'rz2'
    }

    if(mode == 'mx'){
        if(version == 1){
            return 'https://d11.f5traders.com/api'
        }

        if(version == 2){
            return 'https://d11.f5traders.com/api_v2'
        }
    }

    if(mode == 'rz2'){
        if(version == 1){
            return 'https://d11.f5traders.com/api_v3'
        }

        if(version == 2){
            return 'https://d11.f5traders.com/api_v4'
        }
    }

    if(mode == 'test'){
        if(version == 1){
            return localStorage.getItem('api_v1');
        }

        if(version == 2){
            return localStorage.getItem('api_v2');
        }
    }
    
}