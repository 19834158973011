import model from "../model";
import axios from "axios";
import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class CTCK {

    constructor() {
        // this.url = 'https://d11.f5traders.com/api_v2'

        this.url = domain_config(2)
        this.queryInfo = new QueryInfo()
    }
    get_list_ctck(filter) {

        if (global.data_ctck_list) {
            this.queryInfo.info({}, `${this.url}/db11-list-ctck`)
            return Promise.resolve(global.data_ctck_list)
        } else {
            return axios.request({
                url: `${this.url}/db11-list-ctck`,
                method: "POST",
                data: {}
            })

                .then(response => {
                    let datas = response['data']
                    global.data_ctck_list = datas;

                    return datas
                })

                .catch((error) => {

                    console.log(error);
                    return false;
                })

        }



    }


    get_ctck_netbs(filter) {
        let key = global.genKey(filter)
        if (global.data_ctck_netbs && global.data_ctck_netbs[key]) {
            this.queryInfo.info(filter, `${this.url}/db11-ctck-netbs`)
            return Promise.resolve(global.data_ctck_netbs[key])
        } else {
            return axios.request({
                url: `${this.url}/db11-ctck-netbs`,
                method: "POST",
                data: { ...filter }
            })

                .then(response => {
                    let datas = response['data']

                    if (!global.data_ctck_netbs) {
                        global.data_ctck_netbs = {}
                    }

                    global.data_ctck_netbs[key] = datas;

                    return datas
                })

                .catch((error) => {

                    console.log(error);
                    return false;
                })
        }
    }

}

export default CTCK;