import React, { Component } from 'react';
import TableDuy from '../components/table/TableDuy';

class Test extends Component {


    constructor(props) {
        super(props);
        this.structTableTest = {}
        this.structTableTest['struct_column'] = [
            {
                'col_field' : 'id',
                'col_header' : 'ID',
                'col_sort' : true,
                'col_decorator_in' : () => {},
                'col_style' : {}
            }, 
            {
                'col_field' : 'name',
                'col_header' : 'Name',
                'col_sort' : true,
                'col_decorator_in' : () => {},
                'col_style' : {}
            },

        ]
            
        
        

        this.structTableTest['struct_table'] = {
            'link_filter' : 'http://ws:5101/db11-endpoint-trans',
            'sortField': 'buy',
            'sortOrder': -1,
            'quantity' : 25,
            'filters'  : {}

        }
    }
    
    render() {
        return (
            <div>
                <TableDuy structTable={this.structTableTest}></TableDuy>
            </div>
        );
    }
}

export default Test;