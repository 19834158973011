import React, { Component } from 'react';
import CTCK from '../models/admin/CTCK';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
class CTCKView extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            { field: 'expander', header: '', body: true },
            { field: 'index', header: 'Index', body: true },
            { field: 'day', header: 'Day', body: false, sort: true },
            { field: 'ctck_name', header: 'CTCK Name', body: false, sort: true },
            // { field: 'stock', header: 'Stock', body: false, sort: false},

            { field: 'buyValue', header: 'Buy Value', body: true, sort: true },
            { field: 'sellValue', header: 'Sell Value', body: true, sort: true },
            { field: 'netValue', header: 'Net Value', body: true, sort: true },

        ];

        this.state = {
            ctck: '',
            optionsCtck: [],
            filters: {},

            data: [],
            loading: false,

            selectedColumns: [
                { field: 'expander', header: '', body: true },
                { field: 'index', header: 'Index', body: true },
                { field: 'day', header: 'Day', body: false, sort: true },
                { field: 'ctck_name', header: 'CTCK Name', body: false, sort: true },
                // { field: 'stock', header: 'Stock', body: false, sort: false},

                { field: 'buyValue', header: 'Buy Value', body: true, sort: true },
                { field: 'sellValue', header: 'Sell Value', body: true, sort: true },
                { field: 'netValue', header: 'Net Value', body: true, sort: true },

            ],
            expandedRows: {},
            foreignOnly: false,
            filter_td : false,
        }

        this.modeCTCK = new CTCK()
    }

    componentDidMount() {

        this.modeCTCK.get_list_ctck().then(res => {
            this.setState({
                optionsCtck: res
            });
        })

    }

    getData() {
        this.setState({ loading: true });
        let filter = {
            data_filter: Object.values(this.state.filters),
            foreignOnly: this.state.foreignOnly,
            filter_td : this.state.filter_td
        }

        this.modeCTCK.get_ctck_netbs(filter).then(res => {

            if (Object.keys(res).length == 0) {
                this.setState({
                    loading: false,
                    data: []
                });
                return
            }
            let groupObj = {}
            res.map((item, index) => {
                let group = item['day'] + item['ctck_name']

                if (groupObj[group]) {
                    groupObj[group]['value'].push(item)

                    Object.keys(item).map(col => {
                        if (col == 'buyValue' || col == 'sellValue' || col == 'netValue') {

                            groupObj[group][col] += Number(item[col])

                        }
                    })
                } else {
                    groupObj[group] = {
                        ...item, value: [item]
                    }
                    groupObj[group]['unique'] = `${index}ac`
                }

            })

            this.setState({
                data: Object.values(groupObj),
                loading: false,
                expandedRows: {}
            });
        })
    }

    change(col, value, filterkey, type) {
        var filter = this.state.filters;

        if (type == 'text') {

            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": value.trim()
                }
            }
        } else if (type == 'date') {
            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": moment(value).format("YYYY/MM/DD")
                }
            }


        }

        this.setState({
            [col]: value,
            filters: filter
        }, () => {

            this.getData()

        });
    }

    representativeTemplate = (data, props) => {

        if (props.field == "index") {

            return (
                <div >
                    <span>{props.rowIndex + 1}</span>
                </div>
            )
        }


        if (props.field == "expander") {

            if (data['value'] && data['value'].length > 1) {
                let icon = this.state.expandedRows[data['unique']] ? 'down' : 'right'

                return (
                    <button className="p-row-toggler p-link" type="button" onClick={() => this.expanderCus(`${data['unique']}`)}>
                        <span className={`p-row-toggler-icon pi pi-chevron-${icon}`}></span>
                        <span className="p-ink"></span>
                    </button>
                )
            }


        }


        let col = ['buyValue', 'sellValue', 'netValue']

        if (col.includes(props.field)) {


            let result = global.formatNumber(Math.round(data[props.field] * 100) / 100)

            return (
                <div >
                    <span>{result}</span>
                </div>
            )
        }







    }

    expanderCus(id) {

        let expandedRows = this.state.expandedRows

        if (expandedRows[id]) {
            delete expandedRows[id]
        } else {
            expandedRows[id] = true
        }
        this.setState({
            expandedRows
        });
    }

    rowExpansionTemplate(data) {

        return (
            <div className="sub_table">
                <DataTable value={data.value} responsiveLayout="scroll">
                    <Column style={{ width: '5em' }} />

                    <Column field='index' header='Index' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='stock' header='Stock'  ></Column>
                    <Column field='buyValue' header='Buy Value' body={(data, props) => this.representativeTemplate(data, props)}   ></Column>
                    <Column field='sellValue' header='Sell Value' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='netValue' header='Net Value' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>

                </DataTable>
            </div>
        );





    }

    render() {
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });
        return (
            <>
                <div className="p-col-12 p-md-12" >

                    <div className="card widget-table" >
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '20%', marginRight: '20px' }}>
                                <div style={{ paddingBottom: '20px', textAlign: 'center' }}>CTCK</div>
                                <div>
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        value={this.state.ctck}
                                        options={this.state.optionsCtck}
                                        onChange={(e) => this.change('ctck', e.value, 'ctck', 'text')}
                                        placeholder="Select a code" showClear
                                    />

                                </div>
                            </div>


                            <div style={{ width: '26%', marginRight: '20px' }}>
                                <div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <div style={{ textAlign: 'center', paddingBottom: '20px', cursor: 'pointer' }} >
                                            DATE

                                        </div>
                                        <div>
                                            <Calendar
                                                style={{ width: '100%' }}
                                                id="multiple"
                                                dateFormat="dd - M"
                                                value={this.state.day}
                                                onChange={(e) => this.change('day', e.value, 'day', 'date')}
                                                readOnlyInput showButtonBar
                                            />
                                        </div>



                                    </div>






                                </div>
                            </div>

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {Object.keys(this.state.filters).length} filter : {
                                    Object.keys(this.state.filters).map(item => {
                                        let value1 = this.state.filters[item]['value']
                                        // if (item == 'day') {
                                        //     value1 = value1.map(item => moment(item).format('DD - MMM'))

                                        // }
                                        return (
                                            <b onClick={() => this.clearFilter(this.state.filters[item])} style={{ cursor: 'pointer' }}>
                                                <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                                {`     ${this.state.filters[item]['operator']} '${item}' `}    |
                                            </b>)
                                    }

                                    )
                                }

                                {
                                    Object.keys(this.state.filters).length > 0 && <Button onClick={() => this.clearFilter()} style={{ position: 'relative', top: '3px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                                }
                            </div>

                            <div style={{ display: 'flex ', alignItems: 'center' }}>

                                <div style={{ display: 'flex' }}>

                                    <InputSwitch checked={this.state.filter_td} onChange={(e) => this.setState({ filter_td: e.value }, () => this.getData())} />
                                    <span style={{ marginLeft: '10px', marginRight : '10px' }}> Lọc tự doanh</span>

                                    <InputSwitch checked={this.state.foreignOnly} onChange={(e) => this.setState({ foreignOnly: e.value }, () => this.getData())} />
                                    <span style={{ marginLeft: '10px' }}> Foreign Only</span>
                                </div>
                            </div>




                        </div>
                    </div>

                    <div>
                        <DataTable

                            className="p-datatable-customers"
                            value={this.state.data}
                            dataKey="unique"
                            stripedRows

                            loading={this.state.loading}
                            responsiveLayout="scroll"
                            sortField={'day'}
                            sortOrder={-1}


                            expandedRows={this.state.expandedRows}
                            onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                            rowExpansionTemplate={(data) => this.rowExpansionTemplate(data)}
                        >
                            {columnComponents}
                        </DataTable>
                    </div>






                </div>
            </>
        );
    }
}

export default CTCKView;