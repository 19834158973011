import React, { Component } from 'react';
import TableAnalysis from '../components/analysis/TableAnalysis';
import TranCode from '../models/admin/TranCode';
import Transaction from '../models/admin/Transaction';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import User from '../models/admin/User';
import ExtraApi from '../models/admin/ExtraApi';
import moment from 'moment';
class AnalysisView extends Component {
    constructor(props) {
        super(props);
        this.model = new TranCode()
        this.tranModel = new Transaction()
        this.userModel = new User()

        this.extraApi = new ExtraApi()

        this.state = {
            account: null,
            optionAccount: [],

            selectedCountries: null,
            filteredAccount: null,
            chartTran: null,
            tickerOptions: [],
            filters: {},

        }
    }
    handleAccount(res) {
        let parsed = global.parsed()
        let idDefault
        let stockDefault
        if (parsed['id']) {
            idDefault = parsed['id']
            stockDefault = parsed['stock']
        } else {
            idDefault = res['account'][0]
            stockDefault = res['stock'][0]
        }

        let idTepm = {}

        res['account'].map((item, index) => {
            if (!idTepm[item]) {
                idTepm[item] = res['name'][index]
            }
        })

        let optionAccount = []
        let account = []
        Object.keys(idTepm).map(id => {
            if (id == idDefault) {
                account.push({
                    name: `${idTepm[id]} - ${id}`,
                    code: id
                })
            }
            optionAccount.push({
                name: `${idTepm[id]} - ${id}`,
                code: id
            })
        })

        this.setState({
            optionAccount,
            account,
            data: res,
            tickerValue: [{ name: stockDefault, code: stockDefault }],
            filters : {
                ticker :  {
                    "col_name": 'ticker',
                    "operator": "in",
                    "value": [stockDefault]
                }
            }
        }, () => this.handleData(idDefault));
    }
    handleData(aName) {
        // debugger
        let res = this.state.data
        let chart = {}
        this.state.account.map(ac => {

            let out = {}
            res['account'].map((item, index) => {
                if (item == ac['code']) {
                    let day = res['day'][index]
                    if (!out[day]) {
                        out[day] = {
                            'buy': 0,
                            'sell': 0,
                        }
                    }
                    out[day]['buy'] += res['buy'][index]
                    out[day]['sell'] += res['sell'][index]
                }
            })
            if (ac['code'] == aName) {
                this.TableAnalysis.setData(out, ac['name'])
            }
            let day = []
            let value = []

            let dayArr = Object.keys(out)
            dayArr.sort()
            dayArr.map(item => {
                day.push(item)
                value.push(Number(out[item]['buy']) - Number(out[item]['sell']))
            })
            const cumulativeSum = (sum => value => sum += value)(0);

            value = value.map(cumulativeSum)

            chart[ac['code']] = {
                day, value, name: ac['name']
            }

        })
        if (this.state.chartTran) {

            chart = Object.assign({}, chart, this.state.chartTran);
        }
        this.renderChart(Object.values(chart))

    }

    componentDidMount() {
        this.model.getAnalysis().then(res => {
            this.handleAccount(res)
        })

        this.getQuerry()

    }


    async renderChart(chart) {
        let tickText = []

        var data = []

        var dayArr = []


        chart.map(item => {

            let text = []
            item['day'].map((d, index) => {
                text.push(`<b>name = ${item['name']}</b>   </br><b>day = ${d}</b> </br><b>cumsum = ${global.formatNumber(item['value'][index])}</b> `)

            })

            dayArr = dayArr.concat(item['day'])

            data.push({
                x: item['day'],
                y: item['value'],
                type: 'scatter',
                name: item['name'],
                text: text,
                hovertemplate: `<br>%{text}<br>`,
            })
        })
        let dayTick = [...new Set(dayArr)].sort();
        if (this.state.tickerValue != undefined && this.state.tickerValue.length > 0) {
            let vn30 = await this.extraApi.getDataByStock("VN30", "1D", moment(dayTick[0], 'YYYY_MM_DD').format('X'), moment(dayTick[dayTick.length - 1], 'YYYY_MM_DD').format('X'))
            vn30['t'] = vn30['t'].map(time => moment(time, 'X').format('YYYY_MM_DD'))
            data.push({
                name: 'vn30',
                x: vn30['t'],
                y: vn30['c'],
                mode: 'lines',
                xaxis: 'x',
                yaxis: 'y3',
            })

            for (let index = 0; index < this.state.tickerValue.length; index++) {
                const element = this.state.tickerValue[index];

                let kline = await this.extraApi.getDataByStock(element['code'], "1D", moment(dayTick[0], 'YYYY_MM_DD').format('X'), moment(dayTick[dayTick.length - 1], 'YYYY_MM_DD').format('X'))

                kline['t'] = kline['t'].map(time => moment(time, 'X').format('YYYY_MM_DD'))

                data.push({
                    name: element['code'],
                    x: kline['t'],
                    y: kline['c'],
                    mode: 'lines',
                    xaxis: 'x',
                    yaxis: 'y2',
                })

                // data.push({
                //     x: kline['t'],
                //     close: kline['c'],
                //     high: kline['h'],
                //     low: kline['l'],
                //     open: kline['o'],
                //     // text: kline['day'],

                //     type: 'candlestick',
                //     xaxis: 'x',
                //     yaxis: 'y2',
                //     name: element['code'],
                // })

            }


        }

        dayTick.map((item, index) => {
            if (index % 5 == 0) {
                tickText.push(item)
            }
        })



        var layout = {

            height: 800,
            showlegend: true,
            legend: {
                x: 1,
                xanchor: 'right',
                y: 1
            },
            margin: { b: 100, t: 40, l: 80, r: 80 },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikethickness: 2,
                tickmode: "array",
                tickvals: tickText,
                ticktext: tickText,

                showticklabels: true,
                tickangle: 'auto',
                showexponent: 'all'
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikethickness: 2,
                // domain: [0.42, 1]
            },
            shapes: [
                {
                    type: 'line',
                    xref: 'x',
                    yref: 'paper',
                    x0: 0,
                    y0: 0,
                    x1: 0,
                    y1: 0,
                    line: {
                        color: 'black',
                        width: 1,
                        dash: 'dot'
                    }
                }
            ],

            // yaxis2: { domain: [0, 0.4] },
            // yaxis3: {
            //     overlaying: 'y2',
            //     side: 'right',
            // },
            // grid: {
            //     rows: 2,
            //     columns: 1,
            //     subplots: [['xy'], ['xy2']],
            //     roworder: 'top to bottom',
            // }







        };

        if (this.state.tickerValue != undefined && this.state.tickerValue.length > 0) {
            layout['yaxis']['domain'] = [0.42, 1]
            layout['yaxis2'] = { domain: [0, 0.4] }
            layout['yaxis3'] = {
                overlaying: 'y2',
                side: 'right',
            }
            layout['grid'] = {
                rows: 2,
                columns: 1,
                subplots: [['xy'], ['xy2']],
                roworder: 'top to bottom',
            }

        }

        global.Plotly.newPlot(this.chart, data, layout, { scrollZoom: true });
    }
    addAccount(value) {
        this.setState({
            account: value
        }, () => this.handleData());
    }


    searchAccount(value) {
        let out = [...this.state.optionAccount]
        out = out.filter(item => item['name'].toLowerCase().includes(value.toLowerCase()))

        this.setState({
            filteredAccount: out
        });

    }

    async searchSuggest(col, data) {
        if (data.length == 1) return
        if (data[data.length - 1] == ' ') {
            const collection = document.getElementsByClassName("p-autocomplete-panel p-component p-connected-overlay-enter-done")[0];
            if (collection) {
                return
            }
        }
        data = data.trim()
        let struct = {
            "column": col,
            "data": data
        }
        let result = await this.tranModel.suggestion(struct)
        let out = [];
        for (let index = 0; index < 10; index++) {
            const item = result[index];
            out.push({
                name: item,
                code: item
            })

        }
        this.setState({
            nameSuggest: out,
        });


    }
    change(value, col, filterkey, type) {
        
        var filter = this.state.filters;
        if (type == 'account_multi') {
            if (value.length == 0) {
                delete filter[filterkey];
            } else {
                // let dataMul = []
                value.map(item => {
                    // dataMul.push(item['code'])
                    let name = item['name']
                    let check = name.indexOf('(Σ:')
                    if (check > -1) {
                        name = name.substring(0, check)
                        name = name.trim()
                    }

                    item['name'] = name
                    item['code'] = name
                })
                filter[filterkey] = value
            }
        
        }
        if (type == 'ticker_multi') {
            if (value.length == 0) {
                delete filter[filterkey];
            } else {
                let dataMul = []
                value.map(item => {
                    dataMul.push(item['code'])
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dataMul
                }
            }
        
        }



        this.setState({
            [col]: value,
            filters: filter,
        }, () => this.getOneUser());


    }

    async getOneUser() {
        if (this.state.nameValue == undefined || this.state.nameValue.length == 0) {
            this.setState({
                chartTran: null
            }, () => this.handleData());
        } else {
            let chart = {}

            for (let index = 0; index < this.state.filters['name'].length; index++) {
                const ac = this.state.filters['name'][index];
                let filter = {
                    data_filter: [
                        {
                            "col_name": 'name',
                            "operator": "in",
                            "value": ac['code']
                        },
                    ]
                    
                }
                if(this.state.filters['ticker']){
                    filter['data_filter'].push(this.state.filters['ticker'])

                }

                let res = await this.userModel.OneUser(filter)

                let out = {}
                res['account'].map((item, index) => {

                    let day = res['day'][index]
                    if (!out[day]) {
                        out[day] = {
                            'buy': 0,
                            'sell': 0,
                        }
                    }
                    out[day]['buy'] += res['buy'][index]
                    out[day]['sell'] += res['sell'][index]

                })
                let day = []
                let value = []

                let dayArr = Object.keys(out)
                dayArr.sort()
                dayArr.map(item => {
                    day.push(item)
                    value.push(Number(out[item]['buy']) - Number(out[item]['sell']))
                })
                const cumulativeSum = (sum => value => sum += value)(0);

                value = value.map(cumulativeSum)

                chart[ac['code']] = {
                    day, value, name: ac['name']
                }

                

            }

          

            this.setState({
                chartTran: chart
            }, () => this.handleData());


        }

    }

    getQuerry() {
        this.tranModel.query().then(response => {
            let tickerOptions = []
            response['categories']['stock'].map(item => {
                tickerOptions.push({ name: item, code: item },)
            })

            this.setState({
                tickerOptions: tickerOptions,


            });
        }).catch((error) => {
            console.log(error);
        })
    }


    render() {
        return (
            <>

                <div style={{ display: 'flex' }}>
                    <div style={{ width: '80%' }} ref={c => this.chart = c}>

                    </div>

                    <div className="card" style={{ width: '20%', marginLeft: '25px', marginBottom: '0px' }} >
                        <div style={{ marginBottom: '20px' }}>
                            <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH ACCOUNT INSIDERS</div>
                            <AutoComplete
                                className='auto_mul_cus'
                                value={this.state.account}
                                suggestions={this.state.filteredAccount}
                                completeMethod={(e) => this.searchAccount(e.query)}
                                field="name"
                                multiple
                                onChange={(e) => this.addAccount(e.value)}
                                style={{ width: '100%' }}
                            />
                        </div>

                        <div className='card' style={{ background: 'whitesmoke' }}>
                            <div style={{ marginBottom: '20px' }}>
                                <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH ACCOUNT TRANSACTIONS</div>
                                <div>
                                    <AutoComplete
                                        className='auto_mul_cus'
                                        ref={c => this.namAuto = c}
                                        value={this.state.nameValue}
                                        suggestions={this.state.nameSuggest}
                                        completeMethod={(e) => this.searchSuggest('name', e.query)}
                                        field="name"
                                        onChange={(e) => this.change(e.value, 'nameValue', 'name', 'account_multi')}
                                        multiple
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>

                            <div style={{ marginBottom: '20px' }}>
                                <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY TICKER </div>
                                <div>
                                    <MultiSelect style={{ width: '100%' }} value={this.state.tickerValue} onChange={(e) => this.change(e.value, 'tickerValue', 'ticker', 'ticker_multi')} options={this.state.tickerOptions} optionLabel="name" display="chip" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <TableAnalysis ref={c => this.TableAnalysis = c}></TableAnalysis>
            </>
        );
    }
}

export default AnalysisView;