import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
class TableAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : []
        }
    }

    setData(data , name){
        let reuslt = []
        Object.keys(data).map(day => {
            reuslt.push({
                'name' : name,
                'day': day,
                'buy' :  data[day]['buy'],
                'sell' :  data[day]['sell'],
            })
        })
        this.setState({
            data : reuslt
        });
    }

    representativeTemplate = (data, props) => {
      
        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }

        if (props.field == "buy" || props.field == "sell") {
            if (data[props.field] == null) return 'NULL'

            let result = global.formatNumber(data[props.field])
            if (result == 0) result = '-'

           

            return (
                <span >{result}</span>
            )
        }

        if (props.field == "day") {
            let reuslt = data[props.field].replaceAll('_', '/')
            reuslt = moment(reuslt).format('DD - MMM')
            return (
                <span >{reuslt}</span>
            )
        }

       

      


    }
    
    render() {
        return (
            <div className='mt-3'>
                 <div className="card">
                    <DataTable 
                        className="p-datatable-customers"
                        value={this.state.data}
                        stripedRows
                        paginator responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rows={25} rowsPerPageOptions={[25, 50]}
                        sortField="day" sortOrder={-1}
                    >
                        <Column field="index" header="Index" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="name" header="Name" ></Column>
                        <Column field="day" header="Day" sortable body={(data, props) => this.representativeTemplate(data, props)}></Column>
                        <Column field="buy" header="Buy" sortable  body={(data, props) => this.representativeTemplate(data, props)}></Column>
                        <Column field="sell" header="Sell" sortable body={(data, props) => this.representativeTemplate(data, props)}></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default TableAnalysis;