import React, { Component } from 'react';

class DailyVn30View extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }
    render() {
        return (

            <div style={{ width: '100%', height: '95vh' }}>
                <iframe
                    src="https://d11.f5traders.com/daily_vn30"
                    style={{ width: '100%', height: '100%', border: 'none' }}
                />
            </div>
        
        );
    }
}

export default DailyVn30View;