import model from "../model";
import axios from "axios";
import { domain_config } from "../../utilities/Domain";
class QueryInfo {
	constructor() {
		// this.url = 'https://d11.f5traders.com/api'
        this.url = domain_config(1)
	}

	info(filter ,url) {
        filter['url'] = url
		return axios.request({
            url: `${this.url}/db11-data-url`,
            method: "POST",
            data : {...filter}
        })

        
	}






}

export default QueryInfo;