import React, { Component } from 'react';

class ChartNetBuySell extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading : false
        }
    }

    load_data(dataNetBuySell , dataKline, stock ) {
  
        if(Object.keys(dataNetBuySell).length > 0){
        
            let data_chart = []
           
            dataNetBuySell['day']=  dataNetBuySell['day'].map(date =>   {
              
                if(typeof(date) == 'number'){
             
                    return String(date).substring(0, 4) + '-' +  String(date).substring(4, 6) + '-' +  String(date).substring(6, 8)
                }
                return date
                
            });
  
        
            var nemo = {
                x: dataNetBuySell['day'],
                y: dataNetBuySell['nemo'],
                name: 'nemo',
                type: 'bar',
                marker: {
                    color: '#4e99d3'
                  }
            };
            var shark = {
                x: dataNetBuySell['day'],
                y: dataNetBuySell['shark'],
                name: 'shark',
                type: 'bar',
                marker: {
                    color: '#ff7800'
                  }
        
            };
            var sword = {
                x: dataNetBuySell['day'],
                y: dataNetBuySell['sword'],
                name: 'sword',
                type: 'bar',
                marker: {
                    color: '#18af18'
                  }
            };
            var tuna = {
                x: dataNetBuySell['day'],
                y: dataNetBuySell['tuna'],
                name: 'tuna',
                type: 'bar',
                marker: {
                    color: '#dc2c2c'
                  }
            };
            var whale = {
                x: dataNetBuySell['day'],
                y: dataNetBuySell['whale'],
                name: 'whale',
                type: 'bar',
                marker: {
                    color: '#a484c2'
                  }
            };

            let candlestick_data = {
                x: dataNetBuySell['day'],
                close: dataKline['close'],
                high: dataKline['high'],
                low: dataKline['low'],
                open: dataKline['open'],
                type: 'candlestick',
                xaxis: 'x',
                yaxis: 'y2',
                name : stock,
            }




            data_chart = [whale,shark,sword,tuna,nemo,candlestick_data]
           
    
    
            var layout = {
                title: 'Net Buy Sell',
                barmode: 'relative',

                height: 800,
                showlegend: true,
                margin: { b: 100, t: 80, l: 130, r: 0 },
                hovermode: "x unified",
       
                xaxis: {
                  autorange: true,
                  type: "date",
                  categoryorder: 'category ascending',
                  rangeslider: { 'visible': false },
                  showspikes: true,
                  spikemode: 'across',
                  spikesnap: 'cursor',
                  spikedash: 'dot',
                  spikethickness: 2,
                  showticklabels: true,
                  tickangle: 'auto',
                  showexponent: 'all',
                //   tickmode: "array",
                //   tickvals: tickvals,
                //   ticktext: tickvals,
                },
                yaxis: {
                  autorange: true,
                  showspikes: true,
                  spikemode: 'across',
                  spikesnap: 'cursor',
                  spikedash: 'dot',
                  spikethickness: 2,
                  domain: [0.42, 1],
                  hovermode: 'x',
                },
                yaxis2: { domain: [0, 0.4] },
                grid: {
                  rows: 2,
                  columns: 1,
                  subplots: [['xy'], ['xy2']],
                  roworder: 'top to bottom',
                  hovermode: 'x',
                },
                tooltip: 'none'
               
            };
            var config = { responsive: true }
            global.Plotly.newPlot(this.chart, data_chart, layout, config);
        }else{
            global.Plotly.newPlot(this.chart, []);
        }
     
    

        this.setState({
            loading : false
        });
    }
    
    render() {
        return (
            <div style={{ margin: '10px 0px' }}>
                <div style={{ width: '99%' }} ref={c => this.chart = c}>

                </div>
            </div>
        );
    }
}

export default ChartNetBuySell;