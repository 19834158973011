// import React, { Component } from 'react';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import moment from 'moment';
// class TableAnalysis extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             data: []
//         }
//     }

//     setData(data) {
//         this.setState({
//             data
//         });
//     }

//     representativeTemplate = (data, props) => {

//         if (props.field == "index") {

//             return (
//                 <span>{props.rowIndex + 1}</span>
//             )
//         }

//         if (props.field == "buy" || props.field == "sell" || props.field == 'adjEodPrice' || props.field == 'adjAvgCost') {
//             if (data[props.field] == null) return 'NULL'

//             let result = global.formatNumber( Math.round(data[props.field]*100)/100 )
//             if (result == 0) result = '-'



//             return (
//                 <span >{result}</span>
//             )
//         }

//         if (props.field == "day") {
//             let reuslt = data[props.field].toString()

//             let a = [reuslt.slice(0, 4), '_', reuslt.slice(4)].join('');
//             a = [a.slice(0, 7), '_', a.slice(7)].join('');
//             return a
//         }



//         let a = ['portValue' , 'pnl' , 'pnl%' , 'cash' , 'adjPosition']

//         if(a.includes(props.field)){
//             let reuslt = global.formatNumber(Math.round(data[props.field]*100)/100) 

//             return reuslt
//         }






//     }

//     render() {
//         return (
//             <div className='mt-3'>
//                 <div className="card">
//                     <DataTable
//                         className="p-datatable-customers"
//                         value={this.state.data}
//                         stripedRows
//                         paginator responsiveLayout="scroll"
//                         paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
//                         rows={25} rowsPerPageOptions={[25, 50]}
//                         sortField="day" sortOrder={-1}
//                         // filterDisplay="row"
//                     >
//                         <Column field="index" header="Index" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
//                         <Column field="stock" header="Stock" ></Column>
//                         <Column field="day" header="Day" sortable body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
//                         <Column field="buy" header="Buy" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
//                         <Column field="sell" header="Sell" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
//                         <Column field="adjEodPrice" header="Adj Price"  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
//                         <Column field="adjAvgCost" header="Adj Cost"  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
//                         <Column field="portValue" header="Port Value" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
//                         <Column field="pnl" header="PNL" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
//                         <Column field="pnl%" header="PNL %"  body={(data, props) => this.representativeTemplate(data, props)}></Column>
                    
//                         <Column field="cash" header="Cash" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
//                         <Column field="adjPosition" header="Adj Position" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
               
//                     </DataTable>
//                 </div>
//             </div>
//         );
//     }
// }

// export default TableAnalysis;

import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { MultiSelect } from 'primereact/multiselect';
class TableAnalysis extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            { field: 'index', header: 'Index', body: true },
            { field: 'stk', header: 'STK' },
            { field: 'stock', header: 'Stock' },
            { field: 'day', header: 'Day', body: true, sort: true},

            { field: 'buy', header: 'Buy', body: true, sort: true },
            { field: 'sell', header: 'Sell', body: true, sort: true },
            { field: 'adjEodPrice', header: 'Adj Price', body: true },
            { field: 'adjAvgCost', header: 'Adj Cost', body: true },
            { field: 'portValue', header: 'Port Value', body: true },
            { field: 'pnl', header: 'PNL', body: true },
            { field: 'pnl%', header: 'PNL %', body: true },
            { field: 'cash', header: 'Cash', body: true },
            { field: 'adjPosition', header: 'Adj Position', body: true },


            { field: 'adjBuy', header: 'Adj Buy' , body: true},
            { field: 'adjBuyPrice', header: 'Adj Buy Price' , body: true},
            { field: 'adjEquityFlow', header: 'Adj Equity Flow' , body: true},
            { field: 'adjEquityInjection', header: 'Adj Equity Injection' , body: true},
            { field: 'adjSellPrice', header: 'Adj Sell Price' , body: true},
            { field: 'avgCost', header: 'Avg Cost' , body: true},
            { field: 'buyPrice', header: 'Buy Price' , body: true},
            { field: 'cash2', header: 'Cash2' , body: true},
            { field: 'cashFlow', header: 'CashFlow' , body: true},
            { field: 'cashInjection', header: 'Cash Injection' , body: true},
            { field: 'eodPrice', header: 'Eod Price' , body: true},
            { field: 'equityFlow', header: 'Equity Flow' , body: true},
            { field: 'equityValue', header: 'Equity Value' , body: true},
            { field: 'isPT', header: 'isPT' , body: true},
            { field: 'numDays', header: 'Number Days' , body: true},
            { field: 'percentile', header: 'Percentile' , body: true},
            { field: 'position', header: 'Position' , body: true},
            { field: 'positionValue', header: 'Position Value' , body: true},
            { field: 'priceCount', header: 'Price Count' , body: true},
            { field: 'sellPrice', header: 'Sell Price' , body: true},
            { field: 'split', header: 'Split' , body: true},
            { field: 'valBuy', header: 'Val Buy' , body: true},
            { field: 'valSell', header: 'Val Sell' , body: true},

            
          


        ];

      
        this.state = {
            data: [],

            selectedColumns: [
                { field: 'index', header: 'Index', body: true },
                { field: 'stock', header: 'Stock' },
                { field: 'day', header: 'Day', body: true, sort: true},
    
                { field: 'buy', header: 'Buy', body: true, sort: true },
                { field: 'sell', header: 'Sell', body: true, sort: true },
                { field: 'adjEodPrice', header: 'Adj Price', body: true },
                { field: 'adjAvgCost', header: 'Adj Cost', body: true },
                { field: 'portValue', header: 'Port Value', body: true },
                { field: 'pnl', header: 'PNL', body: true },
                { field: 'pnl%', header: 'PNL %', body: true },
                { field: 'cash', header: 'Cash', body: true },
                { field: 'adjPosition', header: 'Adj Position', body: true },

            ],
        }
    }

    setData(data) {
        console.log(data)
        this.setState({
            data
        });
    }

    representativeTemplate = (data, props) => {

        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }

        if (props.field == "buy" || props.field == "sell" || props.field == 'adjEodPrice' || props.field == 'adjAvgCost') {
            if (data[props.field] == null) return 'NULL'

            let result = global.formatNumber( Math.round(data[props.field]*100)/100 )
            if (result == 0) result = '-'



            return (
                <span >{result}</span>
            )
        }

        if (props.field == "day") {
            let reuslt = data[props.field].toString()

            let a = [reuslt.slice(0, 4), '_', reuslt.slice(4)].join('');
            a = [a.slice(0, 7), '_', a.slice(7)].join('');
            return a
        }



        let a = ['portValue' , 'pnl' , 'pnl%' , 'cash' , 'adjPosition']

        if(a.includes(props.field)){
            let reuslt = global.formatNumber(Math.round(data[props.field]*100)/100) 

            return reuslt
        }


      
 



        let b = ['adjBuy' , 'adjBuyPrice' , 'adjEquityFlow' , 'adjEquityInjection' , 'adjSellPrice',
        'avgCost' , 'buyPrice' , 'cash2' , 'cashFlow' , 'cashInjection',
        'eodPrice' , 'equityFlow' , 'isPT' , 'numDays' , 'percentile',
        'position' , 'positionValue' , 'priceCount' , 'sellPrice' , 'split',
        'valBuy' , 'valSell'
    ]

        if(b.includes(props.field)){
            let reuslt = global.formatNumber(Math.round(data[props.field]*100)/100) 

            return reuslt
        }







    }

    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }

    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                <MultiSelect value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} style={{ width: '20em' }} />

            </div>
        );
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });
        return (
            <div className='mt-3'>
                <div className="card">
                    <DataTable
                        className="p-datatable-customers"
                        value={this.state.data}
                        stripedRows
                        paginator responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rows={25} rowsPerPageOptions={[25, 50]}
                        sortField="day" sortOrder={-1}
                        header={header}
                    >
     
                        {columnComponents}
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default TableAnalysis;