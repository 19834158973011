import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';
import TransactionModel from '../models/admin/Transaction';
import TableBuy from '../components/Classify/TableBuy';
import TableSell from '../components/Classify/TableSell';
import ChartBuy from '../components/Classify/ChartBuy';
import ChartSell from '../components/Classify/ChartSell';
import Classify from '../models/admin/Classify';
import { InputSwitch } from 'primereact/inputswitch';
import ChartNetBuySell from '../components/Classify/ChartNetBuySell';
class ClassifyView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: {},
            symbol: 'HPG',
            optionsSymbol: [],
            classify: 'Asset',
            optionsClassify: ['Asset', 'Quantile'],
            valueDay: null,
            arbit : true
            // MA: "0",
            // optionMA: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
        };

        this.model = new Classify()


        this.loadLazyTimeout = null;


        this.DayOpt = [
            {
                'name': '1w',
                'day': 1,
                'type': 'weeks'
            },
            {
                'name': '2w',
                'day': 2,
                'type': 'weeks'
            },
            {
                'name': '1m',
                'day': 1,
                'type': 'months'
            },
            {
                'name': '2m',
                'day': 2,
                'type': 'months'
            },
            {
                'name': '3m',
                'day': 3,
                'type': 'months'
            },
        ]

        this.DayOpt2 = [
            {
                'name': '1D',
                'day': 1,
                'type': 'days'
            },
            {
                'name': '2D',
                'day': 2,
                'type': 'days'
            },
            {
                'name': '3D',
                'day': 3,
                'type': 'days'
            },
            {
                'name': '1W',
                'day': 1,
                'type': 'weeks'
            },
        ]
    }
    componentDidMount() {
        this.getMaxday()
    }
    getMaxday() {
        let model = new TransactionModel()
        model.query().then(res => {
            let stock = res['categories']['stock']
            stock = stock.push('All')

            this.setState({
                valueDay: [new Date(res['max_day'])],
                filters: {
                    'classify': {
                        "col_name": "classify",
                        "operator": "in",
                        "value": this.state.classify
                    },
                    'symbol': {
                        "col_name": "symbol",
                        "operator": "in",
                        "value": this.state.symbol
                    },
                    'day': {
                        "col_name": 'day',
                        "operator": 'in',
                        "value": [res['max_day']]
                    },
                    // 'MA': {
                    //     "col_name": 'MA',
                    //     "operator": 'in',
                    //     "value": this.state.MA
                    // },

                },
                maxDay: res['max_day'],
                optionsSymbol: res['categories']['stock']


            }, () => this.getData());
        })
    }
    getData() {

        clearTimeout(this.loadLazyTimeout);
        this.loadLazyTimeout = setTimeout(() => {


            this.TableBuy.setLoading()
            this.TableSell.setLoading()




            let filter = {
                data_filter: Object.values(this.state.filters),
                arbit: this.state.arbit,
            }


            this.model.getData(filter).then(response => {



                this.TableBuy.setData(response['buy_table'])
                this.TableSell.setData(response['sell_table'])

                // this.ChartBuy.load_data(response['net_chart'])
                // this.ChartSell.load_data(response['vn30_chart'], this.state.symbol)

                this.ChartNetBuySell.load_data(response['net_chart'],response['vn30_chart'], this.state.symbol)


            })
                .catch((error) => {

                    console.log(error)
                    this.TableBuy.setData([])
                    this.TableSell.setData([])

                })
        }, 500);


    }

    change(col, value, filterkey, type) {
        var filter = this.state.filters;
        let isFilter = true
        if (type == 'text') {

            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": value.trim()
                }
            }
        } else if (type == 'date') {

            this.setState({
                [col]: value,
            });
            if (col == 'valueDay') {
                if (value == null) {
                    delete filter[filterkey];

                } else {
                    let dateData = []
                    value.map(item => {
                        dateData.push(moment(item).format("YYYY/MM/DD"))
                    })
                    filter[filterkey] = {
                        "col_name": filterkey,
                        "operator": "in",
                        "value": dateData,
                    }
                    this.setState({
                        fromDay: null,
                        endDay: null,
                    })


                }

                this.setState({
                    [col]: value,
                    filters: filter,
                }, () => this.getData())

                return
            }

            if (col == 'fromDay') {
                // debugger
                if (this.state.endDay !== null && value !== null) {
                    filter[filterkey] = {
                        "col_name": filterkey,
                        "operator": "from",
                        "value": [moment(value).format("YYYY/MM/DD"), moment(this.state.endDay).format("YYYY/MM/DD")],
                    }
                    this.setState({
                        filters: filter,
                        valueDay: null
                    }, () => {
                        this.getData()
                    });
                }
                return

            }

            if (col == 'endDay') {
                if (this.state.fromDay !== null && value !== null) {
                    filter[filterkey] = {
                        "col_name": filterkey,
                        "operator": "from",
                        "value": [moment(this.state.fromDay).format("YYYY/MM/DD"), moment(value).format("YYYY/MM/DD")],
                    }
                    this.setState({
                        filters: filter,
                        valueDay: null
                    }, () => {
                        this.getData()
                    });
                }
                return
            }

        }

        this.setState({
            [col]: value,
            filters: filter
        }, () => {
            if (isFilter) {
                this.getData()
            }
        });
    }

    genDay(opt) {

        let endDay = this.state.maxDay
        let startDay = moment(this.state.maxDay).subtract(opt['day'], opt['type']).format('YYYY/MM/DD');

        var filter = this.state.filters;

        filter['day'] = {
            "col_name": 'day',
            "operator": "from",
            "value": [startDay, endDay],
        }


        this.setState({
            fromDay: new Date(startDay),
            endDay: new Date(endDay),
            filters: filter,
            valueDay: null

        }, () => this.getData());

    }

    genDay2(opt) {
        let endDay = this.state.maxDay
        let startDay = moment(this.state.maxDay).subtract(opt['day'], opt['type']).format('YYYY/MM/DD');

        let out = []

        for (let index = Number(moment(startDay).format('X')); index <= Number(moment(endDay).format('X')); index = index + 86400) {
            out.push(moment(index, 'X').format('YYYY/MM/DD'))

        }



        var filter = this.state.filters;

        filter['day'] = {
            "col_name": 'day',
            "operator": "in",
            "value": out,
        }

        let valueDay = out.map(item => new Date(item))
        this.setState({
            valueDay: valueDay,
            filters: filter,
            fromDay: null,
            endDay: null,

        }, () => this.getData());

    }

    clearFilter(col) {
        if (Object.keys(this.state.filters).length == 0) return
        if (col) {
            if (col['col_name'] != 'day') {
                var filter = this.state.filters;
                delete filter[col['col_name']];
                this.setState({
                    filters: filter,
                }, () => this.getData());
                return
            } else {
                if (col['operator'] == 'in') {
                    this.setState({
                        'valueDay': null
                    });

                } else {
                    this.setState({
                        'fromDay': null,
                        'endDay': null,
                    });
                }

                var filter = this.state.filters;
                delete filter[col['col_name']];
                this.setState({
                    filters: filter,
                }, () => this.getData());
                return
            }

        } else {
            this.setState({
                mode: true,
                valueDay: null,
                fromDay: null,
                endDay: null,
                filters: {},
                symbol: null,
            }, () => this.getData());
        }

    }
    render() {
        return (


            <>

                <div className="p-col-12 p-md-12" >

                    <div className="card widget-table" >
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '20%', marginRight: '20px' }}>
                                <div style={{ paddingBottom: '20px', textAlign: 'center' }}>CLASSIFY BY</div>
                                <div>
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        value={this.state.classify}
                                        options={this.state.optionsClassify}
                                        onChange={(e) => this.change('classify', e.value, 'classify', 'text')}
                                        placeholder="Select a code" showClear
                                    />

                                </div>
                            </div>

                            <div style={{ width: '20%', marginRight: '20px' }}>
                                <div style={{ paddingBottom: '20px', textAlign: 'center' }}>SYMBOL </div>
                                <div>
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        value={this.state.symbol}
                                        options={this.state.optionsSymbol}
                                        onChange={(e) => this.change('symbol', e.value, 'symbol', 'text')}
                                        placeholder="Select a code"
                                        showClear />

                                </div>
                            </div>
                            <div style={{ width: '26%', marginRight: '20px' }}>
                                <div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <div style={{ textAlign: 'center', paddingBottom: '20px', cursor: 'pointer' }} >
                                            DATE

                                        </div>
                                        <div>
                                            <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.valueDay} onChange={(e) => this.change('valueDay', e.value, 'day', 'date')} selectionMode="multiple" readOnlyInput showButtonBar />
                                        </div>


                                        <div style={{ marginTop: '10px' }}>
                                            {
                                                this.DayOpt2.map(item => {

                                                    return (
                                                        <Button key={item['name']} label={item['name']} style={{ color: '#44486D' }} className="p-button-raised p-button-text mr-2" onClick={() => this.genDay2(item)} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>






                                </div>
                            </div>
                            <div style={{ width: '30%', marginRight: '20px' }}>
                                <div style={{ marginBottom: '20px' }}>
                                    <div style={{ textAlign: 'center', paddingBottom: '20px', cursor: 'pointer' }} >
                                        DATE
                                    </div>


                                    <div className='grid'>

                                        <div className='col-6' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: 5 }}>
                                                From
                                            </div>

                                            <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.fromDay} onChange={(e) => this.change('fromDay', e.value, 'day', 'date')} readOnlyInput showButtonBar />


                                        </div>

                                        <div className='col-6' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: 5 }}>
                                                To
                                            </div>

                                            <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.endDay} onChange={(e) => this.change('endDay', e.value, 'day', 'date')} readOnlyInput showButtonBar />


                                        </div>


                                    </div>



                                    <div style={{ marginTop: '10px' }}>

                                        {
                                            this.DayOpt.map(item => {

                                                return (
                                                    <Button key={item['name']} label={item['name']} style={{ color: '#44486D' }} className="p-button-raised p-button-text mr-2" onClick={() => this.genDay(item)} />
                                                )
                                            })
                                        }

                                    </div>

                                </div>
                            </div>

                            {/* <div style={{ width: '20%' }}>




                                <div style={{ paddingBottom: '20px', textAlign: 'center' }}>MA </div>
                                <div>
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        value={this.state.MA}
                                        options={this.state.optionMA}
                                        onChange={(e) => this.change('MA', e.value, 'MA', 'text')}
                                        showClear
                                    />

                                </div>


                            </div> */}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {Object.keys(this.state.filters).length} filter : {
                                    Object.keys(this.state.filters).map(item => {
                                        let value1 = this.state.filters[item]['value']
                                        if (item == 'day') {
                                            value1 = value1.map(item => moment(item).format('DD - MMM'))

                                        }
                                        return (
                                            <b onClick={() => this.clearFilter(this.state.filters[item])} style={{ cursor: 'pointer' }}>
                                                <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                                {`     ${this.state.filters[item]['operator']} '${item}' `}    |
                                            </b>)
                                    }

                                    )
                                }

                                {
                                    Object.keys(this.state.filters).length > 0 && <Button onClick={() => this.clearFilter()} style={{ position: 'relative', top: '3px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                                }
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <InputSwitch checked={this.state.arbit} onChange={(e) => this.setState({ arbit: e.value }, () => this.getData())} />
                                <span style={{ marginLeft: '10px', marginRight: '10px' }}> Include_Arbit</span>

                          
                            </div>




                        </div>
                    </div>






                </div>

                <div className="p-col-12 p-md-12" style={{ display: 'flex', gap: '20px' }}>
                    <div className="card widget-table" style={{ width: '50%' }} >

                        <TableBuy ref={c => this.TableBuy = c}></TableBuy>


                    </div>
                    <div className="card widget-table" style={{ width: '50%' }} >

                        <TableSell ref={c => this.TableSell = c}></TableSell>
                    </div>


                </div>

                <div className="p-col-12 p-md-12" >
                    {/* <ChartBuy ref={c => this.ChartBuy = c}></ChartBuy>

                    <ChartSell ref={c => this.ChartSell = c}></ChartSell> */}

                    <ChartNetBuySell ref={c => this.ChartNetBuySell = c}></ChartNetBuySell>


                </div>




            </>

        );
    }
}

export default ClassifyView;