import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import axios from "axios";
import moment from 'moment';
import Transaction from '../models/admin/Transaction';
import Banner from '../components/Banner';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';


import ToolTip from '../components/Tooltip';
class TransactionTable extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            { field: 'index', header: 'Index', body: true },
            { field: 'id', header: 'STK', body: true, sort: true, filter: false, filterElement: 'renderFilterId', style: { minWidth: '10rem' } },
            { field: 'account', header: 'ID', body: true, sort: true, filter: false, filterElement: 'renderFilterAccount', style: { minWidth: '11rem' } },
            { field: 'name', header: 'Name', body: true, sort: true, filter: false, filterElement: 'renderFilterName', style: { minWidth: '14rem' } },
            { field: 'address', header: 'Address', body: true, sort: true, filter: false, filterElement: 'renderFilterAddress', style: { minWidth: '14rem' } },
            // { field: 'label1', header: 'Type', body: true, sort: true, filter: false, filterElement: 'renderFilterLabel1', style: { maxWidth: '10rem' } },
            { field: 'label1_details', header: 'Group', sort: true, body: true, filter: false, filterElement: 'renderFilterLabel1_details', style: { maxWidth: '10rem' } },
            { field: 'buy', header: 'Buy', sort: true, body: true },
            { field: 'sell', header: 'Sell', sort: true, body: true },
            { field: 'price', header: 'Price', sort: true, body: true },
            { field: 'percentile', header: 'Percentile', sort: true },
            { field: 'value_million', header: 'Value(mil)', body: true, sort: true },
            { field: 'date', header: 'Date', body: true, sort: true, filter: false, filterElement: 'renderFilterDate', style: { minWidth: '10rem' } },
            { field: 'ticker', header: 'Ticker', body: true, filter: false, sort: true, filterElement: 'renderFilterTicker', style: { maxWidth: '10rem' } },

        ];
        this.state = {
            data: [],
            loading: false,
            totalRecords: 0,
            first: 0,
            quantity: 25,
            page: 1,
            sortField: 'date',
            sortOrder: -1,
            filters: {

            },

            selectedColumns: [
                { field: 'index', header: 'Index', body: true },
                { field: 'name', header: 'Name', body: true, sort: true, filter: false, filterElement: 'renderFilterName', style: { minWidth: '14rem' } },
                // { field: 'label1', header: 'Type', body: true, sort: true, filter: false, filterElement: 'renderFilterLabel1', style: { maxWidth: '10rem' } },
                { field: 'label1_details', header: 'Group', sort: true, body: true, filter: false, filterElement: 'renderFilterLabel1_details', style: { maxWidth: '10rem' } },
                { field: 'buy', header: 'Buy', sort: true, body: true },
                { field: 'sell', header: 'Sell', sort: true, body: true },
                { field: 'price', header: 'Price', sort: true, body: true },
                { field: 'percentile', header: 'Percentile', sort: true },
                { field: 'value_million', header: 'Value(mil)', body: true, sort: true },
                { field: 'date', header: 'Date', body: true, sort: true, filter: false, filterElement: 'renderFilterDate', style: { minWidth: '10rem' } },
                { field: 'ticker', header: 'Ticker', body: true, filter: false, sort: true, filterElement: 'renderFilterTicker', style: { maxWidth: '10rem' } },

            ],


            selectedCountry1: null,

            nameSuggest: null,
            accountSuggest: null,
            accountValue: '',
            addressValue: '',
            tickerValue: '',
            startTimeValue: null,
            endTimeValue: null,
            dateValue: [],

            label1Options: [],
            label1_detailsOptions: [],
            tickerOptions: [],



        }





        this.loadLazyTimeout = null;
        this.tooltips = [];

        this.tranModel = new Transaction()
    }


    componentDidMount() {
        let parsed = global.parsed()
        if(parsed['id']){
            this.setState({
                filters :{
                    "id": {
                        "col_name": "id",
                        "operator": "in",
                        "value": parsed['id']
                    }
                },
                idValue : parsed['id']
            });
        }
        if(parsed['name']){
            this.setState({
                filters :{
                    "name": {
                        "col_name": "name",
                        "operator": "in",
                        "value": parsed['name']
                    }
                },
                nameValue : parsed['name']
            });
        }

        if (parsed['stk']) {
            this.setState({
                filters: {
                    "id": {
                        "col_name": "id",
                        "operator": "in",
                        "value": parsed['stk']
                    }
                },
                idValue: parsed['stk']
            });
        }
        if (parsed['group']) {
            this.setState({
                filters: {
                    "label1_details": {
                        "col_name": "label1_details",
                        "operator": "in",
                        "value": parsed['group']
                    }
                },
                label1_detailsValue: parsed['group']
            });
        }


        this.getQuerry()


        // this.clearInterval = setInterval(() => this.getData(), 60000)
    }


    getQuerry() {
        this.tranModel.query().then(response => {
            let tickerOptions = []
            response['categories']['stock'].map(item => {
                tickerOptions.push({ name: item, code: item },)
            })
            let label1Options = []
            response['categories']['label1'].map(item => {
                label1Options.push({ name: item, code: item },)
            })

            this.setState({
                // label1Options: response['categories']['label1'],
                label1Options: label1Options,
                label1_detailsOptions: response['categories']['label1_details'],
                // tickerOptions: response['categories']['stock'],
                tickerOptions: tickerOptions,
                startTimeValue: new Date(response['min_day']),
                endTimeValue: new Date(response['max_day']),
                // filters : {
                //     day: {
                //         "col_name": "day",
                //         "operator": "in",
                //         "value": [
                //             response['max_day']
                //         ]
                //     }
                // },
                // dateValue : [new Date(response['max_day'])]
            }, () => this.getData());
        }).catch((error) => {
            console.log(error);
        })
    }

    getData() {
        clearTimeout(this.loadLazyTimeout);
        this.loadLazyTimeout = setTimeout(() => {
            this.setState({ loading: true });
            let filter = {
                data_filter: Object.values(this.state.filters),
                data_sort: { [this.state.sortField]: this.state.sortOrder == 1 ? 'asc' : 'desc' },
                page_active: this.state.page,
                page_limit: this.state.quantity,
            }

            this.tranModel.filter(filter).then(response => {
                let headerBuy = 0
                let headerSell = 0
                let headerValue = 0
                response['data_table'].map(item => {
                    headerBuy += Number(item['buy'])
                    headerSell += Number(item['sell'])
                    headerValue += Number(item['value_million'])
                })
                this.setState({
                    totalRecords: Number(response['page_total']) * this.state.quantity,
                    data: response['data_table'],
                    loading: false,
                    headerBuy,
                    headerSell,
                    headerValue
                });
            })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    console.log(error);
                })
        }, 500);





    }

    onSort(event) {
        if (event.sortField == 'buy' || event.sortField == 'sell') {
            if (this.state.sortField == event.sortField) return
            this.setState({
                sortField: event.sortField,
                sortOrder: -1
            }, () => this.getData());
        } else {
            this.setState({
                sortField: event.sortField,
                sortOrder: event.sortOrder
            }, () => this.getData());
        }

    }


    onPage(event) {
        this.setState({
            first: event.first,
            quantity: event.rows,
            page: Number(event.page) + 1

        }, () => this.getData());

    }



    async searchSuggest(col, data) {
        if (data.length == 1) return
        if (data[data.length - 1] == ' ') {
            const collection = document.getElementsByClassName("p-autocomplete-panel p-component p-connected-overlay-enter-done")[0];
            if (collection) {
                return
            }
        }
        data = data.trim()
        let struct = {
            "column": col,
            "data": data
        }
        let result = await this.tranModel.suggestion(struct)
        let out = [];
        for (let index = 0; index < 10; index++) {
            const item = result[index];
            out.push({
                name: item,
                code: item
            })

        }
        this.setState({
            nameSuggest: out,
        });


    }

    change(value, col, filterkey, type) {
        var filter = this.state.filters;
        if (type == 'text') {
            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": value.trim()
                }
            }
        } else if (type == 'date') {
            if (value == null) {
                delete filter[filterkey];

            } else {
                let dateData = []
                value.map(item => {
                    dateData.push(moment(item).format("YYYY/MM/DD"))
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dateData,
                }
            }
        } else if (type == 'multi') {
            if (value.length == 0) {
                delete filter[filterkey];
            } else {
                let dataMul = []
                value.map(item => {
                    dataMul.push(item['code'])
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dataMul
                }
            }
        }
        else {
            if (typeof (value) == 'string' && value != '') {

                this.setState({
                    [col]: value,
                });
                return
            }

            if (value == undefined) return

            if (value == '') {
                delete filter[filterkey];
            } else {

                let name = value['name']
                let check = name.indexOf('(Σ:')
                if (check > -1) {
                    name = name.substring(0, check)
                    name = name.trim()
                }
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": name
                }
                value['name'] = name
                value['code'] = name


            }

        }

        this.setState({
            [col]: value,
            filters: filter,
        }, () => this.getData());


    }

    mulClick(value, col, filterkey) {
        let data = this.state[col]
        if (data == undefined || data == '') {
            data = []
        }
        if (!data.includes(value)) {
            data.push({
                name: value, code: value
            })
            this.setState({
                [col]: data
            }, () => this.change(data, col, filterkey, 'multi'));
        }


    }

    dateClick(date) {
        let dateData = []
        this.state.dateValue.map(item => {
            dateData.push(moment(item).format("YYYY_MM_DD"))
        })
        let dateTran = new Date(date.replaceAll('_', '/'))

        if (!dateData.includes(date)) {
            let value = [...this.state.dateValue, dateTran]
            this.setState({
                dateValue: value
            });

            this.change(value, 'dateValue', 'day', 'date')
        }




    }

    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }
    representativeTemplate = (data, props) => {
        if (props.field == "id") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => this.change(data[props.field], "idValue", 'id', 'text')}>{data[props.field]}</span>
            )
        }
        if (props.field == "account") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => this.change(data[props.field], "accountValue", 'account', 'text')}>{data[props.field]}</span>
            )
        }
        if (props.field == "name") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => this.change(data[props.field], "nameValue", 'name', 'text')}>{data[props.field]}</span>
            )
        }
        if (props.field == "address") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => this.change(data[props.field], "addressValue", 'address', 'text')}>{data[props.field]}</span>
            )
        }
        if (props.field == "label1") {

            let result = data[props.field]

            if(result == 'Unknown') result = '-'

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => this.mulClick(data[props.field], "label1Value", 'label1')}>{result}</span>
                // <span style={{ cursor: 'pointer' }} onClick={() => this.change(data[props.field], "label1Value", 'label1', 'text')}>{data[props.field]}</span>
            )
        }
        if (props.field == "label1_details") {

            return (
                //  <span style={{ cursor: 'pointer' }} onClick={() => this.mulClick(data[props.field], "label1Value", 'label1')}>{data[props.field]}</span>
                <span style={{ cursor: 'pointer' }} onClick={() => this.change(data[props.field], "label1_detailsValue", 'label1_details', 'text')}>{data[props.field]}</span>
            )
        }
        if (props.field == "ticker") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => this.mulClick(data[props.field], "tickerValue", 'ticker')}>{data[props.field]}</span>
                // <span style={{ cursor: 'pointer' }} onClick={() => this.change(data[props.field], "tickerValue", 'ticker', 'text')}>{data[props.field]}</span>
            )
        }
        if (props.field == "index") {
            var id = `product.${props.rowIndex }`;

            let ran = props.rowIndex >  Number(props.props.first ) + 11 ? true : false

            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', position: 'relative' }}
                    onClick={() => window.open(`${window.location.origin}/#/portfolio?stk=${data['id']}&name=${data['name']}`)}
                >
                    <span>{props.rowIndex + 1}</span>
                    <i
                        className="pi pi-search tooltip-button"
                        style={{ 'fontSize': '1em', 'lineHeight': 0 }}
                        onMouseEnter={() => {
                            this.tooltips[id].set(true,  data['name'], false , ran)
                            this.tooltips[id].loadData(data['id'] , data['ticker'] , data['name'])

                        }} 
                        onMouseLeave={() => {
                          
                            this.tooltips[id].set(false);
                        }}
                    ></i>

                    <ToolTip ref={tooltip => this.tooltips[id] = tooltip}></ToolTip>
                </div>
            )
        }

        if (props.field == "buy" || props.field == "sell") {
            if (data[props.field] == null) return 'NULL'

            let result = global.formatNumber(data[props.field])
            if (result == 0) result = '-'

            let color
            if(props.field == 'buy'){
                color = 'limegreen'
            }
            if(props.field == 'sell'){
                color = 'orange'
            }

            return (
                <span style={result != '-' ? { color : color} : {} }>{result}</span>
            )
        }

        if (props.field == 'value_million' || props.field == 'price') {
            if (data[props.field] == null) return 'NULL'

            let result = global.formatNumber(Math.round(data[props.field] * 10) / 10)
            if (result == 0) result = '-'

            return (
                <span>{result}</span>
            )

        }
        if (props.field == "date") {
            let reuslt = data[props.field].replaceAll('_', '/')
            reuslt = moment(reuslt).format('DD - MMM')
            return (
                <span style={{ cursor: 'pointer' }} onClick={() => this.dateClick(data[props.field])}>{reuslt}</span>
            )
        }


    }
    clearFilter(col) {
        if (Object.keys(this.state.filters).length == 0) return
        if (col) {
            let value = `${col}Value`
            if (col == 'day') {
                value = `dateValue`
                this.setState({
                    [value]: []
                });
            } else {
                this.setState({
                    [value]: ''
                });
            }
            var filter = this.state.filters;
            delete filter[col];
            this.setState({
                filters: filter,
            }, () => this.getData());
            return
        } else {
            Object.keys(this.state.filters).map(item => {
                if (item == 'day') {
                    let value = `dateValue`

                    this.setState({
                        [value]: []
                    });
                } else {
                    let value = `${item}Value`

                    this.setState({
                        [value]: ''
                    });
                }

            })
            this.setState({
                filters: {},
            }, () => this.getData());
        }


    }
    exportExcel() {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'transaction');

        });
    }
    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }

    AutoKeyEnter(e, data) {

        if (e.key == "Enter") {
            const collection = document.getElementsByClassName("p-autocomplete-panel p-component p-connected-overlay-enter-done")[0];
            collection.style.display = 'none'
            this.change(this.state[`${data}Value`], `${data}Value`, data, 'text')
        }

    }
    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                {/* <h1 style={{  textAlign : 'center' }}>Transaction</h1> */}
                <Banner text='Transactions' icon='pi pi-wallet'></Banner>
                <div style={{ marginTop: '10px' }}>
                    {Object.keys(this.state.filters).length} filter : {
                        Object.keys(this.state.filters).map(item => {
                            let value1 = this.state.filters[item]['value']
                            if (item == 'day') {
                                value1 = value1.map(item => moment(item).format('DD - MMM'))

                            }
                            let col = item == 'label1_details' ? 'group' : item
                            return (
                                <b onClick={() => this.clearFilter(item)} style={{ cursor: 'pointer' }}>
                                    <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                    {`     ${this.state.filters[item]['operator']} '${col}' `}    |
                                </b>)
                        }

                        )
                    }

                    {
                        Object.keys(this.state.filters).length > 0 && <Button onClick={() => this.clearFilter()} style={{ position: 'relative', top: '7px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                    }
                </div>
            </div>
        );
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });
        let headerGroup = (
            <ColumnGroup>
                <Row >
                    {
                        this.state.selectedColumns.map(col => {
                            if (col.body) {
                                if (col.filter) {
                                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                                }
                                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
                            }
                            if (col.filter) {
                                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                            }
                            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;
                
                
                        })
                    }
                </Row>
                <Row >
                    {
                        this.state.selectedColumns.map(item => {
                            let col = {
                                'Name' : 'aaa',
                                'Buy': 'headerBuy',
                                'Sell': 'headerSell',
                                'Value(mil)' : 'headerValue'
                            }


                            if (col[item['header']]) {
                                if(item['header'] == 'Name'){
                               
                                    return (
                                        <Column header={ <div style={{ color: '#72c2ff' }}>  {global.formatNumber(this.state[col['Buy']] - this.state[col['Sell'] ]) }</div> } />
                                    )
                                }else{
                                    return (
                                        <Column header={ <div style={{ color: '#72c2ff' }}>  {global.formatNumber(Math.floor(this.state[col[item['header']]]))}</div> } />
                                    )
                                }
                            }else{
                                return (
                                    <Column header={ ''} />
                                )
                            }

                        })
                    }
                </Row>
            </ColumnGroup>
        );
        return (

            <div className="p-col-12 p-md-12" style={{ display: 'flex' }}>
                <div className="card widget-table" style={{ width: '80%' }} >
                    <DataTable

                        className="p-datatable-customers" value={this.state.data}
                        dataKey="id1111111" stripedRows
                        rows={this.state.quantity}
                        first={this.state.first} totalRecords={this.state.totalRecords}
                        paginator rowsPerPageOptions={[25, 50, 100, 1000]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        emptyMessage="No data."
                        lazy loading={this.state.loading}
                        onPage={(event) => this.onPage(event)}
                        onSort={(event) => this.onSort(event)}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        onFilter={(event) => this.onFilter(event)}
                        // filters={this.state.filters}
                        // filterDisplay="row"
                        responsiveLayout="scroll"
                        header={header}
                        headerColumnGroup={headerGroup}
                    >
                        {columnComponents}
                    </DataTable>

                </div>

                <div className="card" style={{ width: '20%', marginLeft: '25px' }}>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT COLUMN</div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} />

                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY TICKER </div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.tickerValue} onChange={(e) => this.change(e.value, 'tickerValue', 'ticker', 'multi')} options={this.state.tickerOptions} optionLabel="name" display="chip" />
                        </div>
                    </div>
                    {/* <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY TICKER</div>
                        <div>
                            <Dropdown style={{ width: '100%' }} options={this.state.tickerOptions} value={this.state.tickerValue} onChange={(e) => this.change(e.target.value, 'tickerValue', 'ticker', 'text')} className="p-column-filter" showClear />
                        </div>
                    </div> */}

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY ID</div>
                        <div>
                            <AutoComplete
                                value={this.state.accountValue}
                                suggestions={this.state.nameSuggest}
                                completeMethod={(e) => this.searchSuggest('account', e.query)}
                                field="name"
                                onChange={(e) => this.change(e.value, 'accountValue', 'account', 'auto')}
                                inputStyle={{ width: '100%' }}
                                style={{ width: '100%' }}
                                // onBlur={(e) => this.change(this.state.accountValue,  'accountValue', 'account', 'text') }
                                onKeyUp={(e) => this.AutoKeyEnter(e, 'account')}
                            />
                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY NAME</div>
                        <div>
                            <AutoComplete
                                ref={c => this.namAuto = c}
                                value={this.state.nameValue}
                                suggestions={this.state.nameSuggest}
                                completeMethod={(e) => this.searchSuggest('name', e.query)}
                                field="name"
                                onChange={(e) => this.change(e.value, 'nameValue', 'name', 'auto')}
                                inputStyle={{ width: '100%' }}
                                style={{ width: '100%' }}
                                // onBlur={(e) => this.change(this.state.nameValue, 'nameValue', 'name', 'text') }
                                onKeyUp={(e) => this.AutoKeyEnter(e, 'name')}
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY STK</div>
                        <div>
                            <AutoComplete
                                value={this.state.idValue}
                                suggestions={this.state.nameSuggest}
                                completeMethod={(e) => this.searchSuggest('id', e.query)}
                                field="name"
                                onChange={(e) => this.change(e.value, 'idValue', 'id', 'auto')}
                                inputStyle={{ width: '100%' }}
                                style={{ width: '100%' }}
                                // onBlur={(e) => this.change(this.state.idValue,  'idValue', 'id', 'text') }
                                onKeyUp={(e) => this.AutoKeyEnter(e, 'id')}

                            />
                            {/* <InputText style={{ width: '100%' }} className="p-column-filter" value={this.state.idValue} onChange={(e) => this.change(e.target.value, 'idValue', 'id', 'text')} /> */}
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY ADDRESS</div>
                        <div>
                            <AutoComplete
                                value={this.state.addressValue}
                                suggestions={this.state.nameSuggest}
                                completeMethod={(e) => this.searchSuggest('address', e.query)}
                                field="name"
                                onChange={(e) => this.change(e.value, 'addressValue', 'address', 'auto')}
                                inputStyle={{ width: '100%' }}
                                style={{ width: '100%' }}
                                // onBlur={(e) => this.change(this.state.idValue,  'idValue', 'id', 'text') }
                                onKeyUp={(e) => this.AutoKeyEnter(e, 'address')}

                            />
                            {/* <InputText style={{ width: '100%' }} className="p-column-filter" value={this.state.idValue} onChange={(e) => this.change(e.target.value, 'idValue', 'id', 'text')} /> */}
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY TYPE </div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.label1Value} onChange={(e) => this.change(e.value, 'label1Value', 'label1', 'multi')} options={this.state.label1Options} optionLabel="name" display="chip" />
                        </div>
                    </div>
                    {/* <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY TYPE</div>
                        <div>
                            <Dropdown style={{ width: '100%' }} options={this.state.label1Options} value={this.state.label1Value} onChange={(e) => this.change(e.target.value, 'label1Value', 'label1', 'text')} className="p-column-filter" showClear />
                        </div>
                    </div> */}
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY GROUP</div>
                        <div>
                            <Dropdown style={{ width: '100%' }} options={this.state.label1_detailsOptions} value={this.state.label1_detailsValue} onChange={(e) => this.change(e.target.value, 'label1_detailsValue', 'label1_details', 'text')} className="p-column-filter" showClear />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY DATE</div>
                        <div>
                            <Calendar style={{ width: '100%' }} dateFormat="dd - M" id="multiple" value={this.state.dateValue} onChange={(e) => this.change(e.value, 'dateValue', 'day', 'date')} selectionMode="multiple" readOnlyInput showButtonBar />
                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <Button style={{ width: '100%' }} type="button" label='EXPORT TO EXCEL' onClick={() => this.exportExcel()} className="p-button-success " data-pr-tooltip="XLS" />
                    </div>
                </div>
            </div>
        )
    }
}

export default TransactionTable;