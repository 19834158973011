import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import TranCode from '../models/admin/TranCode';
import moment from 'moment';
import Banner from '../components/Banner';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import Transaction from '../models/admin/Transaction';
class TranByCode extends Component {

    constructor(props) {
        super(props)
        this.columns = [
            { "field": "index", "header": "Index", "body": true },
            { "field": "stock", "header": "Stock" },
            { "field": "day", "header": "Day", "body": true },
            { "field": "buy", "header": "Total Buy", "body": true },
            { "field": "sell", "header": "Total Sell", "body": true },
            { "field": "val_buy", "header": "Total Value_buy", "body": true },
            { "field": "val_sell", "header": "Total Value_Sell", "body": true },
            { "field": "VN30", "header": "VN30" },

        ];
        this.columnDay = [
            { "field": "index", "header": "Index", "body": true },
            { "field": "day", "header": "Day", "body": true },
            { "field": "stock", "header": "Stock" },
            { "field": "buy", "header": "Total Buy", "body": true },
            { "field": "sell", "header": "Total Sell", "body": true },
            { "field": "val_buy", "header": "Total Value_buy", "body": true },
            { "field": "val_sell", "header": "Total Value_Sell", "body": true },
            { "field": "VN30", "header": "VN30" },

        ];
        this.state = {
            data: [],
            loading: false,
            totalRecords: 0,
            first: 0,
            quantity: 25,
            page: 1,
            sortField: '',
            sortOrder: -1,
            filters: {

            },
            selectedColumns: this.columns,
            expandedRows: null,

            optionsStock: [],
            optionsGroup: [],
            mode: 'Day'

        }

        this.loadLazyTimeout = null;
        this.model = new TranCode()


        this.model_Ohlc = new Transaction() 
        this.optionsMode = [
            "Stock", "Day"
        ];


    }


    async getData() {
        let filter = {
            data_filter: Object.values(this.state.filters),
        }
        this.setState({loading : true});

        let data_ohlc = await this.model_Ohlc.ohlc({
            "data_filter": [
                {
                    "col_name": "code",
                    "operator": "in",
                    "value": "VN30"
                }
            ]
        })

        this.model.getDataReal(filter, this.state.mode).then(res => {
            let headerBuy = 0;
            let headerSell = 0;
            let headerValue_buy = 0;
            let headerValue_Sell = 0;
 
            res['data'].map(row => {
                headerBuy += row['buy']
                headerSell += row['sell']
                headerValue_buy += row['val_buy']
                headerValue_Sell += row['val_sell']
           
            })

            let length = 1
            if(res['data'].length < 1){
                length = res['data'].length
            }
            let expandedRows = {}
            if(this.state.mode.toLowerCase() == 'stock'){

               res['data'].sort((a,b) => a.stock > b.stock ? 1 : -1);
            }else{
                for (let index = 0; index < length; index++) {
                    expandedRows[res['data'][index][this.state.mode.toLowerCase()]] = true
                    
                }
            }
            res['data'].map(item => {
                item['VN30'] = data_ohlc[item['day']]
            })

            this.setState({
                data: res['data'],
                loading: false,
                optionsStock: res['stock'],
                optionsGroup: res['group'],
                expandedRows : expandedRows,
                selectedColumns: this.state.mode == 'Stock' ? this.columns : this.columnDay,
                headerBuy ,
                headerSell ,
                headerValue_buy ,
                headerValue_Sell ,
            });
        })
    }
    componentDidMount() {



        this.getData()
    }


    change(value, col, filterkey, type) {
        var filter = this.state.filters;
        if (type == 'text') {

            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": value.trim()
                }
            }
        } else if (type == 'date') {

            if (value == null) {
                delete filter[filterkey];

            } else {
                let dateData = []
                value.map(item => {
                    dateData.push(moment(item).format("YYYY/MM/DD"))
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dateData,
                }
            }
        } else if (type == 'multi') {
            // debugger
            if (value.length == 0) {
                delete filter[filterkey];
            } else {
                let dataMul = []
                value.map(item => {
                    dataMul.push(item['code'])
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dataMul
                }
            }
        }
        if (filterkey == 'stock') {
            let a = {}
            value.map(item => {
                a[item['code']] = true
            })
            this.setState({
                [col]: value,
                expandedRows: a,
            });

        } else {
            this.setState({
                [col]: value,
                filters: filter,
            }, () => this.getData());
        }





    }

    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }

    rowExpansionTemplate(data) {
        if (this.state.mode == 'Stock') {
            return (
                <div className="sub_table">
                    <DataTable value={data.value} responsiveLayout="scroll">

                        <Column style={{ width: '3rem' }} />
                        <Column field='day' header='Day' style={{ width: '8rem' }} body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='id' header='STK' sortable  ></Column>
                        <Column field='account' header='ID' sortable  ></Column>
                        <Column field='name' header='Name' sortable  ></Column>
                        <Column field='address' header='Address'  sortable  ></Column>
                        <Column field='group_ah' header='Group' sortable  ></Column>
                        <Column field='price' header='Price' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>

                        <Column field='buy' header='Buy' body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='sell' header='Sell' body={(data, props) => this.representativeTemplate(data, props)}  sortable ></Column>
                        <Column field='val_buy' header='Val_buy' body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='val_sell' header='Val_sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>

                        {/* <Column field='label1' header='Type' sortable   ></Column> */}


                    </DataTable>
                </div>
            );
        } else {
            return (
                <div className="sub_table">
                    <DataTable value={data.value} responsiveLayout="scroll">

                      
                    <Column style={{ width: '3rem' }} />
                        <Column field='stock' header='Stock' sortable   ></Column>
                        <Column field='id' header='STK' sortable  ></Column>
                        <Column field='account' header='ID'  body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='name' header='Name'  body={(data, props) => this.representativeTemplate(data, props)}sortable  ></Column>
                        <Column field='address' header='Address'  sortable  ></Column>
                        <Column field='group_ah' header='Group' sortable  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field='price' header='Price' body={(data, props) => this.representativeTemplate(data, props)}sortable ></Column>

                        <Column field='buy' header='Buy' body={(data, props) => this.representativeTemplate(data, props)}sortable  ></Column>
                        <Column field='sell' header='Sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='val_buy' header='Val_buy' body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='val_sell' header='Val_sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>

                        {/* <Column field='label1' header='Type' sortable  body={(data, props) => this.representativeTemplate(data, props)}  ></Column> */}



                    </DataTable>
                </div>
            );
        }


    }

    representativeTemplate = (data, props) => {

        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }

        if (props.column.props.header == 'Avg Price') {
            let buy = data['buy']
            let sell = data['sell']
            let price = (buy + sell) / 2
            price = Math.round(price * 100) / 100
            price = global.formatNumber(price)
            return price
        }

        let col = ['val_buy', 'val_sell', 'price']

        if (col.includes(props.field)) {

            let result = Math.round(data[props.field] * 100) / 100
            if (result == 0) {
                result = '-'
            } else {
                result = global.formatNumber(result)
            }
            return (
                <span>{result}</span>
            )
        }
        if (props.field == "buy" || props.field == "sell") {
            if (data[props.field] == null) return 'NULL'
            let result = Math.round(data[props.field] * 100) / 100
            result = global.formatNumber(data[props.field])
            if (result == 0) result = '-'

            let color
            if (props.field == 'buy') {
                color = 'limegreen'
            }
            if (props.field == 'sell') {
                color = 'orange'
            }

            return (
                <span style={result != '-' ? { color: color } : {}}>{result}</span>
            )
        }

        if (props.field == "insider_ah") {

            let result = data[props.field].toString()

            return (
                <span>{result}</span>
            )
        }

        if (props.field == "day") {
            let reuslt = data[props.field].replaceAll('_', '/')
            reuslt = moment(reuslt).format('DD - MMM')
            return (
                <span >{reuslt}</span>
            )
        }
        if (props.field == "name") {
            
            return (
                <span style={{cursor : 'pointer'}} onClick={() => window.open(`${window.location.origin}/#/analytics?id=${data['account']}&stock=${data['stock']}`)} >{data[props.field]}</span>
            )
        }
        if (props.field == "account") {
            
            return (
                <span style={{cursor : 'pointer'}} onClick={() => window.open(`${window.location.origin}/#/analytics?id=${data['account']}`)} >{data[props.field]}</span>
            )
        }

        if (props.field == "group_ah") {
            
            return (
                <span style={{cursor : 'pointer'}} onClick={() => window.open(`${window.location.origin}/#/groups?group=${data[props.field]}`)} >{data[props.field]}</span>
            )
        }
        if (props.field == "label1") {
            
            return (
                <span style={{cursor : 'pointer'}} onClick={() => window.open(`${window.location.origin}/#/types?type=${data[props.field]}`)} >{data[props.field]}</span>
            )
        }









    }
    clearFilter(col) {

        if (Object.keys(this.state.filters).length == 0) return
        if (col) {

            let value = `${col}Value`
            if (col == 'day') {
                value = `dateValue`
                this.setState({
                    [value]: []
                });
            } else {
                this.setState({
                    [value]: ''
                });
            }

            var filter = this.state.filters;
            delete filter[col];
            this.setState({
                filters: filter,
            }, () => this.getData());
            return
        } else {
            Object.keys(this.state.filters).map(item => {
                if (item == 'day') {
                    let value = `dateValue`

                    this.setState({
                        [value]: []
                    });
                } else {
                    let value = `${item}Value`

                    this.setState({
                        [value]: ''
                    });
                }

            })
            this.setState({
                filters: {},
            }, () => this.getData());
        }


    }
    expandAll() {
        let expandedRows = {};
        this.state.data.forEach(p => expandedRows[`${p[this.state.mode.toLowerCase()]}`] = true);

        this.setState({
            expandedRows
        });
    }
    collapseAll() {
        this.setState({
            expandedRows: null
        });
    }

    changeMode(mode) {
        this.setState({
            // filters : {},
            mode: mode
        }, () => this.getData());
    }

    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                {/* <h1 style={{ textAlign: 'center' }}>Insiders</h1> */}

                <Banner text='Insiders' icon='pi pi-shield'></Banner>

                <div style={{display : 'flex' , justifyContent : 'space-between'}}>
                    <div style={{ marginTop: '10px' }}>
                        {Object.keys(this.state.filters).length} filter : {
                            Object.keys(this.state.filters).map(item => {
                                let value1 = this.state.filters[item]['value']
                                if (item == 'day') {
                                    value1 = value1.map(item => moment(item).format('DD - MMM'))

                                }
                                return (
                                    <b onClick={() => this.clearFilter(item)} style={{ cursor: 'pointer' }}>
                                        <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                        {`     ${this.state.filters[item]['operator']} '${item}' `}    |
                                    </b>)
                            }

                            )
                        }

                        {
                            Object.keys(this.state.filters).length > 0 && <Button onClick={() => this.clearFilter()} style={{ position: 'relative', top: '7px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                        }
                    </div>

                    <div>
                        <Button label="Expand All" onClick={() => this.expandAll()} className="ml-2 mr-2" />
                        <Button label="Collapse All" onClick={() => this.collapseAll()} className="ml-2 mr-2" />
                    </div>
                </div>
            </div>
        );
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });
        let headerGroup = (
            <ColumnGroup>
                <Row >
                    <Column header={'Index'} />
                    {
                        this.state.selectedColumns.map(item => {

                            return(
                                <Column header={item['header']} />
                            )

                        })
                    }
                </Row>
                <Row >
                    <Column  colSpan={4}  />
                    {
                        this.state.selectedColumns.map(item => {
                            let col = {
                                'Total Buy': 'headerBuy',
                                'Total Sell': 'headerSell',
                                'Total Value_buy': 'headerValue_buy',
                                'Total Value_Sell': 'headerValue_Sell',
                              
                            }


                            if (col[item['header']]) {
                                return (
                                    <Column header={ <div style={{ color: '#72c2ff' }}>  {global.formatNumber(Math.floor(this.state[col[item['header']]]))}</div> } />
                                )
                            }

                        })
                    }
                    <Column  />
                </Row>
            </ColumnGroup>
        );
        return (

            <div className="p-col-12 p-md-12" style={{ display: 'flex' }}>
                <div className="card widget-table" style={{ width: '80%' }} >
                    <DataTable

                        className="p-datatable-customers" value={this.state.data}
                        dataKey={this.state.mode.toLowerCase()}
                        stripedRows
                        emptyMessage="No data."
                        lazy loading={this.state.loading}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        onFilter={(event) => this.onFilter(event)}
                        responsiveLayout="scroll"
                        header={header}
                        expandedRows={this.state.expandedRows}
                        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={(data) => this.rowExpansionTemplate(data)}
                        headerColumnGroup={headerGroup}
                    >
                        <Column expander style={{ width: '3rem' }} />
                        {columnComponents}
                    </DataTable>

                </div>

                <div className="card" style={{ width: '20%', marginLeft: '25px' }}>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT COLUMN </div>
                        <div>
                            <MultiSelect value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} style={{ width: '100%' }} />
                        </div>
                    </div>
                    {/* <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY STOCK </div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.stockValue} onChange={(e) => this.change(e.value, 'stockValue', 'stock', 'multi')} options={this.state.optionsStock} optionLabel="name" display="chip" />
                        </div>
                    </div> */}
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY DATE</div>
                        <div>
                            <Calendar style={{ width: '100%' }} dateFormat="dd - M" id="multiple" value={this.state.dateValue} onChange={(e) => this.change(e.value, 'dateValue', 'day', 'date')} selectionMode="multiple" readOnlyInput showButtonBar />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY GROUP </div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.groupValue} onChange={(e) => this.change(e.value, 'groupValue', 'group', 'multi')} options={this.state.optionsGroup} optionLabel="name" display="chip" />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT LAYOUT </div>
                        <div style={{ display: 'flex', background: '#f1f1f5', borderRadius: '8px', padding: '8px' }}>
                            <div className="field-radiobutton" style={{ flex: 1, margin: 0 }} >
                                <RadioButton value="Day" onChange={(e) => this.changeMode(e.value)} checked={this.state.mode === 'Day'} />
                                <label htmlFor="city1">Day</label>
                            </div>
                            <div className="field-radiobutton" style={{ flex: 1, margin: 0 }}>
                                <RadioButton value="Stock" onChange={(e) => this.changeMode(e.value)} checked={this.state.mode === 'Stock'} />
                                <label htmlFor="city1">Stock</label>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default TranByCode;