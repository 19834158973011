import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import axios from "axios";
import moment from 'moment';
import TopBuySellModel from '../../models/admin/TopBuySell';
import Banner from '../Banner';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { InputSwitch } from 'primereact/inputswitch';
class TopBuy extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            { field: 'index', header: 'Index', body: true },
            { field: 'id', header: 'STK', body: true, filter: false },
            { field: 'name', header: 'Name', body: true, filter: false, style: { width: '25rem' } },

            { field: 'buy DXG', header: ' Buy DXG', body: true },
            { field: 'buy HPG', header: ' Buy HPG', body: true },
            { field: 'buy MBB', header: ' Buy MBB', body: true },
            { field: 'buy MSN', header: ' Buy MSN', body: true },
            { field: 'buy MWG', header: ' Buy MWG', body: true },
            { field: 'buy SSI', header: ' Buy SSI', body: true },
            { field: 'buy STB', header: ' Buy STB', body: true },
            { field: 'buy TCB', header: ' Buy TCB', body: true },
            { field: 'buy VIC', header: ' Buy VIC', body: true },
            { field: 'buy VND', header: ' Buy VND', body: true },
            { field: 'buy VPB', header: ' Buy VPB', body: true },
            { field: 'buy NVL', header: ' Buy NVL', body: true },
            { field: 'buy HDB', header: ' Buy HDB', body: true },
            { field: 'buy EIB', header: ' Buy EIB', body: true },
            { field: 'buy VRE', header: ' Buy VRE', body: true },

            { field: 'buy VHM', header: ' Buy VHM', body: true },
            { field: 'buy CTG', header: ' Buy CTG', body: true },
            { field: 'buy VCB', header: ' Buy VCB', body: true },
            { field: 'buy HSG', header: ' Buy HSG', body: true },
            { field: 'buy FPT', header: ' Buy FPT', body: true },
            { field: 'buy VNM', header: ' Buy VNM', body: true },
            { field: 'buy ACB', header: ' Buy ACB', body: true },
            { field: 'buy DIG', header: ' Buy DIG', body: true },
            // { field: 'buy PVD', header: ' Buy PVD', body: true },
            { field: 'buy MSB', header: ' Buy MSB', body: true },
            { field: 'buy PNJ', header: ' Buy PNJ', body: true } ,
            { field: 'buy FRT', header: ' Buy FRT', body: true } ,
            { field: 'buy DGC', header: ' Buy DGC', body: true } ,
            // { field: 'buy KBC', header: ' Buy KBC', body: true },

            { field: 'total_buy', header: 'Total  Buy', body: true },
            { field: 'day_val_buy', header: ' Day Val Buy', body: true },

        ];
        this.state = {
            data: [],
            loading: false,
            totalRecords: 0,
            first: 0,
            quantity: 25,
            page: 1,
            sortField: '',
            sortOrder: -1,
            filters: {

            },
            matchedOnly: this.props.matchedOnly,
            rankByGroup: this.props.rankByGroup,
            foreignOnly: this.props.foreignOnly,
            selectedColumns: [
                { field: 'index', header: 'Index', body: true },

                { field: 'name', header: 'Name', body: true, filter: false, style: { width: '25rem' } },

                { field: 'buy DXG', header: 'Buy DXG', body: true },
                { field: 'buy HPG', header: 'Buy HPG', body: true },
                { field: 'buy MBB', header: 'Buy MBB', body: true },
                { field: 'buy MSN', header: 'Buy MSN', body: true },
                { field: 'buy MWG', header: 'Buy MWG', body: true },
                { field: 'buy SSI', header: 'Buy SSI', body: true },
                { field: 'buy STB', header: 'Buy STB', body: true },
                { field: 'buy TCB', header: 'Buy TCB', body: true },
                { field: 'buy VIC', header: 'Buy VIC', body: true },
                { field: 'buy VND', header: 'Buy VND', body: true },
                { field: 'buy VPB', header: 'Buy VPB', body: true },
                { field: 'buy NVL', header: 'Buy NVL', body: true },
                { field: 'buy HDB', header: 'Buy HDB', body: true },
                { field: 'buy EIB', header: 'Buy EIB', body: true },
                { field: 'buy VRE', header: 'Buy VRE', body: true },

                { field: 'buy VHM', header: 'Buy VHM', body: true },
                { field: 'buy CTG', header: 'Buy CTG', body: true },
                { field: 'buy VCB', header: 'Buy VCB', body: true },
                { field: 'buy HSG', header: 'Buy HSG', body: true },
                { field: 'buy FPT', header: 'Buy FPT', body: true },
                { field: 'buy VNM', header: 'Buy VNM', body: true },
                { field: 'buy ACB', header: 'Buy ACB', body: true },
                { field: 'buy DIG', header: 'Buy DIG', body: true },
                // { field: 'buy PVD', header: 'Buy PVD', body: true },
                { field: 'buy MSB', header: 'Buy MSB', body: true },
                { field: 'buy PNJ', header: 'Buy PNJ', body: true },
                { field: 'buy FRT', header: 'Buy FRT', body: true },
                { field: 'buy DGC', header: 'Buy DGC', body: true },
                // { field: 'buy KBC', header: 'Buy KBC', body: true },

                { field: 'total_buy', header: 'Total Buy', body: true },
                { field: 'day_val_buy', header: 'Day Val Buy', body: true },
            ],
            expandedRows: null,






        }





        this.loadLazyTimeout = null;


    }



    setLoading() {
        this.setState({ loading: true });
    }
    setSelectCol(data) {
        this.setState({
            selectedColumns: data
        });
    }

    setData(data) {
        let headerDXG = 0
        let headerHPG = 0
        let headerMBB = 0
        let headerMSN = 0
        let headerMWG = 0
        let headerSSI = 0
        let headerSTB = 0
        let headerTCB = 0
        let headerVIC = 0
        let headerVND = 0
        let headerVPB = 0
        let headerNVL = 0
        let headerHDB = 0
        let headerEIB = 0
        let headerVRE = 0
        let headerVHM = 0
        let headerCTG = 0
        let headerVCB = 0
        let headerHSG = 0
        let headerFPT = 0
        let headerVNM = 0
        let headerACB = 0
        let headerDIG = 0
        // let headerPVD = 0
        let headerMSB = 0
        let headerPNJ = 0
        let headerFRT = 0
        let headerDGC = 0
        // let headerKBC = 0
        let headerTotalBuy = 0
        let headerDayValBuy = 0

        data.map(item => {
            headerDXG += item['buy DXG']
            headerHPG += item['buy HPG']
            headerMBB += item['buy MBB']
            headerMSN += item['buy MSN']
            headerMWG += item['buy MWG']
            headerSSI += item['buy SSI']
            headerSTB += item['buy STB']
            headerTCB += item['buy TCB']
            headerVIC += item['buy VIC']
            headerVND += item['buy VND']
            headerVPB += item['buy VPB']
            headerNVL += item['buy NVL']
            headerHDB += item['buy HDB']
            headerEIB += item['buy EIB']
            headerVRE += item['buy VRE']
            headerVHM += item['buy VHM']
            headerCTG += item['buy CTG']
            headerVCB += item['buy VCB']
            headerHSG += item['buy HSG']
            headerFPT += item['buy FPT']
            headerVNM += item['buy VNM']
            headerACB += item['buy ACB']
            headerDIG += item['buy DIG']
            // headerPVD += item['buy PVD']
            headerMSB += item['buy MSB']
            headerPNJ += item['buy PNJ']
            headerFRT += item['buy FRT']
            headerDGC += item['buy DGC']
            // headerKBC += item['buy KBC']
            headerTotalBuy += item['total_buy']
            headerDayValBuy += item['day_val_buy']
        })
        this.setState({
            data: data,
            loading: false,
            headerDXG,
            headerHPG,
            headerMBB,
            headerMSN,
            headerMWG,
            headerSSI,
            headerSTB,
            headerTCB,
            headerVIC,
            headerVND,
            headerVPB,
            headerNVL,
            headerHDB,
            headerEIB,
            headerVRE,
            headerVHM,
            headerCTG,
            headerVCB,
            headerHSG,
            headerFPT,
            headerVNM,
            headerACB,
            headerDIG,
            // headerPVD,
            headerMSB,
            headerPNJ,
            headerFRT,
            headerDGC,
            // headerKBC,
            headerTotalBuy,
            headerDayValBuy,
        });

    }




    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }
    representativeTemplate = (data, props) => {
        if (props.field == "id") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/?stk=${data['id']}`)}>{data[props.field]}</span>
            )
        }

        if (props.field == "name") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/?stk=${data['id']}`)} >{data[props.field]}</span>
            )
        }


        if (props.field == "index") {



            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => window.open(`${window.location.origin}/#/analytics2?stk=${data['id']}&name=${data['name']}`)}
                >
                    <span>{props.rowIndex + 1}</span>
                    <i className="pi pi-search" style={{ 'fontSize': '1em', 'lineHeight': 0 }}></i>
                </div>
            )
        }
        let col = ['buy DXG', 'buy HPG', 'buy MBB', 'buy MSN', 'buy MWG', 'buy SSI', 'buy STB', 'buy TCB', 'buy VIC',
            'buy VND', 'buy VPB', 'buy NVL', 'buy HDB', 'buy EIB', 'buy VRE',
            'buy VHM', 'buy CTG', 'buy VCB', 'buy HSG', 'buy FPT', 'buy VNM', 'buy ACB', 'buy DIG', 'buy MSB','buy PNJ', 'buy FRT', 'buy DGC',
            'total_buy', 'day_val_buy',]

        if (col.includes(props.field)) {
            let result = Math.round(data[props.field] * 100) / 100
            if (result == 0) result = '-'
            if (data[props.field] == undefined) result = '-'
            return (
                <span>{result}</span>
            )
        }







    }

    exportExcel() {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'top_buy_sell');

        });
    }
    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }



    rowExpansionTemplate(data) {
        return (
            <div className="sub_table">
                <DataTable value={data.value} responsiveLayout="scroll" sortField='day' sortOrder={-1}>
                    <Column field="day" header="Day" style={{ width: '31rem', paddingLeft: '47px' }} sortable ></Column>
                    <Column field='buy DXG' header='Buy DXG' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='buy HPG' header='Buy HPG' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='buy MBB' header='Buy MBB' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='buy MSN' header='Buy MSN' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy MWG' header='Buy MWG' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='buy SSI' header='Buy SSI' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy STB' header='Buy STB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy TCB' header='Buy TCB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy VIC' header='Buy VIC' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy VND' header='Buy VND' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy VPB' header='Buy VPB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy NVL' header='Buy NVL' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy HDB' header='Buy HDB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy EIB' header='Buy EIB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy VRE' header='Buy VRE' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>

                    <Column field='buy VHM' header='Buy VHM' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy CTG' header='Buy CTG' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy VCB' header='Buy VCB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy HSG' header='Buy HSG' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy FPT' header='Buy FPT' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy VNM' header='Buy VNM' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy ACB' header='Buy ACB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy DIG' header='Buy DIG' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    {/* <Column field='buy PVD' header='Buy PVD' body={(data, props) => this.representativeTemplate(data, props)}  ></Column> */}
                    <Column field='buy MSB' header='Buy MSB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy PNJ' header='Buy PNJ' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy FRT' header='Buy FRT' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='buy DGC' header='Buy DGC' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    {/* <Column field='buy KBC' header='Buy KBC' body={(data, props) => this.representativeTemplate(data, props)}  ></Column> */}
                    <Column field='total_buy' header='Total Buy' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='day_val_buy' header='Day Val Buy' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>

                </DataTable>
            </div>
        );
    }

    expandAll() {
        let expandedRows = {};
        this.state.data.forEach(p => expandedRows[`${p.id}`] = true);

        this.setState({
            expandedRows
        });
    }
    collapseAll() {
        this.setState({
            expandedRows: null
        });
    }
    render() {
        const header = (
            <div style={{ textAlign: 'left', position: 'relative' }}>
                <Banner text='Top Buy' icon='pi pi-heart'></Banner>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ marginTop: '10px' }}>
                        {Object.keys(this.props.filterOp).length} filter : {
                            Object.keys(this.props.filterOp).map(item => {
                                let value1 = this.props.filterOp[item]['value']
                                if (item == 'day') {
                                    value1 = value1.map(item => moment(item).format('DD - MMM'))

                                }
                                return (
                                    <b onClick={() => this.props.clearFilter(this.props.filterOp[item])} style={{ cursor: 'pointer' }}>
                                        <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                        {`     ${this.props.filterOp[item]['operator']} '${item}' `}    |
                                    </b>)
                            }

                            )
                        }

                        {/* {
                        Object.keys(this.props.filterOp).length > 0 && <Button onClick={() => this.props.clearFilter()} style={{ position: 'relative', top: '7px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                    } */}
                    </div>
                    <div style={{ display: 'flex ', alignItems: 'center' }}>
                        {/* <Button label="Expand All" onClick={() => this.expandAll()} className="ml-2 mr-2" />
                        <Button label="Collapse All" onClick={() => this.collapseAll()} className="ml-2 mr-2" /> */}
                        <div style={{ display: 'flex' }}>

                            <InputSwitch checked={this.state.matchedOnly} onChange={(e) => this.setState({ matchedOnly: e.value }, () => this.props.setMathedOnly(e.value))} />
                            <span style={{ marginLeft: '10px', marginRight: '10px' }}> Matched only</span>

                            <InputSwitch checked={this.state.rankByGroup} onChange={(e) => this.setState({ rankByGroup: e.value }, () => this.props.setRankByGroup(e.value))} />
                            <span style={{ marginLeft: '10px', marginRight: '10px' }}> Rank By Group</span>
                            
                            <InputSwitch checked={this.state.foreignOnly} onChange={(e) => this.setState({ foreignOnly: e.value }, () => this.props.setForeignOnly(e.value))} />
                            <span style={{ marginLeft: '10px' }}> Foreign Only</span>

                        </div>
                    </div>

                </div>


            </div>
        );
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });
        let headerGroup = (
            <ColumnGroup>
                <Row >
                    <Column style={{ width: '3em' }} />
                    {
                        this.state.selectedColumns.map(item => {

                            return (
                                <Column header={item['header']} />
                            )

                        })
                    }
                </Row>
                <Row >
                    <Column colSpan={3} />
                    {
                        this.state.selectedColumns.map(item => {

                            let col = {
                                'Buy DXG': 'headerDXG',
                                'Buy HPG': 'headerHPG',
                                'Buy MBB': 'headerMBB',
                                'Buy MSN': 'headerMSN',
                                'Buy MWG': 'headerMWG',
                                'Buy SSI': 'headerSSI',
                                'Buy STB': 'headerSTB',
                                'Buy TCB': 'headerTCB',
                                'Buy VIC': 'headerVIC',
                                'Buy VND': 'headerVND',
                                'Buy VPB': 'headerVPB',
                                'Buy NVL': 'headerNVL',
                                'Buy HDB': 'headerHDB',
                                'Buy EIB': 'headerEIB',
                                'Buy VRE': 'headerVRE',

                                'Buy VHM': 'headerVHM',
                                'Buy CTG': 'headerCTG',
                                'Buy VCB': 'headerVCB',
                                'Buy HSG': 'headerHSG',
                                'Buy FPT': 'headerFPT',
                                'Buy VNM': 'headerVNM',
                                'Buy ACB': 'headerACB',
                                'Buy DIG': 'headerDIG',
                                // 'Buy PVD': 'headerPVD',
                                'Buy MSB': 'headerMSB',
                                'Buy PNJ': 'headerPNJ',
                                'Buy FRT': 'headerFRT',
                                'Buy DGC': 'headerDGC',
                                // 'Buy KBC': 'headerKBC',
                                'Total Buy': 'headerTotalBuy',
                                'Day Val Buy': 'headerDayValBuy',
                                
                            }


                            if (col[item['header']]) {
                                return (

                                    <Column header={<div style={{ color: '#72c2ff' }}>  {global.formatNumber(Math.round(this.state[col[item['header']]] * 100) / 100)}</div>} />
                                )
                            }

                        })
                    }
                </Row>
            </ColumnGroup>
        );
        return (

            <div className="p-col-12 p-md-12" style={{ display: 'flex' }}>
                <div className="card widget-table" style={{ width: '80%' }} >
                    <DataTable

                        className="p-datatable-customers"
                        value={this.state.data}
                        dataKey="id"
                        // rowHover
                        stripedRows
                        emptyMessage="No data."
                        lazy loading={this.state.loading}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        onFilter={(event) => this.onFilter(event)}
                        responsiveLayout="scroll"
                        header={header}
                        headerColumnGroup={headerGroup}
                        expandedRows={this.state.expandedRows}
                        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={(data) => this.rowExpansionTemplate(data)}
                    >
                        <Column expander style={{ width: '3em' }} />
                        {columnComponents}
                    </DataTable>

                </div>
                {
                    this.props.children
                }

            </div>
        )
    }
}

export default TopBuy;