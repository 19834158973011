import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Analysis from '../../models/admin/Analysis';

class TableFavorite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
               
            ],
            deleteDialog: false,
            stkDel: '',
            stk : '',
            name :'',
            note : '',
            loading : false
        }

        this.model = new Analysis()
    }
    componentDidMount(){
      
        this.readFavorite(true)
        
    }

    representativeTemplate = (data, props) => {

        if (props.field == "index") {

            return (
                <span style={{fontSize : '11px'}}>{props.rowIndex + 1}</span>
            )
        }
        if (props.field == "act") {
            let stkDel = data['stk']
            return (
                <i
                    style={{ cursor: 'pointer', color: 'red' }}
                    className="pi pi-trash"
                    onClick={() => {
                        this.setState({
                            deleteDialog: true,
                            stkDel
                        });
                    }}
                ></i>
            )
        }

        if (props.field == "percentile") {

            let result = Math.round(data[props.field]*100)/100
            return (
                <span style={{fontSize : '11px'}}>{result}</span>
            )
        }
        if (props.field == "name") {

            return (
                <span 
                    style={{  cursor: 'pointer' , fontSize : '11px' }}
                    onClick={() => {
                        this.props.parent.getData(data['stk'] , data['stock'])
                        if(this.state.optFavorite[data['stk']]){
                            this.setState({
                                stk : data['stk'] ,
                                name :this.state.optFavorite[data['stk']]['name'],
                                note : this.state.optFavorite[data['stk']]['note'],
                            });
                        }
                    }
                       
                    }>
                    {data[props.field]}
                </span>
            )
        }

    }

   

    deleteDialogFooter() {
        return (
            <>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => this.setState({ deleteDialog: false })} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => this.deleteFavorite()} />
            </>
        )
    }

    saveFavorite(){
        let params = {
            "id" : this.state.stk,
            "name":this.state.name,
            "note":this.state.note,
            "stock" : this.props.parent.ChartAnalysis.state.stockValue.code
        }
        this.model.editFavorite(params).then(res => {
            this.readFavorite()
        })
    }

    readFavorite(isFirst = false){
        this.setState({
            loading : true
        },() => {
            this.model.readFavorite().then(res => {
         
                let result = []
                Object.keys(res).map(k => {
                    let params = {
                        'stk' : k,
                        ...res[k]
                    }
                    result.push(params)
                })
                this.setState({
                    data : result,
                    loading : false,
                    optFavorite : res
                });
                if(isFirst){
                    let parsed = global.parsed()
                    let stk = parsed['stk']
                    if(res[stk]){
                        this.setState({
                            stk : stk,
                            name : parsed['name'],
                            note : res[stk]['note'],
                            loading : false,
                           
                        });
                    }else{
                        this.setState({
                            stk : stk,
                            name : parsed['name'],
                            loading : false,
                          
                        });
                    }
                   
    
                }
    
            })
        });
       
    }
    deleteFavorite() {
        let params = {
            "id" : this.state.stkDel
        }
        this.model.removeFavorite(params).then(res => {
            this.readFavorite()
        })
        this.setState({
            deleteDialog: false
        });
    }

    render() {
        return (
            <>
                <div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>STK </div>
                        <div>

                         
                            <InputText style={{ width: '100%' }} value={this.state.stk} onChange={(e) => {
                                this.setState({
                                    stk : e.target.value
                                });
                                 this.props.parent.getData(e.target.value , false)
                            }}/>

                        </div>

                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>NAME </div>
                        <div>

                            <InputText style={{ width: '100%' }} value={this.state.name} disabled />

                        </div>

                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>NOTE </div>
                        <div>

                            <InputTextarea style={{ width: '100%' }} value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })} cols={30} />

                        </div>

                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <Button style={{ width: '100%' }} type="button" label='SAVE TO FAVORITE' onClick={() => this.saveFavorite()} className="p-button-success " data-pr-tooltip="XLS" />
                    </div>
                    <hr></hr>
                </div>
                <div style={{overflow:'auto' , height : '330px'}}>
                    <DataTable
                        value={this.state.data}
                        stripedRows
                        responsiveLayout="scroll"
                        loading={this.state.loading}
                
                        
                    >
                        <Column field="index"  header="Index" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="name" sortable header="Name" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="percentile" sortable header="Rank" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="stock" header="Stock" style={{fontSize : '11px'}} ></Column>
                        <Column field="note" header="Note" style={{fontSize : '11px'}} ></Column>
                        <Column field="act" header="" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    </DataTable>

                    <Dialog
                        visible={this.state.deleteDialog}
                        style={{ width: '450px' }}
                        header="Confirm"
                        modal
                        onHide={() => this.setState({ deleteDialog: false })}
                        footer={() => this.deleteDialogFooter()}
                    >
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.stkDel && <span>Are you sure you want to delete STK : <b>{this.state.stkDel}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </>

        );
    }
}

export default TableFavorite;