import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import Banner from '../Banner';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
class OneStockSell extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            { field: 'index', header: 'Index', body: true },
            { field: 'id', header: 'STK', body: true, filter: false },
            { field: 'name', header: 'Name', body: true, filter: false, style: { maxWidth: '14rem' } },


            { field: 'sell', header: 'Sell', body: true, sort: true },
            { field: 'sell_order', header: 'Sell Order', body: true },
            { field: 'price', header: 'Price', body: true },
            // { field: 'position', header: 'Position', body: true },
            { field: 'indv_position', header: 'Position', body: true },
            // { field: 'type', header: 'Type', body: true },
            { field: 'label', header: 'Label', body: true },

            { field: 'percentile', header: 'Percentile' },
            { field: 'group', header: 'Group' },

            { field: 'buy', header: 'Buy', body: true },
            { field: 'buy_order', header: 'Buy Order', body: true },
            { field: 'icon', header: '', body: true },




        ];
        this.columnsCTCK = [
            { field: 'index', header: 'Index', body: true },
            { field: 'ctck_id', header: 'ID', filter: false },
            { field: 'ctck_name', header: 'Name', filter: false, style: { maxWidth: '14rem' } },
            { field: 'id', header: 'Num STK', filter: false },
            { field: 'num_sell', header: 'Num Sell',  filter: false },

            { field: 'sell', header: 'Sell', body: true, sort: true },
            { field: 'sell_order', header: 'Sell Order', body: true },


            { field: 'price', header: 'Price', body: true },
            { field: 'position', header: 'Position', body: true },
            { field: 'percentile', header: 'Percentile' },

            { field: 'buy', header: 'Buy', body: true },
            { field: 'buy_order', header: 'Buy Order', body: true },

            { field: 'val_buy', header: 'Val Buy', body: true },
            { field: 'val_sell', header: 'Val Sell', body: true },
            { field: 'icon', header: '', body: true },


        ];
        this.state = {
            data: [],
            loading: false,
            totalRecords: 0,
            first: 0,
            quantity: 25,
            page: 1,
            sortField: 'sell',
            sortOrder: -1,
            filters: {

            },
            selectedColumns: [
                { field: 'index', header: 'Index', body: true },
                { field: 'id', header: 'STK', body: true, filter: false },
                { field: 'name', header: 'Name', body: true, filter: false, style: { maxWidth: '14rem' } },


                { field: 'sell', header: 'Sell', body: true, sort: true },
                { field: 'sell_order', header: 'Sell Order', body: true },
                { field: 'price', header: 'Price', body: true },
                // { field: 'position', header: 'Position', body: true },
                { field: 'indv_position', header: 'Position', body: true },
                // { field: 'type', header: 'Type', body: true },
                { field: 'label', header: 'Label', body: true },
                { field: 'icon', header: '', body: true },




            ],
            selectedColumnsCTCK: [
                { field: 'index', header: 'Index', body: true },
                { field: 'ctck_id', header: 'ID', filter: false },
                { field: 'ctck_name', header: 'Name', filter: false, style: { maxWidth: '14rem' } },
                { field: 'id', header: 'Num STK', filter: false },
                { field: 'num_sell', header: 'Num Sell',  filter: false },
                { field: 'sell', header: 'Sell', body: true, sort: true },
                { field: 'sell_order', header: 'Sell Order', body: true },
                { field: 'price', header: 'Price', body: true },
                { field: 'position', header: 'Position', body: true },


            ],







        }





        this.loadLazyTimeout = null;


    }



    setLoading() {
        this.setState({ loading: true });
    }


    setData(data, rankByCTCK) {
        let sellFooter = 0
        let sellOrderFooter = 0
        data.map(item => {
            sellFooter += Number(item['sell'])
            sellOrderFooter += Number(item['sell_order'])
        })

        data.sort(function (a, b) { return b.sell - a.sell });
        this.setState({
            data: data,
            loading: false,
            sellFooter,
            sellOrderFooter,
            rankByCTCK
        });

    }

    onSort(event) {
        this.setState({
            sortField: event.sortField,
            sortOrder: -1
        });

        // this.props.setSort(event.sortField , event.sortOrder )

    }



    onColumnToggle(event) {
        if (this.state.rankByCTCK) {
            let selectedColumns = event.value;
            let orderedSelectedColumns = this.columnsCTCK.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
            this.setState({ selectedColumnsCTCK: orderedSelectedColumns });
        } else {
            let selectedColumns = event.value;
            let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
            this.setState({ selectedColumns: orderedSelectedColumns });
        }

    }
    representativeTemplate = (data, props) => {
        if (props.field == "icon") {
            if (data['value'] && data['value'].length > 1) {
                return
            }

            return (
                <div style={{ cursor: 'pointer' }}
                    onClick={() => window.open(`${window.location.origin}/#/portfolio?stk=${data['id']}&name=${data['name']}`)}
                >

                    <i className="pi pi-search" style={{ 'fontSize': '1em', 'lineHeight': 0 }}></i>
                </div>
            )
        }
        if (props.field == "id") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/?id=${data[props.field]}`)}>{data[props.field]}</span>
            )
        }

        if (props.field == "name") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/?name=${data[props.field]}`)}>{data[props.field]}</span>
            )
        }


        if (props.field == "index") {


            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>{props.rowIndex + 1}</span>
                    {data.type == 'Insider' && <i class="pi pi-star-fill" style={{ color: 'orange' }}></i>}

                </div>

            )
        }

        let col = ['buy', 'buy_order', 'sell', 'sell_order']

        if (col.includes(props.field)) {
            if (data[props.field] == null) return 'NULL'

            let result = global.formatNumber(data[props.field])
            if (result == 0) result = '-'

            let color
            if (props.field == 'buy') {
                color = 'limegreen'
            }
            if (props.field == 'sell') {
                color = 'orange'
            }



            return (
                <span style={result != '-' ? { color: color } : {}}>{result}</span>
            )

        }

        if (props.field == "type") {

            let result = data[props.field]

            if (result == 'Unknown') result = '-'

            return (
                <span >{result}</span>
            )
        }
        if (props.field == "label") {

            let result = data[props.field]

            if (result == 'Unknown') result = '-'

            return (
                <span >{result}</span>
            )
        }

        if (props.field == "price" || props.field == 'indv_position' || props.field == 'position') {

            if (data[props.field] == null) return ''

            let result = global.formatNumber(Math.floor(data[props.field]))
            return (
                
                <span style={props.field == 'indv_position'  || props.field == 'position' & this.props.color_position ? {color : 'blue'} : {} } >

                {result}
            </span>

            )


        }








    }

    exportExcel() {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'one_stock');

        });
    }
    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }

    renderColumnComponents() {
        if (this.state.rankByCTCK) {
            return this.state.selectedColumnsCTCK.map(col => {
                if (col.body) {
                    if (col.filter) {
                        return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                    }
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
                }
                if (col.filter) {
                    return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


            });
        } else {
            return this.state.selectedColumns.map(col => {
                if (col.body) {
                    if (col.filter) {
                        return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                    }
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
                }
                if (col.filter) {
                    return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


            });
        }

    }




    render() {
        const header = (
            <div style={{ textAlign: 'left', position: 'relative' }}>
                <Banner text='Top Sell' icon='pi pi-star' sell={true} ></Banner>
                {
                    this.state.rankByCTCK ?
                        <MultiSelect value={this.state.selectedColumnsCTCK} options={this.columnsCTCK} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} style={{ width: '20em' }} />
                        :
                        <MultiSelect value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} style={{ width: '20em' }} />
                }

            </div>
        );

        let footerGroup = (
            <ColumnGroup>
                <Row >
                    <Column colSpan={this.state.rankByCTCK ? 5 : 3} />
                    <Column footer={global.formatNumber(Math.round(this.state.sellFooter))} footerStyle={{ color: 'orange' }} />
                    <Column footer={global.formatNumber(Math.round(this.state.sellOrderFooter))} footerStyle={{ color: 'orange' }} />
                </Row>
            </ColumnGroup>
        );
        return (
            <DataTable

                className="p-datatable-customers" value={this.state.data}
                dataKey="id"
                stripedRows
                emptyMessage="No data."
                lazy loading={this.state.loading}
                sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                onFilter={(event) => this.onFilter(event)}
                // onSort={(event) => this.onSort(event)}
                responsiveLayout="scroll"
                header={header}
                footerColumnGroup={footerGroup}

            >

                {this.renderColumnComponents()}
            </DataTable>


        )
    }
}

export default OneStockSell;