import model from "../model";
import axios from "axios";
import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class TopBuySell {

    constructor() {
        // this.url = 'https://d11.f5traders.com/api'
        this.url = domain_config(1)
        this.queryInfo = new QueryInfo()
    }

    getTopBuySell(filter, isDashboard,rankByGroup) {
        let key = global.genKey(filter)
		if (global.data_all_stock && global.data_all_stock[key]) {
            this.queryInfo.info(filter , `${this.url}/db11-endpoint-top-buy-sell` )
			return Promise.resolve(global.data_all_stock[key])
		} else {
            return axios.request({
                url: `${this.url}/db11-endpoint-top-buy-sell`,
                method: "POST",
                data: { ...filter }
            })

                .then(response => {

                    response = response['data'];


                    let outBuy = {}
                    let outSell = {}
                    Object.keys(response).map(mode => {    //mode = buy----selll
                        let col = Object.keys(response[mode])
                        let length = response[mode][col[0]].length


                        for (let index = 0; index < length; index++) {
                            let dataCol = {}
                            col.map(item => {
                                // if (item != 'id' ) {
                                    if (!dataCol['value']) dataCol['value'] = {}
                                    dataCol['value'][item] = response[mode][item][index]

                                // }
                                dataCol[item] = response[mode][item][index]



                            })

                            if(rankByGroup){
                                // debugger
                                if (mode == 'buy') {
                                    let ks = dataCol['group'] != '' ? dataCol['group'] : dataCol['name']
                                    if (outBuy[ks]) {
                                        outBuy[ks]['value'].push(dataCol['value'])
    
                                        Object.keys(dataCol['value']).map(col => {
                                            if (col != 'day' && col != 'group' && col != 'val_group_buy' && col != 'name'  ) {
    
                                                outBuy[ks][col] += Number(dataCol['value'][col])
    
                                            }
                                        })
                                    } else {
                                        outBuy[ks] = {
                                            ...dataCol, value: [dataCol['value']]
                                        }
                                    }
    
                                } else {
                                    let ks = dataCol['group'] != '' ? dataCol['group'] : dataCol['name']
                                    if (outSell[ks]) {
                                        outSell[ks]['value'].push(dataCol['value'])
    
                                        Object.keys(dataCol['value']).map(col => {
                                            if (col != 'day' && col != 'group' && col != 'val_group_sell' && col != 'name') {
    
                                                outSell[ks][col] += Number(dataCol['value'][col])
    
                                            }
                                        })
                                    } else {
                                        outSell[ks] = {
                                            ...dataCol, value: [dataCol['value']]
                                        }
                                    }
                                }
    
                            }else{
                                if (mode == 'buy') {
                                    if (outBuy[dataCol['id']]) {
                                        outBuy[dataCol['id']]['value'].push(dataCol['value'])
    
                                        Object.keys(dataCol['value']).map(col => {
                                            if (col != 'day' && col != 'name') {
    
                                                outBuy[dataCol['id']][col] += Number(dataCol['value'][col])
    
                                            }
                                        })
                                    } else {
                                        outBuy[dataCol['id']] = {
                                            ...dataCol, value: [dataCol['value']]
                                        }
                                    }
    
                                } else {
                                    if (outSell[dataCol['id']]) {
                                        outSell[dataCol['id']]['value'].push(dataCol['value'])
    
                                        Object.keys(dataCol['value']).map(col => {
                                            if (col != 'day' && col != 'name') {
    
                                                outSell[dataCol['id']][col] += Number(dataCol['value'][col])
    
                                            }
                                        })
                                    } else {
                                        outSell[dataCol['id']] = {
                                            ...dataCol, value: [dataCol['value']]
                                        }
                                    }
                                }
    
                                
                            }


                           
                        }



                    })



                    let out = {
                        buy: Object.values(outBuy),
                        sell: Object.values(outSell),
                    }
                    global.Top10BuySell = {
                        dashboard: response,
                        table: out
                    }
                    if (isDashboard) {
                        return response
                    }

                    if (!global.data_all_stock) {
						global.data_all_stock = {}
					}

					global.data_all_stock[key] = out;
                    return out;
                })

                .catch((error) => {
                    if (error.response.status == 401) {
                        window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent(window.location.href)}`
                        return false;
                    }
                    console.log(error);
                    return false;
                })

        }
            
     

    }






}

export default TopBuySell;