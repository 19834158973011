import React, { Component } from 'react';

import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';

import { Toast } from 'primereact/toast';
class SettingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode : localStorage.getItem('mode')
        }
    }

    OnSave() {
        localStorage.setItem('mode', this.state.mode)
        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Save Domain Success', life: 3000 });
        window.location.reload();
    }

    render() {
        return (

            <div className='card' style={{display : 'flex'}} >
                 <Toast ref={(el) => this.toast = el} />
                <div className="p-col-6 p-md-6" style={{width : '50%'}} >
                   
                    <h5> SELECT DOMAIN</h5>
                    <div className="field-radiobutton">
                        <RadioButton inputId="city1" value="mx" onChange={(e) => this.setState({  mode : 'mx' })} checked={this.state.mode === 'mx'} />
                        <label htmlFor="city1">MX</label>
                    </div>

                    <div className="field-radiobutton">
                        <RadioButton inputId="city2" value="rz2" onChange={(e) => this.setState({  mode : 'rz2' })} checked={this.state.mode === 'rz2'} />
                
                        <label htmlFor="city2">RZ2</label>
                    </div>

                 
                    <Button label="Save" onClick={() => this.OnSave()} />
                </div>

          
            </div>
        );
    }
}

export default SettingView;