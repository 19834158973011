import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import moment from 'moment';
import TranGroup from '../models/admin/TranGroup';
import Transaction from '../models/admin/Transaction';
import Banner from '../components/Banner';
import { RadioButton } from 'primereact/radiobutton';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import Type from '../models/admin/Type';


class TypeView extends Component {
    constructor(props) {
        super(props);
        this.model = new Type()
        this.columns = [
            { "field": "index", "header": "Index", "body": true },
            { "field": "label1", "header": "Type", "body": false },

            { "field": "buy", "header": "Total buy", "body": true },
            { "field": "sell", "header": "Total sell", "body": true },
            { "field": "buy_order", "header": "Total buy order", "body": true },
            { "field": "sell_order", "header": "Total sell order", "body": true }
        ];
        this.columnsDay = [
            { "field": "index", "header": "Index", "body": true },
            { "field": "day", "header": "Day", "body": true },

            { "field": "buy", "header": "Total buy", "body": true },
            { "field": "sell", "header": "Total sell", "body": true },
            { "field": "buy_order", "header": "Total buy order", "body": true },
            { "field": "sell_order", "header": "Total sell order", "body": true }
        ];
        this.state = {
            // mode: 'Type',
            mode: 'Day',

            data: [],
            loading: false,
            filters: {},
            selectedColumns: this.columns,
            expandedRows: null,


            optionsStock: [],
            optionsType: [],

            endDay: null,
            fromDay: null,

            data_start: null,
            data_end: null,


        }



        this.loadLazyTimeout = null;
    }

    getQuerry() {
        let model = new Transaction()
        model.query().then(response => {
            let stockOpt = []
            response['categories']['stock'].map(item => {
                stockOpt.push({ name: item, code: item },)
            })
            let typeOpt = []
            response['categories']['label1'].map(item => {
                typeOpt.push({ name: item, code: item },)
            })

            this.setState({
                optionsStock: stockOpt,
                optionsType : typeOpt
            }, () => this.getData());
        }).catch((error) => {
            console.log(error);
        })
    }

    getData() {
    
        let parsed = global.parsed()
        if(parsed['type']){
            this.setState({
                filters :{
                    "type": {
                        "col_name": "type",
                        "operator": "in",
                        "value": [parsed['type']]
                    }
                },
                typeValue : [{name : parsed['type'] , code : parsed['type']}]
            });
        }
       
       
        clearTimeout(this.loadLazyTimeout);
        this.loadLazyTimeout = setTimeout(() => {
            this.setState({ loading: true });

            let filter = {}
            if (this.state.data_start && this.state.data_end) {
                filter['data_filter'] =
    
                    [
                        {
                            "col_name": "day",
                            "operator": "from",
                            "value": [
                                moment(this.state.data_start).format('YYYY/MM/DD'),
                                moment(this.state.data_end).format('YYYY/MM/DD'),
                            ]
                        }
                    ]
    
            }
            let filterLocal = {
                data_filter: Object.values(this.state.filters),
            }
            this.model.getData(filter, this.state.mode , filterLocal).then(res => {
                console.log(res)
                let expandedRows = {}
                if (res['data'].length > 0) {
                    var datakey
                    if (this.state.mode == 'Type') {
                        datakey = 'label1'
                        res['data'].sort((a, b) => a.label1 > b.label1 ? 1 : -1);
                    } else {
                        datakey = this.state.mode.toLowerCase()
                    }
                    expandedRows[res['data'][0][datakey]] = true
                }
                this.setState({
                    data: res['data'],
                    loading: false,
                    expandedRows: expandedRows,
                    selectedColumns: this.state.mode == 'Day' ? this.columnsDay : this.columns,
    
                });
                // debugger
                if (!this.state.data_start && !this.state.data_end) {
                    this.setState({
                        data_start: res['min_day'],
                        data_end: res['max_day'],
                    });
    
                }
            })


        }, 500);
    }

    change(value, col, filterkey, type) {
        var filter = this.state.filters;
        if (type == 'text') {
            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": value.trim()
                }
            }
        } else if (type == 'date') {
            if (value == null) {
                delete filter[filterkey];

            } else {
                let dateData = []
                value.map(item => {
                    dateData.push(moment(item).format("YYYY/MM/DD"))
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dateData,
                }
                this.setState({
                    fromDay: null,
                    endDay: null,
                })
            }
        } else if (type == 'multi') {
            if (value.length == 0) {
                delete filter[filterkey];
            } else {
                let dataMul = []
                value.map(item => {
                    dataMul.push(item['code'])
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dataMul
                }
            }
        }
        else {
            if (typeof (value) == 'string' && value != '') {

                this.setState({
                    [col]: value,
                });
                return
            }

            if (value == undefined) return

            if (value == '') {
                delete filter[filterkey];
            } else {

                let name = value['name']
                let check = name.indexOf('(Σ:')
                if (check > -1) {
                    name = name.substring(0, check)
                    name = name.trim()
                }
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": name
                }
                value['name'] = name
                value['code'] = name


            }

        }
        this.setState({
            [col]: value,
            filters: filter,
        }, () => this.filterData())


    }
    changeDate(col, value, filterkey) {
        var filter = this.state.filters;
        this.setState({
            [col]: value,
        });

        if (col == 'fromDay') {
            if (this.state.endDay !== null && value !== null) {
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "from",
                    "value": [moment(value).format("YYYY/MM/DD"), moment(this.state.endDay).format("YYYY/MM/DD")],
                }
                this.setState({
                    filters: filter,
                    dateValue: null
                }, () => {
                    this.filterData()
                });
            }

        } else {
            if (this.state.fromDay !== null && value !== null) {
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "from",
                    "value": [moment(this.state.fromDay).format("YYYY/MM/DD"), moment(value).format("YYYY/MM/DD")],
                }
                this.setState({
                    filters: filter,
                    dateValue: null
                }, () => {
                    this.filterData()
                });
            }
        }



    }
    filterData() {
        clearTimeout(this.loadLazyTimeout);
        this.loadLazyTimeout = setTimeout(() => {
            this.setState({ loading: true });
            let filter = {
                data_filter: Object.values(this.state.filters),
            }

            console.log(this.state.filters)
            this.model.filterData(filter, this.state.mode).then(res => {



                let expandedRows = {}
                if (res['data'].length > 0) {
                    var datakey
                    if (this.state.mode == 'Type') {
                        datakey = 'label1'
                        res['data'].sort((a, b) => a.label1 > b.label1 ? 1 : -1);
                    } else {
                        datakey = this.state.mode.toLowerCase()
                    }
                    expandedRows[res['data'][0][datakey]] = true
                }

                this.setState({
                    data: res['data'],
                    expandedRows: expandedRows,
                    loading: false,
                    selectedColumns: this.state.mode == 'Day' ? this.columnsDay : this.columns,
                });
            })

        }, 500);

    }

    changeMode(mode) {
        this.setState({
            mode: mode
        }, () => this.filterData());
    }

    componentDidMount() {
        this.getQuerry()
    }
    rowExpansionTemplate(data) {

        if (this.state.mode == 'Type') {
            return (
                <div className="sub_table">
                    <DataTable value={data.value} responsiveLayout="scroll">
                        <Column style={{ width: '3em' }} />
                        {/* <Column style={{ width: '13rem' }}  ></Column> */}
                        {/* <Column field='label1' header='Type'  ></Column> */}
                        <Column field='day' header='Day' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='stock' header='Stock' sortable   ></Column>
                        <Column field='id' header='STK' sortable  ></Column>
                        <Column field='account' header='ID' sortable  ></Column>
                        <Column field='name' header='Name' sortable  ></Column>
                        <Column field='address' header='Address' sortable  ></Column>
                        {/* <Column field='insider_trade' header='Insider trade'  ></Column>
                        <Column field='label1_details' header='Label1 Details'  ></Column> */}
                        <Column field='buy' header='Buy' body={(data, props) => this.representativeTemplate(data, props)}  sortable ></Column>
                        <Column field='sell' header='Sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='buy_order' header='Buy order' body={(data, props) => this.representativeTemplate(data, props)} sortable   ></Column>
                        <Column field='sell_order' header='Sell order' body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        {/* <Column field='percentile' header='percentile'   ></Column>
                        <Column field='price' header='price'   ></Column>
                        <Column field='type_ah' header='type_ah'   ></Column> */}
                         <Column field='label1_details' header='Group' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>




                    </DataTable>
                </div>
            );
        }

        if (this.state.mode == 'Day') {
            return (
                <div className="sub_table">
                    <DataTable value={data.value} responsiveLayout="scroll">
                        <Column style={{ width: '3em' }} />

                        <Column field='label1' header='Type' sortable  ></Column>
                        {/* <Column field='day' header='Day' body={(data, props) => this.representativeTemplate(data, props)} ></Column> */}
                        <Column field='stock' header='Stock' sortable   ></Column>
                        <Column field='id' header='STK' sortable  ></Column>
                        <Column field='account' header='ID' sortable  ></Column>
                        <Column field='name' header='Name' sortable ></Column>
                        <Column field='address' header='Address' sortable  ></Column>

                        <Column field='buy' header='Buy' body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='sell' header='Sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='buy_order' header='Buy order' body={(data, props) => this.representativeTemplate(data, props)} sortable   ></Column>
                        <Column field='sell_order' header='Sell order' body={(data, props) => this.representativeTemplate(data, props)}  sortable  ></Column>
                        <Column field='label1_details' header='Group' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    </DataTable>
                </div>
            );
        }



    }
    expandAll() {
        let expandedRows = {};
        this.state.data.forEach((p, index) => {
            if (index < 5) {
                expandedRows[`${p.day}`] = true
            } else {
                return
            }
        });

        this.setState({
            expandedRows
        });
    }
    collapseAll() {
        this.setState({
            expandedRows: null
        });
    }

    representativeTemplate = (data, props) => {

        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }

        let col = ['buy', 'sell', 'buy_order', 'sell_order']

        if (col.includes(props.field)) {
            let result = Math.round(data[props.field] * 100) / 100
            if (result == 0) {
                result = '-'
            } else {
                result = global.formatNumber(result)
            }
            return (
                <span>{result}</span>
            )
        }

        if (props.field == "day") {
            let reuslt = data[props.field].replaceAll('_', '/')
            reuslt = moment(reuslt).format('DD - MMM')
            return (
                <span >{reuslt}</span>
            )
        }

        // if (props.field == "group_ah" || props.field == 'id') {
        //     let group = []
        //     let id = []

        //     data['value'].map(item => {
        //         if (group.indexOf(item['group_ah']) < 0) {
        //             group.push(item['group_ah'])
        //         }
        //         if (id.indexOf(item['id']) < 0) {
        //             id.push(item['id'])
        //         }
        //     })

        //     if (props.field == "group_ah") {

        //         return (
        //             <span >{group.length}</span>
        //         )
        //     } else {

        //         return (
        //             <span >{id.length}</span>
        //         )
        //     }


        // }

  
        if (props.field == "label1_details") {
            
            return (
                <span style={{cursor : 'pointer'}} onClick={() => window.open(`${window.location.origin}/#/groups?group=${data[props.field]}`)} >{data[props.field]}</span>
            )
        }



    }
    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }

    clearFilter(col) {

        if (Object.keys(this.state.filters).length == 0) return
        if (col) {
            let value = `${col}Value`
            if (col == 'day') {
                value = `dateValue`
                this.setState({
                    [value]: [],
                    fromDay: [],
                    endDay: [],
                });
            }
            else {
                this.setState({
                    [value]: ''
                });
            }

            var filter = this.state.filters;
            delete filter[col];
            this.setState({
                filters: filter,
            }, () => this.filterData());
            return
        } else {
            Object.keys(this.state.filters).map(item => {
                if (item == 'day') {
                    let value = `dateValue`

                    this.setState({
                        [value]: [],
                        fromDay: [],
                        endDay: [],
                    });
                } else {
                    let value = `${item}Value`

                    this.setState({
                        [value]: ''
                    });
                }

            })
            this.setState({
                filters: {},
            }, () => this.filterData());
        }


    }

    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                <Banner text='Type' icon='pi pi-slack'></Banner>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ marginTop: '10px' }}>
                        {Object.keys(this.state.filters).length} filter : {
                            Object.keys(this.state.filters).map(item => {
                                let value1 = this.state.filters[item]['value']
                                if (item == 'day') {
                                    value1 = value1.map(item => moment(item).format('DD - MMM'))

                                }
                                return (
                                    <b onClick={() => this.clearFilter(item)} style={{ cursor: 'pointer' }}>
                                        <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                        {`     ${this.state.filters[item]['operator']} '${item}' `}    |
                                    </b>)
                            }

                            )
                        }

                        {
                            Object.keys(this.state.filters).length > 0 && <Button onClick={() => this.clearFilter()} style={{ position: 'relative', top: '7px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                        }
                    </div>

                    <div>
                        <Button label="Expand Top 5" onClick={() => this.expandAll()} className="ml-2 mr-2" />
                        <Button label="Collapse All" onClick={() => this.collapseAll()} className="ml-2 mr-2" />
                    </div>

                </div>


            </div>
        );
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });
        return (
            <div className="p-col-12 p-md-12" style={{ display: 'flex' }}>
                <div className="card widget-table" style={{ width: '80%' }} >
                    <DataTable

                        className="p-datatable-customers" value={this.state.data}
                        dataKey={this.state.mode == 'Type' ? 'label1' : this.state.mode.toLowerCase()}
                        stripedRows
                        emptyMessage="No data."
                        lazy loading={this.state.loading}
                        onFilter={(event) => this.onFilter(event)}
                        responsiveLayout="scroll"
                        header={header}

                        // headerColumnGroup={headerGroup}
                        expandedRows={this.state.expandedRows}
                        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={(data) => this.rowExpansionTemplate(data)}
                    >
                        <Column expander style={{ width: '3em' }} />
                        {columnComponents}
                    </DataTable>

                </div>

                <div className="card" style={{ width: '20%', marginLeft: '25px' }}>

                    <div style={{ marginBottom: '10px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>CACHED DATA</div>
                        <div style={{ display: 'flex' }}>
                            <Calendar style={{ width: '50%', marginRight: '5px' }} id="multiple-a" dateFormat="dd - M" value={this.state.data_start} onChange={(e) => this.setState({ data_start: e.value })} readOnlyInput showButtonBar />
                            <Calendar style={{ width: '50%' }} id="multiple-b" dateFormat="dd - M" value={this.state.data_end} onChange={(e) => this.setState({ data_end: e.value })} readOnlyInput showButtonBar />
                        </div>
                        {/* <div style={{ marginTop: '10px' }}>

                            {
                                this.DayOpt.map(item => {

                                    return (
                                        <Button key={item['name']} label={item['name']} className="p-button-raised p-button-text mr-2" onClick={() => this.genDay(item)} />
                                    )
                                })
                            }




                        </div> */}
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            <Button label="Reload Cached" onClick={() => { this.getData() }} />
                        </div>
                    </div>
                    <hr></hr>

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT COLUMN </div>
                        <div>
                            <MultiSelect value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY STOCK </div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.stockValue} onChange={(e) => this.change(e.value, 'stockValue', 'stock', 'multi')} options={this.state.optionsStock} optionLabel="name" display="chip" />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY DATE</div>
                        <div>
                            <   Calendar style={{ width: '100%' }} dateFormat="dd - M" id="multiple" value={this.state.dateValue} onChange={(e) => this.change(e.value, 'dateValue', 'day', 'date')} selectionMode="multiple" readOnlyInput showButtonBar />
                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>FROM DATE</div>
                        <div>
                            <Calendar style={{ width: '100%' }} id="multiple5" dateFormat="dd - M" value={this.state.fromDay} onChange={(e) => this.changeDate('fromDay', e.value, 'day')} readOnlyInput showButtonBar />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>TO DATE</div>
                        <div>
                            <Calendar style={{ width: '100%' }} id="multiple2" dateFormat="dd - M" value={this.state.endDay} onChange={(e) => this.changeDate('endDay', e.value, 'day')} readOnlyInput showButtonBar />
                        </div>
                    </div>

                    
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY TYPE </div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.typeValue} onChange={(e) => this.change(e.value, 'typeValue', 'type', 'multi')} options={this.state.optionsType} optionLabel="name" display="chip" />
                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT LAYOUT </div>
                        <div style={{ display: 'flex', background: '#f1f1f5', borderRadius: '8px', padding: '8px' }}>
                            <div className="field-radiobutton" style={{ flex: 1, margin: 0 }} >
                                <RadioButton value="Day" onChange={(e) => this.changeMode(e.value)} checked={this.state.mode === 'Day'} />
                                <label htmlFor="city1">Day</label>
                            </div>
                            <div className="field-radiobutton" style={{ flex: 1, margin: 0 }} >
                                <RadioButton value="Type" onChange={(e) => this.changeMode(e.value)} checked={this.state.mode === 'Type'} />
                                <label htmlFor="city1">Type</label>
                            </div>
                        </div>
                    </div>


                </div>


            </div>
        );
    }
}

export default TypeView;