import React, { Component } from 'react';
import TopBuy from '../components/TopBuySell/TopBuy';
import TopSell from '../components/TopBuySell/TopSell';
import { SelectButton } from 'primereact/selectbutton';

import TopBuySellModel from '../models/admin/TopBuySell';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { Button } from 'primereact/button';
import TransactionModel from '../models/admin/Transaction';
import { MultiSelect } from 'primereact/multiselect';
import TopBuyGroup from '../components/TopBuySell/TopBuyGroup';
import TopSellGroup from '../components/TopBuySell/TopSellGroup';
class TopBuySell extends Component {

    constructor(props) {
        super(props);
        this.state = {

            valueDay: null,
            fromDay: null,
            endDay: null,
            filters: {},
            maxDay: null,
            selectedColumns: [
                { field: 'index', header: 'Index', body: true },
                { field: 'name', header: 'Name', body: true, style: { width: '25rem' } },
                { field: 'DXG', header: 'DXG', body: true },
                { field: 'HPG', header: 'HPG', body: true },
                { field: 'MBB', header: 'MBB', body: true },
                { field: 'MSN', header: 'MSN', body: true },
                { field: 'MWG', header: 'MWG', body: true },
                { field: 'SSI', header: 'SSI', body: true },
                { field: 'STB', header: 'STB', body: true },
                { field: 'TCB', header: 'TCB', body: true },
                { field: 'VIC', header: 'VIC', body: true },
                { field: 'VND', header: 'VND', body: true },
                { field: 'VPB', header: 'VPB', body: true },
                { field: 'NVL', header: 'NVL', body: true },
                { field: 'HDB', header: 'HDB', body: true },
                { field: 'EIB', header: 'EIB', body: true },
                { field: 'VRE', header: 'VRE', body: true },

                { field: 'VHM', header: 'VHM', body: true },
                { field: 'CTG', header: 'CTG', body: true },
                { field: 'VCB', header: 'VCB', body: true },
                { field: 'HSG', header: 'HSG', body: true },
                { field: 'FPT', header: 'FPT', body: true },
                { field: 'VNM', header: 'VNM', body: true },
                { field: 'ACB', header: 'ACB', body: true },
                { field: 'DIG', header: 'DIG', body: true },
                // { field: 'PVD', header: 'PVD', body: true },
                { field: 'MSB', header: 'MSB', body: true },

                { field: 'PNJ', header: 'PNJ', body: true },
                { field: 'FRT', header: 'FRT', body: true },
                { field: 'DGC', header: 'DGC', body: true },
                // { field: 'KBC', header: 'KBC', body: true },

                { field: 'total', header: 'Total', body: true },
                { field: 'day_val', header: 'Day Val', body: true },
            ],

            selectedColumnsGroup: [
                { field: 'index', header: 'Index', body: true },

                { field: 'group', header: 'Group/Name', body: true, filter: false },

                { field: 'DXG', header: 'DXG', body: true },
                { field: 'HPG', header: 'HPG', body: true },
                { field: 'MBB', header: 'MBB', body: true },
                { field: 'MSN', header: 'MSN', body: true },
                { field: 'MWG', header: 'MWG', body: true },
                { field: 'SSI', header: 'SSI', body: true },
                { field: 'STB', header: 'STB', body: true },
                { field: 'TCB', header: 'TCB', body: true },
                { field: 'VIC', header: 'VIC', body: true },
                { field: 'VND', header: 'VND', body: true },
                { field: 'VPB', header: 'VPB', body: true },
                { field: 'NVL', header: 'NVL', body: true },
                { field: 'HDB', header: 'HDB', body: true },
                { field: 'EIB', header: 'EIB', body: true },
                { field: 'VRE', header: 'VRE', body: true },

                { field: 'VHM', header: 'VHM', body: true },
                { field: 'CTG', header: 'CTG', body: true },
                { field: 'VCB', header: 'VCB', body: true },
                { field: 'HSG', header: 'HSG', body: true },
                { field: 'FPT', header: 'FPT', body: true },
                { field: 'VNM', header: 'VNM', body: true },
                { field: 'ACB', header: 'ACB', body: true },
                { field: 'DIG', header: 'DIG', body: true },
                // { field: 'PVD', header: 'PVD', body: true },
                { field: 'MSB', header: 'MSB', body: true },

                { field: 'PNJ', header: 'PNJ', body: true },
                { field: 'FRT', header: 'FRT', body: true },
                { field: 'DGC', header: 'DGC', body: true },
                // { field: 'KBC', header: 'KBC', body: true },

                { field: 'sum', header: 'Total', body: true },
                // { field: 'val_group', header: 'Total Group', body: true },
            ],
            matchedOnly: true,
            rankByGroup: true,
            foreignOnly: false,
            top: '20',


        };
        this.columns = [
            { field: 'index', header: 'Index', body: true },
            { field: 'id', header: 'STK', body: true },
            { field: 'name', header: 'Name', body: true, style: { width: '25rem' } },
            { field: 'DXG', header: 'DXG', body: true },
            { field: 'HPG', header: 'HPG', body: true },
            { field: 'MBB', header: 'MBB', body: true },
            { field: 'MSN', header: 'MSN', body: true },
            { field: 'MWG', header: 'MWG', body: true },
            { field: 'SSI', header: 'SSI', body: true },
            { field: 'STB', header: 'STB', body: true },
            { field: 'TCB', header: 'TCB', body: true },
            { field: 'VIC', header: 'VIC', body: true },
            { field: 'VND', header: 'VND', body: true },
            { field: 'VPB', header: 'VPB', body: true },
            { field: 'NVL', header: 'NVL', body: true },
            { field: 'HDB', header: 'HDB', body: true },
            { field: 'EIB', header: 'EIB', body: true },
            { field: 'VRE', header: 'VRE', body: true },

            { field: 'VHM', header: 'VHM', body: true },
            { field: 'CTG', header: 'CTG', body: true },
            { field: 'VCB', header: 'VCB', body: true },
            { field: 'HSG', header: 'HSG', body: true },
            { field: 'FPT', header: 'FPT', body: true },
            { field: 'VNM', header: 'VNM', body: true },
            { field: 'ACB', header: 'ACB', body: true },
            { field: 'DIG', header: 'DIG', body: true },
            // { field: 'PVD', header: 'PVD', body: true },
            { field: 'MSB', header: 'MSB', body: true },

            { field: 'PNJ', header: 'PNJ', body: true },
            { field: 'FRT', header: 'FRT', body: true },
            { field: 'DGC', header: 'DGC', body: true },
            // { field: 'KBC', header: 'KBC', body: true },

            { field: 'total', header: 'Total', body: true },
            { field: 'day_val', header: 'Day Val', body: true },

        ];
        this.columnsGroup = [
            { field: 'index', header: 'Index', body: true },
            // { field: 'id', header: 'STK', body: true, filter: false },
            { field: 'group', header: 'Group/Name', body: true, filter: false },

            { field: 'DXG', header: 'DXG', body: true },
            { field: 'HPG', header: 'HPG', body: true },
            { field: 'MBB', header: 'MBB', body: true },
            { field: 'MSN', header: 'MSN', body: true },
            { field: 'MWG', header: 'MWG', body: true },
            { field: 'SSI', header: 'SSI', body: true },
            { field: 'STB', header: 'STB', body: true },
            { field: 'TCB', header: 'TCB', body: true },
            { field: 'VIC', header: 'VIC', body: true },
            { field: 'VND', header: 'VND', body: true },
            { field: 'VPB', header: 'VPB', body: true },
            { field: 'NVL', header: 'NVL', body: true },
            { field: 'HDB', header: 'HDB', body: true },
            { field: 'EIB', header: 'EIB', body: true },
            { field: 'VRE', header: 'VRE', body: true },

            { field: 'VHM', header: 'VHM', body: true },
            { field: 'CTG', header: 'CTG', body: true },
            { field: 'VCB', header: 'VCB', body: true },
            { field: 'HSG', header: 'HSG', body: true },
            { field: 'FPT', header: 'FPT', body: true },
            { field: 'VNM', header: 'VNM', body: true },
            { field: 'ACB', header: 'ACB', body: true },
            { field: 'DIG', header: 'DIG', body: true },
            // { field: 'PVD', header: 'PVD', body: true },
            { field: 'MSB', header: 'MSB', body: true },

            { field: 'PNJ', header: 'PNJ', body: true },
            { field: 'FRT', header: 'FRT', body: true },
            { field: 'DGC', header: 'DGC', body: true },
            // { field: 'KBC', header: 'KBC', body: true },
            { field: 'sum', header: 'Total', body: true },
            // { field: 'val_group', header: 'Total Group', body: true },

        ];
        this.options = ['Day', 'Multi Day'];

        this.tranModel = new TopBuySellModel()

        this.loadLazyTimeout = null;

        this.DayOpt = [
            {
                'name': '1w',
                'day': 1,
                'type': 'weeks'
            },
            {
                'name': '2w',
                'day': 2,
                'type': 'weeks'
            },
            {
                'name': '1m',
                'day': 1,
                'type': 'months'
            },
            {
                'name': '2m',
                'day': 2,
                'type': 'months'
            },
            {
                'name': '3m',
                'day': 3,
                'type': 'months'
            },
        ]

        this.DayOpt2 = [
            {
                'name': '1D',
                'day': 1,
                'type': 'days'
            },
            {
                'name': '2D',
                'day': 2,
                'type': 'days'
            },
            {
                'name': '3D',
                'day': 3,
                'type': 'days'
            },
            {
                'name': '1W',
                'day': 1,
                'type': 'weeks'
            },
        ]
    }


    componentDidMount() {

        // this.getData()
        this.getMaxday()


    }
    getMaxday() {
        let model = new TransactionModel()
        model.query().then(res => {
            this.setState({
                valueDay: [new Date(res['max_day'])],
                filters: {
                    'day': {
                        "col_name": 'day',
                        "operator": 'in',
                        "value": [res['max_day']]
                    },
                    'top': {
                        "col_name": 'top',
                        "operator": 'in',
                        "value": this.state.top
                    },
                },
                maxDay: res['max_day']

            }, () => this.getData());
        })
    }

    getData() {
        clearTimeout(this.loadLazyTimeout);
        this.loadLazyTimeout = setTimeout(() => {
            if (this.state.rankByGroup) {
                this.TopBuyGroup.setLoading()
                this.TopSellGroup.setLoading()
            } else {
                this.TopBuy.setLoading()
                this.TopSell.setLoading()
            }
            let filter = {
                data_filter: Object.values(this.state.filters),
                matchedOnly: this.state.matchedOnly,
                rankByGroup: this.state.rankByGroup,
                foreignOnly: this.state.foreignOnly
            }
            this.tranModel.getTopBuySell(filter, false, this.state.rankByGroup).then(response => {

                if (this.state.rankByGroup) {
                    this.TopBuyGroup.setData(response['buy'])
                    this.TopSellGroup.setData(response['sell'])
                } else {
                    this.TopBuy.setData(response['buy'])
                    this.TopSell.setData(response['sell'])
                }

            })
                .catch((error) => {

                    if (this.state.rankByGroup) {
                        this.TopBuyGroup.setData(null)
                        this.TopSellGroup.setData(null)
                    } else {
                        this.TopBuy.setData(null)
                        this.TopSell.setData(null)
                    }

                    console.log(error);
                })
        }, 500);


    }


    change(col, value, filterkey, type) {
        var filter = this.state.filters;

        if (type == 'text') {

            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": value.trim()
                }
            }
        }

        this.setState({
            [col]: value,
            filters: filter
        }, () => this.getData());
    }

    changeDate(col, value, filterkey) {
        var filter = this.state.filters;
        this.setState({
            [col]: value,
        });
        if (col == 'valueDay') {
            if (value == null) {
                delete filter[filterkey];

            } else {
                let dateData = []
                value.map(item => {
                    dateData.push(moment(item).format("YYYY/MM/DD"))
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dateData,
                }
                this.setState({
                    fromDay: null,
                    endDay: null,
                })


            }

            this.setState({
                filters: filter,
            }, () => this.getData())

            return
        }

        if (col == 'fromDay') {
            // debugger
            if (this.state.endDay !== null && value !== null) {
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "from",
                    "value": [moment(value).format("YYYY/MM/DD"), moment(this.state.endDay).format("YYYY/MM/DD")],
                }
                this.setState({
                    filters: filter,
                    valueDay: null
                }, () => {
                    this.getData()
                });
            }
            return

        } else {
            if (this.state.fromDay !== null && value !== null) {
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "from",
                    "value": [moment(this.state.fromDay).format("YYYY/MM/DD"), moment(value).format("YYYY/MM/DD")],
                }
                this.setState({
                    filters: filter,
                    valueDay: null
                }, () => {
                    this.getData()
                });
            }
            return
        }
    }

    genDay(opt) {
        let endDay = moment().format('YYYY/MM/DD')
        let startDay = moment().subtract(opt['day'], opt['type']).format('YYYY/MM/DD');

        var filter = this.state.filters;

        filter['day'] = {
            "col_name": 'day',
            "operator": "from",
            "value": [startDay, endDay],
        }


        this.setState({
            fromDay: new Date(startDay),
            endDay: new Date(endDay),
            filters: filter

        }, () => this.getData());

    }

    genDay2(opt) {
        let endDay = this.state.maxDay
        let startDay = moment(this.state.maxDay).subtract(opt['day'], opt['type']).format('YYYY/MM/DD');

        let out = []

        for (let index = Number(moment(startDay).format('X')); index <= Number(moment(endDay).format('X')); index = index + 86400) {
            out.push(moment(index, 'X').format('YYYY/MM/DD'))

        }



        var filter = this.state.filters;

        filter['day'] = {
            "col_name": 'day',
            "operator": "in",
            "value": out,
        }

        let valueDay = out.map(item => new Date(item))
        this.setState({
            valueDay: valueDay,
            filters: filter

        }, () => this.getData());

    }



    renderFilter() {

        return (
            <div style={{ marginBottom: '20px' }}>
                <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY DATE</div>
                <div>
                    <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.valueDay} onChange={(e) => this.changeDate('valueDay', e.value, 'day')} selectionMode="multiple" readOnlyInput showButtonBar />
                </div>

                <div style={{ marginTop: '15px' }}>

                    {
                        this.DayOpt2.map(item => {

                            return (
                                <Button key={item['name']} label={item['name']} style={{ color: '#44486D' }} className="p-button-raised p-button-text mr-2" onClick={() => this.genDay2(item)} />
                            )
                        })
                    }

                </div>
                <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                    <div style={{ textAlign: 'center', paddingBottom: '10px' }}>FROM DATE</div>
                    <div>
                        <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.fromDay} onChange={(e) => this.changeDate('fromDay', e.value, 'day')} readOnlyInput showButtonBar />
                    </div>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <div style={{ textAlign: 'center', paddingBottom: '10px' }}>TO DATE</div>
                    <div>
                        <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.endDay} onChange={(e) => this.changeDate('endDay', e.value, 'day')} readOnlyInput showButtonBar />
                    </div>
                </div>

                <div style={{ marginTop: '10px' }}>

                    {
                        this.DayOpt.map(item => {

                            return (
                                <Button key={item['name']} label={item['name']} style={{ color: '#44486D' }} className="p-button-raised p-button-text mr-2" onClick={() => this.genDay(item)} />
                            )
                        })
                    }




                </div>
            </div>
        )


    }



    clearFilter(col) {
        if (Object.keys(this.state.filters).length == 0) return
        if (col) {
            if (col['operator'] == 'in') {
                this.setState({
                    'valueDay': null
                });

            } else {
                this.setState({
                    'fromDay': null,
                    'endDay': null,
                });
            }

            var filter = this.state.filters;
            delete filter[col['col_name']];
            this.setState({
                filters: filter,
            }, () => this.getData());
            return
        }

    }

    mapingCol(data) {
        let buy = []
        let sell = []
        if (this.state.rankByGroup) {
            data.map(item => {
                let field = item['field']
                let header = item['header']
                if (field == "expander" || field == 'index' || field == 'id' || field == 'group') {
                    buy.push(item)
                    sell.push(item)
                } else if (field == 'sum') {
                    buy.push({
                        ...item,
                        header: `${header} Buy`
                    })
                    sell.push({
                        ...item,
                        header: `${header} Sell`
                    })
                } else if (field == 'val_group') {
                    buy.push({
                        ...item,
                        field: `${field}_buy`,
                        header: `${header} Buy`
                    })
                    sell.push({
                        ...item,
                        field: `${field}_sell`,
                        header: `${header} Sell`
                    })
                }
                else {
                    buy.push({
                        ...item,
                        field: `buy ${field}`,
                        header: `Buy ${header}`
                    })
                    sell.push({
                        ...item,
                        field: `sell ${field}`,
                        header: `Sell ${header}`
                    })
                }
            })
        } else {
            data.map(item => {
                let field = item['field']
                let header = item['header']
                if (field == 'index' || field == 'id' || field == 'name') {
                    buy.push(item)
                    sell.push(item)
                } else if (field == 'total' || field == 'day_val') {
                    buy.push({
                        ...item,
                        field: `${field}_buy`,
                        header: `${header} Buy`
                    })
                    sell.push({
                        ...item,
                        field: `${field}_sell`,
                        header: `${header} Sell`
                    })
                }
                else {
                    buy.push({
                        ...item,
                        field: `buy ${field}`,
                        header: `Buy ${header}`
                    })
                    sell.push({
                        ...item,
                        field: `sell ${field}`,
                        header: `Sell ${header}`
                    })
                }
            })
        }


        if (this.state.rankByGroup) {
            this.TopBuyGroup.setSelectCol(buy)
            this.TopSellGroup.setSelectCol(sell)
        } else {
            this.TopBuy.setSelectCol(buy)
            this.TopSell.setSelectCol(sell)
        }


    }


    onColumnToggle(event) {
        let selectedColumns = event.value;
        if (this.state.rankByGroup) {
            let orderedSelectedColumns = this.columnsGroup.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
            this.setState({ selectedColumnsGroup: orderedSelectedColumns }, () => this.mapingCol(orderedSelectedColumns));
        } else {
            let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
            this.setState({ selectedColumns: orderedSelectedColumns }, () => this.mapingCol(orderedSelectedColumns));
        }

    }
    setMathedOnly(val) {

        this.setState({
            matchedOnly: val
        }, () => this.getData());

    }

    setRankByGroup(val) {

        this.setState({
            rankByGroup: val
        }, () => this.getData());

    }

    setForeignOnly(val) {

        this.setState({
            foreignOnly: val
        }, () => this.getData());

    }

    render() {
        return (
            <div>
                {

                    this.state.rankByGroup ?
                        <TopBuyGroup
                            ref={c => this.TopBuyGroup = c}
                            filterOp={this.state.filters}
                            clearFilter={(col) => this.clearFilter(col)}
                            setMathedOnly={(val) => this.setMathedOnly(val)}
                            setRankByGroup={(val) => this.setRankByGroup(val)}
                            setForeignOnly={(val) => this.setForeignOnly(val)}
                            matchedOnly={this.state.matchedOnly}
                            rankByGroup={this.state.rankByGroup}
                            foreignOnly={this.state.foreignOnly} >

                            <div className="card" style={{ width: '20%', marginLeft: '25px' }}>

                                <div style={{ marginBottom: '20px' }}>
                                    <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT COLUMN </div>
                                    <div>
                                        <MultiSelect
                                            style={{ width: '100%' }}
                                            value={this.state.rankByGroup ? this.state.selectedColumnsGroup : this.state.selectedColumns}
                                            options={this.state.rankByGroup ? this.columnsGroup : this.columns}
                                            optionLabel="header"
                                            onChange={(e) => this.onColumnToggle(e)}
                                        />
                                    </div>
                                </div>


                                {
                                    this.renderFilter()
                                }

                                <div >
                                    <div style={{ paddingBottom: '20px', textAlign: 'center' }}>SELECT TOP</div>
                                    <div>


                                        <input style={{ width: '100%' }} className='p-inputtext p-component' list="browsers" value={this.state.top} onChange={(e) => this.change('top', e.target.value, 'top', 'text')} />

                                        <datalist id="browsers">
                                            <option value="30" />
                                            <option value="50" />
                                            <option value="100" />
                                            <option value="200" />
                                            <option value="500" />
                                        </datalist>


                                    </div>
                                </div>


                            </div>


                        </TopBuyGroup> :
                        <TopBuy
                            ref={c => this.TopBuy = c}
                            filterOp={this.state.filters}
                            clearFilter={(col) => this.clearFilter(col)}
                            setMathedOnly={(val) => this.setMathedOnly(val)}
                            setRankByGroup={(val) => this.setRankByGroup(val)}
                            setForeignOnly={(val) => this.setForeignOnly(val)}
                            matchedOnly={this.state.matchedOnly}
                            rankByGroup={this.state.rankByGroup}
                            foreignOnly={this.state.foreignOnly}>

                            <div className="card" style={{ width: '20%', marginLeft: '25px' }}>

                                <div style={{ marginBottom: '20px' }}>
                                    <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT COLUMN </div>
                                    <div>
                                        <MultiSelect style={{ width: '100%' }} value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} />
                                    </div>
                                </div>

                                {/* <SelectButton style={{ width: '100%', marginBottom: '20px' }} className='cus_selectButton' value={this.state.mode} options={this.options} onChange={(e) => this.changeMode(e.value)} /> */}

                                {
                                    this.renderFilter()
                                }

                                <div >
                                    <div style={{ paddingBottom: '20px', textAlign: 'center' }}>SELECT TOP</div>
                                    <div>


                                        <input style={{ width: '100%' }} className='p-inputtext p-component' list="browsers" value={this.state.top} onChange={(e) => this.change('top', e.target.value, 'top', 'text')} />

                                        <datalist id="browsers">
                                            <option value="30" />
                                            <option value="50" />
                                            <option value="100" />
                                            <option value="200" />
                                            <option value="500" />
                                        </datalist>


                                    </div>
                                </div>


                            </div>

                        </TopBuy>

                }

                {

                    this.state.rankByGroup ?
                        <TopSellGroup ref={c => this.TopSellGroup = c}></TopSellGroup> :
                        <TopSell ref={c => this.TopSell = c}></TopSell>

                }

            </div>
        );
    }
}

export default TopBuySell;