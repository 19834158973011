import model from "../model";
import axios from "axios";
import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class TranCode {

    constructor() {
        // this.url = 'https://d11.f5traders.com/api'
        this.url = domain_config(1)
        this.queryInfo = new QueryInfo()
    }

    getDataReal(filter , mode) {
        if (global.dataInsiders) {
            this.queryInfo.info(filter , `${this.url}/db11-endpoint-insider-trans` )
            if (filter['data_filter'].length > 0) {
                let data = global.dataInsiders

                let dataTemp = structuredClone(data['dataT'])

                let leng = dataTemp['day'].length
                let stockTemp = [], dayTemp = [], accountTemp = [], nameTemp = [], idTemp = [], type_ahTemp = [], group_ahTemp = [], buyTemp = [], sellTemp = [], priceTemp = [], val_buyTemp = [], val_sellTemp = [], insider_ahTemp = [], addressTemp = []
                for (let index = 0; index < leng; index++) {
                    // debugger
                    let check = true

                    for (let y = 0; y < filter['data_filter'].length; y++) {
                        let col = filter['data_filter'][y]['col_name']
                        let value = filter['data_filter'][y]['value']
                        if(col == 'group'){
                           col = 'group_ah'
                        }
                        if(col == 'day'){
                            value = value.map(item => item.replaceAll('/', '_'))
                        }

                        if (!value.includes(dataTemp[col][index])) {
                            check = false
                            break
                        }


                    }

                    if (check == true) {
                        stockTemp.push(dataTemp['stock'][index])
                        dayTemp.push(dataTemp['day'][index])
                        accountTemp.push(dataTemp['account'][index])
                        nameTemp.push(dataTemp['name'][index])
                        idTemp.push(dataTemp['id'][index])
                        type_ahTemp.push(dataTemp['type_ah'][index])
                        group_ahTemp.push(dataTemp['group_ah'][index])
                        buyTemp.push(dataTemp['buy'][index])
                        sellTemp.push(dataTemp['sell'][index])
                        priceTemp.push(dataTemp['price'][index])
                        val_buyTemp.push(dataTemp['val_buy'][index])
                        val_sellTemp.push(dataTemp['val_sell'][index])
                        insider_ahTemp.push(dataTemp['insider_ah'][index])
                        addressTemp.push(dataTemp['address'][index])
                    }
                }

                dataTemp['stock'] = stockTemp
                dataTemp['day'] = dayTemp
                dataTemp['account'] = accountTemp
                dataTemp['name'] = nameTemp
                dataTemp['id'] = idTemp
                dataTemp['type_ah'] = type_ahTemp
                dataTemp['group_ah'] = group_ahTemp
                dataTemp['buy'] = buyTemp
                dataTemp['sell'] = sellTemp
                dataTemp['price'] = priceTemp
                dataTemp['val_buy'] = val_buyTemp
                dataTemp['val_sell'] = val_sellTemp
                dataTemp['insider_ah'] = insider_ahTemp
                dataTemp['address'] = addressTemp

                let out = this.handleData(dataTemp , mode)
                data['data'] = Object.values(out)
                return Promise.resolve(data)

            } else {
                let data = global.dataInsiders
                let dataTemp = structuredClone(data['dataT'])
                let out = this.handleData(dataTemp , mode)
                data['data'] = Object.values(out)
                return Promise.resolve(data)
            }


        } else {
            return axios.request({
                url: `${this.url}/db11-endpoint-insider-trans`,
                method: "POST",
                data: {}
            })

                .then(response => {
                    response = response['data'];
                    let res = JSON.parse(response)
                    let out = this.handleData(res , mode)

                    let stock = ["HPG", "SSI", "STB", "TCB", "VPB", "DXG", "MBB", "MSN", "MWG", "VIC", "VND"];
                    let group = [...new Set(res['group_ah'])].filter(item => item);

                    let stockOptions = []
                    stock.map(item => {
                        stockOptions.push({ name: item, code: item },)
                    })
                    let groupOptions = []
                    group.map(item => {
                        groupOptions.push({ name: item, code: item },)
                    })

                    global.dataInsiders = {
                        data: Object.values(out),
                        stock: stockOptions,
                        group: groupOptions,
                        dataT: res
                    }

                    return Promise.resolve({
                        data: Object.values(out),
                        stock: stockOptions,
                        group: groupOptions
                    }

                    )

                })

                .catch((error) => {
                    return false

                })
        }



    }

    handleData(res , mode) {
        let out = {}
        let col = Object.keys(res)

        let length = res['day'].length


        for (let index = 0; index < length; index++) {
            let dataCol = {}
            col.map(item => {
                if (!dataCol['value']) dataCol['value'] = {}
                dataCol['value'][item] = res[item][index]
                dataCol[item] = res[item][index]



            })

            if(mode == 'Stock'){
                if (out[dataCol['stock']]) {
                    out[dataCol['stock']]['value'].push(dataCol['value'])
    
                    Object.keys(dataCol['value']).map(col => {
                        if (col == 'buy' || col == 'sell' || col == 'val_buy' || col == 'val_sell') {
    
                            out[dataCol['stock']][col] += Number(dataCol['value'][col])
    
                        }
                    })
                } else {
                    out[dataCol['stock']] = {
                        ...dataCol, value: [dataCol['value']]
                    }
                }
            }else{
                if (out[dataCol['day']]) {
                    out[dataCol['day']]['value'].push(dataCol['value'])
    
                    Object.keys(dataCol['value']).map(col => {
                        if (col == 'buy' || col == 'sell' || col == 'val_buy' || col == 'val_sell') {
    
                            out[dataCol['day']][col] += Number(dataCol['value'][col])
    
                        }
                    })
                } else {
                    out[dataCol['day']] = {
                        ...dataCol, value: [dataCol['value']]
                    }
                }
            }



           
        }

        return out
    }

    getDashBoard(){
        if(global.dataInsiders){
            return Promise.resolve(global.dataInsiders['dataT']) 
        }else{
            return axios.request({
                url: `${this.url}/db11-endpoint-insider-trans`,
                method: "POST",
                data: {}
            })

                .then(response => {
                    let mode = 'Day'
                    response = response['data'];
                    let res = JSON.parse(response)
                    let out = this.handleData(res , mode)

                    let stock = ["HPG", "SSI", "STB", "TCB", "VPB", "DXG", "MBB", "MSN", "MWG", "VIC", "VND"];
                    let group = [...new Set(res['group_ah'])].filter(item => item);

                    let stockOptions = []
                    stock.map(item => {
                        stockOptions.push({ name: item, code: item },)
                    })
                    let groupOptions = []
                    group.map(item => {
                        groupOptions.push({ name: item, code: item },)
                    })

                    global.dataInsiders = {
                        data: Object.values(out),
                        stock: stockOptions,
                        group: groupOptions,
                        dataT: res
                    }

                    return Promise.resolve(res)

                })

                .catch((error) => {
                    return false

                })
        }
    }

    getAnalysis(){
        if(global.dataInsiders){
            return Promise.resolve(global.dataInsiders['dataT']) 
        }else{
            return axios.request({
                url: `${this.url}/db11-endpoint-insider-trans`,
                method: "POST",
                data: {}
            })

                .then(response => {
                    let mode = 'Day'
                    response = response['data'];
                    let res = JSON.parse(response)
                    let out = this.handleData(res , mode)

                    let stock = ["HPG", "SSI", "STB", "TCB", "VPB", "DXG", "MBB", "MSN", "MWG", "VIC", "VND"];
                    let group = [...new Set(res['group_ah'])].filter(item => item);

                    let stockOptions = []
                    stock.map(item => {
                        stockOptions.push({ name: item, code: item },)
                    })
                    let groupOptions = []
                    group.map(item => {
                        groupOptions.push({ name: item, code: item },)
                    })

                    global.dataInsiders = {
                        data: Object.values(out),
                        stock: stockOptions,
                        group: groupOptions,
                        dataT: res
                    }

                    return Promise.resolve(res)

                })

                .catch((error) => {
                    return false

                })
        }
    }
 
    






}

export default TranCode;