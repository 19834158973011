import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import axios from "axios";
import moment from 'moment';
class TableDuy extends Component {
    constructor(props) {
        super(props);
        this.table = this.props.structTable;
        let structTable = this.table['struct_table']
        this.state = {
            data: [],
            loading: false,
            totalRecords: 0,
            first: 0,
            quantity: structTable['quantity'],
            page: 1,
            sortField: structTable['sortField'],
            sortOrder: structTable['sortOrder'],
            filters: structTable['filters'],

            selectedColumns: this.table['struct_column'],
        }

    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        this.setState({ loading: true });
        let filter = {
            data_filter: Object.values(this.state.filters),
            data_sort: { [this.state.sortField]: this.state.sortOrder == 1 ? 'asc' : 'desc' },
            page_active: this.state.page,
            page_limit: this.state.quantity,
        }
        axios.request({
            url: this.table['struct_table']['link_filter'],
            method: 'POST',
            data: filter
        }).then(response => {
            response = response['data'];
            this.setState({
                totalRecords: response['page_total'],
                data: response['data_table'],
                loading: false
            });
        })
            .catch((error) => {
                this.setState({
                    loading: false
                });
                console.log(error);
            })
    }

    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.table['struct_column'].filter(col => selectedColumns.some(sCol => sCol.col_field === col.col_field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }

    onSort(event) {

        this.setState({
            sortField: event.sortField,
            sortOrder: event.sortOrder
        }, () => this.getData());
    }


    onPage(event) {
        this.setState({
            first: event.first,
            quantity: event.rows,
            page: Number(event.page) + 1

        }, () => this.getData());

    }


    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                <MultiSelect value={this.state.selectedColumns} options={this.table['struct_column']} optionLabel="col_header" onChange={(e) => this.onColumnToggle(e)} style={{ width: '20em' }} />
            </div>
        );

        const columnComponents = this.state.selectedColumns.map(col => {

            return <Column field={col.col_field} header={col.col_header} sortable={col.col_sort} />;


        });

        return (
            <div className="p-col-12 p-md-12" style={{ display: 'flex' }}>
                <div className="card widget-table" style={{ width: '80%' }} >
                    <DataTable

                        className="p-datatable-customers" value={this.state.data}
                        dataKey="id1111111" rowHover
                        rows={this.state.quantity}
                        first={this.state.first} totalRecords={this.state.totalRecords}
                        paginator rowsPerPageOptions={[25, 50]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        emptyMessage="No data."
                        lazy loading={this.state.loading}
                        onPage={(event) => this.onPage(event)}
                        onSort={(event) => this.onSort(event)}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        onFilter={(event) => this.onFilter(event)} filters={this.state.filters}
                        // filterDisplay="row"
                        responsiveLayout="scroll"
                        header={header}
                    >
                        {columnComponents}
                    </DataTable>

                </div>


            </div>
        );
    }
}

export default TableDuy;