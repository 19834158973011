import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Banner from '../Banner';
import './index.scss'
class TableSell extends Component {

    constructor(props) {
        super(props)
        this.columns = [
            { field: 'index', header: 'Index', body: true },

            { field: 'label', header: 'Label', body: false, filter: false },
            { field: 'no_players', header: 'no_players', body: true, },
            { field: 'median_size', header: 'median_size', body: true },
            { field: 'mean_size', header: 'mean_size', body: true },
            { field: 'median_price',header : 'median_price', body: true },
            { field: 'mean_price', header: 'mean_price', body: true },
            { field: 'total', header: 'total', body: true },



        ];

        this.state = {
            data: [],
            loading: false,
            filters: {},
            selectedColumns: [
                { field: 'index', header: 'Index', body: true },
                { field: 'label', header: 'Label', body: false, filter: false },
                { field: 'no_players', header: 'no_players', body: true, },
                { field: 'median_size', header: 'median_size', body: true },
                { field: 'mean_size', header: 'mean_size', body: true },
                { field: 'median_price',header : 'median_price', body: true },
                { field: 'mean_price', header: 'mean_price', body: true },
                { field: 'total', header: 'total', body: true },

            ],

        }

        this.loadLazyTimeout = null;


    }

    setLoading() {
        this.setState({ loading: true });
    }

    setData(data) {
        this.setState({
            data: data,
            loading: false,
        });

    }
    rowClass(row) {
        if(row['label'] == 'Whale'){
            return 'row-whale' 
            
        }

        if(row['label'] == 'Shark'){
            return 'row-shark' 
            
        }

        if(row['label'] == 'Sword'){
            return 'row-sword' 
            
        }

        if(row['label'] == 'Tuna'){
            return 'row-tuna' 
            
        }

        if(row['label'] == 'Nemo'){
            return 'row-nemo' 
            
        }
    
    }

    renderColumnComponents() {

        return this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });


    }

    representativeTemplate = (data, props) => {
    
        if (props.field == "index") {
    
            return (
                <div  >
                    <span>{props.rowIndex + 1}</span>
                </div>
            )
        }


        let col = [ 'no_players','median_size','mean_size','median_price','mean_price', 'total' ]

        if (col.includes(props.field)) {
            let result = Math.round(data[props.field] * 100) / 100
            if (result == 0) result = '-'
            if (data[props.field] == undefined) result = '-'
            return (
                <span>{global.formatNumber(result)}</span>
            )
        }







    }
    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                <Banner text='Sell' icon='pi pi-star' sell={true}></Banner>
            </div>
        );
        return (
            <DataTable
                className="p-datatable-customers" value={this.state.data}
                dataKey="id"
                stripedRows
                emptyMessage="No data."
                lazy loading={this.state.loading}

                responsiveLayout="scroll"
                header={header}
                rowClassName={(row) => this.rowClass(row)}
            >

                {this.renderColumnComponents()}
            </DataTable>
        );
    }
}

export default TableSell;