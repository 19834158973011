import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import AppWrapper from './AppWrapper';
import axios from "axios";
const queryString = require('query-string');
//import * as serviceWorker from './serviceWorker';

function clearNumber(string){
	if(!string) return 0;
	return string.toString().replace(/[^\d\-\.]/g,'');
}

global.formatNumber = (num) => {
	if(!num) return 0;
	num = clearNumber(num);
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


global.parsed = () => {
	let search = window.location.hash
	search = search.replace(/^[^?]*/, '');
	var parsed = queryString.parse(search);
	return parsed
}

setInterval(function() {
	axios.request({
		url:  `https://authen.f5traders.com/authen/login/refreshToken`,
		method: "POST",
		withCredentials : true
	})

		.then(response => {
			console.log(response)
			return response;
		})

		.catch((error) => {
			console.log(error);
			return false;
		})
}, 1000 * 60 * 30);

global.User = "Admin"
function getUser(){
	axios.request({
		url:  `https://authen.f5traders.com/authen/login/getUser`,
		method: "POST",
		withCredentials : true
	})

		.then(response => {
			global.User = response['data']['username']
			return response;
		})

		.catch((error) => {
			console.log(error);
			return false;
		})
}

global.logout = () => {
	axios.request({
		url:  `https://authen.f5traders.com/authen/login/logout`,
		method: "POST",
		withCredentials : true
	})

		.then(response => {
			window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent('https://d11.f5traders.com/#/')}`
			// return response;
		})

		.catch((error) => {
			console.log(error);
			return false;
		})
}

global.genKey = (filter) => {

	filter['data_filter'].sort(function (a, b) {
		return a['col_name'] - b['col_name'];
	})
	let key = ''
	filter['data_filter'].map(item => {
		let temp = item['col_name']
		if (typeof (item['value']) == 'string') {
			temp += item['value']
		} else {
			item['value'].sort()
			item['value'].map(v => {
				temp += v
			})
		}

		key += temp
	})
	
	if (filter['data_sort'] && Object.keys(filter['data_sort']).length > 0) {
		key += Object.keys(filter['data_sort'])[0]
		key += Object.values(filter['data_sort'])[0]

	}
	if (filter['page_active']) {
		key += filter['page_active']


	}
	if (filter['page_limit']) {
		key += filter['page_limit']


	}
	if (filter.hasOwnProperty('matchedOnly') ) {
		key += 'matchedOnly'
		key += filter['matchedOnly']
	}
	
	if ( filter.hasOwnProperty('rankByGroup')) {
		key += 'rankByGroup'
		key += filter['rankByGroup']
	}

	if ( filter.hasOwnProperty('rankByCTCK')) {
		key += 'rankByCTCK'
		key += filter['rankByCTCK']
	}

	if ( filter.hasOwnProperty('injection')) {
		key += 'injection'
		key += filter['injection']
	}
	if ( filter.hasOwnProperty('groupByStock')) {
		key += 'groupByStock'
		key += filter['groupByStock']
	}
	if ( filter.hasOwnProperty('arbit')) {
		key += 'arbit'
		key += filter['arbit']
	}

	if ( filter.hasOwnProperty('foreignOnly')) {
		key += 'foreignOnly'
		key += filter['foreignOnly']
	}

	if ( filter.hasOwnProperty('filter_td')) {
		key += 'filter_td'
		key += filter['filter_td']
	}

	return key
}


getUser()
ReactDOM.render(
	<HashRouter>
		<AppWrapper></AppWrapper>
	</HashRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
