import model from "../model";
import axios from "axios";

import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class Analysis {

    constructor() {
        // this.url = 'https://d11.f5traders.com/api_v2'
        

        this.url = domain_config(2)
        this.queryInfo = new QueryInfo()
    }

    getData(filter) {
        return axios.request({
            url: `${this.url}/db11-pnl`,
            method: "POST",
            data: { ...filter }
        })

            .then(response => {
                let datas = response['data']
                
                let stockUnique = []

                let tranferData = {}

                datas.map(data => {
                    let stock = data['stock']
                    if(!stockUnique.includes(stock)){
                        stockUnique.push(stock)
                    }

                    if(!tranferData[stock]){
                        tranferData[stock] = []
                    }
                       
                    tranferData[stock].push(data)
                    
                })
                
                return {
                    'stockUnique' : stockUnique,
                    'data' : tranferData
                }
            })

            .catch((error) => {

                console.log(error);
                return false;
            })



    }
    readFavorite(){
        return axios.request({
            url: `${this.url}/db11-favorite/read`,
            method: "POST",
            data: { }
        })

            .then(response => {
                let datas = response['data']
                
                return datas
            })

            .catch((error) => {

                console.log(error);
                return false;
            })
    }

    editFavorite(data){
        return axios.request({
            url: `${this.url}/db11-favorite/edit`,
            method: "POST",
            data: {...data }
        })

            .then(response => {
                let datas = response['data']
                
                return datas
            })

            .catch((error) => {

                console.log(error);
                return false;
            })
    }

    removeFavorite(data){
        return axios.request({
            url: `${this.url}/db11-favorite/delete`,
            method: "POST",
            data: {...data }
        })

            .then(response => {
                let datas = response['data']
                
                return datas
            })

            .catch((error) => {

                console.log(error);
                return false;
            })
    }







}

export default Analysis;