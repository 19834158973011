import React, { Component } from 'react';
import TranCode from '../../models/admin/TranCode';

class SunburstChart extends Component {
    constructor(props) {
        super(props);

        this.model = new TranCode()
    }

    getData() {
        this.model.getDashBoard().then(res => {
            let result = this.handleData(res)
            this.renderChart(result)

        })
    }


    handleData(res) {
        let ids = []
        let labels = []
        let parents = []
        let values = []

        let totalBuy = 0
        res['val_buy'] =  res['val_buy'].map(item => item/1000000000)
        res['val_sell'] =  res['val_sell'].map(item => item/1000000000)
        res['val_buy'].map(item => {
            if (item > 0) {
                totalBuy += parseInt(item)
            }
        })
        let totalSell = 0
        res['val_sell'].map(item => {
            if (item > 0) {
                totalSell += parseInt(item)
            }
        })
        ids.push('buy', 'sell')
        labels.push('buy', 'sell')
        parents.push('', '')
        values.push(totalBuy, totalSell)

        let stock = {}
        let name = {}
        res['stock'].map((item, index) => {
            if (!stock[item]) {
                stock[item] = {
                    'buy': 0,
                    'sell': 0,
                }
            }
            stock[item]['buy'] += parseInt(res['val_buy'][index])
            stock[item]['sell'] += parseInt(res['val_sell'][index])


            let key = item + '&' + res['name'][index]
            if (!name[key]) {
                name[key] = {
                    'buy': 0,
                    'sell': 0,
                }
            }
            name[key]['buy'] += parseInt(res['val_buy'][index])
            name[key]['sell'] += parseInt(res['val_sell'][index])

        })


        Object.keys(stock).map(item => {

            Object.keys(stock[item]).map(val => {
                ids.push(`${item}-${val}`)
                labels.push(item)
                parents.push(val)
                values.push(stock[item][val])
            })
        })
        Object.keys(name).map((item) => {
            let stock = item.substring(0, item.indexOf('&'))
            let traders = item.substring(item.indexOf('&') + 1)

            Object.keys(name[item]).map(val => {

                ids.push(`${traders}-${stock}-${val}`)
                labels.push(traders)
                parents.push(`${stock}-${val}`)
                values.push(name[item][val])
            })
        })



        return {
            ids,
            labels,
            parents,
            values
        }




    }

    renderChart(result) {
        var data = [{
            type: "sunburst",
            ids: result['ids'],
            labels: result['labels'],
            parents: result['parents'],
            values: result['values'],
            textinfo: 'label+value+percent entry',
            "branchvalues": 'total',
            marker: {
                "colors": ["green", "orange", '#19d3f3', '#19d3f3', '#ab63fa', '#ab63fa', '#ef553b', '#ef553b', '#ffa15a', '#ffa15a', '#00cc96', '#00cc96', '#636efa', '#636efa']
            },

        }];

        var layout = {
            height: 900,
            margin: { l: 0, r: 0, b: 100, t: 100 },
        };



        global.Plotly.newPlot(this.chart1, data, layout);
    }
    componentDidMount() {
        this.getData()
    }
    render() {
        return (
            // <div style={{ width: '100%', marginTop: '20px' }} ref={c => this.chart = c}>
            //     <iframe src={`assets/test1/sunburst_insiders.html`} style={{ width: '100%', height: '900px', border: 'none' }}></iframe>
            // </div>
            <div style={{ width: '100%', marginTop: '20px' }} ref={c => this.chart1 = c}>

            </div>
        );
    }
}

export default SunburstChart;