import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import axios from "axios";
import moment from 'moment';
import TopBuySellModel from '../../models/admin/TopBuySell';
import Banner from '../Banner';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
class TopSell extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            { field: 'index', header: 'Index', body: true },
            { field: 'id', header: 'STK', body: true, filter: false },
            { field: 'name', header: 'Name', body: true, filter: false },

            { field: 'sell DXG', header: ' Sell DXG', body: true },
            { field: 'sell HPG', header: ' Sell HPG', body: true },
            { field: 'sell MBB', header: ' Sell MBB', body: true },
            { field: 'sell MSN', header: ' Sell MSN', body: true },
            { field: 'sell MWG', header: ' Sell MWG', body: true },
            { field: 'sell SSI', header: ' Sell SSI', body: true },
            { field: 'sell STB', header: ' Sell STB', body: true },
            { field: 'sell TCB', header: ' Sell TCB', body: true },
            { field: 'sell VIC', header: ' Sell VIC', body: true },
            { field: 'sell VND', header: ' Sell VND', body: true },
            { field: 'sell VPB', header: ' Sell VPB', body: true },
            { field: 'sell NVL', header: ' Sell NVL', body: true },
            { field: 'sell HDB', header: ' Sell HDB', body: true },
            { field: 'sell EIB', header: ' Sell EIB', body: true },
            { field: 'sell VRE', header: ' Sell VRE', body: true },
            { field: 'sell VHM', header: ' Sell VHM', body: true },
            { field: 'sell CTG', header: ' Sell CTG', body: true },
            { field: 'sell VCB', header: ' Sell VCB', body: true },
            { field: 'sell HSG', header: ' Sell HSG', body: true },
            { field: 'sell FPT', header: ' Sell FPT', body: true },
            { field: 'sell VNM', header: ' Sell VNM', body: true },
            { field: 'sell ACB', header: ' Sell ACB', body: true } ,
            { field: 'sell DIG', header: ' Sell DIG', body: true } ,
            // { field: 'sell PVD', header: ' Sell PVD', body: true } ,
            { field: 'sell MSB', header: ' Sell MSB', body: true } ,
            { field: 'sell PNJ', header: ' Sell PNJ', body: true },
            { field: 'sell FRT', header: ' Sell FRT', body: true },
            { field: 'sell DGC', header: ' Sell DGC', body: true },
            // { field: 'sell KBC', header: ' Sell KBC', body: true },
            { field: 'total_sell', header: 'Total  Sell', body: true },
            { field: 'day_val_sell', header: ' Day Val Sell', body: true },

        ];
        this.state = {
            data: [],
            loading: false,
            totalRecords: 0,
            first: 0,
            quantity: 25,
            page: 1,
            sortField: '',
            sortOrder: -1,
            filters: {

            },
            selectedColumns: [
                { field: 'index', header: 'Index', body: true },
                { field: 'name', header: 'Name', body: true, filter: false},

                { field: 'sell DXG', header: 'Sell DXG', body: true },
                { field: 'sell HPG', header: 'Sell HPG', body: true },
                { field: 'sell MBB', header: 'Sell MBB', body: true },
                { field: 'sell MSN', header: 'Sell MSN', body: true },
                { field: 'sell MWG', header: 'Sell MWG', body: true },
                { field: 'sell SSI', header: 'Sell SSI', body: true },
                { field: 'sell STB', header: 'Sell STB', body: true },
                { field: 'sell TCB', header: 'Sell TCB', body: true },
                { field: 'sell VIC', header: 'Sell VIC', body: true },
                { field: 'sell VND', header: 'Sell VND', body: true },
                { field: 'sell VPB', header: 'Sell VPB', body: true },
                { field: 'sell NVL', header: 'Sell NVL', body: true },
                { field: 'sell HDB', header: 'Sell HDB', body: true },
                { field: 'sell EIB', header: 'Sell EIB', body: true },
                { field: 'sell VRE', header: 'Sell VRE', body: true },

                { field: 'sell VHM', header: 'Sell VHM', body: true },
                { field: 'sell CTG', header: 'Sell CTG', body: true },
                { field: 'sell VCB', header: 'Sell VCB', body: true },
                { field: 'sell HSG', header: 'Sell HSG', body: true },
                { field: 'sell FPT', header: 'Sell FPT', body: true },
                { field: 'sell VNM', header: 'Sell VNM', body: true },
                { field: 'sell ACB', header: 'Sell ACB', body: true },
                { field: 'sell DIG', header: 'Sell DIG', body: true },
                // { field: 'sell PVD', header: 'Sell PVD', body: true },
                { field: 'sell MSB', header: 'Sell MSB', body: true },
                { field: 'sell PNJ', header: 'Sell PNJ', body: true },
                { field: 'sell FRT', header: 'Sell FRT', body: true },
                { field: 'sell DGC', header: 'Sell DGC', body: true },
                // { field: 'sell KBC', header: 'Sell KBC', body: true },
                { field: 'total_sell', header: 'Total Sell', body: true },
                { field: 'day_val_sell', header: 'Day Val Sell', body: true },
            ],
            expandedRows: null,






        }
        this.loadLazyTimeout = null;


    }

    setSelectCol(data) {
        this.setState({
            selectedColumns: data
        });
    }

    setLoading() {
        this.setState({ loading: true });
    }

    setData(data) {
        let headerDXG = 0
        let headerHPG = 0
        let headerMBB = 0
        let headerMSN = 0
        let headerMWG = 0
        let headerSSI = 0
        let headerSTB = 0
        let headerTCB = 0
        let headerVIC = 0
        let headerVND = 0
        let headerVPB = 0
        let headerNVL = 0
        let headerHDB = 0
        let headerEIB = 0
        let headerVRE = 0
        let headerVHM = 0
        let headerCTG = 0
        let headerVCB = 0
        let headerHSG = 0
        let headerFPT = 0
        let headerVNM = 0
        let headerACB = 0
        let headerDIG = 0
        // let headerPVD = 0
        let headerMSB = 0
        let headerPNJ = 0
        let headerFRT = 0
        let headerDGC = 0
        // let headerKBC = 0
        let headerTotalSell = 0
        let headerDayValSell = 0

        data.map(item => {
            headerDXG += item['sell DXG']
            headerHPG += item['sell HPG']
            headerMBB += item['sell MBB']
            headerMSN += item['sell MSN']
            headerMWG += item['sell MWG']
            headerSSI += item['sell SSI']
            headerSTB += item['sell STB']
            headerTCB += item['sell TCB']
            headerVIC += item['sell VIC']
            headerVND += item['sell VND']
            headerVPB += item['sell VPB']
            headerNVL += item['sell NVL']
            headerHDB += item['sell HDB']
            headerEIB += item['sell EIB']
            headerVRE += item['sell VRE']

            headerVHM += item['sell VHM']
            headerCTG += item['sell CTG']
            headerVCB += item['sell VCB']
            headerHSG += item['sell HSG']
            headerFPT += item['sell FPT']
            headerVNM += item['sell VNM']
            headerACB += item['sell ACB']
            headerDIG += item['sell DIG']
            // headerPVD += item['sell PVD']
            headerMSB += item['sell MSB']
            headerPNJ += item['sell PNJ']
            headerFRT += item['sell FRT']
            headerDGC += item['sell DGC']
            // headerKBC += item['sell KBC']
            headerTotalSell += item['total_sell']
            headerDayValSell += item['day_val_sell']
        })
        this.setState({
            data: data,
            loading: false,
            headerDXG,
            headerHPG,
            headerMBB,
            headerMSN,
            headerMWG,
            headerSSI,
            headerSTB,
            headerTCB,
            headerVIC,
            headerVND,
            headerVPB,
            headerNVL,
            headerHDB,
            headerEIB,
            headerVRE,

            headerVHM,
            headerCTG,
            headerVCB,
            headerHSG,
            headerFPT,
            headerVNM,
            headerACB,
            headerDIG,
            // headerPVD,
            headerMSB,
            headerPNJ,
            headerFRT,
            headerDGC,
            // headerKBC,
            headerTotalSell,
            headerDayValSell,
        });
    }






    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }
    representativeTemplate = (data, props) => {
        if (props.field == "id") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/?stk=${data['id']}`)}>{data[props.field]}</span>
            )
        }

        if (props.field == "name") {

            return (
                <span style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/#/?stk=${data['id']}`)}>{data[props.field]}</span>
            )
        }


        if (props.field == "index") {
        


            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => window.open(`${window.location.origin}/#/analytics2?stk=${data['id']}&name=${data['name']}`)}
                >
                    <span>{props.rowIndex + 1}</span>
                    <i className="pi pi-search" style={{ 'fontSize': '1em', 'lineHeight': 0 }}></i>
                </div>
            )
        }

        let col = ['sell DXG', 'sell HPG', 'sell MBB', 'sell MSN', 'sell MWG', 'sell SSI', 
        'sell STB', 'sell TCB', 'sell VIC', 'sell VND', 'sell VPB', 'sell NVL','sell HDB' , 'sell VND' , 'sell EIB','sell VRE',
        'sell VHM', 'sell CTG', 'sell VCB', 'sell HSG', 'sell FPT' ,'sell VNM','sell ACB', 'sell DIG', 'sell MSB', 'sell PNJ', 'sell FRT', 'sell DGC',
        'total_sell', 'day_val_sell',]

        if (col.includes(props.field)) {
            let result = Math.round(data[props.field] * 100) / 100
            if (result == 0) result = '-'
            if(data[props.field] == undefined) result = '-'
            return (
                <span>{result}</span>
            )
        }







    }

    exportExcel() {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'top_buy_sell');

        });
    }
    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }





    rowExpansionTemplate(data) {
        return (
            <div className="sub_table">
                <DataTable value={data.value} responsiveLayout="scroll"  sortField='day' sortOrder={-1}>
                    <Column field="day" header="Day" style={{ width : '31rem' ,  paddingLeft: '47px' }}  sortable ></Column>
                    <Column field='sell DXG' header='Sell DXG' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell HPG' header='Sell HPG' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell MBB' header='Sell MBB' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell MSN' header='Sell MSN' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell MWG' header='Sell MWG' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell SSI' header='Sell SSI' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell STB' header='Sell STB' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell TCB' header='Sell TCB' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell VIC' header='Sell VIC' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell VND' header='Sell VND' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell VPB' header='Sell VPB' body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell NVL' header='Sell NVL'  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell HDB' header='Sell HDB'  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell EIB' header='Sell EIB'  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell VRE' header='Sell VRE'  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                    <Column field='sell VHM' header='Sell VHM' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell CTG' header='Sell CTG' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell VCB' header='Sell VCB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell HSG' header='Sell HSG' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell FPT' header='Sell FPT' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell VNM' header='Sell VNM' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell ACB' header='Sell ACB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell DIG' header='Sell DIG' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    {/* <Column field='sell PVD' header='Sell PVD' body={(data, props) => this.representativeTemplate(data, props)}  ></Column> */}
                    <Column field='sell MSB' header='Sell MSB' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell PNJ' header='Sell PNJ' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell FRT' header='Sell FRT' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='sell DGC' header='Sell DGC' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    {/* <Column field='sell KBC' header='Sell KBC' body={(data, props) => this.representativeTemplate(data, props)}  ></Column> */}
                    <Column field='total_sell' header='Total Sell' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                    <Column field='day_val_sell' header='Day Val Sell' body={(data, props) => this.representativeTemplate(data, props)} ></Column>

                </DataTable>
            </div>
        );
    }

    expandAll() {
        let expandedRows = {};
        this.state.data.forEach(p => expandedRows[`${p.id}`] = true);

        this.setState({
            expandedRows
        });
    }
    collapseAll() {
        this.setState({
            expandedRows: null
        });
    }
    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                <Banner text='Top Sell' icon='pi pi-heart' sell={true}></Banner>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <Button label="Expand All" onClick={() => this.expandAll()} className="ml-2 mr-2" />
                    <Button label="Collapse All" onClick={() => this.collapseAll()} className="ml-2 mr-2" /> */}
                </div>

            </div>
        );
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });
        let headerGroup = (
            <ColumnGroup>
                <Row >
                    <Column style={{ width: '3em' }} />
                    {
                        this.state.selectedColumns.map(item => {

                            return (
                                <Column header={item['header']} />
                            )

                        })
                    }
                </Row>
                <Row >
                    <Column colSpan={3} />
                    {
                        this.state.selectedColumns.map(item => {

                            let col = {
                                'Sell DXG': 'headerDXG',
                                'Sell HPG': 'headerHPG',
                                'Sell MBB': 'headerMBB',
                                'Sell MSN': 'headerMSN',
                                'Sell MWG': 'headerMWG',
                                'Sell SSI': 'headerSSI',
                                'Sell STB': 'headerSTB',
                                'Sell TCB': 'headerTCB',
                                'Sell VIC': 'headerVIC',
                                'Sell VND': 'headerVND',
                                'Sell VPB': 'headerVPB',
                                'Sell NVL': 'headerNVL',
                                'Sell HDB': 'headerHDB',
                                'Sell EIB': 'headerEIB',
                                'Sell VRE': 'headerVRE',
                                'Sell VHM': 'headerVHM',
                                'Sell CTG': 'headerCTG',
                                'Sell VCB': 'headerVCB',
                                'Sell HSG': 'headerHSG',
                                'Sell FPT': 'headerFPT',
                                'Sell VNM': 'headerVNM',
                                'Sell ACB': 'headerACB',
                                'Sell DIG': 'headerDIG',
                                // 'Sell PVD': 'headerPVD',
                                'Sell MSB': 'headerMSB',
                                'Sell PNJ': 'headerPNJ',
                                'Sell FRT': 'headerFRT',
                                'Sell DGC': 'headerDGC',
                                // 'Sell KBC': 'headerKBC',
                                'Total Sell': 'headerTotalSell',
                                'Day Val Sell': 'headerDayValSell',
                                
                            }


                            if (col[item['header']]) {
                                return (
                                    
                                    <Column header={<div style={{ color: 'orange' }}>  {global.formatNumber(Math.round(this.state[col[item['header']]] * 100) / 100)}</div>} />
                                )
                            }

                        })
                    }
                </Row>
            </ColumnGroup>
        );
        return (

            <div className="p-col-12 p-md-12" style={{ display: 'flex' }}>
                <div className="card widget-table" style={{ width: '80%' }} >
                    <DataTable

                        className="p-datatable-customers" value={this.state.data}
                        dataKey="id" stripedRows
                        emptyMessage="No data."
                        lazy loading={this.state.loading}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        onFilter={(event) => this.onFilter(event)}
                        responsiveLayout="scroll"
                        header={header}
                        headerColumnGroup={headerGroup}
                        expandedRows={this.state.expandedRows}
                        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={(data) => this.rowExpansionTemplate(data)}
                    >
                        <Column expander style={{ width: '3em' }} />
                        {columnComponents}
                    </DataTable>

                </div>

                <div style={{ width: '20%', marginLeft: '25px' }}>

                </div>


            </div>
        )
    }
}

export default TopSell;