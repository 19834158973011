import React, { Component } from 'react';
import Arbit_Unwind from '../models/admin/Arbit_Unwind';
class ArbitUnwindView extends Component {

    constructor(props) {
        super(props);

        this.model = new Arbit_Unwind()
    }

    componentDidMount() {

        this.model.getData().then(res => {

            var Arbit_Data = {
                x: [],
                y: [],
                type: 'bar',
                name: 'Arbit'
            };
            var Unwind_Data = {
                x: [],
                y: [],
                type: 'bar',
                name: 'Unwind'
            };

            var VN30_Data = {
                name: 'VN30',
                x: [],
                close: [],
                high: [],
                low: [],
                open: [],
                // text: kline['day'],
        
                type: 'candlestick',
                xaxis: 'x',
                yaxis: 'y2',
            };
            let tickvals = []
            let sharps_data = []
            res.map((item,index) => {

                Arbit_Data['x'].push(item['day'])
                Arbit_Data['y'].push(item['arbit'])

                Unwind_Data['x'].push(item['day'])
                Unwind_Data['y'].push(item['unwind'])

                VN30_Data['x'].push(item['day'])
                VN30_Data['close'].push(item['close'])
                VN30_Data['high'].push(item['high'])
                VN30_Data['low'].push(item['low'])
                VN30_Data['open'].push(item['open'])

                // if(index % 50 ==0){
                if(item['ticker_change']){
                    tickvals.push(item['day'])
                    sharps_data.push( {
                        type: 'line',
                        yref: 'paper',y0: 0,y1: 1,
                        xref: 'x',x0: item['day'],x1: item['day'],
                        line: {
                          color: 'black',
                          width: 0.2,
                          dash: 'dot'
                        }
                      })
                }
                
            })
            var data = [Arbit_Data, Unwind_Data];
            var Acc_Data = {
                name: '',
                x: [],
                y: [],
                mode: 'lines',
                line: {
                    color: '#3d9970',
                  },
                legendgroup: 'group_acc' ,
              };
            for(let i=0 ; i <= res.length -2; i++){

                Acc_Data['name'] = `Accumulated Net Arbit Unwind`
                Acc_Data['x'].push(res[i]['day'])
                Acc_Data['y'].push(res[i]['cum_net_arbit_unwind'])
                if(i == res.length -2){
                    Acc_Data['x'].push(res[i+1]['day'])
                    Acc_Data['y'].push(res[i+1]['cum_net_arbit_unwind'])

                    data.push(Acc_Data)
                    break
                }
                if(res[i+1]['ticker_change']){
                    data.push(Acc_Data)
                    Acc_Data = {
                        name: '',
                        x: [],
                        y: [],
                        mode: 'lines',
                        line: {
                            color: '#3d9970',
                          },
                          showlegend: false,
                          legendgroup: 'group_acc' ,
                    };


                }


              
            }

            data.push(VN30_Data)
            
            // var data = [Arbit_Data, Unwind_Data, Acc_Data, VN30_Data];

            var layout = {
         
                height: 800,
                showlegend: true,
                margin: { b: 100, t: 40, l: 130, r: 0 },
                hovermode: "x unified",
                barmode: 'group',
                xaxis: {
                  autorange: true,
                  type: "category",
                  categoryorder: 'category ascending',
                  rangeslider: { 'visible': false },
                  showspikes: true,
                  spikemode: 'across',
                  spikesnap: 'cursor',
                  spikedash: 'dot',
                  spikethickness: 2,
                  showticklabels: true,
                  tickangle: 'auto',
                  showexponent: 'all',
                  tickmode: "array",
                  tickvals: tickvals,
                  ticktext: tickvals,
                },
                yaxis: {
                  autorange: true,
                  showspikes: true,
                  spikemode: 'across',
                  spikesnap: 'cursor',
                  spikedash: 'dot',
                  spikethickness: 2,
                  domain: [0.42, 1]
                },
                yaxis2: { domain: [0, 0.4] },
                barmode: 'relative',
                grid: {
                  rows: 2,
                  columns: 1,
                  subplots: [['xy'], ['xy2']],
                  roworder: 'top to bottom',
                },
                shapes:sharps_data
              };
            global.Plotly.newPlot(this.chart, data, layout );

        })
    }
    render() {
        return (

            <div style={{ margin: '10px 0px' }}>
                <div style={{ width: '99%' }} ref={c => this.chart = c}>

                </div>
            </div>

        );
    }
}

export default ArbitUnwindView;