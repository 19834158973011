import React, { Component } from 'react'

import { render } from 'react-dom'
import Transaction from '../models/admin/Transaction';

class ToolTip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            flag: false,
            text: '',
            cus_tom: false
        }
        this.id = 'dfhdaiope35486045';


        this.model = new Transaction()

    }

    set(flag, text, scroll = true, cus_tom) {
        this.setState({ flag: flag, cus_tom: cus_tom });
        if (flag) {
            this.tooltipTimeout = setTimeout(() => { this.setState({ flag: false }) }, 300000);
            // this.setChartContent()
           

        }
        if (scroll) {
            setTimeout(() => {
                var elmnt = document.getElementById(this.id);
                elmnt.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
            }, 200);
        }
    }

    componentWillUnmount() {
        if (this.tooltipTimeout) clearTimeout(this.tooltipTimeout);
    }
  
    loadData(id, stock , name) {
        let params = {
            "id": id,
            "stock": stock
        }
        this.model.pnl(params).then(res => {
            if(Object.keys(res) == 0){
                this.setState({
                    flag : false

                });
                return
            }
            let pnl = {
                x : [],
                y : [],
                type: 'scatter',
                name : 'pnl'
            }
            let adjPosition = {
                x : [],
                y : [],
                "type": "bar",
                xaxis: 'x',
                yaxis: 'y2',
                name : 'adjPosition'
            }
            let kline = {
                x: [],
                close: [],
                high: [],
                low: [],
                open: [],
                name : stock,
      
                "type": "candlestick",
                "xaxis": "x",
                "yaxis": "y3"
            }

            let tickText = []
            let range = res['plot_data'].length > 30 ? 30 : 10
            res['plot_data'].map((item , index) => {

                if (index % range == 0) {
                    tickText.push(item['day'])
                }

        
                pnl['x'].push(item['day'])
                pnl['y'].push(item['pnl'])

                adjPosition['x'].push(item['day'])
                adjPosition['y'].push(item['adjPosition'])

                kline['x'].push(item['day'])
                kline['open'].push(item['open'])
                kline['high'].push(item['high'])
                kline['low'].push(item['low'])
                kline['close'].push(item['close'])
            })

 
            this.setChartContent(pnl , adjPosition , kline , tickText)

   

            let text = res['sharpe'] == "" ? `${name} , stk : ${id}, active_days : ${res['active_days']}` : `${name} , stk : ${id}, sharpe : ${res['sharpe'].toFixed(2)}, active_days : ${res['active_days']}`

            this.setState({
                text
            });

        })
    }

    setChartContent(pnl , adjPosition , kline , tickText) {
        var layout = {

            height: 400,
            width : 900,
            showlegend: true,
            margin: { b: 20, t: 20, l: 50, r: 0 },
        
            xaxis: {
              autorange: true,
              type: "category",
            //   categoryorder: 'category ascending',
              rangeslider: { 'visible': false },
              showspikes: true,
              spikemode: 'across',
              spikesnap: 'cursor',
              spikedash: 'dot',
              spikethickness: 2,
              tickmode: "array",
              tickvals: tickText,
              ticktext: tickText,
                showticklabels: true,
              tickangle: 'auto',
              showexponent: 'all'
      
            },
       
            grid: {
              rows: 3,
              columns: 1,
              subplots: [['xy'], ['xy2'] , ['xy3']],
              roworder: 'top to bottom',
            }
          };

        var data = [pnl , adjPosition , kline];


        global.Plotly.newPlot(this.chart, data ,layout);
    }





    render() {

        return (
            <>
                <style id="wrgatgrtga">{`
						.input_tootip{
							position: absolute;
							border: solid thin orange;
							border-radius: 5px;
                            top: -6px;
                            left: 150%;
                            z-index: 1000;
						   
						}
						.input_tootip::before{
							content: '';
                         
                            top: 5px;
                            left: -7px;
						    border: solid thin orange;
						    position: absolute;
						    width: 16px;
						    height: 16px;
						    background: white;
						    transform: rotate(45deg);
						    z-index: 0;
							
						}

                        .input_tootip2{
							position: absolute;
							border: solid thin orange;
							border-radius: 5px;
                            bottom : 0px;
                            left: 150%;

                            z-index: 1000;
						   
						}
						.input_tootip2::before{
							content: '';
                         
                            bottom: 3px;
                            left: -7px;
						    border: solid thin orange;
						    position: absolute;
						    width: 16px;
						    height: 16px;
						    background: white;
						    transform: rotate(45deg);
						    z-index: 0;
							
						}
						.input_tootip div , .input_tootip2 div  {
					    	padding: 5px;
							background: white;
							position:relative;
							border-radius: 5px;
							z-index:1;
							white-space: nowrap;
					    	
						}
						
						
					`}</style>
                <div id={this.id} className={this.state.cus_tom ? 'input_tootip2' : 'input_tootip'} style={{ display: this.state.flag ? 'block' : 'none' }}>
                    <div className='box_flex'>
                        <i className="fa fa-exclamation-circle" style={{ color: 'orange' }}>
                        </i>
                        &nbsp;{this.state.text}

                        <div ref={c => this.chart = c}>

                        </div>

                    </div>


                </div>
            </>
        )

    }
}




export default ToolTip;
