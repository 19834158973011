import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';

import TransactionModel from '../models/admin/Transaction';
import OutStanding from '../models/admin/OutStanding';
import OutStandingRightView from '../components/OutStanding/OutStandingRightView';
import OutStandingLeftView from '../components/OutStanding/OutStandingLeftView';
class OutstandingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {},

            // code: 'HPG',
            code: 'MWG',
            optionsCode: [],
            valueDay: null,
            top: '100',
        };

        this.model = new OutStanding()


        this.loadLazyTimeout = null;
    }
    componentDidMount() {
        this.getMaxday()
    }
    getMaxday() {
        let model = new TransactionModel()
        model.query().then(res => {
            this.setState({
                valueDay: new Date(res['max_day']),
                filters: {
                    'code': {
                        "col_name": "code",
                        "operator": "in",
                        "value": this.state.code
                    },
                    'day': {
                        "col_name": 'day',
                        "operator": 'in',
                        "value": res['max_day']
                    },
                    'top': {
                        "col_name": 'top',
                        "operator": 'in',
                        "value": this.state.top
                    },

                },
                maxDay: res['max_day'],
                optionsCode: res['categories']['stock']


            }, () => this.getData());
        })
    }
    getData() {

        this.OutStandingRightView.setLoading()
        this.OutStandingLeftView.setLoading()

        let filter = {
            data_filter: Object.values(this.state.filters),

        }
        // filter['data_filter'][1]['value'] = [filter['data_filter'][1]['value']]


        this.model.getData(filter).then(response => {
          
            const mid = Math.ceil(response.length / 2);
            const arr1 = response.slice(0, mid );
            const arr2 = response.slice(mid , response.length );
            this.OutStandingRightView.setData(arr1)
            this.OutStandingLeftView.setData(arr2 , mid)
     



        })
            .catch((error) => {
                this.OutStandingRightView.setData(null)
                this.OutStandingLeftView.setData(null)


            })



    }

    change(col, value, filterkey, type) {
        var filter = this.state.filters;

      
    
        if (type == 'text') {

            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": value.trim()
                }
            }
        }else if (type == 'date') {
            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": moment(value).format("YYYY/MM/DD")
                }
            }
        }

        this.setState({
            [col]: value,
            filters: filter
        }, () => {
            this.getData()
        });
    }
    render() {
        return (
            <div>
                <div className="p-col-12 p-md-12" >

                    <div className="card widget-table" >
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '20%', marginRight: '20px' }}>
                                <div style={{ paddingBottom: '20px', textAlign: 'center' }}>SEARCH BY CODE</div>
                                <div>
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        value={this.state.code}
                                        options={this.state.optionsCode}
                                        onChange={(e) => this.change('code', e.value, 'code', 'text')}
                                        placeholder="Select a code"
                                        showClear
                                    />

                                </div>
                            </div>
                            <div style={{ width: '26%', marginRight: '20px' }}>
                                <div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <div style={{ textAlign: 'center', paddingBottom: '20px', cursor: 'pointer' }} >
                                            SEARCH BY DATE

                                        </div>
                                        <div>
                                            <Calendar
                                                style={{ width: '100%' }}
                                                dateFormat="dd - M" v
                                                value={this.state.valueDay}
                                   
                                                onChange={(e) => this.change('valueDay', e.value, 'day', 'date')}
                                                readOnlyInput
                                                showButtonBar />
                                        </div>



                                    </div>


                                </div>
                            </div>


                            <div style={{ width: '20%' }}>
                                <div style={{ paddingBottom: '20px', textAlign: 'center' }}>SELECT TOP</div>
                                <div>


                                    <input 
                                    style={{ width: '100%' }} 
                                    className='p-inputtext p-component' 
                                    list="browsers" 
                                    value={this.state.top} 
                                    onChange={(e) => this.change('top', e.target.value, 'top', 'text')}
                                     />

                                    <datalist id="browsers">

                                        <option value="50" />
                                        <option value="100" />
                                        <option value="200" />
                                    </datalist>



                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <div>
                                {Object.keys(this.state.filters).length} filter : {
                                    Object.keys(this.state.filters).map(item => {
                                        let value1 = this.state.filters[item]['value']
                                        // if (item == 'day') {
                                        //     value1 = value1.map(item => moment(item).format('DD - MMM'))

                                        // }
                                        return (
                                            <b onClick={() => this.clearFilter(this.state.filters[item])} style={{ cursor: 'pointer' }}>
                                                <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                                {`     ${this.state.filters[item]['operator']} '${item}' `}    |
                                            </b>)
                                    }

                                    )
                                }

                                {
                                    Object.keys(this.state.filters).length > 0 && <Button onClick={() => this.clearFilter()} style={{ position: 'relative', top: '7px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                                }
                            </div>


                        </div>
                    </div>






                </div>

                <div className="p-col-12 p-md-12" style={{ display: 'flex', gap: '20px' }}>
                    <div className="card widget-table" style={{ width: '50%' }} >

                        <OutStandingRightView ref={c => this.OutStandingRightView = c}></OutStandingRightView>

                    </div>
                    <div className="card widget-table" style={{ width: '50%' }} >

                        <OutStandingLeftView ref={c => this.OutStandingLeftView = c}></OutStandingLeftView>

                    </div>


                </div>
            </div>
        );
    }
}

export default OutstandingView;