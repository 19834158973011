import model from "../model";
import axios from "axios";
import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class Arbit_Unwind {

    constructor() {
        this.url = domain_config(2)
        this.queryInfo = new QueryInfo()
    }

    getData() {
        if (global.data_arbit_unwind) {
            this.queryInfo.info({}, `${this.url}/db11-arbit-unwind2`)
            return Promise.resolve(global.data_arbit_unwind)
        } else {
            return axios.request({
                url: `${this.url}/db11-arbit-unwind2`,
                method: "GET",
            })

                .then(response => {
                    let datas = response['data']

                    global.data_arbit_unwind = datas;
                    
                    return datas

                
                })

                .catch((error) => {

                    console.log(error);
                    return false;
                })

        }



    }









}

export default Arbit_Unwind;