import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import DashboardModel from '../models/admin/Dashboard'
class Dashboard2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code:  { name: 'HPG', code: 'HPG' },
        }

        this.optionsCode = [
            { name: 'HDB', code: 'HDB' },
            { name: 'HPG', code: 'HPG' },
            { name: 'MSN', code: 'MSN' },
            { name: 'STB', code: 'STB' },
            { name: 'TCB', code: 'TCB' },
            { name: 'VCI', code: 'VCI' },
            { name: 'VIC', code: 'VIC' },
            { name: 'VPB', code: 'VPB' },
        ];

        this.dashboardModel = new DashboardModel()

    }

    getData() {
        let filter = {
          'ticker': this.state.code['code']
        }
        this.dashboardModel.getDataInsider(filter).then(res => {
        })
      }

    ChangeCode(data) {
        this.setState({
          code: data
        }, () => this.renderIframe());
      }

    renderIframe() {
        if (this.state.code == null) return

        let src = `assets/test1/${this.state.code['code']}.html`
        // console.log(src)
        this.getData()
        return(
            <iframe src={src} style={{ width: '100%', height: '90vh', border: 'none' }}></iframe>
        )
    }

    render() {
        return (
            <div>
                <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'flex-end' }}>
                    <Dropdown value={this.state.code} options={this.optionsCode} onChange={(e) => this.ChangeCode(e.value)} optionLabel="name" placeholder="Select a code" />
                </div>
                {
                    this.renderIframe()
                }
            </div>

        );
    }
}

export default Dashboard2;