import React, { Component } from 'react';

class Banner extends Component {

    render() {
        let color = this.props.sell ? 'orange' : '#0066CC'
        let colorIcon = this.props.sell ? 'orange' : '#42abfe'
        let imgSrc = this.props.sell ? 'assets/layout/images/bannerSell.jpg' : 'assets/layout/images/bannerBuy.jpg'
        return (
            <div style={{ position: 'relative', marginBottom: '10px' }}>
                <div style={{ position: 'absolute', top: '3px', left: '7px' }}>
                    <i className={this.props.icon} style={{ fontSize: '30px', marginRight: '10px', color: colorIcon }}></i>
                    <span
                        style={ { textTransform: 'uppercase', fontSize: '30px', color: color }}> {this.props.text}
                    </span>
                </div>
                <img src={imgSrc} alt="avatar" style={{ width: '100%', height: '45px' }} />
            </div>
        );
    }
}

export default Banner;