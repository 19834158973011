import React, { Component } from 'react';
import ChartAnalysis from '../components/analysis2/ChartAnalysis';
import TableAnalysis from '../components/analysis2/TableAnalysis';
import Analysis from '../models/admin/Analysis';

class Analysis2View extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataRes : []
        }
        
        this.model = new Analysis()
    }
    
    componentDidMount(){
        let parsed = global.parsed()

        if (parsed['stk']) {
            
             
            if(parsed['stock']){
                this.getData(parsed['stk'] , parsed['stock'])
            }else{
                this.getData(parsed['stk'] , false)

            }
            
        }
    }

    getData(stk , stock){
        let filter = [
            {

                "col_name": "id",
                "operator": "in",
                "value": stk

            }
        ];
        this.model.getData({'data_filter' : filter }).then(res => {
            let stocks = res['stockUnique']
            let optStock = []

            stocks.map(item => {
                optStock.push({ 
                    name: item,
                    code: item })
            })

            this.setState({
                dataRes : res['data']
            });
            if(stock == false){
                this.ChartAnalysis.setStock(optStock , optStock[0])
                this.ChartAnalysis.setData(res['data'][optStock[0]['code']])
                this.TableAnalysis.setData(res['data'][optStock[0]['code']])
            }else{
                let stockConfig = {
                    'name' : stock,
                    'code' : stock
                }
                this.ChartAnalysis.setStock(optStock , stockConfig)
                this.ChartAnalysis.setData(res['data'][stockConfig['code']])
                this.TableAnalysis.setData(res['data'][stockConfig['code']])
           
            }
    
          

        })
    }

    changeStock(stock){

        this.ChartAnalysis.setData(this.state.dataRes[stock])

        this.TableAnalysis.setData(this.state.dataRes[stock])

   
    }
    render() {
        return (
            <>
                <ChartAnalysis parent={this} changeStock={(s) => this.changeStock(s)} ref={c => this.ChartAnalysis = c}></ChartAnalysis>
             
                <TableAnalysis ref={c => this.TableAnalysis = c}></TableAnalysis>
            </>
        );
    }
}

export default Analysis2View;