import model from "../model";
import axios from "axios";

import QueryInfo from "./QueryInfo";

const queryString = require('query-string');
class ExtraApi {
	constructor() {
	
        this.urlStock = 'https://histdatafeed.vps.com.vn/tradingview/history?'
	}



	getDataByStock(symbol,resolution, from, to, ){

        let params = {
            symbol,
            resolution,
            from,
            to
        }

        const stringified = queryString.stringify(params);
		return axios.request({
			url: `${this.urlStock}${stringified}`,
			method: "GET",
		})

			.then(response => {
				response = response['data'];
				return response;
			})

			.catch((error) => {
				console.log(error);
				return false;
			})
	}




}

export default ExtraApi;