import React, { Component } from 'react';
import TopBuySellModel from '../../models/admin/TopBuySell';

class ChartTop10 extends Component {
    constructor(props) {
        super(props);
        this.model = new TopBuySellModel()
    }


    componentDidMount() {
        this.renderChart()


    }

    renderChart() {


        this.model.getTopBuySell({ data_filter: [] }, true).then(res => {


            let buy = {
                'x': [],
                'y': [],
            };
            let sell = {
                'x': [],
                'y': [],
            }
            let buyTitle = res['buy']['day'][0]
            let sellTitle = res['sell']['day'][0]
            Object.keys(res).map((item, index) => {

                if (item == 'buy') {
                    buy['x'] = res[item]['name']
                    buy['y'] = res[item]['day_val_buy']
                } else {
                    sell['x'] = res[item]['name']
                    sell['y'] = res[item]['day_val_sell']
                }
            })


            var buyData =
            {
                x: buy.x,
                y: buy.y,
                text: buy.y.map(item => item.toFixed(2)),
                type: 'bar',
                marker: {
                    color: '#60bbb6'
                },
                name: 'buy'
            };

            var selLData = {
                x: sell.x,
                y: sell.y,
                text: sell.y.map(item => item.toFixed(2)),
                type: 'bar',
                marker: {
                    color: '#ec8182'
                },
                name: 'sell',
                xaxis: 'x2',
                yaxis: 'y2',
            }

            var data = [buyData, selLData];

            var layout = {
                height: 900,
                margin: { b: 150 },
                grid: { rows: 2, columns: 1, pattern: 'independent' },
                title: {
                    text: 'Top 10 Buy Sell',
                },
                yaxis: {
                    title: buyTitle
                },
                yaxis2: {
                    title: sellTitle
                }
            }

            global.Plotly.newPlot(this.chartTop10, data, layout);
        })

    }

    render() {
        return (

            <div style={{ width: '100%', marginTop: '20px' }} ref={c => this.chartTop10 = c}>

            </div>

        );
    }
}

export default ChartTop10;