import React, { Component } from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';

import Banner from '../Banner';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

class OutStandingRightView extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            { field: 'index', header: 'Index', body: true },
            { field: 'stk_str', header: 'STK', body: false, filter: false },
            { field: 'name', header: 'Name', body: false, filter: false },
            { field: 'address', header: 'Address', body: false, filter: false },
            { field: 'current_balance', header: 'Balance', body: true, filter: false },


        ];

        this.state = {
            data: [],
            loading: false,
            totalRecords: 0,
            first: 0,
            quantity: 25,
            page: 1,
            sortField: 'buy',
            sortOrder: -1,
            filters: {},
            rankByCTCK: false,
            selectedColumns: [
                { field: 'index', header: 'Index', body: true },
                { field: 'stk_str', header: 'STK', body: false, filter: false },
                { field: 'name', header: 'Name', body: false, filter: false },
                { field: 'address', header: 'Address', body: false, filter: false },
                { field: 'current_balance', header: 'Balance', body: true, filter: false },

            ],


        }


        this.loadLazyTimeout = null;


    }

    setLoading() {
        this.setState({ loading: true });
    }
    setData(data ) {
        this.setState({
            data: data,
            loading: false,
        });
    }
    renderColumnComponents() {

        return this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });


    }
    representativeTemplate = (data, props) => {
     

        if (props.field == "index") {

           
            return (
                <div >
                    <span>{props.rowIndex + 1}</span>
          
                </div>
            
            )
        }

        if (props.field == "current_balance") {

           
            return (
                <div >
                    <span>{global.formatNumber(data[props.field])}</span>
          
                </div>
            
            )
        }
    







    }
    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
       
    }
    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                {/* <Banner text='Top Buy' icon='pi pi-star'></Banner> */}


                <MultiSelect value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} style={{ width: '20em' }} />



            </div>
        );
        return (
            <div>
                <DataTable
                    className="p-datatable-customers" value={this.state.data}
                    dataKey="id"
                    stripedRows
                    emptyMessage="No data."
                    lazy loading={this.state.loading}
                    // sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                    // onFilter={(event) => this.onFilter(event)}
                    // onSort={(event) => this.onSort(event)}
                    responsiveLayout="scroll"
                header={header}
                // footerColumnGroup={footerGroup}

                >
                    {this.renderColumnComponents()}
                </DataTable>
            </div>
        );
    }
}

export default OutStandingRightView;