import React, { Component } from 'react';

class ChartBuy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }


    load_data(data ) {
  
        if(Object.keys(data).length > 0){
        
            let data_chart = []
           
            data['day']=  data['day'].map(date =>   {
              
                if(typeof(date) == 'number'){
             
                    return String(date).substring(0, 4) + '-' +  String(date).substring(4, 6) + '-' +  String(date).substring(6, 8)
                }
                return date
                
            });
  
        
            var nemo = {
                x: data['day'],
                y: data['nemo'],
                name: 'nemo',
                type: 'bar',
                marker: {
                    color: '#4e99d3'
                  }
            };
            var shark = {
                x: data['day'],
                y: data['shark'],
                name: 'shark',
                type: 'bar',
                marker: {
                    color: '#ff7800'
                  }
        
            };
            var sword = {
                x: data['day'],
                y: data['sword'],
                name: 'sword',
                type: 'bar',
                marker: {
                    color: '#18af18'
                  }
            };
            var tuna = {
                x: data['day'],
                y: data['tuna'],
                name: 'tuna',
                type: 'bar',
                marker: {
                    color: '#dc2c2c'
                  }
            };
            var whale = {
                x: data['day'],
                y: data['whale'],
                name: 'whale',
                type: 'bar',
                marker: {
                    color: '#a484c2'
                  }
            };




            data_chart = [whale,shark,sword,tuna,nemo]
           
    
    
            var layout = {
                title: 'Net Buy Sell',
                // height: 500,
 
                barmode: 'relative',
                showlegend: true,

                hovermode: "x unified",
                hoverdistance: 10,
                autosize: true,
                xaxis: {
                    autorange: true,
                    type: "date",
                    categoryorder: "category ascending",
                    rangeslider: { visible: false },
                    showspikes: true,
                    spikemode: "across",
                    spikesnap: "cursor",
                    spikedash: "dot",
         
                    spikethickness: 2,
                
                    showexponent: "all",
       
                },
                yaxis: {
                    visible: true,
                    showline: true,
                    tickcolor: '#eeee',
                    linecolor: '#eeee',
                    autorange: true,
                    showspikes: true,
                    spikemode: "across",
                    spikesnap: "cursor",
                    spikedash: "dot",
      
                    spikethickness: 2,
        

                },
               
            };
            var config = { responsive: true }
            global.Plotly.newPlot(this.chart, data_chart, layout, config);
        }else{
            global.Plotly.newPlot(this.chart, []);
        }
     
    

        this.setState({
            loading : false
        });
    }

    render() {
        return (
            <div style={{ margin: '10px 0px' }}>
                <div style={{ width: '99%' }} ref={c => this.chart = c}>

                </div>
            </div>
        );
    }
}

export default ChartBuy;