import model from "../model";
import axios from "axios";
import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class User {
	constructor() {
		// this.url = 'https://d11.f5traders.com/api'
		this.url = domain_config(1)
		this.queryInfo = new QueryInfo()
	}

	filter(filter) {
		let key = global.genKey(filter)
		if (global.data_user && global.data_user[key]) {
			this.queryInfo.info(filter , `${this.url}/db11-endpoint-ranks` )
			return Promise.resolve(global.data_user[key])
		} else {
			return axios.request({
				url: `${this.url}/db11-endpoint-ranks`,
				method: "POST",
				data: { ...filter, }
			})

				.then(response => {
					// if (loading) App.loading(false, 'Loading...');
					response = response['data'];
					if (!global.data_user) {
						global.data_user = {}
					}

					global.data_user[key] = response;
					return response;
				})

				.catch((error) => {
					if (error.response.status == 401) {
						window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent(window.location.href)}`
						return false;
					}
					console.log(error);
					return false;
				})
		}
	}

	OneUser(filter){
		return axios.request({
			url: `${this.url}/db11-one-user-url`,
			method: "POST",
			data: { ...filter },
		
		})

			.then(response => {
				response = response['data'];
				// if (!global.data_user) {
				// 	global.data_user = {}
				// }

				// global.data_user[key] = response;
				return response;
			})

			.catch((error) => {
				console.log(error);
				return false;
			})
	}




}

export default User;