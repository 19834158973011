import model from "../model";
import axios from "axios";
import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class Classify {

    constructor() {
        // this.url = 'https://d11.f5traders.com/api_v2'

        this.url = domain_config(2)
        this.queryInfo = new QueryInfo()
    }

    getData(filter) {
        let key = global.genKey(filter)
        if (global.data_classify && global.data_classify[key]) {
			this.queryInfo.info(filter , `${this.url}/db11-classify` )
			return Promise.resolve(global.data_classify[key])
		} else {
            return axios.request({
                url: `${this.url}/db11-classify`,
                method: "POST",
                data: { ...filter }
            })

                .then(response => {
                    let datas = response['data']


                    if (!global.data_classify) {
                        global.data_classify = {}
                    }
                    
                    global.data_classify[key] = datas;
                    return datas
                })

                .catch((error) => {

                    console.log(error);
                    return false;
                })
            }



    }


}

export default Classify;