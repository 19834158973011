// import React, { Component } from 'react';
// import { MultiSelect } from 'primereact/multiselect';
// import { Dropdown } from 'primereact/dropdown';
// class ChartAnalysis extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             stockValue: null,
//             stockOptions: []
//         }
//     }

//     setStock(optStock, valueStock) {
//         this.setState({
//             stockOptions: optStock,
//             stockValue: valueStock
//         });
//     }
//     setData(data) {
//         let parsed = global.parsed()
//         var stock = {
//             x: [],
//             y: [],
//             type: 'scatter',
//             name: `${parsed['name']} - ${parsed['stk']}`,
//             text: [],
//             hovertemplate: `<br>%{text}<br>`,
//         };
//         let tickText = []
//         let buyPos = {
//             x: [],
//             y: [],
//             name: "Buy",
//             marker: { color: 'green', size: 8, symbol: 'star-triangle-up' },
//             mode: "markers",
//             type: 'scatter',
//             text: [],
//             hovertemplate: `<br>%{text}<br>`,
//         }
//         let sellPos = {
//             x: [],
//             y: [],
//             name: "Sell",
//             marker: { color: 'red', size: 8, symbol: 'star-triangle-down' },
//             mode: "markers",
//             text: [],
//             hovertemplate: `<br>%{text}<br>`,
//             type: 'scatter'
//         }
//         var Position = {
//             x: [],
//             y: [],
//             type: 'bar',
//             name: 'Adj Position',
//             // text: [],
//             xaxis: 'x',
//             yaxis: 'y2',
//             marker: {
//                 color: '#1f77b4'
//             },
//             hovertemplate: '<b>%{x}</b>' +
//                         '<br><b>%{y:,}</b>',
//         };
//         data.map((item, index) => {
//             let day = item['day'].toString()
//             day = [day.slice(0, 4), '_', day.slice(4)].join('');
//             day = [day.slice(0, 7), '_', day.slice(7)].join('');
//             let pnl = item['pnl']

//             stock['x'].push(day)
//             stock['y'].push(pnl)
//             stock['text'].push(`<b>PNL</b>   </br> <b>${day}</b>   </br> <b>${global.formatNumber(Math.round(pnl*100)/100) }</b> `)
//             if (index % 10 == 0) {
//                 tickText.push(day)
//             }

//             let adjPosition = item['adjPosition']

//             Position['x'].push(day)
//             Position['y'].push(adjPosition)

//             let buy = item['buy']
//             let sell = item['sell']
//             if (buy > 0 && pnl != 0) {
//                 buyPos['x'].push(day)
//                 let pnlAdj = pnl > 0 ?  pnl * 0.95 :  pnl * 1.05
//                 buyPos['y'].push(pnlAdj)
//                 buyPos['text'].push(`<b>Buy</b> </br><b>${day}</b></br><b>${global.formatNumber(Math.round(buy * 100) / 100)}</b></br><b>Cp ${global.formatNumber(Math.floor(item['adjBuyPrice']))} VNĐ</b> `)
//             }
//             if (sell > 0 && pnl != 0) {
//                 sellPos['x'].push(day)
//                 let pnlAdj = pnl > 0 ?  pnl * 1.05 :  pnl * 0.95
//                 sellPos['y'].push(pnlAdj)
//                 sellPos['text'].push(`<b>Sell</b>   </br> <b>${day}</b>   </br> <b>${global.formatNumber(Math.round(sell * 100) / 100)}</b> </br><b>Cp ${global.formatNumber(Math.floor(item['adjSellPrice']))} VNĐ</b> `)
//             }
//         })


//         let dataChart = [stock, buyPos , sellPos , Position]
//         this.renderChart(dataChart, tickText)

//     }
//     renderChart(dataChart, tickText) {
//         var layout = {

//             height: 600,
//             showlegend: true,
//             margin: { b: 100, t: 40, l: 80, r: 80 },
//             xaxis: {
//                 autorange: true,
//                 type: "category",
//                 categoryorder: 'category ascending',
//                 rangeslider: { 'visible': false },
//                 showspikes: true,
//                 spikemode: 'across',
//                 spikesnap: 'cursor',
//                 spikedash: 'dot',
//                 spikethickness: 2,
//                 tickmode: "array",
//                 tickvals: tickText,
//                 ticktext: tickText,

//                 showticklabels: true,
//                 tickangle: 'auto',
//                 showexponent: 'all',

//                 showticklabels: true,

//             },
//             yaxis: {
//                 autorange: true,
//                 showspikes: true,
//                 spikemode: 'across',
//                 spikesnap: 'cursor',
//                 spikedash: 'dot',
//                 spikethickness: 2,
//                 domain: [0.25, 1]
//             },
//             yaxis2: { domain: [0, 0.2] },

//             grid: {
//                 rows: 2,
//                 columns: 1,
//                 subplots: [['xy'], ['xy2']],
//                 roworder: 'top to bottom',
//               }
//         }


//         global.Plotly.newPlot(this.chart, dataChart, layout);


//     }
//     change(data) {
//         this.setState({
//             stockValue: data
//         });

//         this.props.changeStock(data['code'])
//     }

//     render() {
//         return (
//             <div style={{ display: 'flex' }}>
//                 <div style={{ width: '80%' }} ref={c => this.chart = c}>

//                 </div>

//                 <div className="card" style={{ width: '20%', marginLeft: '25px', marginBottom: '0px' }} >



//                     <div style={{ marginBottom: '20px' }}>
//                         <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY TICKER </div>
//                         <div>
//                             {/* <MultiSelect
//                                 style={{ width: '100%' }}
//                                 value={this.state.stockValue}
//                                 // onChange={(e) => this.change(e.value, 'tickerValue', 'ticker', 'ticker_multi')}
//                                 options={this.state.stockOptions}
//                                 optionLabel="name" display="chip"
//                             /> */}
//                             <Dropdown
//                                 style={{ width: '100%' }}
//                                 value={this.state.stockValue}
//                                 options={this.state.stockOptions}
//                                 onChange={(e) => this.change(e.value)}
//                                 optionLabel="name"
//                             />
//                         </div>
//                     </div>


//                 </div>
//             </div>
//         );
//     }
// }

// export default ChartAnalysis;

import React, { Component } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';

import TableFavorite from './TableFavorite';
class ChartAnalysis extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stockValue: null,
            stockOptions: [],
        }
    }

    setStock(optStock, valueStock) {
        this.setState({
            stockOptions: optStock,
            stockValue: valueStock
        });
    }
    setData(data) {
        var stock = {
            x: [],
            y: [],
            type: 'scatter',
            name: 'PNL',
            text: [],
            hovertemplate: `<br>%{text}<br>`,
        };
        let tickText = []
        let buyPos = {
            x: [],
            y: [],
            name: "Buy",
            marker: { color: 'green', size: 8, symbol: 'star-triangle-up' },
            mode: "markers",
            type: 'scatter',
            text: [],
            hovertemplate: `<br>%{text}<br>`,
        }
        let sellPos = {
            x: [],
            y: [],
            name: "Sell",
            marker: { color: 'red', size: 8, symbol: 'star-triangle-down' },
            mode: "markers",
            text: [],
            hovertemplate: `<br>%{text}<br>`,
            type: 'scatter'
        }

        var Position = {
            x: [],
            y: [],
            type: 'bar',
            name: 'Adj Position',
            // text: [],
            xaxis: 'x',
            yaxis: 'y2',
            marker: {
                color: '#1f77b4'
            },
            hovertemplate: '<b>%{x}</b>' +
                        '<br><b>%{y:,}</b>',
        };
        data.map((item, index) => {
            let day = item['day'].toString()
            day = [day.slice(0, 4), '_', day.slice(4)].join('');
            day = [day.slice(0, 7), '_', day.slice(7)].join('');
            let pnl = item['pnl']

            stock['x'].push(day)
            stock['y'].push(pnl)
            stock['text'].push(`<b>PNL</b>   </br> <b>${day}</b>   </br> <b>${global.formatNumber(Math.round(pnl * 100) / 100)}</b> `)
            if (index % 10 == 0) {
                tickText.push(day)
            }

            let adjPosition = item['adjPosition']

            Position['x'].push(day)
            Position['y'].push(adjPosition)
            // Position['text'].push(`<b>${global.formatNumber(Math.round(adjPosition * 100) / 100)} <b> `)
          

            let buy = item['buy']
            let sell = item['sell']
            if (buy > 0 && pnl != 0) {
                buyPos['x'].push(day)
                let pnlAdj = pnl > 0 ? pnl * 0.95 : pnl * 1.05
                buyPos['y'].push(pnlAdj)
                buyPos['text'].push(`<b>Buy</b> </br><b>${day}</b></br><b>${global.formatNumber(Math.round(buy * 100) / 100)}</b></br><b>Cp ${global.formatNumber(Math.floor(item['adjBuyPrice']))} VNĐ</b> `)
            }
            if (sell > 0 && pnl != 0) {
                sellPos['x'].push(day)
                let pnlAdj = pnl > 0 ? pnl * 1.05 : pnl * 0.95
                sellPos['y'].push(pnlAdj)
                sellPos['text'].push(`<b>Sell</b>   </br> <b>${day}</b>   </br> <b>${global.formatNumber(Math.round(sell * 100) / 100)}</b> </br><b>Cp ${global.formatNumber(Math.floor(item['adjSellPrice']))} VNĐ</b> `)
            }
        })


        let dataChart = [stock, buyPos, sellPos , Position]
        this.renderChart(dataChart, tickText)

    }
    renderChart(dataChart, tickText) {
        var layout = {

            height: 800,
            showlegend: true,
            margin: { b: 100, t: 40, l: 80, r: 80 },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikethickness: 2,
                tickmode: "array",
                tickvals: tickText,
                ticktext: tickText,

                showticklabels: true,
                tickangle: 'auto',
                showexponent: 'all',

                showticklabels: true,

            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikethickness: 2,
                domain: [0.25, 1]
            },
            yaxis2: { domain: [0, 0.2] },

            grid: {
                rows: 2,
                columns: 1,
                subplots: [['xy'], ['xy2']],
                roworder: 'top to bottom',
              }
        }


        global.Plotly.newPlot(this.chart, dataChart, layout);


    }
    change(data) {
        this.setState({
            stockValue: data
        });

        this.props.changeStock(data['code'])
    }


    render() {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ width: '75%' }} ref={c => this.chart = c}>

                </div>

                <div className="card" style={{ width: '25%', marginLeft: '25px', marginBottom: '0px' }} >



                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY TICKER </div>
                        <div>
                            {/* <MultiSelect
                                style={{ width: '100%' }}
                                value={this.state.stockValue}
                                // onChange={(e) => this.change(e.value, 'tickerValue', 'ticker', 'ticker_multi')}
                                options={this.state.stockOptions}
                                optionLabel="name" display="chip"
                            /> */}
                            <Dropdown
                                style={{ width: '100%' }}
                                value={this.state.stockValue}
                                options={this.state.stockOptions}
                                onChange={(e) => this.change(e.value)}
                                optionLabel="name"
                            />


                        </div>

                    </div>
                    <hr></hr>
                   

                    <TableFavorite parent={this.props.parent}></TableFavorite>

                </div>
            </div>
        );
    }
}

export default ChartAnalysis;