import model from "../model";
import axios from "axios";
import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class Transaction {

	constructor() {
		// this.url = 'https://d11.f5traders.com/api'
		this.url = domain_config(1)
		this.queryInfo = new QueryInfo()
	}

	filter(filter) {
		let key = global.genKey(filter)
		if (global.data_tran && global.data_tran[key]) {
			this.queryInfo.info(filter , `${this.url}/db11-endpoint-trans` )
			return Promise.resolve(global.data_tran[key])
		} else {
			return axios.request({
				url: `${this.url}/db11-endpoint-trans`,
				method: "POST",
				data: { ...filter, }
			})

				.then(response => {
					// if (loading) App.loading(false, 'Loading...');
					response = response['data'];
					if (!global.data_tran) {
						global.data_tran = {}
					}

					global.data_tran[key] = response;
					return response;
				})

				.catch((error) => {
					if (error.response.status == 401) {
						window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent(window.location.href)}`
						return false;
					}
					console.log(error);
					return false;
				})
		}
	}

	query() {
		if (global.dataQuerry) {
			return Promise.resolve(
				global.dataQuerry
			)
		} else {
			return axios.request({
				url: `${this.url}/querry`,
				method: "POST",
			})

				.then(response => {
					// if (loading) App.loading(false, 'Loading...');
					response = response['data'];
					global.dataQuerry = response
					return response;
				})

				.catch((error) => {
					if (error.response.status == 401) {
						window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent(window.location.href)}`
						return false;
					}

					return false

				})

		}


	}

	suggestion(data) {
		return axios.request({
			url: `${this.url}/db11-endpoint-suggestion`,
			method: "POST",
			data: { ...data }
		})

			.then(response => {
				// if (loading) App.loading(false, 'Loading...');
				response = response['data']['data'];
				return response;
			})

			.catch((error) => {
				if (error.response.status == 401) {
					window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent(window.location.href)}`
					return false;
				}
				console.log(error);
				return false;
			})
	}

	pnl(data){
		let url2 = `${domain_config(2)}/db11-pnl-plot`
		return axios.request({
			url: url2,
			method: "POST",
			data: { ...data }
		})

			.then(response => {
				// if (loading) App.loading(false, 'Loading...');
				response = response['data'];
				return response;
			})

			.catch((error) => {
				
				console.log(error);
				return false;
			})
	}

	ohlc(filter){
		let key = global.genKey(filter)
		if (global.data_ohlc && global.data_ohlc[key]) {
			return Promise.resolve(global.data_ohlc[key])
		} else {
			let url2 = `${domain_config(2)}/db11-ohlc`
			return axios.request({
				url: url2,
				method: "POST",
				data: { ...filter }
			})

				.then(response => {
					// if (loading) App.loading(false, 'Loading...');
					response = response['data'];
					let tran_data = {}

					response.map(item => {
						tran_data[item['day']] = item['close']
					})

					if (!global.data_ohlc) {
						global.data_ohlc = {}
					}

					global.data_ohlc[key] = tran_data;
					return tran_data;
				})

				.catch((error) => {
					
					console.log(error);
					return false;
				})

		}
	}


}

export default Transaction;