import React, { useState , useRef , useLocation , useEffect  } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
const queryString = require('query-string');

export const Login = (props) => {
	const toast = useRef(null);
	useEffect(() => {
		var parsed = global.parsed();
		if(parsed.messgae){
			toast.current.show({severity:'error', summary: 'Error Message', detail: parsed.messgae, life: 3000});
		}
		
	  });

	return (
		<div className="login-body">
			<div className="login-image">
				<img src={`assets/layout/images/pages/login-${props.colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="atlantis" />
			</div>
			<div className="login-panel p-fluid">
				<div className="flex flex-column">
					<div className="flex align-items-center mb-6 logo-container">
						{/* <img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-logo" alt="login-logo"/>
						<img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-appname" alt="login-appname"/> */}
						F5 TRADERS
					</div>
					<form className="form-container" method='POST' action='https://authen.f5traders.com/authen/login/loginUser'>
						<span className="p-input-icon-left">
							<i className="pi pi-user"></i>
							<InputText name="username" type="text" placeholder="User Name" />
						</span>
						<span className="p-input-icon-left">
							<i className="pi pi-key"></i>
							<InputText name="password"  type="password" placeholder="Password" />
						</span>
						{/* <InputText name="nextlink" value={`${window.location.origin}${window.location.pathname}`} style={{display:'none'}}/> */}
						<InputText name="nextlink" value={`${global.parsed()['nexLink']}`} style={{display:'none'}}/>
						<button className="flex p-link">Forgot your password?</button>

						<div className="button-container">
							<Button style={{color : 'black'}} type="submit" label="Login"></Button>
							{/* <span>Don’t have an account?<button className="p-link" >Sign-up here</button></span> */}
						</div>
					</form>
					
				</div>

				<div className="login-footer flex align-items-center">
					<div className="flex align-items-center login-footer-logo-container">
						<img src="assets/layout/images/logo-gray.png" className="login-footer-logo" alt="login-footer-logo" />
						<img src="assets/layout/images/appname-gray.png" className="login-footer-appname" alt="login-footer-appname" />
					</div>
					<span>Copyright 2021</span>
				</div>
			</div>
			<Toast ref={toast} />
		</div>
	)
}