import model from "../model";
import axios from "axios";

import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class OutStanding {

    constructor() {
      
        // this.url = 'https://d11.f5traders.com/api_v2'
        this.url = domain_config(2)
        this.queryInfo = new QueryInfo()
    }




    getData(filter) {    

        let key = global.genKey(filter)
 
        if (global.data_out_standing && global.data_out_standing[key]) {
            this.queryInfo.info(filter, `${this.url}/db11-outstanding`)
            return Promise.resolve(global.data_out_standing[key])
        } else {
      
            return axios.request({
                url: `${this.url}/db11-outstanding`,
                method: "POST",
                data: { ...filter }
            })

                .then(response => {

                    let out = response['data']

                    if (!global.data_out_standing) {
                        global.data_out_standing = {}
                    }

                    global.data_out_standing[key] = out;
                    return out;

                })

                .catch((error) => {

                    console.log(error);
                    return false;
                })

        }

    }








}

export default OutStanding;