import model from "../model";
import axios from "axios";
import moment from 'moment';
import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class TranGroup {

    constructor() {
        // this.url = 'https://d11.f5traders.com/api'
        this.url = domain_config(1)
        this.queryInfo = new QueryInfo()
    }

    arrayBufferToBase64 = (buffer) => {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return binary;
    }

    decode = (res) => {
        // Decode base64 (convert ascii to binary)
        var strData = atob(res);


        // Convert binary string to character-number array
        var charData = strData.split('').map(function (x) {
            return x.charCodeAt(0);
        });
        // Turn number array into byte-array
        var binData = new Uint8Array(charData);

        // Pako magic
        var data = global.pako.inflate(binData);
        data = this.arrayBufferToBase64(new Uint16Array(data))
        return JSON.parse(data);
   }


    getData(mode , filter) {
        if (global.data_groups ) {
            this.queryInfo.info({} , `${this.url}/db11-endpoint-group-trans` )
            return Promise.resolve(global.data_groups['data'])
        } else {
            return axios.request({
                url: `${this.url}/db11-endpoint-group-trans`,
                method: "POST",
            })

                .then(response => {

                    let res = this.decode(response['data'])
                    

                    let out = this.handleData(res, mode)


                  

                    global.data_groups = {
                        'data' : Object.values(out).reverse(),
                        'res' : res
                    };
                    if(filter['data_filter'].length > 0){
                        return this.filterData(filter,mode)
                    }



                    return Object.values(out).reverse();
                })

                .catch((error) => {
                    if (error.response.status == 401) {
                        window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent(window.location.href)}`
                        return false;
                    }

                    return false

                })
        }

    }

    handleData(res, mode) {
        let out = {}
        let col = Object.keys(res)

        let length = res['day'].length


        for (let index = 0; index < length; index++) {
            let dataCol = {}
            col.map(item => {
                if (!dataCol['value']) dataCol['value'] = {}
                dataCol['value'][item] = res[item][index]
                dataCol[item] = res[item][index]



            })

            if (mode == 'Day') {
                if (out[dataCol['day']]) {
                    out[dataCol['day']]['value'].push(dataCol['value'])

                    Object.keys(dataCol['value']).map(col => {
                        if (col == 'buy' || col == 'sell' || col == 'val_buy' || col == 'val_sell' || col == 'val_buy_sell' || col == 'val_group') {

                            out[dataCol['day']][col] += Number(dataCol['value'][col])

                        }
                    })
                } else {
                    out[dataCol['day']] = {
                        ...dataCol, value: [dataCol['value']]
                    }
                }
            } else if (mode == 'Stock') {
                if (out[dataCol['stock']]) {
                    out[dataCol['stock']]['value'].push(dataCol['value'])

                    Object.keys(dataCol['value']).map(col => {
                        if (col == 'buy' || col == 'sell' || col == 'val_buy' || col == 'val_sell' || col == 'val_buy_sell' || col == 'val_group') {

                            out[dataCol['stock']][col] += Number(dataCol['value'][col])

                        }
                    })
                } else {
                    out[dataCol['stock']] = {
                        ...dataCol, value: [dataCol['value']]
                    }
                }
            } else {
                if (out[dataCol['group_ah']]) {
                    out[dataCol['group_ah']]['value'].push(dataCol['value'])

                    Object.keys(dataCol['value']).map(col => {
                        if (col == 'buy' || col == 'sell' || col == 'val_buy' || col == 'val_sell' || col == 'val_buy_sell' || col == 'val_group') {

                            out[dataCol['group_ah']][col] += Number(dataCol['value'][col])

                        }
                    })
                } else {
                    out[dataCol['group_ah']] = {
                        ...dataCol, value: [dataCol['value']]
                    }
                }
            }







        }

        return out
    }

    filterData(filter, mode) {
        this.queryInfo.info(filter , `${this.url}/db11-endpoint-group-trans` )
        let dataTemp = structuredClone(global.data_groups['res'])
        let leng = dataTemp['day'].length
        let buyTemp = [], dayTemp = [], group_ahTemp = [], idTemp = [], nameTemp = [], sellTemp = [], stockTemp = [], val_buyTemp = [], val_sellTemp = [], val_buy_sellTemp = [], val_groupTemp = []
        for (let index = 0; index < leng; index++) {
            // debugger
            let check = true

            for (let y = 0; y < filter['data_filter'].length; y++) {
                let col = filter['data_filter'][y]['col_name']

                let value = filter['data_filter'][y]['value']
                if (col == 'group') {
                    col = 'group_ah'
                }
                if (col == 'day') {
                    if (filter['data_filter'][y]['operator'] == 'from') {
                        let startDay = value[0]
                        let endDay = value[1]
                        let out = []

                        for (let index = Number(moment(startDay).format('X')); index <= Number(moment(endDay).format('X')); index = index + 86400) {
                            out.push(moment(index, 'X').format('YYYY_MM_DD'))

                        }

                        value = out
                    } else {
                        value = value.map(item => item.replaceAll('/', '_'))
                    }
                }

                if (!value.includes(dataTemp[col][index])) {
                    check = false
                    break
                }


            }

            if (check == true) {
                stockTemp.push(dataTemp['stock'][index])
                dayTemp.push(dataTemp['day'][index])
                nameTemp.push(dataTemp['name'][index])
                idTemp.push(dataTemp['id'][index])
                group_ahTemp.push(dataTemp['group_ah'][index])
                buyTemp.push(dataTemp['buy'][index])
                sellTemp.push(dataTemp['sell'][index])
                val_buyTemp.push(dataTemp['val_buy'][index])
                val_sellTemp.push(dataTemp['val_sell'][index])
                val_buy_sellTemp.push(dataTemp['val_buy_sell'][index])
                val_groupTemp.push(dataTemp['val_group'][index])


            }
        }
        dataTemp['buy'] = buyTemp
        dataTemp['day'] = dayTemp
        dataTemp['group_ah'] = group_ahTemp
        dataTemp['id'] = idTemp
        dataTemp['name'] = nameTemp
        dataTemp['sell'] = sellTemp
        dataTemp['stock'] = stockTemp
        dataTemp['val_buy'] = val_buyTemp
        dataTemp['val_sell'] = val_sellTemp
        dataTemp['val_buy_sell'] = val_buy_sellTemp
        dataTemp['val_group'] = val_groupTemp

        let out = this.handleData(dataTemp, mode)

        if (mode == 'Group') {
            let ok = Object.values(out).sort(function (a, b) {
                return b.val_buy_sell - a.val_buy_sell
            })

            return Promise.resolve(ok)
        }

        return Promise.resolve(Object.values(out).reverse())

    }



}

export default TranGroup;