import model from "../model";
import axios from "axios";
import QueryInfo from "./QueryInfo";
import { domain_config } from "../../utilities/Domain";
class OneStock {

    constructor() {
      
        // this.url = 'https://d11.f5traders.com/api_v2'

        this.url = domain_config(2)
  
        this.queryInfo = new QueryInfo()
    }

    getData(filter) {
        let key = global.genKey(filter)
        if (global.data_one_stock && global.data_one_stock[key]) {
            this.queryInfo.info(filter, `${this.url}/db11-one-stock-summary`)
            return Promise.resolve(global.data_one_stock[key])
        } else {

            return axios.request({
                url: `${this.url}/db11-one-stock-summary`,
                method: "POST",
                data: { ...filter }
            })

                .then(response => {

                    response = response['data'];
                    let outBuy = []
                    let outSell = []

                    Object.keys(response).map(mode => {    //mode = buy----selll
                        let col = Object.keys(response[mode])
                        let length = response[mode][col[0]].length


                        for (let index = 0; index < length; index++) {
                            let dataCol = {}
                            col.map(item => {
                                dataCol[item] = response[mode][item][index]
                            })

                            if (mode == 'buy') {

                                outBuy.push(dataCol)


                            } else {

                                outSell.push(dataCol)

                            }

                        }

                    })



                    let out = {
                        buy: outBuy,
                        sell: outSell,
                    }
                    if (!global.data_one_stock) {
                        global.data_one_stock = {}
                    }

                    global.data_one_stock[key] = out;
                    return out;

                })

                .catch((error) => {
                    if (error.response.status == 401) {
                        window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent(window.location.href)}`
                        return false;
                    }
                    console.log(error);
                    return false;
                })


        }



    }

    async getData2(filter) {

        let key = global.genKey(filter)
        if (global.data_one_stock && global.data_one_stock[key]) {
            this.queryInfo.info(filter, `${this.url}/db11-one-stock-summary`)
            return Promise.resolve(global.data_one_stock[key])
        } else {

            try {
                let res = await axios.request({
                    url: `${this.url}/db11-one-stock-summary`,
                    method: "POST",
                    data: { ...filter }
                })

                return this.handleRes(res, key)
            } catch (err) {
                let url2 = `${domain_config(1)}/db11-one-stock-summary`
                let res = await axios.request({
                    url: url2,
                    method: "POST",
                    data: { ...filter }
                })
                return this.handleRes(res, key)

            }

        }

    }

    handleRes(response, key) {
        response = response['data'];
        let outBuy = []
        let outSell = []

        Object.keys(response).map(mode => {    //mode = buy----selll
            let col = Object.keys(response[mode])
            let length = response[mode][col[0]].length


            for (let index = 0; index < length; index++) {
                let dataCol = {}
                col.map(item => {
                    dataCol[item] = response[mode][item][index]
                })

                if (mode == 'buy') {

                    outBuy.push(dataCol)


                } else {

                    outSell.push(dataCol)

                }

            }

        })



        let out = {
            buy: outBuy,
            sell: outSell,
        }

        if (!global.data_one_stock) {
            global.data_one_stock = {}
        }

        global.data_one_stock[key] = out;
        return out;
    }






}

export default OneStock;