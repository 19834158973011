import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import DashboardModel from '../models/admin/Dashboard'

import { klineHardCode } from '../models/klineHardCode';
import ChartTop10 from '../components/ChartDashboard/ChartTop10';
import SunburstChart from '../components/ChartDashboard/SunburstChart';
class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: { name: 'HPG', code: 'HPG' },

    }

    this.optionsCode = [
      { name: 'HDB', code: 'HDB' },
      { name: 'HPG', code: 'HPG' },
      { name: 'MSN', code: 'MSN' },
      { name: 'STB', code: 'STB' },
      { name: 'TCB', code: 'TCB' },
      { name: 'VCI', code: 'VCI' },
      { name: 'VIC', code: 'VIC' },
      { name: 'VPB', code: 'VPB' },
    ];


    this.dashboardModel = new DashboardModel()
  }



  ChangeCode(data) {
    this.setState({
      code: data
    }, () => this.getData());
  }

  kFormatter(num) {
    return Math.abs(num) > 999 ? global.formatNumber(Math.sign(num)*((Math.abs(num)/1000).toFixed(1)))   + ' k' : global.formatNumber(Math.sign(num)*Math.abs(num))
  }


  renderChart(buy, sell, kline, vn30, tickvals, ticktext) {
    var buyData = {
      x: buy.x,
      y: buy.y,
      mode: 'markers',
      type: 'scatter',
      text: buy.text,
      hovertemplate: `<br>%{text}<br>`,
      marker: {
        color: buy.color,
        size: buy.size,
      },
      name: 'buy'
    };
    var sellData = {
      x: sell.x,
      y: sell.y,
      mode: 'markers',
      type: 'scatter',
      text: sell.text,
      hovertemplate: `<br>%{text}<br>`,
      marker: {
        color: sell.color,
        size: sell.size
      },
      name: 'sell'
    };


    var KlineData = {
      name: this.state.code['code'],
      x: kline['x'],
      close: kline['close'],
      high: kline['high'],
      low: kline['low'],
      open: kline['open'],
      text: kline['day'],

      type: 'candlestick',
      xaxis: 'x',
      yaxis: 'y2',
    };

    var vn30Data = {
      name: 'vn30',
      x: vn30['x'],
      y: vn30['close'],
      line: {
        color: '#636efa',
      },
      text : vn30['text'],
      hovertemplate: `<br>%{text}<br>`,
      mode: 'lines',
      xaxis: 'x',
      yaxis: 'y3',
    };



    var data = [buyData, sellData, KlineData, vn30Data];

    var layout = {

      height: 800,
      showlegend: true,
      margin: { b: 20, t: 40, l: 130, r: 0 },
      // autosize: true,
      // hovermode: "x unified",
      // hoverdistance: 10,
      //  barmode: 'group',
      // font: {
      //   size: 12,
      // },
      xaxis: {
        autorange: true,
        type: "category",
        categoryorder: 'category ascending',
        rangeslider: { 'visible': false },
        showspikes: true,
        spikemode: 'across',
        spikesnap: 'cursor',
        spikedash: 'dot',
        // spikecolor: 'black',
        spikethickness: 2,
        tickmode: "array",
        tickvals: tickvals,
        ticktext: ticktext,

        // showgrid: false,
        showticklabels: true,
        tickangle: 'auto',
        showexponent: 'all'
        // gridcolor: 'white',
      },
      yaxis: {
        autorange: true,
        showspikes: true,
        spikemode: 'across',
        spikesnap: 'cursor',
        spikedash: 'dot',
        // spikecolor: 'black',
        spikethickness: 2,
        domain: [0.42, 1]
      },
      shapes: [
        {
          type: 'line',
          xref: 'x',
          yref: 'paper',
          x0: 0,
          y0: 0,
          x1: 0,
          y1: 0,
          line: {
            color: 'black',
            width: 1,
            dash: 'dot'
          }
        }
      ],

      yaxis3: {
        overlaying: 'y2',
        side: 'right',
      },

 
      yaxis2: { domain: [0, 0.4] },



      grid: {
        rows: 2,
        columns: 1,
        subplots: [['xy'], ['xy2']],
        roworder: 'top to bottom',
      }
    };
    global.Plotly.newPlot(this.chart, data, layout , {scrollZoom: true} );
  }


  getData() {
    let filter = {
      'ticker': this.state.code['code']
    }
    let insiderData = klineHardCode[this.state.code['code']]['insider']
    let pricesData = klineHardCode[this.state.code['code']]['prices']
    let vn30Data = klineHardCode[this.state.code['code']]['vn30_index']

    vn30Data['text'] = vn30Data['day'].map((item , index) => `<b>${item}</b>   </br>close = ${vn30Data['close'][index]}`)

    // handle data insiderData tach thanh buy va sell

    let buy = {
      'x': [],
      'y': [],
      'size': [],
      'color': [],
      'text': []
    }
    let sell = {
      'x': [],
      'y': [],
      'size': [],
      'color': [],
      'text': []
    }

    let scale = Math.max(...insiderData['size']) - Math.min(...insiderData['size'])
    let minVloumn = Math.min(...insiderData['size'])

    insiderData['day'].map((d, index) => {
      if (insiderData['side'][index] == 'buy') {
        let size = 15 + ((Number(insiderData['size'][index]) - minVloumn) / scale) * 85
        buy['x'].push(insiderData['x'][index])
        buy['y'].push(insiderData['name'][index])
        // buy['size'].push(insiderData['size'][index] / 1000)
        buy['size'].push(size)
        buy['color'].push('#60bbb6')
        buy['text'].push(`<b>${insiderData['name'][index]}</b>   </br><b>${this.kFormatter(insiderData['volume'][index])} cổ phiếu</b> </br><b>${insiderData['day'][index]}</b> </br></br>side = ${insiderData['side'][index]} </br>x = ${insiderData['x'][index]}   </br>size = ${insiderData['size'][index]}`)
      } else {
        let size = 15 + ((Number(insiderData['size'][index]) - minVloumn) / scale) * 85
        sell['x'].push(insiderData['x'][index])
        sell['y'].push(insiderData['name'][index])
        // sell['size'].push(insiderData['size'][index] / 1000)
        sell['size'].push(size)
        sell['color'].push('#ec8182')
        sell['text'].push(`<b>${insiderData['name'][index]}</b>   </br><b>${this.kFormatter(insiderData['volume'][index])} cổ phiếu</b> </br><b>${insiderData['day'][index]}</b> </br></br>side = ${insiderData['side'][index]} </br>x = ${insiderData['x'][index]}   </br>size = ${insiderData['size'][index]}`)
      }
    })

    let tickvals = []
    let ticktext = []
    insiderData['x'].map((item, index) => {
      if (item % 10 == 0) {
        tickvals.push(item)
        ticktext.push(insiderData['day'][index])
      }
    })

    this.renderChart(buy, sell, pricesData, vn30Data , tickvals,ticktext)

   




    this.dashboardModel.getDataInsider(filter).then(res => {

 
    })
  }

  componentDidMount() {
    this.getData()
  }


  render() {
    return (
      <div>
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'flex-end' }}>
          <Dropdown value={this.state.code} options={this.optionsCode} onChange={(e) => this.ChangeCode(e.value)} optionLabel="name" placeholder="Select a code" />
        </div>
        <div  ref={c => this.chart = c}>

        </div>
        <SunburstChart></SunburstChart>
        <ChartTop10></ChartTop10>
      </div>
    );
  }
}

export default Dashboard;