export const klineHardCode ={
    "HDB": {
      "prices": {
        "t": [
          1644192000000000000,
          1644278400000000000,
          1644364800000000000,
          1644451200000000000,
          1644537600000000000,
          1644796800000000000,
          1644883200000000000,
          1644969600000000000,
          1645056000000000000,
          1645142400000000000,
          1645401600000000000,
          1645488000000000000,
          1645574400000000000,
          1645660800000000000,
          1645747200000000000,
          1646006400000000000,
          1646092800000000000,
          1646179200000000000,
          1646265600000000000,
          1646352000000000000,
          1646611200000000000,
          1646697600000000000,
          1646784000000000000,
          1646870400000000000,
          1646956800000000000,
          1647216000000000000,
          1647302400000000000,
          1647388800000000000,
          1647475200000000000,
          1647561600000000000,
          1647820800000000000,
          1647907200000000000,
          1647993600000000000,
          1648080000000000000,
          1648166400000000000,
          1648425600000000000,
          1648512000000000000,
          1648598400000000000,
          1648684800000000000,
          1648771200000000000,
          1649030400000000000,
          1649116800000000000,
          1649203200000000000,
          1649289600000000000,
          1649376000000000000,
          1649721600000000000,
          1649808000000000000,
          1649894400000000000,
          1649980800000000000,
          1650240000000000000,
          1650326400000000000,
          1650412800000000000,
          1650499200000000000,
          1650585600000000000,
          1650844800000000000,
          1650931200000000000,
          1651017600000000000,
          1651104000000000000,
          1651190400000000000,
          1651622400000000000,
          1651708800000000000,
          1651795200000000000,
          1652054400000000000,
          1652140800000000000,
          1652227200000000000,
          1652313600000000000,
          1652400000000000000,
          1652659200000000000,
          1652745600000000000,
          1652832000000000000,
          1652918400000000000,
          1653004800000000000,
          1653264000000000000,
          1653350400000000000,
          1653436800000000000,
          1653523200000000000,
          1653609600000000000,
          1653868800000000000,
          1653955200000000000,
          1654041600000000000,
          1654128000000000000,
          1654214400000000000,
          1654473600000000000,
          1654560000000000000,
          1654646400000000000,
          1654732800000000000,
          1654819200000000000,
          1655078400000000000,
          1655164800000000000,
          1655251200000000000,
          1655337600000000000,
          1655424000000000000,
          1655683200000000000,
          1655769600000000000,
          1655856000000000000,
          1655942400000000000,
          1656028800000000000,
          1656288000000000000,
          1656374400000000000,
          1656460800000000000,
          1656547200000000000,
          1656633600000000000,
          1656892800000000000,
          1656979200000000000,
          1657065600000000000,
          1657152000000000000,
          1657238400000000000,
          1657497600000000000,
          1657584000000000000,
          1657670400000000000,
          1657756800000000000,
          1657843200000000000,
          1658102400000000000,
          1658188800000000000,
          1658275200000000000,
          1658361600000000000,
          1658448000000000000,
          1658707200000000000,
          1658793600000000000,
          1658880000000000000,
          1658966400000000000,
          1659052800000000000,
          1659312000000000000,
          1659398400000000000,
          1659484800000000000,
          1659571200000000000,
          1659657600000000000,
          1659916800000000000,
          1660003200000000000,
          1660089600000000000,
          1660176000000000000,
          1660262400000000000,
          1660521600000000000,
          1660608000000000000,
          1660694400000000000,
          1660780800000000000,
          1660867200000000000,
          1661126400000000000
        ],
        "close": [
          31.1,
          31.4,
          31.6,
          31.5,
          31.2,
          29.2,
          29.5,
          29.25,
          29.75,
          29.5,
          29.6,
          29.55,
          29.65,
          28.8,
          29.25,
          28.8,
          28.55,
          27.35,
          28.25,
          28.0,
          27.4,
          27.35,
          27.0,
          26.95,
          27.3,
          27.1,
          27.2,
          27.15,
          27.5,
          27.6,
          28.3,
          28.3,
          28.0,
          28.1,
          27.95,
          27.4,
          27.55,
          28.3,
          28.35,
          29.45,
          29.1,
          28.75,
          29.0,
          28.9,
          28.25,
          27.8,
          28.0,
          27.3,
          26.3,
          26.0,
          25.9,
          25.75,
          24.9,
          25.15,
          23.55,
          24.5,
          25.0,
          25.2,
          25.3,
          24.8,
          25.15,
          24.05,
          23.0,
          24.05,
          24.25,
          22.95,
          22.2,
          22.5,
          24.0,
          24.0,
          24.3,
          24.35,
          24.2,
          24.6,
          25.65,
          25.55,
          25.8,
          25.85,
          26.1,
          26.15,
          26.0,
          25.4,
          25.3,
          25.3,
          25.95,
          25.8,
          25.9,
          24.55,
          24.9,
          24.5,
          24.95,
          24.6,
          23.75,
          23.6,
          23.6,
          23.5,
          23.0,
          23.35,
          24.25,
          24.4,
          24.0,
          24.5,
          24.55,
          24.75,
          23.85,
          24.0,
          23.7,
          22.9,
          23.5,
          23.7,
          23.7,
          23.6,
          23.5,
          23.4,
          23.85,
          24.05,
          23.8,
          23.6,
          23.6,
          23.9,
          24.4,
          24.15,
          24.7,
          24.85,
          24.85,
          24.9,
          24.7,
          25.0,
          25.15,
          25.05,
          25.3,
          25.4,
          26.3,
          26.35,
          26.75,
          26.8,
          26.25,
          25.65
        ],
        "open": [
          31.4,
          31.15,
          31.7,
          31.3,
          31.4,
          30.5,
          29.2,
          29.8,
          29.4,
          29.4,
          29.3,
          29.15,
          29.6,
          29.5,
          29.2,
          29.3,
          28.8,
          28.25,
          27.7,
          28.5,
          27.7,
          27.4,
          27.3,
          27.55,
          26.95,
          27.25,
          27.15,
          27.4,
          27.45,
          27.85,
          27.6,
          28.45,
          28.4,
          28.0,
          28.1,
          27.7,
          27.45,
          27.7,
          28.65,
          28.1,
          29.9,
          29.1,
          28.6,
          29.15,
          29.0,
          28.25,
          27.8,
          28.0,
          27.3,
          26.3,
          26.4,
          25.9,
          25.7,
          25.15,
          25.15,
          23.0,
          24.3,
          25.0,
          25.35,
          25.25,
          25.0,
          24.3,
          23.55,
          22.6,
          24.1,
          24.0,
          22.9,
          22.9,
          22.9,
          24.0,
          23.65,
          24.3,
          24.6,
          24.1,
          24.6,
          25.3,
          25.5,
          26.1,
          25.85,
          26.1,
          26.15,
          25.9,
          25.2,
          25.3,
          25.5,
          25.75,
          25.8,
          25.0,
          24.1,
          25.0,
          24.6,
          24.4,
          24.6,
          23.2,
          23.6,
          23.6,
          24.0,
          23.1,
          23.3,
          23.6,
          24.6,
          23.9,
          24.7,
          24.45,
          24.5,
          23.85,
          24.05,
          23.45,
          23.0,
          23.5,
          23.7,
          23.7,
          23.95,
          23.5,
          23.8,
          24.0,
          24.05,
          23.55,
          23.6,
          23.6,
          24.15,
          24.65,
          24.2,
          24.6,
          24.8,
          25.0,
          24.8,
          24.9,
          25.0,
          25.15,
          25.3,
          25.4,
          25.6,
          26.3,
          26.35,
          26.85,
          26.4,
          26.15
        ],
        "high": [
          31.9,
          31.8,
          32.5,
          31.65,
          31.65,
          30.8,
          29.7,
          29.85,
          29.75,
          29.6,
          29.65,
          29.65,
          29.9,
          29.55,
          29.55,
          29.35,
          28.9,
          28.4,
          28.25,
          28.5,
          27.7,
          27.6,
          27.5,
          27.55,
          27.4,
          27.35,
          27.45,
          27.4,
          27.8,
          28.15,
          28.3,
          28.5,
          28.5,
          28.3,
          28.2,
          28.2,
          27.7,
          28.4,
          28.7,
          29.45,
          29.9,
          29.5,
          29.25,
          29.5,
          29.25,
          28.3,
          28.25,
          28.1,
          27.5,
          26.7,
          26.5,
          26.2,
          25.8,
          25.7,
          25.4,
          24.6,
          25.2,
          25.45,
          25.45,
          25.3,
          25.2,
          24.8,
          23.95,
          24.2,
          24.5,
          24.05,
          23.3,
          22.95,
          24.0,
          24.35,
          24.4,
          24.7,
          24.65,
          24.6,
          25.65,
          25.8,
          25.85,
          26.1,
          26.1,
          26.45,
          26.2,
          26.0,
          25.75,
          25.55,
          26.25,
          26.0,
          26.4,
          25.6,
          25.3,
          25.0,
          25.5,
          24.75,
          24.6,
          24.0,
          24.1,
          23.7,
          24.0,
          23.45,
          24.6,
          24.7,
          24.85,
          24.5,
          24.8,
          24.95,
          24.65,
          24.2,
          24.15,
          23.65,
          23.5,
          24.0,
          23.75,
          23.95,
          23.95,
          23.55,
          23.95,
          24.4,
          24.2,
          23.8,
          23.75,
          23.9,
          24.5,
          24.65,
          24.8,
          25.05,
          24.9,
          25.35,
          24.8,
          25.1,
          25.2,
          25.2,
          25.6,
          25.45,
          26.3,
          26.4,
          26.8,
          26.9,
          26.7,
          26.15
        ],
        "low": [
          31.1,
          30.9,
          31.5,
          30.95,
          31.1,
          29.2,
          28.85,
          29.1,
          29.2,
          29.2,
          28.85,
          28.95,
          29.5,
          28.0,
          29.1,
          28.65,
          28.35,
          27.3,
          27.3,
          28.0,
          27.15,
          27.3,
          27.0,
          26.95,
          26.9,
          26.95,
          27.1,
          27.0,
          27.25,
          27.6,
          27.6,
          28.15,
          28.0,
          27.8,
          27.95,
          27.3,
          27.25,
          27.6,
          28.15,
          28.0,
          29.0,
          28.75,
          28.55,
          28.9,
          28.25,
          27.6,
          27.5,
          27.3,
          26.25,
          25.6,
          25.85,
          25.7,
          24.1,
          24.7,
          23.4,
          22.7,
          23.95,
          25.0,
          25.0,
          24.8,
          24.5,
          24.0,
          22.8,
          22.4,
          23.75,
          22.8,
          21.5,
          22.0,
          22.05,
          23.6,
          23.25,
          24.0,
          23.65,
          23.85,
          24.6,
          25.3,
          25.3,
          25.7,
          25.7,
          25.6,
          25.85,
          25.2,
          25.0,
          24.8,
          25.05,
          25.7,
          25.5,
          24.5,
          24.1,
          23.95,
          24.4,
          23.9,
          23.4,
          23.2,
          23.6,
          23.25,
          23.0,
          23.0,
          23.2,
          23.6,
          24.0,
          23.45,
          24.45,
          24.45,
          23.5,
          23.8,
          23.7,
          22.9,
          23.0,
          23.4,
          23.25,
          23.55,
          23.5,
          23.25,
          23.65,
          24.0,
          23.8,
          23.55,
          23.5,
          23.45,
          23.95,
          24.15,
          24.2,
          24.6,
          24.6,
          24.7,
          24.5,
          24.7,
          24.9,
          24.9,
          25.25,
          25.25,
          25.45,
          26.0,
          26.35,
          26.5,
          26.25,
          25.65
        ],
        "volume": [
          5636100,
          5773600,
          7762300,
          5831800,
          4089300,
          15851700,
          5535200,
          4300300,
          3130000,
          3744200,
          6166700,
          4648000,
          4830000,
          14817600,
          4047700,
          4814300,
          6802600,
          11479300,
          4099800,
          3674300,
          6996500,
          4817700,
          4310900,
          4006000,
          2941600,
          2358200,
          2024200,
          3690400,
          3335700,
          2331600,
          3361200,
          3100400,
          2676600,
          2563200,
          2374000,
          3373300,
          1820800,
          5840700,
          2562900,
          7510400,
          5171400,
          2532700,
          4630400,
          6900400,
          2462900,
          3126000,
          3384900,
          3783800,
          5895200,
          3617400,
          3081000,
          3150300,
          4749600,
          3343400,
          4043700,
          4387900,
          2477600,
          2841700,
          2248100,
          2188600,
          1473300,
          2186300,
          4028300,
          3446700,
          1473500,
          2914500,
          4888300,
          2452800,
          2515100,
          2847700,
          3109400,
          2321500,
          2385600,
          2350200,
          3348900,
          1879100,
          1672700,
          1969700,
          2242700,
          2355800,
          1996500,
          1109800,
          1590300,
          1542300,
          2656600,
          865000,
          3244500,
          2229000,
          2729000,
          3231500,
          2201600,
          2041400,
          1923600,
          1663100,
          1570400,
          997000,
          2243200,
          1268100,
          2159200,
          1397100,
          2399900,
          2049800,
          1048500,
          2225700,
          3078000,
          1007500,
          1115300,
          1518800,
          770300,
          1282900,
          1603600,
          1566300,
          787300,
          848900,
          1318300,
          2225400,
          1149300,
          793000,
          823200,
          692700,
          2767900,
          1777600,
          2445900,
          2238100,
          1828300,
          2244800,
          1668400,
          2331400,
          3084200,
          1973800,
          4795700,
          1486600,
          4823100,
          2330800,
          4696900,
          5013300,
          3826300,
          911000
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "stock": [
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ]
      },
      "insider": {
        "id": [
          "011C017759",
          "011C091488",
          "069C010991",
          "011C017759",
          "011C082027",
          "011C091488",
          "044C108555",
          "069C010991",
          "011C091488"
        ],
        "day": [
          "2022_02_24",
          "2022_02_24",
          "2022_02_24",
          "2022_03_07",
          "2022_03_07",
          "2022_03_07",
          "2022_03_07",
          "2022_03_07",
          "2022_03_21"
        ],
        "stock": [
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB",
          "HDB"
        ],
        "side": [
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell"
        ],
        "TYPES": [
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider"
        ],
        "TradeValue": [
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN
        ],
        "volume": [
          600000,
          350000,
          607800,
          100000,
          100000,
          200000,
          170000,
          150000,
          28800
        ],
        "name": [
          "H\u00c0 \u0110\u1ee8C H\u00d9NG",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "Th\u00e1i Qu\u1ed1c Minh",
          "H\u00c0 \u0110\u1ee8C H\u00d9NG",
          "NGUY\u1ec4N TH\u1eca NG\u1eccC LI\u00caN",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "Nguy\u1ec5n Th\u1ecb Minh Ng\u1ecdc",
          "Th\u00e1i Qu\u1ed1c Minh",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N"
        ],
        "Value": [
          17.3,
          10.25,
          17.35,
          2.73,
          2.74,
          5.46,
          4.67,
          4.1,
          0.82
        ],
        "i": [
          5473,
          5474,
          5472,
          5442,
          5441,
          5438,
          5439,
          5440,
          5415
        ],
        "x": [
          247,
          247,
          247,
          254,
          254,
          254,
          254,
          254,
          264
        ],
        "size": [
          11884,
          8400,
          11984,
          3962,
          3962,
          5937,
          5384,
          5000,
          2123
        ],
        "hover_name": [
          "H\u00c0 \u0110\u1ee8C H\u00d9NG<br>600.0 k c\u1ed5 phi\u1ebfu<br>2022_02_24",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>350.0 k c\u1ed5 phi\u1ebfu<br>2022_02_24",
          "Th\u00e1i Qu\u1ed1c Minh<br>607.8 k c\u1ed5 phi\u1ebfu<br>2022_02_24",
          "H\u00c0 \u0110\u1ee8C H\u00d9NG<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_03_07",
          "NGUY\u1ec4N TH\u1eca NG\u1eccC LI\u00caN<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_03_07",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_03_07",
          "Nguy\u1ec5n Th\u1ecb Minh Ng\u1ecdc<br>170.0 k c\u1ed5 phi\u1ebfu<br>2022_03_07",
          "Th\u00e1i Qu\u1ed1c Minh<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_03_07",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>28.8 k c\u1ed5 phi\u1ebfu<br>2022_03_21"
        ]
      },
      "vn30_index": {
        "close": [
          1541.25,
          1550.46,
          1552.44,
          1548.95,
          1545.92,
          1505.07,
          1527.36,
          1521.47,
          1540.51,
          1531.47,
          1533.4,
          1532.36,
          1538.83,
          1522.04,
          1526.5,
          1517.18,
          1520.12,
          1498.61,
          1522.49,
          1525.34,
          1509.12,
          1490.17,
          1489.25,
          1490.24,
          1477.14,
          1461.1,
          1468.89,
          1472.69,
          1469.92,
          1476.94,
          1502.67,
          1513.4,
          1505.59,
          1497.44,
          1498.36,
          1484.16,
          1500.57,
          1500.23,
          1508.53,
          1542.47,
          1548.04,
          1539.2,
          1557.11,
          1541.96,
          1524.31,
          1507.2,
          1525.39,
          1518.01,
          1493.74,
          1468.25,
          1440.61,
          1435.5,
          1426.87,
          1444.32,
          1366.39,
          1396.9,
          1402.03,
          1400.88,
          1417.31,
          1389.59,
          1404.88,
          1373.21,
          1314.04,
          1345.46,
          1349.82,
          1279.76,
          1223.76,
          1215.08,
          1279.55,
          1286.41,
          1283.55,
          1282.51,
          1255.35,
          1272.71,
          1310.7,
          1309.5,
          1335.68,
          1342.87,
          1332.59,
          1335.49,
          1325.49,
          1327.4,
          1327.04,
          1324.37,
          1342.03,
          1342.92,
          1325.69,
          1260.85,
          1261.16,
          1253.09,
          1280.37,
          1258.03,
          1225.56,
          1224.54,
          1227.18,
          1240.58,
          1235.47,
          1256.67,
          1273.41,
          1273.4,
          1248.92,
          1252.24,
          1248.37,
          1242.05,
          1211.94,
          1229.23,
          1231.54,
          1209.02,
          1219.44,
          1216.94,
          1221.94,
          1220.14,
          1214.28,
          1211.7,
          1225.62,
          1235.25,
          1228.84,
          1222.6,
          1218.49,
          1219.43,
          1236.14,
          1232.0,
          1256.25,
          1265.97,
          1269.83,
          1277.73,
          1272.06,
          1276.7,
          1281.44,
          1277.15,
          1272.33,
          1280.96,
          1293.79,
          1295.1,
          1300.4,
          1299.93,
          1294.93,
          1290.87
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ],
        "color": [
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index"
        ]
      }
    },
    "HPG": {
      "prices": {
        "t": [
          1644192000000000000,
          1644278400000000000,
          1644364800000000000,
          1644451200000000000,
          1644537600000000000,
          1644796800000000000,
          1644883200000000000,
          1644969600000000000,
          1645056000000000000,
          1645142400000000000,
          1645401600000000000,
          1645488000000000000,
          1645574400000000000,
          1645660800000000000,
          1645747200000000000,
          1646006400000000000,
          1646092800000000000,
          1646179200000000000,
          1646265600000000000,
          1646352000000000000,
          1646611200000000000,
          1646697600000000000,
          1646784000000000000,
          1646870400000000000,
          1646956800000000000,
          1647216000000000000,
          1647302400000000000,
          1647388800000000000,
          1647475200000000000,
          1647561600000000000,
          1647820800000000000,
          1647907200000000000,
          1647993600000000000,
          1648080000000000000,
          1648166400000000000,
          1648425600000000000,
          1648512000000000000,
          1648598400000000000,
          1648684800000000000,
          1648771200000000000,
          1649030400000000000,
          1649116800000000000,
          1649203200000000000,
          1649289600000000000,
          1649376000000000000,
          1649721600000000000,
          1649808000000000000,
          1649894400000000000,
          1649980800000000000,
          1650240000000000000,
          1650326400000000000,
          1650412800000000000,
          1650499200000000000,
          1650585600000000000,
          1650844800000000000,
          1650931200000000000,
          1651017600000000000,
          1651104000000000000,
          1651190400000000000,
          1651622400000000000,
          1651708800000000000,
          1651795200000000000,
          1652054400000000000,
          1652140800000000000,
          1652227200000000000,
          1652313600000000000,
          1652400000000000000,
          1652659200000000000,
          1652745600000000000,
          1652832000000000000,
          1652918400000000000,
          1653004800000000000,
          1653264000000000000,
          1653350400000000000,
          1653436800000000000,
          1653523200000000000,
          1653609600000000000,
          1653868800000000000,
          1653955200000000000,
          1654041600000000000,
          1654128000000000000,
          1654214400000000000,
          1654473600000000000,
          1654560000000000000,
          1654646400000000000,
          1654732800000000000,
          1654819200000000000,
          1655078400000000000,
          1655164800000000000,
          1655251200000000000,
          1655337600000000000,
          1655424000000000000,
          1655683200000000000,
          1655769600000000000,
          1655856000000000000,
          1655942400000000000,
          1656028800000000000,
          1656288000000000000,
          1656374400000000000,
          1656460800000000000,
          1656547200000000000,
          1656633600000000000,
          1656892800000000000,
          1656979200000000000,
          1657065600000000000,
          1657152000000000000,
          1657238400000000000,
          1657497600000000000,
          1657584000000000000,
          1657670400000000000,
          1657756800000000000,
          1657843200000000000,
          1658102400000000000,
          1658188800000000000,
          1658275200000000000,
          1658361600000000000,
          1658448000000000000,
          1658707200000000000,
          1658793600000000000,
          1658880000000000000,
          1658966400000000000,
          1659052800000000000,
          1659312000000000000,
          1659398400000000000,
          1659484800000000000,
          1659571200000000000,
          1659657600000000000,
          1659916800000000000,
          1660003200000000000,
          1660089600000000000,
          1660176000000000000,
          1660262400000000000,
          1660521600000000000,
          1660608000000000000,
          1660694400000000000,
          1660780800000000000,
          1660867200000000000,
          1661126400000000000
        ],
        "close": [
          32.58,
          34.472,
          35.796,
          35.569,
          35.683,
          34.964,
          34.812,
          34.812,
          35.191,
          35.607,
          35.304,
          35.115,
          35.342,
          34.812,
          34.737,
          35.72,
          35.493,
          35.493,
          37.915,
          37.688,
          38.672,
          37.423,
          37.726,
          37.196,
          36.023,
          34.661,
          34.926,
          34.964,
          34.774,
          35.191,
          35.456,
          35.493,
          35.418,
          35.115,
          35.115,
          34.737,
          34.699,
          34.434,
          34.131,
          34.585,
          34.699,
          34.888,
          35.758,
          35.229,
          35.115,
          34.131,
          34.207,
          33.98,
          33.45,
          32.277,
          32.769,
          32.693,
          32.807,
          33.147,
          30.839,
          31.18,
          32.164,
          32.618,
          32.769,
          31.785,
          31.823,
          31.634,
          30.234,
          31.104,
          30.763,
          28.947,
          27.169,
          27.471,
          28.909,
          28.909,
          28.682,
          28.493,
          27.812,
          26.412,
          26.071,
          26.488,
          26.828,
          26.828,
          26.26,
          25.996,
          25.087,
          25.277,
          25.201,
          25.125,
          24.898,
          25.579,
          25.428,
          24.066,
          22.931,
          22.249,
          23.46,
          23.2,
          21.6,
          20.8,
          21.5,
          21.8,
          21.8,
          22.9,
          22.5,
          22.75,
          22.3,
          22.4,
          22.0,
          21.9,
          21.45,
          21.75,
          22.45,
          22.05,
          22.3,
          22.3,
          22.2,
          23.2,
          22.6,
          22.15,
          22.35,
          22.7,
          22.2,
          21.9,
          21.65,
          21.25,
          21.4,
          21.5,
          22.8,
          22.9,
          23.75,
          23.65,
          23.3,
          23.6,
          24.1,
          23.8,
          23.4,
          23.95,
          24.0,
          24.55,
          24.3,
          24.15,
          23.75,
          23.55
        ],
        "open": [
          32.391,
          32.542,
          34.661,
          35.872,
          35.266,
          35.191,
          34.964,
          34.888,
          34.812,
          34.964,
          35.645,
          35.039,
          35.304,
          35.38,
          34.888,
          34.85,
          36.099,
          35.569,
          36.023,
          38.596,
          37.991,
          38.294,
          37.84,
          37.84,
          37.234,
          35.796,
          34.774,
          35.493,
          35.266,
          34.964,
          35.342,
          35.493,
          35.72,
          35.569,
          35.229,
          34.888,
          34.964,
          34.661,
          34.547,
          34.056,
          34.812,
          34.812,
          34.812,
          35.948,
          35.38,
          35.191,
          34.283,
          34.283,
          33.98,
          33.337,
          32.542,
          32.92,
          32.542,
          33.299,
          33.147,
          30.385,
          31.18,
          32.164,
          32.466,
          32.769,
          32.239,
          31.331,
          31.407,
          29.893,
          31.028,
          30.726,
          28.871,
          28.039,
          27.396,
          29.061,
          28.228,
          28.531,
          28.72,
          27.812,
          26.109,
          26.488,
          26.488,
          26.866,
          26.752,
          26.109,
          25.731,
          25.201,
          25.844,
          25.201,
          25.201,
          24.898,
          25.579,
          24.974,
          23.839,
          22.779,
          22.817,
          23.45,
          22.75,
          21.35,
          20.85,
          21.5,
          21.85,
          22.0,
          22.85,
          22.3,
          22.9,
          22.3,
          22.65,
          22.1,
          21.7,
          21.45,
          22.0,
          22.4,
          22.05,
          22.2,
          22.2,
          22.35,
          23.2,
          22.5,
          22.35,
          22.35,
          22.7,
          22.2,
          21.8,
          21.45,
          21.5,
          21.6,
          21.7,
          22.9,
          22.7,
          23.9,
          23.4,
          23.4,
          23.9,
          24.0,
          24.05,
          23.3,
          24.1,
          24.0,
          24.6,
          24.1,
          24.1,
          23.65
        ],
        "high": [
          33.072,
          34.472,
          36.553,
          36.25,
          35.948,
          35.948,
          35.229,
          35.191,
          35.38,
          36.061,
          35.948,
          35.266,
          35.72,
          35.456,
          35.077,
          36.175,
          36.402,
          35.834,
          37.915,
          38.823,
          39.732,
          38.748,
          38.067,
          37.915,
          37.31,
          36.477,
          35.342,
          35.683,
          35.342,
          35.456,
          35.72,
          35.531,
          35.91,
          35.569,
          35.531,
          35.456,
          35.002,
          35.115,
          34.774,
          34.812,
          35.039,
          35.115,
          36.288,
          36.023,
          35.607,
          35.191,
          34.396,
          34.472,
          34.056,
          33.715,
          33.223,
          33.299,
          33.374,
          33.337,
          33.147,
          31.331,
          32.466,
          32.882,
          32.845,
          32.769,
          32.239,
          32.542,
          31.709,
          31.104,
          31.255,
          30.763,
          29.174,
          28.38,
          28.909,
          29.288,
          28.834,
          28.607,
          28.909,
          28.001,
          26.45,
          26.942,
          27.169,
          27.131,
          26.752,
          26.336,
          25.958,
          25.731,
          25.92,
          25.39,
          25.314,
          26.147,
          25.92,
          25.352,
          23.877,
          23.385,
          23.725,
          23.5,
          23.0,
          21.5,
          22.0,
          21.85,
          22.5,
          22.9,
          22.85,
          23.15,
          23.4,
          22.6,
          22.65,
          22.35,
          22.25,
          21.75,
          22.55,
          22.65,
          22.5,
          22.8,
          22.4,
          23.5,
          23.3,
          22.6,
          22.75,
          23.0,
          22.8,
          22.3,
          22.0,
          21.5,
          21.7,
          21.95,
          22.85,
          23.15,
          23.85,
          23.95,
          23.65,
          23.9,
          24.3,
          24.4,
          24.15,
          24.05,
          24.2,
          24.7,
          24.65,
          24.35,
          24.25,
          23.75
        ],
        "low": [
          31.634,
          32.542,
          34.585,
          35.342,
          35.039,
          34.926,
          34.358,
          34.51,
          34.812,
          34.85,
          35.229,
          34.585,
          35.002,
          34.131,
          34.737,
          34.85,
          35.456,
          35.266,
          35.796,
          37.688,
          37.84,
          37.423,
          36.969,
          37.158,
          35.456,
          34.661,
          34.396,
          34.964,
          34.737,
          34.85,
          35.115,
          35.039,
          35.418,
          35.077,
          34.964,
          34.661,
          34.661,
          34.358,
          33.904,
          33.677,
          34.623,
          34.774,
          34.812,
          35.229,
          35.077,
          34.093,
          33.828,
          33.98,
          33.412,
          32.277,
          32.466,
          32.618,
          31.861,
          32.05,
          30.839,
          29.969,
          31.104,
          32.164,
          32.428,
          31.785,
          31.407,
          31.331,
          30.234,
          29.288,
          30.688,
          28.947,
          27.055,
          27.471,
          27.244,
          28.644,
          27.888,
          28.19,
          27.471,
          25.882,
          25.012,
          26.147,
          26.336,
          26.715,
          26.109,
          25.579,
          25.012,
          25.163,
          25.05,
          24.709,
          24.785,
          24.785,
          25.428,
          23.99,
          22.855,
          21.833,
          22.628,
          21.95,
          21.6,
          20.5,
          20.85,
          21.5,
          21.7,
          21.35,
          22.25,
          22.1,
          22.3,
          21.55,
          22.0,
          21.75,
          21.3,
          21.4,
          22.0,
          21.95,
          21.95,
          22.15,
          22.1,
          22.35,
          22.5,
          22.1,
          22.3,
          22.3,
          22.2,
          21.8,
          21.6,
          21.05,
          21.25,
          21.5,
          21.6,
          22.7,
          22.6,
          23.5,
          23.15,
          23.3,
          23.65,
          23.8,
          23.3,
          23.25,
          23.8,
          24.0,
          24.15,
          24.05,
          23.65,
          23.45
        ],
        "volume": [
          16609600,
          26528800,
          40297200,
          23598200,
          17343300,
          18246700,
          15564400,
          9350300,
          11353300,
          21375700,
          12177300,
          17669600,
          12981100,
          22445800,
          12775700,
          35200100,
          23374000,
          19896100,
          76236800,
          33378900,
          38456100,
          40509400,
          34775200,
          26828300,
          44340200,
          33845300,
          20864200,
          11418800,
          14957700,
          17727600,
          19313200,
          22965400,
          17291200,
          14927500,
          13444600,
          19505200,
          12986000,
          15726700,
          18892300,
          19701900,
          12156300,
          12124200,
          29707400,
          14512900,
          13738800,
          18233500,
          12712600,
          12038800,
          18378500,
          23186000,
          19054800,
          13236800,
          17812100,
          12698000,
          29055100,
          23280100,
          18780400,
          13611800,
          12347800,
          14901300,
          13817300,
          15010600,
          26283600,
          19440000,
          9559800,
          25252600,
          42997700,
          29389700,
          23415100,
          16073200,
          16040000,
          13929400,
          10178900,
          42201300,
          39418000,
          16225800,
          17591900,
          15054500,
          18840200,
          21644600,
          25380600,
          14709900,
          17769400,
          19376400,
          24841800,
          26979900,
          15182300,
          21236000,
          28438400,
          28702400,
          33385900,
          30237200,
          37272900,
          39935100,
          21442500,
          6926200,
          12828800,
          20739600,
          17838300,
          20190300,
          19950600,
          15200000,
          12443200,
          15093400,
          18233600,
          10828000,
          19504900,
          12476400,
          12146500,
          17855700,
          12520300,
          47697200,
          19929000,
          16276900,
          16893100,
          23043400,
          16642200,
          20722100,
          26378200,
          28601000,
          23510500,
          20587600,
          38143900,
          27729900,
          58846000,
          30031900,
          42516600,
          30365700,
          48222600,
          30124400,
          34352700,
          33797300,
          31513400,
          65490300,
          27443900,
          23215100,
          31457200,
          10064700
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "stock": [
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ]
      },
      "insider": {
        "id": [
          "006C063520",
          "006C027817",
          "001C268686",
          "001C268686",
          "006C063520",
          "001C268686",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "001C268686",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "001C268686",
          "003C123898",
          "006C063520",
          "006C063520",
          "003C123898",
          "001C268686",
          "003C123898",
          "006C063520",
          "003C060262",
          "003C060262",
          "006C063520",
          "001C268686",
          "006C063520",
          "006C086007",
          "006C086007",
          "006C086007",
          "006C086007",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "006C063520",
          "001C268686",
          "001C268686",
          "003C060262",
          "006C063520",
          "091C530699",
          "006C086007",
          "006C086007",
          "006C063520",
          "001C268686",
          "003C123888",
          "006C086007",
          "006C063520",
          "003C123888",
          "006C063520",
          "003C123888",
          "006C027817",
          "006C027817",
          "006C063520",
          "006C027817",
          "006C063520",
          "006C086007",
          "003C123888",
          "006C086007",
          "006C063520",
          "006C063520",
          "003C123888",
          "006C063520",
          "006C027817",
          "006C063520",
          "006C063520",
          "006C027817",
          "006C027817",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "001C268686",
          "006C063520",
          "003C060262",
          "003C123888",
          "091C530699",
          "006C063520",
          "003C060262",
          "003C107929",
          "003C107929",
          "006C050188",
          "003C060262",
          "009C258279",
          "006C086007",
          "006C063520",
          "001C268686",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "006C063520",
          "006C063520",
          "001C268686",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "006C063520",
          "006C063520",
          "006C063520",
          "001C268686",
          "001C268686",
          "001C268686",
          "001C268686",
          "001C268686",
          "006C050188",
          "001C268686",
          "001C268686",
          "006C063520",
          "001C268686",
          "091C530699",
          "091C530699",
          "001C268686",
          "006C086007",
          "001C268686",
          "006C063520",
          "003C123888",
          "091C530699",
          "003C123888",
          "001C268686",
          "006C063520"
        ],
        "day": [
          "2022_02_07",
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_14",
          "2022_02_15",
          "2022_02_15",
          "2022_02_18",
          "2022_02_22",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_02",
          "2022_03_03",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_07",
          "2022_03_08",
          "2022_03_08",
          "2022_03_09",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_11",
          "2022_03_16",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_25",
          "2022_03_28",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_01",
          "2022_04_04",
          "2022_04_06",
          "2022_04_08",
          "2022_04_12",
          "2022_04_15",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_19",
          "2022_04_19",
          "2022_05_12",
          "2022_05_12",
          "2022_05_12",
          "2022_05_12",
          "2022_05_12",
          "2022_05_13",
          "2022_05_13",
          "2022_05_16",
          "2022_05_16",
          "2022_05_17",
          "2022_05_17",
          "2022_05_17",
          "2022_05_18",
          "2022_05_18",
          "2022_05_19",
          "2022_05_19",
          "2022_05_19",
          "2022_05_20",
          "2022_05_20",
          "2022_05_23",
          "2022_05_23",
          "2022_05_23",
          "2022_05_24",
          "2022_05_24",
          "2022_05_25",
          "2022_05_25",
          "2022_05_25",
          "2022_05_26",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_06",
          "2022_06_06",
          "2022_06_07",
          "2022_06_09",
          "2022_06_13",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_22",
          "2022_06_24",
          "2022_06_27",
          "2022_06_27",
          "2022_06_28",
          "2022_06_30",
          "2022_07_01",
          "2022_07_05",
          "2022_07_06",
          "2022_07_06",
          "2022_07_06",
          "2022_07_06",
          "2022_07_06",
          "2022_07_07",
          "2022_07_07",
          "2022_07_07",
          "2022_07_07",
          "2022_07_07",
          "2022_07_08",
          "2022_07_08",
          "2022_07_13",
          "2022_07_18",
          "2022_07_19",
          "2022_07_19",
          "2022_07_21",
          "2022_07_22",
          "2022_07_22",
          "2022_07_25",
          "2022_07_27",
          "2022_07_27",
          "2022_07_28",
          "2022_07_28",
          "2022_07_29",
          "2022_07_29",
          "2022_07_29",
          "2022_08_01",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_08",
          "2022_08_09",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_15",
          "2022_08_16",
          "2022_08_16",
          "2022_08_16",
          "2022_08_16",
          "2022_08_16",
          "2022_08_17",
          "2022_08_19",
          "2022_08_19"
        ],
        "stock": [
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG",
          "HPG"
        ],
        "side": [
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy"
        ],
        "TYPES": [
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider"
        ],
        "TradeValue": [
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          2.2,
          -0.1,
          2.1,
          -5.4,
          -3.5,
          -3.0,
          0.0,
          -3.5,
          -0.2,
          -0.4,
          2.2,
          2.9,
          3.1,
          8.4,
          2.1,
          61.3,
          0.9,
          0.5,
          0.1,
          6.5,
          2.2,
          14.7,
          -2.2,
          -0.2,
          -1.1,
          1.1,
          1.1,
          -1.1,
          -1.1,
          0.2,
          1.1,
          2.2,
          -0.4,
          -1.1,
          2.1,
          -2.1,
          -1.3,
          -2.2,
          2.2,
          -2.3,
          -0.1,
          -0.1,
          0.0,
          0.0,
          -0.5,
          0.2,
          -0.7,
          -0.7,
          1.2,
          0.2,
          -4.8,
          -62.9,
          0.0,
          -148.9,
          -0.4,
          -1.2,
          -60.5,
          -147.3,
          -13.9,
          0.2,
          1.2
        ],
        "volume": [
          50000,
          30000,
          20000,
          10000,
          103200,
          10000,
          125100,
          50000,
          35500,
          25000,
          150000,
          150000,
          200000,
          60000,
          21000,
          300000,
          50000,
          350000,
          50000,
          100000,
          20000,
          30000,
          450000,
          100000,
          350000,
          200000,
          30000,
          150000,
          100000,
          5580000,
          2470000,
          450000,
          30000,
          50000,
          876400,
          4373600,
          5050000,
          2650000,
          50000,
          100000,
          53700,
          146300,
          100000,
          10000,
          150000,
          300000,
          50000,
          100000,
          30000,
          48000,
          60000,
          20000,
          6296700,
          24900,
          3722000,
          739300,
          39300,
          230000,
          100000,
          8258400,
          6750000,
          50000,
          12131500,
          250000,
          3460100,
          60000,
          130000,
          50000,
          60000,
          150000,
          3535700,
          4000000,
          1964300,
          3800,
          200000,
          500000,
          303800,
          100000,
          200000,
          300000,
          80000,
          120000,
          200000,
          100000,
          50000,
          50000,
          200000,
          250000,
          50000,
          50000,
          100000,
          150000,
          100000,
          100000,
          100000,
          100000,
          102400,
          2400,
          100000,
          250000,
          162700,
          137300,
          1200,
          151200,
          10000,
          20000,
          100000,
          132700,
          145000,
          390000,
          100000,
          2857300,
          40000,
          22000,
          5000,
          300000,
          100000,
          675000,
          100000,
          10000,
          50000,
          50000,
          50000,
          50000,
          50000,
          10000,
          50000,
          100000,
          20000,
          50000,
          100000,
          100000,
          60000,
          100000,
          100000,
          100000,
          5000,
          5000,
          2000,
          2000,
          20000,
          10000,
          30000,
          30000,
          50000,
          10000,
          200000,
          2619100,
          2000,
          6075000,
          16200,
          50000,
          2463600,
          6090900,
          566400,
          10000,
          50000
        ],
        "name": [
          "NGUY\u1ec4N NAM TH\u00c1I",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "Nguy\u1ec5n V\u0103n Ki\u1ec3u",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "Nguy\u1ec5n V\u0103n Ki\u1ec3u",
          "T\u1ea1 Tu\u1ea5n Quang",
          "Nguy\u1ec5n V\u0103n Ki\u1ec3u",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "V\u0169 \u0110\u1ee9c S\u00ednh",
          "V\u0169 \u0110\u1ee9c S\u00ednh",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "V\u0169 \u0110\u1ee9c S\u00ednh",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "Tr\u1ea7n Tuy\u1ebft Hoa",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "V\u0169 \u0110\u1ee9c S\u00ednh",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng",
          "Tr\u1ea7n Tuy\u1ebft Hoa",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "V\u0169 \u0110\u1ee9c S\u00ednh",
          "Nguy\u1ec5n Nam Th\u00e1i",
          "Nguy\u1ec5n Nam Th\u00e1i",
          "V\u01af\u01a0NG NG\u1eccC LINH",
          "V\u0169 \u0110\u1ee9c S\u00ednh",
          "V\u0169 \u0110\u1ee9c S\u00ednh",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "V\u01af\u01a0NG NG\u1eccC LINH",
          "T\u1ea1 Tu\u1ea5n Quang",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "Tr\u1ea7n Tuy\u1ebft Hoa",
          "Tr\u1ea7n Tuy\u1ebft Hoa",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng",
          "Tr\u1ea7n Tuy\u1ebft Hoa",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng",
          "T\u1ea1 Tu\u1ea5n Quang",
          "NGUY\u1ec4N NAM TH\u00c1I"
        ],
        "Value": [
          2.08,
          1.24,
          0.89,
          0.47,
          4.91,
          0.48,
          5.74,
          2.33,
          1.66,
          1.15,
          7.03,
          6.86,
          9.12,
          2.76,
          0.98,
          14.13,
          2.31,
          16.31,
          2.31,
          4.67,
          0.94,
          1.47,
          22.18,
          5.2,
          18.23,
          10.16,
          1.52,
          7.52,
          4.89,
          281.32,
          123.34,
          21.61,
          1.46,
          2.35,
          40.93,
          205.25,
          236.21,
          125.11,
          2.28,
          4.54,
          2.44,
          6.59,
          4.47,
          0.45,
          6.91,
          14.15,
          2.32,
          4.64,
          1.34,
          2.2,
          2.64,
          0.87,
          272.89,
          1.09,
          147.94,
          28.82,
          1.54,
          9.11,
          3.96,
          302.75,
          257.86,
          1.81,
          449.67,
          9.41,
          128.01,
          2.18,
          4.98,
          1.91,
          2.24,
          5.57,
          132.26,
          150.53,
          73.95,
          0.15,
          7.32,
          18.85,
          10.79,
          3.47,
          6.89,
          10.05,
          2.71,
          4.22,
          7.05,
          3.58,
          1.79,
          1.74,
          6.84,
          8.29,
          1.66,
          1.66,
          3.28,
          5.07,
          3.18,
          2.94,
          3.1,
          2.32,
          2.21,
          0.05,
          2.08,
          5.38,
          3.5,
          2.99,
          0.03,
          3.46,
          0.23,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN
        ],
        "i": [
          2366,
          2367,
          2364,
          2361,
          2360,
          2358,
          2357,
          2356,
          2355,
          2354,
          2352,
          2349,
          2348,
          2347,
          2346,
          2340,
          2331,
          2324,
          5460,
          5456,
          5453,
          5451,
          5448,
          5444,
          5436,
          5437,
          5434,
          5433,
          5431,
          5429,
          5426,
          5423,
          5425,
          5418,
          5416,
          5414,
          5413,
          5412,
          5411,
          5409,
          5406,
          5955,
          5403,
          5404,
          5321,
          5319,
          5317,
          5310,
          5307,
          5306,
          5303,
          5954,
          5953,
          5952,
          5940,
          5942,
          5938,
          5944,
          5936,
          5927,
          5928,
          5923,
          5919,
          5907,
          5912,
          5916,
          5899,
          5901,
          5892,
          5891,
          5887,
          5876,
          5877,
          5862,
          5869,
          5865,
          5855,
          5856,
          5841,
          5846,
          5847,
          5154,
          5150,
          5148,
          5145,
          5142,
          5133,
          5127,
          5116,
          5111,
          5104,
          5078,
          5826,
          5808,
          5789,
          5782,
          5771,
          5760,
          5743,
          5722,
          5723,
          5706,
          3946,
          3938,
          3923,
          2871,
          2867,
          4558,
          4539,
          4538,
          4540,
          4534,
          4541,
          4528,
          4529,
          4525,
          4527,
          4524,
          4517,
          4518,
          5577,
          5561,
          5545,
          5541,
          5518,
          5511,
          5510,
          5503,
          4246,
          4245,
          740,
          735,
          686,
          685,
          689,
          3306,
          3308,
          3302,
          3292,
          3282,
          5701,
          5694,
          5690,
          5680,
          5671,
          5672,
          5651,
          5641,
          5645,
          5636,
          5640,
          5639,
          5638,
          5637,
          5630,
          5620,
          5619
        ],
        "x": [
          234,
          234,
          235,
          236,
          237,
          237,
          238,
          239,
          239,
          240,
          240,
          243,
          245,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          251,
          252,
          252,
          253,
          254,
          254,
          255,
          255,
          256,
          256,
          257,
          258,
          258,
          261,
          263,
          264,
          265,
          266,
          268,
          269,
          271,
          272,
          273,
          273,
          274,
          276,
          278,
          279,
          282,
          282,
          283,
          284,
          284,
          284,
          299,
          299,
          299,
          299,
          299,
          300,
          300,
          301,
          301,
          302,
          302,
          302,
          303,
          303,
          304,
          304,
          304,
          305,
          305,
          306,
          306,
          306,
          307,
          307,
          308,
          308,
          308,
          309,
          309,
          310,
          311,
          312,
          313,
          314,
          316,
          316,
          317,
          319,
          321,
          323,
          324,
          325,
          326,
          326,
          327,
          328,
          328,
          330,
          331,
          331,
          332,
          334,
          335,
          337,
          338,
          338,
          338,
          338,
          338,
          339,
          339,
          339,
          339,
          339,
          340,
          340,
          343,
          346,
          347,
          347,
          349,
          350,
          350,
          351,
          353,
          353,
          354,
          354,
          355,
          355,
          355,
          356,
          356,
          357,
          358,
          359,
          361,
          362,
          362,
          363,
          364,
          364,
          365,
          366,
          366,
          367,
          367,
          367,
          367,
          367,
          368,
          370,
          370
        ],
        "size": [
          2746,
          2161,
          1824,
          1430,
          4032,
          1430,
          4498,
          2746,
          2331,
          1998,
          5000,
          5000,
          5937,
          3011,
          1860,
          7623,
          2746,
          8400,
          2746,
          3962,
          1824,
          2161,
          9862,
          3962,
          8400,
          5937,
          2161,
          5000,
          3962,
          53601,
          30646,
          9862,
          2161,
          2746,
          15250,
          45323,
          50038,
          32152,
          2746,
          3962,
          2846,
          4927,
          3962,
          1430,
          5000,
          7623,
          2746,
          3962,
          2161,
          2691,
          3011,
          1824,
          58261,
          1994,
          40568,
          13628,
          2444,
          6465,
          3962,
          70274,
          61127,
          2746,
          91736,
          6805,
          38588,
          3011,
          4599,
          2746,
          3011,
          5000,
          39164,
          42625,
          26224,
          1120,
          5937,
          10556,
          7683,
          3962,
          5937,
          7623,
          3504,
          4392,
          5937,
          3962,
          2746,
          2746,
          5937,
          6805,
          2746,
          2746,
          3962,
          5000,
          3962,
          3962,
          3962,
          3962,
          4015,
          1032,
          3962,
          6805,
          5246,
          4747,
          943,
          5023,
          1430,
          1824,
          3962,
          4654,
          4901,
          8998,
          3962,
          33849,
          2465,
          1895,
          1188,
          7623,
          3962,
          12836,
          3962,
          1430,
          2746,
          2746,
          2746,
          2746,
          2746,
          1430,
          2746,
          3962,
          1824,
          2746,
          3962,
          3962,
          3011,
          3962,
          3962,
          3962,
          1188,
          1188,
          1004,
          1004,
          1824,
          1430,
          2161,
          2161,
          2746,
          1430,
          5937,
          31896,
          1004,
          56837,
          1684,
          2746,
          30591,
          56940,
          11445,
          1430,
          2746
        ],
        "hover_name": [
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_02_07",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_02_07",
          "T\u1ea1 Tu\u1ea5n Quang<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_02_08",
          "T\u1ea1 Tu\u1ea5n Quang<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_02_09",
          "NGUY\u1ec4N NAM TH\u00c1I<br>103.2 k c\u1ed5 phi\u1ebfu<br>2022_02_10",
          "T\u1ea1 Tu\u1ea5n Quang<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_02_10",
          "NGUY\u1ec4N NAM TH\u00c1I<br>125.1 k c\u1ed5 phi\u1ebfu<br>2022_02_11",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_02_14",
          "NGUY\u1ec4N NAM TH\u00c1I<br>35.5 k c\u1ed5 phi\u1ebfu<br>2022_02_14",
          "T\u1ea1 Tu\u1ea5n Quang<br>25.0 k c\u1ed5 phi\u1ebfu<br>2022_02_15",
          "NGUY\u1ec4N NAM TH\u00c1I<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_02_15",
          "NGUY\u1ec4N NAM TH\u00c1I<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_02_18",
          "NGUY\u1ec4N NAM TH\u00c1I<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_02_22",
          "T\u1ea1 Tu\u1ea5n Quang<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_02_22",
          "T\u1ea1 Tu\u1ea5n Quang<br>21.0 k c\u1ed5 phi\u1ebfu<br>2022_02_23",
          "NGUY\u1ec4N NAM TH\u00c1I<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_02_24",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_02_25",
          "NGUY\u1ec4N NAM TH\u00c1I<br>350.0 k c\u1ed5 phi\u1ebfu<br>2022_02_28",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_03_01",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_03_02",
          "T\u1ea1 Tu\u1ea5n Quang<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_03_02",
          "T\u1ea1 Tu\u1ea5n Quang<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_03_03",
          "Nguy\u1ec5n V\u0103n Ki\u1ec3u<br>450.0 k c\u1ed5 phi\u1ebfu<br>2022_03_03",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_03_04",
          "NGUY\u1ec4N NAM TH\u00c1I<br>350.0 k c\u1ed5 phi\u1ebfu<br>2022_03_07",
          "Nguy\u1ec5n V\u0103n Ki\u1ec3u<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_03_07",
          "T\u1ea1 Tu\u1ea5n Quang<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_03_08",
          "Nguy\u1ec5n V\u0103n Ki\u1ec3u<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_03_08",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_03_09",
          "V\u0169 \u0110\u1ee9c S\u00ednh<br>5,580.0 k c\u1ed5 phi\u1ebfu<br>2022_03_09",
          "V\u0169 \u0110\u1ee9c S\u00ednh<br>2,470.0 k c\u1ed5 phi\u1ebfu<br>2022_03_10",
          "NGUY\u1ec4N NAM TH\u00c1I<br>450.0 k c\u1ed5 phi\u1ebfu<br>2022_03_11",
          "T\u1ea1 Tu\u1ea5n Quang<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_03_11",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_03_16",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>876.4 k c\u1ed5 phi\u1ebfu<br>2022_03_18",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>4,373.6 k c\u1ed5 phi\u1ebfu<br>2022_03_21",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>5,050.0 k c\u1ed5 phi\u1ebfu<br>2022_03_22",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>2,650.0 k c\u1ed5 phi\u1ebfu<br>2022_03_23",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_03_25",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_03_28",
          "NGUY\u1ec4N NAM TH\u00c1I<br>53.7 k c\u1ed5 phi\u1ebfu<br>2022_03_30",
          "NGUY\u1ec4N NAM TH\u00c1I<br>146.3 k c\u1ed5 phi\u1ebfu<br>2022_03_31",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_04_01",
          "T\u1ea1 Tu\u1ea5n Quang<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_04_01",
          "NGUY\u1ec4N NAM TH\u00c1I<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_04_04",
          "NGUY\u1ec4N NAM TH\u00c1I<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_04_06",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_04_08",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_04_12",
          "T\u1ea1 Tu\u1ea5n Quang<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_04_15",
          "NGUY\u1ec4N NAM TH\u00c1I<br>48.0 k c\u1ed5 phi\u1ebfu<br>2022_04_15",
          "T\u1ea1 Tu\u1ea5n Quang<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_04_18",
          "T\u1ea1 Tu\u1ea5n Quang<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_04_19",
          "V\u0169 \u0110\u1ee9c S\u00ednh<br>6,296.7 k c\u1ed5 phi\u1ebfu<br>2022_04_19",
          "NGUY\u1ec4N NAM TH\u00c1I<br>24.9 k c\u1ed5 phi\u1ebfu<br>2022_04_19",
          "Tr\u1ea7n Tuy\u1ebft Hoa<br>3,722.0 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>739.3 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>39.3 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "NGUY\u1ec4N NAM TH\u00c1I<br>230.0 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "T\u1ea1 Tu\u1ea5n Quang<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng<br>8,258.4 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>6,750.0 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_05_16",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng<br>12,131.5 k c\u1ed5 phi\u1ebfu<br>2022_05_16",
          "NGUY\u1ec4N NAM TH\u00c1I<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng<br>3,460.1 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P<br>130.0 k c\u1ed5 phi\u1ebfu<br>2022_05_18",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_05_18",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "NGUY\u1ec4N NAM TH\u00c1I<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>3,535.7 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng<br>4,000.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>1,964.3 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "NGUY\u1ec4N NAM TH\u00c1I<br>3.8 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "NGUY\u1ec4N NAM TH\u00c1I<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "NGUY\u1ec4N NAM TH\u00c1I<br>303.8 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "NGUY\u1ec4N NAM TH\u00c1I<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_05_25",
          "NGUY\u1ec4N NAM TH\u00c1I<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_05_25",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P<br>80.0 k c\u1ed5 phi\u1ebfu<br>2022_05_25",
          "TR\u1ea6N TH\u1eca THU DI\u1ec6P<br>120.0 k c\u1ed5 phi\u1ebfu<br>2022_05_26",
          "NGUY\u1ec4N NAM TH\u00c1I<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_05_26",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_27",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_05_30",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_05_31",
          "NGUY\u1ec4N NAM TH\u00c1I<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_06_01",
          "NGUY\u1ec4N NAM TH\u00c1I<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_06_02",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "NGUY\u1ec4N NAM TH\u00c1I<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_06_09",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_13",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "NGUY\u1ec4N NAM TH\u00c1I<br>102.4 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "NGUY\u1ec4N NAM TH\u00c1I<br>2.4 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "T\u1ea1 Tu\u1ea5n Quang<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_06_22",
          "NGUY\u1ec4N NAM TH\u00c1I<br>162.7 k c\u1ed5 phi\u1ebfu<br>2022_06_22",
          "NGUY\u1ec4N NAM TH\u00c1I<br>137.3 k c\u1ed5 phi\u1ebfu<br>2022_06_24",
          "NGUY\u1ec4N NAM TH\u00c1I<br>1.2 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "NGUY\u1ec4N NAM TH\u00c1I<br>151.2 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "T\u1ea1 Tu\u1ea5n Quang<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "T\u1ea1 Tu\u1ea5n Quang<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_06_30",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "V\u0169 \u0110\u1ee9c S\u00ednh<br>132.7 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng<br>145.0 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "Tr\u1ea7n Tuy\u1ebft Hoa<br>390.0 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "V\u0169 \u0110\u1ee9c S\u00ednh<br>2,857.3 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "Nguy\u1ec5n Nam Th\u00e1i<br>40.0 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "Nguy\u1ec5n Nam Th\u00e1i<br>22.0 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "V\u01af\u01a0NG NG\u1eccC LINH<br>5.0 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "V\u0169 \u0110\u1ee9c S\u00ednh<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "V\u0169 \u0110\u1ee9c S\u00ednh<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>675.0 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_08",
          "T\u1ea1 Tu\u1ea5n Quang<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_07_08",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_19",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_19",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "T\u1ea1 Tu\u1ea5n Quang<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_07_22",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_22",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "T\u1ea1 Tu\u1ea5n Quang<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_28",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_28",
          "T\u1ea1 Tu\u1ea5n Quang<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_07_29",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_29",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_29",
          "NGUY\u1ec4N NAM TH\u00c1I<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_08_01",
          "T\u1ea1 Tu\u1ea5n Quang<br>5.0 k c\u1ed5 phi\u1ebfu<br>2022_08_01",
          "T\u1ea1 Tu\u1ea5n Quang<br>5.0 k c\u1ed5 phi\u1ebfu<br>2022_08_02",
          "T\u1ea1 Tu\u1ea5n Quang<br>2.0 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "T\u1ea1 Tu\u1ea5n Quang<br>2.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "T\u1ea1 Tu\u1ea5n Quang<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_08",
          "V\u01af\u01a0NG NG\u1eccC LINH<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          "T\u1ea1 Tu\u1ea5n Quang<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          "T\u1ea1 Tu\u1ea5n Quang<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "T\u1ea1 Tu\u1ea5n Quang<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "Tr\u1ea7n Tuy\u1ebft Hoa<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_08_12",
          "Tr\u1ea7n Tuy\u1ebft Hoa<br>2,619.1 k c\u1ed5 phi\u1ebfu<br>2022_08_15",
          "T\u1ea1 Tu\u1ea5n Quang<br>2.0 k c\u1ed5 phi\u1ebfu<br>2022_08_15",
          "NGUY\u1ec4N TH\u1eca T\u1ed0 HO\u00c0I<br>6,075.0 k c\u1ed5 phi\u1ebfu<br>2022_08_16",
          "T\u1ea1 Tu\u1ea5n Quang<br>16.2 k c\u1ed5 phi\u1ebfu<br>2022_08_16",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_08_16",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng<br>2,463.6 k c\u1ed5 phi\u1ebfu<br>2022_08_16",
          "Tr\u1ea7n Tuy\u1ebft Hoa<br>6,090.9 k c\u1ed5 phi\u1ebfu<br>2022_08_16",
          "Nguy\u1ec5n Minh T\u01b0\u1edfng<br>566.4 k c\u1ed5 phi\u1ebfu<br>2022_08_17",
          "T\u1ea1 Tu\u1ea5n Quang<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19",
          "NGUY\u1ec4N NAM TH\u00c1I<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19"
        ]
      },
      "vn30_index": {
        "close": [
          1541.25,
          1550.46,
          1552.44,
          1548.95,
          1545.92,
          1505.07,
          1527.36,
          1521.47,
          1540.51,
          1531.47,
          1533.4,
          1532.36,
          1538.83,
          1522.04,
          1526.5,
          1517.18,
          1520.12,
          1498.61,
          1522.49,
          1525.34,
          1509.12,
          1490.17,
          1489.25,
          1490.24,
          1477.14,
          1461.1,
          1468.89,
          1472.69,
          1469.92,
          1476.94,
          1502.67,
          1513.4,
          1505.59,
          1497.44,
          1498.36,
          1484.16,
          1500.57,
          1500.23,
          1508.53,
          1542.47,
          1548.04,
          1539.2,
          1557.11,
          1541.96,
          1524.31,
          1507.2,
          1525.39,
          1518.01,
          1493.74,
          1468.25,
          1440.61,
          1435.5,
          1426.87,
          1444.32,
          1366.39,
          1396.9,
          1402.03,
          1400.88,
          1417.31,
          1389.59,
          1404.88,
          1373.21,
          1314.04,
          1345.46,
          1349.82,
          1279.76,
          1223.76,
          1215.08,
          1279.55,
          1286.41,
          1283.55,
          1282.51,
          1255.35,
          1272.71,
          1310.7,
          1309.5,
          1335.68,
          1342.87,
          1332.59,
          1335.49,
          1325.49,
          1327.4,
          1327.04,
          1324.37,
          1342.03,
          1342.92,
          1325.69,
          1260.85,
          1261.16,
          1253.09,
          1280.37,
          1258.03,
          1225.56,
          1224.54,
          1227.18,
          1240.58,
          1235.47,
          1256.67,
          1273.41,
          1273.4,
          1248.92,
          1252.24,
          1248.37,
          1242.05,
          1211.94,
          1229.23,
          1231.54,
          1209.02,
          1219.44,
          1216.94,
          1221.94,
          1220.14,
          1214.28,
          1211.7,
          1225.62,
          1235.25,
          1228.84,
          1222.6,
          1218.49,
          1219.43,
          1236.14,
          1232.0,
          1256.25,
          1265.97,
          1269.83,
          1277.73,
          1272.06,
          1276.7,
          1281.44,
          1277.15,
          1272.33,
          1280.96,
          1293.79,
          1295.1,
          1300.4,
          1299.93,
          1294.93,
          1291.06
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ],
        "color": [
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index"
        ]
      }
    },
    "MSN": {
      "prices": {
        "t": [
          1644192000000000000,
          1644278400000000000,
          1644364800000000000,
          1644451200000000000,
          1644537600000000000,
          1644796800000000000,
          1644883200000000000,
          1644969600000000000,
          1645056000000000000,
          1645142400000000000,
          1645401600000000000,
          1645488000000000000,
          1645574400000000000,
          1645660800000000000,
          1645747200000000000,
          1646006400000000000,
          1646092800000000000,
          1646179200000000000,
          1646265600000000000,
          1646352000000000000,
          1646611200000000000,
          1646697600000000000,
          1646784000000000000,
          1646870400000000000,
          1646956800000000000,
          1647216000000000000,
          1647302400000000000,
          1647388800000000000,
          1647475200000000000,
          1647561600000000000,
          1647820800000000000,
          1647907200000000000,
          1647993600000000000,
          1648080000000000000,
          1648166400000000000,
          1648425600000000000,
          1648512000000000000,
          1648598400000000000,
          1648684800000000000,
          1648771200000000000,
          1649030400000000000,
          1649116800000000000,
          1649203200000000000,
          1649289600000000000,
          1649376000000000000,
          1649721600000000000,
          1649808000000000000,
          1649894400000000000,
          1649980800000000000,
          1650240000000000000,
          1650326400000000000,
          1650412800000000000,
          1650499200000000000,
          1650585600000000000,
          1650844800000000000,
          1650931200000000000,
          1651017600000000000,
          1651104000000000000,
          1651190400000000000,
          1651622400000000000,
          1651708800000000000,
          1651795200000000000,
          1652054400000000000,
          1652140800000000000,
          1652227200000000000,
          1652313600000000000,
          1652400000000000000,
          1652659200000000000,
          1652745600000000000,
          1652832000000000000,
          1652918400000000000,
          1653004800000000000,
          1653264000000000000,
          1653350400000000000,
          1653436800000000000,
          1653523200000000000,
          1653609600000000000,
          1653868800000000000,
          1653955200000000000,
          1654041600000000000,
          1654128000000000000,
          1654214400000000000,
          1654473600000000000,
          1654560000000000000,
          1654646400000000000,
          1654732800000000000,
          1654819200000000000,
          1655078400000000000,
          1655164800000000000,
          1655251200000000000,
          1655337600000000000,
          1655424000000000000,
          1655683200000000000,
          1655769600000000000,
          1655856000000000000,
          1655942400000000000,
          1656028800000000000,
          1656288000000000000,
          1656374400000000000,
          1656460800000000000,
          1656547200000000000,
          1656633600000000000,
          1656892800000000000,
          1656979200000000000,
          1657065600000000000,
          1657152000000000000,
          1657238400000000000,
          1657497600000000000,
          1657584000000000000,
          1657670400000000000,
          1657756800000000000,
          1657843200000000000,
          1658102400000000000,
          1658188800000000000,
          1658275200000000000,
          1658361600000000000,
          1658448000000000000,
          1658707200000000000,
          1658793600000000000,
          1658880000000000000,
          1658966400000000000,
          1659052800000000000,
          1659312000000000000,
          1659398400000000000,
          1659484800000000000,
          1659571200000000000,
          1659657600000000000,
          1659916800000000000,
          1660003200000000000,
          1660089600000000000,
          1660176000000000000,
          1660262400000000000,
          1660521600000000000,
          1660608000000000000,
          1660694400000000000,
          1660780800000000000,
          1660867200000000000,
          1661126400000000000
        ],
        "close": [
          122.345,
          120.36,
          121.269,
          123.751,
          123.917,
          125.406,
          132.768,
          130.782,
          134.836,
          135.084,
          132.602,
          130.286,
          130.038,
          131.941,
          130.7,
          129.045,
          129.459,
          129.873,
          131.61,
          133.43,
          130.865,
          131.362,
          128.632,
          124.082,
          117.878,
          112.584,
          116.72,
          116.223,
          114.155,
          113.08,
          119.946,
          122.841,
          121.6,
          121.269,
          121.104,
          120.442,
          119.119,
          119.119,
          117.63,
          121.187,
          124.082,
          125.406,
          127.225,
          124.909,
          122.841,
          124.484,
          125.973,
          124.782,
          124.087,
          123.094,
          119.62,
          123.392,
          122.102,
          122.399,
          115.153,
          115.153,
          118.131,
          115.649,
          115.153,
          112.274,
          118.131,
          116.145,
          114.061,
          115.252,
          111.083,
          103.34,
          96.192,
          89.541,
          95.795,
          102.446,
          109.594,
          107.013,
          103.737,
          108.204,
          109.693,
          107.807,
          109.197,
          109.197,
          111.579,
          114.16,
          113.465,
          111.976,
          116.145,
          117.039,
          118.429,
          117.634,
          116.145,
          108.204,
          105.524,
          105.226,
          109.891,
          116.145,
          110.189,
          110.189,
          105.226,
          109.197,
          108.7,
          112.175,
          113.167,
          113.167,
          111.182,
          108.7,
          107.7,
          102.0,
          100.0,
          103.0,
          104.9,
          102.5,
          102.0,
          101.0,
          102.5,
          101.2,
          102.6,
          102.0,
          103.0,
          105.5,
          108.5,
          108.6,
          110.0,
          109.9,
          109.9,
          106.1,
          109.4,
          110.0,
          110.0,
          107.9,
          104.5,
          104.8,
          105.2,
          105.8,
          106.8,
          107.2,
          108.5,
          109.0,
          111.3,
          112.0,
          112.0,
          112.0
        ],
        "open": [
          119.946,
          121.104,
          121.187,
          122.428,
          124.082,
          122.841,
          125.571,
          132.354,
          130.7,
          132.354,
          135.167,
          129.294,
          130.203,
          129.459,
          133.181,
          130.7,
          129.873,
          128.218,
          131.279,
          131.941,
          132.106,
          130.7,
          131.113,
          128.797,
          123.255,
          117.63,
          112.087,
          118.043,
          116.637,
          114.983,
          114.9,
          120.36,
          121.435,
          122.758,
          121.6,
          119.946,
          120.773,
          118.374,
          119.036,
          119.119,
          123.255,
          125.571,
          125.654,
          128.218,
          125.323,
          129.051,
          125.08,
          126.072,
          125.278,
          125.576,
          123.988,
          120.513,
          121.109,
          122.102,
          123.69,
          115.848,
          115.153,
          118.131,
          116.145,
          115.153,
          113.167,
          114.16,
          115.153,
          113.267,
          112.869,
          111.083,
          102.347,
          98.277,
          87.357,
          97.383,
          101.255,
          108.998,
          107.807,
          103.737,
          108.204,
          109.693,
          108.005,
          109.197,
          107.211,
          109.197,
          114.16,
          113.664,
          111.976,
          114.259,
          117.138,
          117.634,
          114.259,
          112.274,
          103.34,
          106.218,
          106.218,
          107.211,
          114.16,
          109.991,
          110.189,
          105.226,
          107.707,
          108.998,
          112.175,
          108.204,
          111.38,
          111.083,
          109.5,
          107.9,
          102.0,
          100.0,
          103.5,
          101.5,
          103.5,
          100.7,
          101.2,
          101.9,
          101.1,
          102.5,
          102.1,
          103.2,
          105.1,
          105.7,
          107.0,
          109.2,
          110.0,
          111.0,
          107.0,
          109.6,
          109.5,
          110.0,
          107.9,
          104.5,
          106.0,
          105.2,
          106.0,
          106.7,
          107.9,
          108.5,
          109.3,
          111.5,
          112.0,
          112.0
        ],
        "high": [
          122.841,
          122.179,
          122.841,
          123.751,
          125.323,
          125.406,
          132.768,
          132.354,
          134.836,
          136.49,
          135.167,
          132.189,
          131.941,
          134.836,
          133.181,
          132.271,
          130.286,
          130.7,
          134.009,
          134.67,
          134.34,
          132.437,
          132.271,
          130.948,
          125.323,
          117.63,
          116.72,
          118.043,
          117.712,
          118.043,
          120.111,
          124.909,
          127.225,
          124.082,
          122.262,
          120.69,
          121.931,
          120.36,
          119.367,
          122.924,
          128.218,
          127.225,
          128.218,
          128.218,
          125.323,
          129.051,
          128.554,
          126.569,
          125.675,
          125.576,
          124.583,
          124.087,
          124.087,
          124.087,
          123.69,
          123.194,
          120.91,
          119.024,
          117.734,
          115.947,
          118.131,
          117.634,
          115.351,
          115.252,
          114.16,
          111.877,
          104.233,
          98.872,
          95.795,
          102.446,
          109.594,
          110.884,
          108.105,
          109.097,
          112.671,
          109.891,
          111.083,
          109.197,
          111.579,
          114.16,
          114.16,
          113.664,
          116.145,
          117.039,
          120.613,
          119.124,
          117.634,
          114.16,
          108.998,
          108.204,
          112.572,
          116.145,
          116.642,
          112.969,
          113.167,
          109.197,
          109.395,
          112.572,
          113.366,
          114.16,
          113.366,
          111.182,
          110.0,
          108.5,
          106.2,
          104.5,
          104.9,
          103.9,
          104.0,
          103.7,
          103.0,
          103.5,
          103.8,
          102.5,
          105.5,
          106.9,
          109.0,
          111.3,
          110.0,
          110.0,
          110.5,
          112.0,
          111.0,
          111.0,
          110.2,
          110.2,
          108.0,
          105.8,
          106.1,
          107.0,
          107.5,
          107.2,
          108.8,
          109.2,
          111.7,
          114.9,
          113.9,
          113.8
        ],
        "low": [
          118.292,
          119.946,
          119.946,
          121.518,
          122.51,
          122.51,
          124.082,
          130.286,
          130.452,
          131.362,
          132.437,
          129.294,
          129.459,
          128.714,
          130.7,
          129.045,
          128.632,
          128.218,
          129.624,
          130.7,
          130.7,
          130.286,
          127.805,
          124.082,
          117.878,
          110.929,
          112.087,
          115.81,
          114.155,
          113.08,
          114.817,
          118.292,
          120.029,
          121.269,
          119.698,
          118.292,
          118.705,
          117.547,
          117.382,
          117.878,
          122.841,
          124.082,
          124.165,
          124.909,
          122.841,
          122.995,
          125.08,
          123.591,
          123.194,
          123.094,
          118.627,
          119.62,
          121.109,
          121.605,
          114.954,
          108.303,
          110.189,
          115.649,
          114.259,
          112.175,
          112.572,
          114.16,
          110.189,
          109.395,
          110.686,
          103.34,
          96.192,
          89.541,
          85.074,
          92.817,
          99.27,
          106.218,
          103.24,
          103.24,
          106.715,
          107.211,
          107.211,
          108.105,
          107.211,
          109.197,
          112.075,
          110.686,
          107.211,
          112.373,
          117.039,
          117.237,
          114.259,
          108.204,
          103.34,
          103.935,
          106.218,
          106.516,
          110.189,
          108.402,
          105.226,
          105.226,
          107.707,
          107.707,
          109.494,
          108.204,
          110.686,
          108.204,
          107.1,
          102.0,
          100.0,
          100.0,
          103.5,
          101.5,
          101.5,
          100.7,
          101.1,
          101.0,
          100.5,
          101.0,
          102.0,
          103.0,
          103.7,
          105.7,
          107.0,
          108.0,
          108.0,
          106.1,
          106.5,
          107.1,
          106.8,
          106.7,
          104.1,
          104.5,
          104.5,
          104.7,
          105.3,
          105.7,
          106.5,
          107.1,
          108.5,
          110.6,
          110.8,
          111.0
        ],
        "volume": [
          611900,
          502000,
          585500,
          1433500,
          487500,
          925700,
          1575800,
          823200,
          1673100,
          1183000,
          957200,
          559100,
          459600,
          989000,
          572500,
          426900,
          360000,
          822900,
          752900,
          1197900,
          942300,
          634300,
          875100,
          1245100,
          1565500,
          1649600,
          1020500,
          503900,
          766800,
          2545700,
          890400,
          969400,
          1396000,
          760300,
          545600,
          630000,
          595200,
          675400,
          589200,
          808700,
          1685400,
          739300,
          1374300,
          1113200,
          1169600,
          1373600,
          989500,
          800000,
          1015000,
          890800,
          943100,
          1116500,
          1507800,
          1750600,
          1076900,
          2206600,
          1241700,
          837600,
          1098700,
          512200,
          933300,
          825300,
          1084200,
          1088600,
          990800,
          1061600,
          1761600,
          1052900,
          1573900,
          1291600,
          1722900,
          930900,
          700700,
          829900,
          829700,
          834000,
          771700,
          671100,
          1265300,
          1097900,
          457800,
          394800,
          1729500,
          1460100,
          1318800,
          711400,
          632500,
          1101100,
          1658900,
          997000,
          1394900,
          1627200,
          862000,
          954600,
          802700,
          397300,
          543000,
          882000,
          1209600,
          713400,
          456000,
          478200,
          357800,
          942500,
          1181300,
          444900,
          609700,
          565100,
          287100,
          549100,
          356200,
          413900,
          666600,
          367200,
          679400,
          1032900,
          1241500,
          461100,
          487400,
          334400,
          619200,
          840100,
          730600,
          1114600,
          1452700,
          1593800,
          1920800,
          637400,
          502900,
          560100,
          772100,
          252200,
          554600,
          619600,
          1170500,
          1715300,
          458300,
          249500
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "stock": [
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ]
      },
      "insider": {
        "id": [
          "068FIA6413",
          "068FIA6413",
          "068C008778",
          "068C005097",
          "068C005250",
          "068C005097",
          "068FIA6413",
          "068C005250",
          "068C022188",
          "068C005959",
          "068C005959",
          "007C003499",
          "068C008984",
          "068C008778",
          "007C003555",
          "068C005959",
          "068C008984",
          "039C521671",
          "039C541155",
          "039C541156",
          "039C541155",
          "068C008984",
          "068C008984",
          "068C008984",
          "068C010768",
          "068C010768",
          "068C005155",
          "068C005155",
          "068C005097",
          "068C005097",
          "068C008984",
          "068C005250",
          "068C005250",
          "068C008984",
          "021C415870",
          "021C415870",
          "021C328632",
          "021C328632",
          "021C328630",
          "021C328630",
          "068C005155",
          "068FIA6413",
          "068C005155",
          "068C008778",
          "068C010768",
          "068FIA6413",
          "006C196979",
          "068C005155",
          "068FIA6413",
          "068C005097",
          "068FIA6413"
        ],
        "day": [
          "2022_05_31",
          "2022_06_02",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_06",
          "2022_06_07",
          "2022_06_07",
          "2022_06_08",
          "2022_06_08",
          "2022_06_09",
          "2022_06_15",
          "2022_06_15",
          "2022_06_15",
          "2022_06_15",
          "2022_06_17",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_12",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_25",
          "2022_07_25",
          "2022_07_25",
          "2022_07_25",
          "2022_07_25",
          "2022_07_25",
          "2022_07_28",
          "2022_07_28",
          "2022_07_29",
          "2022_08_03",
          "2022_08_04",
          "2022_08_09",
          "2022_08_11",
          "2022_08_11",
          "2022_08_11",
          "2022_08_17",
          "2022_08_18"
        ],
        "stock": [
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN",
          "MSN"
        ],
        "side": [
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell"
        ],
        "TYPES": [
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider"
        ],
        "TradeValue": [
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          -1.6,
          28.5,
          30.4,
          -31.7,
          -28.5,
          -0.8,
          -2.0,
          -18.0,
          -52.5,
          -53.9,
          -13.6,
          -15.3,
          -1.6,
          -0.6,
          -99.5,
          -3.0,
          -7.3,
          -147.7,
          -97.7,
          98.8,
          -98.8,
          98.2,
          -98.2,
          97.7,
          -1.1,
          -1.1,
          -15.4,
          -0.7,
          -123.0,
          -2.1,
          99.3,
          -99.3,
          -2.1,
          -11.0,
          -2.3
        ],
        "volume": [
          10000,
          10000,
          6000,
          15000,
          100000,
          85000,
          10000,
          194200,
          28000,
          422600,
          4300,
          130000,
          670000,
          22500,
          130000,
          300000,
          14600,
          257200,
          273500,
          286000,
          257200,
          7500,
          18800,
          164700,
          465000,
          473000,
          119300,
          136900,
          14900,
          6000,
          975000,
          29400,
          70600,
          1400000,
          900000,
          909500,
          909500,
          904200,
          904200,
          900000,
          9800,
          10000,
          142000,
          6200,
          1140000,
          20000,
          930000,
          930000,
          20000,
          100000,
          20000
        ],
        "name": [
          "CHETAN PRAKASH BAXI",
          "CHETAN PRAKASH BAXI",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "CHETAN PRAKASH BAXI",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Tr\u1ea7n \u0110\u1ee9c Anh",
          "Th\u00e1i Quang Huy",
          "Th\u00e1i Quang Huy",
          "Th\u00e1i Minh Ho\u00e0ng ",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "Th\u00e1i Quang Huy",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "Tr\u1ea7n \u0110\u1ee9c Anh",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh",
          "Th\u00e1i Minh Ho\u00e0ng",
          "Th\u00e1i Minh Ho\u00e0ng",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "Th\u00e1i Minh Ho\u00e0ng",
          "CHETAN PRAKASH BAXI",
          "Th\u00e1i Minh Ho\u00e0ng",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh",
          "CHETAN PRAKASH BAXI",
          null,
          "Th\u00e1i Minh Ho\u00e0ng",
          "CHETAN PRAKASH BAXI",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "CHETAN PRAKASH BAXI"
        ],
        "Value": [
          1.12,
          1.14,
          0.69,
          1.69,
          11.7,
          9.95,
          1.15,
          22.67,
          3.34,
          50.42,
          0.51,
          13.78,
          71.02,
          2.38,
          13.78,
          35.1,
          1.62,
          28.55,
          30.36,
          31.75,
          28.55,
          0.83,
          1.99,
          18.03,
          52.55,
          53.92,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN
        ],
        "i": [
          5137,
          5125,
          5126,
          5121,
          5107,
          5109,
          5101,
          5095,
          5088,
          5083,
          5080,
          5805,
          5797,
          5810,
          5806,
          5777,
          5758,
          5767,
          5764,
          5748,
          5751,
          5737,
          5726,
          5704,
          3935,
          3910,
          2997,
          2870,
          2861,
          4567,
          5583,
          5540,
          5529,
          5512,
          5489,
          5493,
          5487,
          5494,
          5488,
          5495,
          737,
          736,
          682,
          3290,
          3277,
          5689,
          5663,
          5657,
          5660,
          5631,
          5623
        ],
        "x": [
          312,
          314,
          314,
          315,
          316,
          316,
          317,
          317,
          318,
          318,
          319,
          323,
          323,
          323,
          323,
          325,
          326,
          326,
          326,
          326,
          326,
          327,
          328,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          342,
          347,
          348,
          349,
          351,
          351,
          351,
          351,
          351,
          351,
          354,
          354,
          355,
          358,
          359,
          362,
          364,
          364,
          364,
          368,
          369
        ],
        "size": [
          1430,
          1430,
          1241,
          1638,
          3962,
          3622,
          1430,
          5832,
          2097,
          9472,
          1149,
          4599,
          12774,
          1913,
          4599,
          7623,
          1622,
          6926,
          7195,
          7398,
          6926,
          1315,
          1781,
          5284,
          10072,
          10184,
          4378,
          4739,
          1634,
          1241,
          16370,
          2142,
          3278,
          20858,
          15522,
          15630,
          15630,
          15570,
          15570,
          15522,
          1422,
          1430,
          4842,
          1251,
          18171,
          1824,
          15863,
          15863,
          1824,
          3962,
          1824
        ],
        "hover_name": [
          "CHETAN PRAKASH BAXI<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_05_31",
          "CHETAN PRAKASH BAXI<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_06_02",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>6.0 k c\u1ed5 phi\u1ebfu<br>2022_06_02",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>15.0 k c\u1ed5 phi\u1ebfu<br>2022_06_03",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>85.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "CHETAN PRAKASH BAXI<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>194.2 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "Tr\u1ea7n \u0110\u1ee9c Anh<br>28.0 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "Th\u00e1i Quang Huy<br>422.6 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "Th\u00e1i Quang Huy<br>4.3 k c\u1ed5 phi\u1ebfu<br>2022_06_09",
          "Th\u00e1i Minh Ho\u00e0ng <br>130.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>670.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>22.5 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>130.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "Th\u00e1i Quang Huy<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>14.6 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Tr\u1ea7n \u0110\u1ee9c Anh<br>257.2 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>273.5 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh<br>286.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>257.2 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>7.5 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>18.8 k c\u1ed5 phi\u1ebfu<br>2022_06_22",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>164.7 k c\u1ed5 phi\u1ebfu<br>2022_06_24",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh<br>465.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh<br>473.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Th\u00e1i Minh Ho\u00e0ng<br>119.3 k c\u1ed5 phi\u1ebfu<br>2022_06_29",
          "Th\u00e1i Minh Ho\u00e0ng<br>136.9 k c\u1ed5 phi\u1ebfu<br>2022_06_30",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>14.9 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>6.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>975.0 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>29.4 k c\u1ed5 phi\u1ebfu<br>2022_07_19",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>70.6 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>1,400.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh<br>900.0 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh<br>909.5 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>909.5 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>904.2 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>904.2 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>900.0 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "Th\u00e1i Minh Ho\u00e0ng<br>9.8 k c\u1ed5 phi\u1ebfu<br>2022_07_28",
          "CHETAN PRAKASH BAXI<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_07_28",
          "Th\u00e1i Minh Ho\u00e0ng<br>142.0 k c\u1ed5 phi\u1ebfu<br>2022_07_29",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>6.2 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh<br>1,140.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "CHETAN PRAKASH BAXI<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          NaN,
          "Th\u00e1i Minh Ho\u00e0ng<br>930.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "CHETAN PRAKASH BAXI<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_08_17",
          "CHETAN PRAKASH BAXI<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_18"
        ]
      },
      "vn30_index": {
        "close": [
          1541.25,
          1550.46,
          1552.44,
          1548.95,
          1545.92,
          1505.07,
          1527.36,
          1521.47,
          1540.51,
          1531.47,
          1533.4,
          1532.36,
          1538.83,
          1522.04,
          1526.5,
          1517.18,
          1520.12,
          1498.61,
          1522.49,
          1525.34,
          1509.12,
          1490.17,
          1489.25,
          1490.24,
          1477.14,
          1461.1,
          1468.89,
          1472.69,
          1469.92,
          1476.94,
          1502.67,
          1513.4,
          1505.59,
          1497.44,
          1498.36,
          1484.16,
          1500.57,
          1500.23,
          1508.53,
          1542.47,
          1548.04,
          1539.2,
          1557.11,
          1541.96,
          1524.31,
          1507.2,
          1525.39,
          1518.01,
          1493.74,
          1468.25,
          1440.61,
          1435.5,
          1426.87,
          1444.32,
          1366.39,
          1396.9,
          1402.03,
          1400.88,
          1417.31,
          1389.59,
          1404.88,
          1373.21,
          1314.04,
          1345.46,
          1349.82,
          1279.76,
          1223.76,
          1215.08,
          1279.55,
          1286.41,
          1283.55,
          1282.51,
          1255.35,
          1272.71,
          1310.7,
          1309.5,
          1335.68,
          1342.87,
          1332.59,
          1335.49,
          1325.49,
          1327.4,
          1327.04,
          1324.37,
          1342.03,
          1342.92,
          1325.69,
          1260.85,
          1261.16,
          1253.09,
          1280.37,
          1258.03,
          1225.56,
          1224.54,
          1227.18,
          1240.58,
          1235.47,
          1256.67,
          1273.41,
          1273.4,
          1248.92,
          1252.24,
          1248.37,
          1242.05,
          1211.94,
          1229.23,
          1231.54,
          1209.02,
          1219.44,
          1216.94,
          1221.94,
          1220.14,
          1214.28,
          1211.7,
          1225.62,
          1235.25,
          1228.84,
          1222.6,
          1218.49,
          1219.43,
          1236.14,
          1232.0,
          1256.25,
          1265.97,
          1269.83,
          1277.73,
          1272.06,
          1276.7,
          1281.44,
          1277.15,
          1272.33,
          1280.96,
          1293.79,
          1295.1,
          1300.4,
          1299.93,
          1294.93,
          1291.06
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ],
        "color": [
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index"
        ]
      }
    },
    "STB": {
      "prices": {
        "t": [
          1644192000000000000,
          1644278400000000000,
          1644364800000000000,
          1644451200000000000,
          1644537600000000000,
          1644796800000000000,
          1644883200000000000,
          1644969600000000000,
          1645056000000000000,
          1645142400000000000,
          1645401600000000000,
          1645488000000000000,
          1645574400000000000,
          1645660800000000000,
          1645747200000000000,
          1646006400000000000,
          1646092800000000000,
          1646179200000000000,
          1646265600000000000,
          1646352000000000000,
          1646611200000000000,
          1646697600000000000,
          1646784000000000000,
          1646870400000000000,
          1646956800000000000,
          1647216000000000000,
          1647302400000000000,
          1647388800000000000,
          1647475200000000000,
          1647561600000000000,
          1647820800000000000,
          1647907200000000000,
          1647993600000000000,
          1648080000000000000,
          1648166400000000000,
          1648425600000000000,
          1648512000000000000,
          1648598400000000000,
          1648684800000000000,
          1648771200000000000,
          1649030400000000000,
          1649116800000000000,
          1649203200000000000,
          1649289600000000000,
          1649376000000000000,
          1649721600000000000,
          1649808000000000000,
          1649894400000000000,
          1649980800000000000,
          1650240000000000000,
          1650326400000000000,
          1650412800000000000,
          1650499200000000000,
          1650585600000000000,
          1650844800000000000,
          1650931200000000000,
          1651017600000000000,
          1651104000000000000,
          1651190400000000000,
          1651622400000000000,
          1651708800000000000,
          1651795200000000000,
          1652054400000000000,
          1652140800000000000,
          1652227200000000000,
          1652313600000000000,
          1652400000000000000,
          1652659200000000000,
          1652745600000000000,
          1652832000000000000,
          1652918400000000000,
          1653004800000000000,
          1653264000000000000,
          1653350400000000000,
          1653436800000000000,
          1653523200000000000,
          1653609600000000000,
          1653868800000000000,
          1653955200000000000,
          1654041600000000000,
          1654128000000000000,
          1654214400000000000,
          1654473600000000000,
          1654560000000000000,
          1654646400000000000,
          1654732800000000000,
          1654819200000000000,
          1655078400000000000,
          1655164800000000000,
          1655251200000000000,
          1655337600000000000,
          1655424000000000000,
          1655683200000000000,
          1655769600000000000,
          1655856000000000000,
          1655942400000000000,
          1656028800000000000,
          1656288000000000000,
          1656374400000000000,
          1656460800000000000,
          1656547200000000000,
          1656633600000000000,
          1656892800000000000,
          1656979200000000000,
          1657065600000000000,
          1657152000000000000,
          1657238400000000000,
          1657497600000000000,
          1657584000000000000,
          1657670400000000000,
          1657756800000000000,
          1657843200000000000,
          1658102400000000000,
          1658188800000000000,
          1658275200000000000,
          1658361600000000000,
          1658448000000000000,
          1658707200000000000,
          1658793600000000000,
          1658880000000000000,
          1658966400000000000,
          1659052800000000000,
          1659312000000000000,
          1659398400000000000,
          1659484800000000000,
          1659571200000000000,
          1659657600000000000,
          1659916800000000000,
          1660003200000000000,
          1660089600000000000,
          1660176000000000000,
          1660262400000000000,
          1660521600000000000,
          1660608000000000000,
          1660694400000000000,
          1660780800000000000,
          1660867200000000000,
          1661126400000000000
        ],
        "close": [
          35.6,
          35.85,
          35.05,
          34.75,
          35.3,
          32.85,
          33.5,
          33.6,
          33.9,
          33.35,
          33.2,
          33.7,
          33.6,
          32.9,
          33.0,
          32.85,
          32.65,
          31.25,
          31.85,
          32.0,
          31.5,
          30.85,
          31.1,
          31.55,
          32.05,
          32.5,
          32.7,
          32.9,
          32.8,
          33.1,
          33.1,
          34.05,
          34.0,
          33.65,
          33.65,
          31.85,
          32.3,
          32.25,
          31.7,
          32.2,
          32.2,
          31.8,
          32.35,
          31.9,
          31.0,
          30.8,
          31.35,
          30.8,
          30.1,
          29.0,
          27.5,
          27.55,
          27.65,
          28.8,
          26.8,
          27.4,
          28.2,
          27.7,
          27.7,
          26.75,
          26.9,
          25.5,
          23.75,
          23.9,
          23.55,
          21.95,
          20.45,
          19.05,
          20.35,
          21.75,
          21.75,
          21.6,
          20.35,
          21.75,
          22.75,
          22.4,
          22.25,
          22.8,
          22.35,
          22.25,
          21.55,
          21.15,
          20.5,
          20.2,
          21.6,
          22.3,
          21.9,
          20.6,
          20.9,
          20.7,
          21.0,
          20.25,
          18.85,
          19.2,
          20.5,
          21.3,
          21.2,
          21.5,
          22.55,
          22.6,
          21.5,
          22.0,
          22.35,
          23.05,
          22.5,
          22.6,
          22.5,
          22.05,
          22.6,
          23.05,
          23.3,
          23.0,
          22.55,
          22.95,
          23.05,
          23.1,
          23.0,
          22.85,
          22.95,
          23.05,
          24.45,
          24.7,
          25.2,
          24.95,
          25.45,
          25.2,
          25.25,
          25.3,
          25.25,
          24.9,
          25.2,
          25.65,
          25.65,
          25.55,
          25.4,
          25.2,
          25.1,
          25.1
        ],
        "open": [
          36.0,
          35.35,
          36.0,
          35.0,
          34.75,
          34.85,
          33.1,
          33.75,
          33.6,
          33.6,
          33.35,
          32.9,
          33.9,
          33.4,
          33.1,
          33.0,
          33.0,
          32.5,
          31.55,
          31.85,
          31.2,
          31.05,
          31.0,
          31.75,
          31.4,
          32.05,
          32.5,
          32.8,
          33.0,
          32.8,
          33.1,
          33.35,
          34.0,
          34.0,
          33.6,
          33.5,
          32.1,
          31.95,
          32.1,
          31.65,
          32.5,
          32.05,
          31.8,
          32.4,
          31.9,
          31.15,
          31.0,
          31.35,
          30.7,
          30.1,
          29.1,
          27.8,
          27.4,
          28.1,
          28.8,
          26.6,
          27.0,
          27.85,
          27.55,
          27.7,
          27.0,
          26.0,
          25.1,
          22.55,
          23.9,
          23.45,
          21.5,
          21.1,
          18.7,
          20.95,
          21.3,
          21.75,
          21.75,
          20.35,
          22.0,
          22.6,
          22.5,
          22.45,
          22.6,
          22.3,
          22.35,
          21.5,
          21.15,
          20.5,
          20.4,
          22.25,
          21.8,
          21.05,
          20.35,
          21.0,
          21.1,
          20.5,
          20.3,
          18.85,
          19.7,
          20.15,
          21.3,
          21.4,
          21.5,
          22.1,
          22.55,
          21.4,
          22.3,
          22.4,
          22.6,
          22.5,
          23.0,
          22.25,
          22.15,
          22.6,
          22.9,
          23.4,
          23.05,
          22.55,
          23.25,
          23.05,
          23.3,
          23.0,
          22.95,
          22.85,
          23.35,
          24.65,
          24.5,
          25.0,
          24.85,
          25.65,
          25.1,
          25.25,
          25.3,
          25.2,
          25.2,
          25.05,
          25.9,
          25.8,
          25.55,
          25.2,
          25.25,
          25.0
        ],
        "high": [
          36.3,
          36.2,
          36.7,
          35.25,
          35.35,
          35.0,
          33.9,
          34.1,
          34.25,
          33.8,
          33.55,
          33.7,
          33.95,
          33.7,
          33.55,
          33.35,
          33.0,
          32.55,
          31.9,
          32.55,
          32.0,
          31.65,
          31.3,
          32.0,
          32.45,
          32.6,
          33.15,
          32.95,
          33.15,
          33.25,
          33.45,
          34.2,
          34.3,
          34.05,
          34.0,
          33.55,
          32.35,
          32.65,
          32.45,
          32.25,
          32.7,
          32.3,
          32.6,
          32.9,
          31.95,
          31.45,
          31.5,
          31.5,
          31.0,
          30.2,
          29.4,
          28.2,
          28.35,
          29.0,
          29.0,
          27.5,
          28.2,
          28.2,
          28.15,
          27.7,
          27.05,
          26.6,
          25.1,
          23.9,
          24.0,
          23.45,
          21.95,
          21.3,
          20.35,
          21.75,
          22.4,
          22.05,
          21.75,
          21.75,
          22.85,
          22.75,
          22.7,
          23.0,
          22.8,
          22.6,
          22.4,
          21.7,
          21.4,
          20.55,
          21.6,
          22.35,
          22.7,
          21.6,
          21.25,
          21.05,
          21.25,
          20.5,
          20.55,
          19.6,
          20.5,
          21.7,
          21.8,
          21.75,
          22.8,
          23.3,
          22.75,
          22.05,
          22.95,
          23.6,
          23.4,
          23.15,
          23.05,
          22.45,
          22.8,
          23.4,
          23.35,
          23.75,
          23.4,
          23.15,
          23.5,
          23.6,
          23.5,
          23.3,
          22.95,
          23.05,
          24.65,
          24.9,
          25.6,
          25.5,
          25.65,
          25.7,
          25.25,
          25.65,
          25.5,
          25.3,
          25.6,
          25.65,
          26.35,
          25.8,
          25.6,
          25.65,
          25.5,
          25.2
        ],
        "low": [
          35.45,
          35.35,
          35.0,
          34.2,
          34.45,
          32.85,
          32.7,
          33.1,
          33.45,
          33.2,
          32.8,
          32.8,
          33.6,
          32.0,
          32.9,
          32.8,
          32.65,
          31.1,
          31.1,
          31.5,
          31.2,
          30.65,
          30.5,
          31.55,
          31.3,
          31.85,
          32.35,
          32.55,
          32.65,
          32.55,
          32.8,
          33.35,
          33.65,
          33.6,
          33.6,
          31.8,
          31.75,
          31.5,
          31.7,
          31.55,
          32.15,
          31.8,
          31.55,
          31.75,
          30.9,
          30.5,
          30.7,
          30.75,
          30.1,
          29.0,
          27.5,
          27.45,
          27.1,
          27.8,
          26.8,
          25.65,
          26.45,
          27.4,
          27.4,
          26.7,
          25.9,
          25.4,
          23.75,
          22.55,
          22.8,
          21.95,
          20.45,
          19.05,
          18.6,
          20.7,
          21.25,
          21.5,
          20.15,
          20.1,
          22.0,
          22.3,
          22.1,
          22.25,
          22.35,
          22.0,
          21.45,
          20.95,
          20.5,
          19.8,
          20.3,
          21.7,
          21.75,
          20.5,
          20.35,
          19.95,
          20.8,
          19.8,
          18.85,
          18.7,
          19.6,
          20.15,
          21.05,
          21.15,
          21.35,
          22.1,
          21.5,
          20.7,
          22.1,
          22.35,
          22.45,
          22.1,
          22.35,
          21.65,
          21.95,
          22.6,
          22.8,
          22.95,
          22.55,
          22.45,
          22.95,
          23.0,
          23.0,
          22.8,
          22.7,
          22.75,
          23.25,
          24.3,
          24.5,
          24.85,
          24.75,
          25.1,
          24.85,
          25.1,
          25.0,
          24.9,
          24.8,
          25.05,
          25.65,
          25.35,
          25.3,
          25.0,
          25.05,
          24.9
        ],
        "volume": [
          18652400,
          18823800,
          32928900,
          33959900,
          18450500,
          37421900,
          31169300,
          20057100,
          15739300,
          18494900,
          21150600,
          24415700,
          13866100,
          30479000,
          17789600,
          16458800,
          16919600,
          45276000,
          19790900,
          13257500,
          16727500,
          16477900,
          14911000,
          12219700,
          15987700,
          20978200,
          17471800,
          11148500,
          10884800,
          26734000,
          12852100,
          24584000,
          15498400,
          9921600,
          11344100,
          36749100,
          12694700,
          15931800,
          9023400,
          9156000,
          10191000,
          8729900,
          12151500,
          11478600,
          18745300,
          9624800,
          6924200,
          6467600,
          10461600,
          14856200,
          15852000,
          12024300,
          9902300,
          11530600,
          14386300,
          11114800,
          8593800,
          6523900,
          11168500,
          8979400,
          13505600,
          15245000,
          23972900,
          18391700,
          13068000,
          24214900,
          22537200,
          35190100,
          30818300,
          27100600,
          22221600,
          14328800,
          30824100,
          17952200,
          20351500,
          15266200,
          15040300,
          16390500,
          12812300,
          14727100,
          15929800,
          12083400,
          17162600,
          16797600,
          11493800,
          16071100,
          17446000,
          16680200,
          12472300,
          13262200,
          11245300,
          12492500,
          16894800,
          10840400,
          9689900,
          15465200,
          11596900,
          9786600,
          24887500,
          13808600,
          18789800,
          16869800,
          22132700,
          30049300,
          17725300,
          15935900,
          15739700,
          21170600,
          12469100,
          17150700,
          12876400,
          16982600,
          14305700,
          11691000,
          11199600,
          17945400,
          11581700,
          6665200,
          9279700,
          9170900,
          40238200,
          17762000,
          16701100,
          18607500,
          14338500,
          13127200,
          12665800,
          7664100,
          7863800,
          8967800,
          15664300,
          8391200,
          13671000,
          9490900,
          11130300,
          12921000,
          7722200,
          3181800
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "stock": [
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ]
      },
      "insider": {
        "id": [
          "026C228688",
          "026C228688",
          "011C091488",
          "011C091488",
          "026C228688",
          "026C228688",
          "026C228688",
          "026C228688",
          "026C228688",
          "011C091488",
          "021C056082",
          "021C056082",
          "026C228688",
          "011C091488",
          "011C091488",
          "039C531980",
          "039C531982",
          "026C110311",
          "039C531981",
          "011C091488",
          "068C238888",
          "026C110311",
          "068C238888",
          "068C205425",
          "039C531980",
          "011C091488",
          "033C669999",
          "033C669999",
          "011C091488",
          "011C091488",
          "039C531981",
          "033C669999",
          "033C017052",
          "039C531980",
          "039C531981",
          "033C669999",
          "033C669999",
          "011C091488",
          "021C056082",
          "011C091488",
          "021C056082",
          "021C056082",
          "011C091488",
          "011C091488",
          "011C091488",
          "011C091488",
          "021C056082",
          "079C012531",
          "079C012531",
          "079C012531",
          "011C091488",
          "011C091488",
          "079C012531",
          "011C091488",
          "079C012531",
          "011C091488",
          "079C012531",
          "079C012531",
          "039C531980",
          "039C531981",
          "033C017052",
          "033C017052",
          "039C531982",
          "033C017052",
          "011C091488",
          "021C056082",
          "021C056082",
          "033C017052",
          "033C017052",
          "011C091488",
          "033C017052",
          "068C238888",
          "068C205425",
          "011C091488",
          "026C110311",
          "068C238888",
          "033C017052",
          "039C531981",
          "039C531980",
          "021C056082",
          "033C017052",
          "026C110311",
          "021C056082",
          "021C056082",
          "033C017052",
          "033C017052",
          "021C056082",
          "021C056082",
          "021C056082",
          "021C056082",
          "021C056082",
          "021C056082",
          "021C056082",
          "021C056082",
          "021C056082"
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_15",
          "2022_02_18",
          "2022_02_24",
          "2022_03_02",
          "2022_03_03",
          "2022_03_09",
          "2022_03_11",
          "2022_03_15",
          "2022_03_16",
          "2022_03_28",
          "2022_04_07",
          "2022_04_08",
          "2022_04_08",
          "2022_04_08",
          "2022_04_08",
          "2022_04_08",
          "2022_04_12",
          "2022_04_12",
          "2022_04_15",
          "2022_04_18",
          "2022_04_18",
          "2022_05_11",
          "2022_05_11",
          "2022_05_12",
          "2022_05_12",
          "2022_05_13",
          "2022_05_13",
          "2022_05_13",
          "2022_05_16",
          "2022_05_16",
          "2022_05_16",
          "2022_05_16",
          "2022_05_17",
          "2022_05_17",
          "2022_05_18",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_06_02",
          "2022_06_06",
          "2022_06_07",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_13",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_23",
          "2022_06_24",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_04",
          "2022_07_04",
          "2022_07_04",
          "2022_07_04",
          "2022_07_04",
          "2022_07_05",
          "2022_07_05",
          "2022_07_05",
          "2022_07_05",
          "2022_07_05",
          "2022_07_07",
          "2022_07_08",
          "2022_07_12",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_21",
          "2022_07_25",
          "2022_07_25",
          "2022_07_26",
          "2022_07_26",
          "2022_08_03",
          "2022_08_04",
          "2022_08_11",
          "2022_08_19"
        ],
        "stock": [
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB",
          "STB"
        ],
        "side": [
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell"
        ],
        "TYPES": [
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider"
        ],
        "TradeValue": [
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          -3.9,
          -4.8,
          -15.1,
          -0.4,
          -1.7,
          -7.6,
          -0.3,
          -4.5,
          0.2,
          -0.2,
          -3.8,
          -2.0,
          4.3,
          -1.8,
          -26.6,
          -13.9,
          -2.2,
          -21.3,
          -0.7,
          -3.5,
          -8.2,
          -9.0,
          -0.2,
          -2.3,
          -5.6,
          0.0,
          0.0,
          -3.0,
          -3.9,
          -0.1,
          0.0,
          0.0,
          0.6,
          -0.8,
          1.3,
          1.3,
          0.1,
          -0.5
        ],
        "volume": [
          300000,
          300000,
          50000,
          180000,
          49700,
          49700,
          500000,
          500000,
          500000,
          200000,
          75000,
          40000,
          500000,
          100000,
          200000,
          164300,
          358000,
          992700,
          224000,
          150000,
          490200,
          120000,
          100000,
          510000,
          299000,
          50000,
          3329400,
          8254500,
          595400,
          500000,
          900000,
          479000,
          800000,
          180700,
          31000,
          1912300,
          1361500,
          100000,
          100000,
          200000,
          100000,
          100000,
          100000,
          100000,
          250000,
          200000,
          50000,
          252000,
          252000,
          252000,
          100000,
          100000,
          234000,
          200000,
          153000,
          50000,
          126000,
          207000,
          253500,
          799000,
          20000,
          80000,
          358000,
          15200,
          200000,
          7000,
          7000,
          170000,
          89200,
          200000,
          80000,
          1190500,
          620000,
          100000,
          952700,
          29700,
          150000,
          356000,
          390500,
          10000,
          100000,
          250000,
          200,
          100,
          130000,
          165600,
          3500,
          100,
          100,
          28300,
          33500,
          50000,
          50000,
          5000,
          20000
        ],
        "name": [
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng",
          "Tr\u01b0\u01a1ng Ng\u1ecdc Xuy\u1ebfn",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "Tr\u1ea7n Qu\u1ed1c Vi\u1ec7t",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu",
          "Tr\u1ea7n Qu\u1ed1c Vi\u1ec7t",
          "Nguy\u1ec5n Qu\u1ed1c B\u1ea3o",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "Tr\u01b0\u01a1ng Ng\u1ecdc Xuy\u1ebfn",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "Tr\u1ea7n Qu\u1ed1c Vi\u1ec7t",
          "Nguy\u1ec5n Qu\u1ed1c B\u1ea3o",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu",
          "Tr\u1ea7n Qu\u1ed1c Vi\u1ec7t",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng"
        ],
        "Value": [
          10.66,
          10.67,
          1.76,
          6.28,
          1.62,
          1.67,
          16.24,
          16.03,
          15.69,
          6.24,
          2.38,
          1.31,
          16.31,
          3.24,
          6.38,
          5.19,
          11.2,
          31.16,
          7.01,
          4.74,
          15.08,
          3.69,
          3.07,
          15.09,
          8.92,
          1.15,
          77.57,
          186.39,
          13.2,
          10.29,
          18.41,
          9.92,
          15.78,
          3.51,
          0.6,
          39.69,
          26.3,
          1.99,
          2.17,
          4.35,
          2.18,
          2.17,
          2.06,
          2.1,
          5.26,
          4.49,
          1.12,
          5.43,
          5.17,
          5.12,
          2.0,
          2.16,
          5.22,
          4.12,
          3.17,
          1.05,
          2.55,
          3.9,
          4.78,
          15.06,
          0.43,
          1.7,
          7.59,
          0.33,
          4.51,
          0.16,
          0.16,
          3.83,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN
        ],
        "i": [
          2365,
          2363,
          2362,
          2359,
          2353,
          2350,
          2339,
          5455,
          5449,
          5430,
          5424,
          5422,
          5417,
          5410,
          5318,
          5315,
          5313,
          5312,
          5314,
          5316,
          5309,
          5311,
          5305,
          5301,
          5302,
          5950,
          5946,
          5935,
          5943,
          5932,
          5930,
          5925,
          5921,
          5922,
          5924,
          5918,
          5904,
          5909,
          5900,
          5896,
          5893,
          5881,
          5870,
          5861,
          5850,
          5842,
          5155,
          5128,
          5115,
          5098,
          5105,
          5089,
          5082,
          5821,
          5801,
          5790,
          5775,
          5754,
          5753,
          5752,
          5716,
          5707,
          5705,
          3942,
          3919,
          3932,
          3924,
          3920,
          2998,
          2874,
          4566,
          4560,
          4562,
          412,
          4561,
          4551,
          4546,
          4545,
          4544,
          4552,
          4522,
          4516,
          5589,
          5593,
          5575,
          5567,
          5519,
          5504,
          5492,
          5485,
          5482,
          3297,
          3285,
          5673,
          5614
        ],
        "x": [
          234,
          235,
          236,
          237,
          240,
          243,
          247,
          251,
          252,
          256,
          258,
          260,
          261,
          269,
          277,
          278,
          278,
          278,
          278,
          278,
          279,
          279,
          282,
          283,
          283,
          298,
          298,
          299,
          299,
          300,
          300,
          300,
          301,
          301,
          301,
          301,
          302,
          302,
          303,
          303,
          304,
          305,
          306,
          306,
          307,
          308,
          309,
          314,
          316,
          317,
          317,
          318,
          319,
          321,
          323,
          324,
          325,
          326,
          326,
          326,
          329,
          330,
          330,
          331,
          332,
          332,
          332,
          332,
          333,
          334,
          336,
          336,
          336,
          336,
          336,
          337,
          337,
          337,
          337,
          337,
          339,
          340,
          342,
          342,
          343,
          344,
          349,
          351,
          351,
          352,
          352,
          358,
          359,
          364,
          370
        ],
        "size": [
          7623,
          7623,
          2746,
          5571,
          2738,
          2738,
          10556,
          10556,
          10556,
          5937,
          3385,
          2465,
          10556,
          3962,
          5937,
          5276,
          8521,
          16567,
          6361,
          5000,
          10421,
          4392,
          3962,
          10692,
          7607,
          2746,
          37583,
          70251,
          11824,
          10556,
          15522,
          10267,
          14356,
          5584,
          2193,
          25751,
          20470,
          3962,
          3962,
          5937,
          3962,
          3962,
          3962,
          3962,
          6805,
          5937,
          2746,
          6839,
          6839,
          6839,
          3962,
          3962,
          6533,
          5937,
          5058,
          2746,
          4517,
          6062,
          6864,
          14345,
          1824,
          3504,
          8521,
          1645,
          5937,
          1291,
          1291,
          5384,
          3719,
          5937,
          3504,
          18706,
          12141,
          3962,
          16120,
          2151,
          5000,
          8491,
          9006,
          1430,
          3962,
          6805,
          840,
          825,
          4599,
          5301,
          1102,
          825,
          825,
          2106,
          2270,
          2746,
          2746,
          1188,
          1824
        ],
        "hover_name": [
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_02_07",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_02_08",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_02_09",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>180.0 k c\u1ed5 phi\u1ebfu<br>2022_02_10",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p<br>49.7 k c\u1ed5 phi\u1ebfu<br>2022_02_15",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p<br>49.7 k c\u1ed5 phi\u1ebfu<br>2022_02_18",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_02_24",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_03_02",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_03_03",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_03_09",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>75.0 k c\u1ed5 phi\u1ebfu<br>2022_03_11",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>40.0 k c\u1ed5 phi\u1ebfu<br>2022_03_15",
          "\u0110\u1eb7ng \u0110\u00ecnh Hi\u1ec7p<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_03_16",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_03_28",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_04_07",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng<br>164.3 k c\u1ed5 phi\u1ebfu<br>2022_04_08",
          "Tr\u01b0\u01a1ng Ng\u1ecdc Xuy\u1ebfn<br>358.0 k c\u1ed5 phi\u1ebfu<br>2022_04_08",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu<br>992.7 k c\u1ed5 phi\u1ebfu<br>2022_04_08",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu<br>224.0 k c\u1ed5 phi\u1ebfu<br>2022_04_08",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_04_08",
          "Tr\u1ea7n Qu\u1ed1c Vi\u1ec7t<br>490.2 k c\u1ed5 phi\u1ebfu<br>2022_04_12",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu<br>120.0 k c\u1ed5 phi\u1ebfu<br>2022_04_12",
          "Tr\u1ea7n Qu\u1ed1c Vi\u1ec7t<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_04_15",
          "Nguy\u1ec5n Qu\u1ed1c B\u1ea3o<br>510.0 k c\u1ed5 phi\u1ebfu<br>2022_04_18",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng<br>299.0 k c\u1ed5 phi\u1ebfu<br>2022_04_18",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_05_11",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t<br>3,329.4 k c\u1ed5 phi\u1ebfu<br>2022_05_11",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t<br>8,254.5 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>595.4 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu<br>900.0 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t<br>479.0 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>800.0 k c\u1ed5 phi\u1ebfu<br>2022_05_16",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng<br>180.7 k c\u1ed5 phi\u1ebfu<br>2022_05_16",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu<br>31.0 k c\u1ed5 phi\u1ebfu<br>2022_05_16",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t<br>1,912.3 k c\u1ed5 phi\u1ebfu<br>2022_05_16",
          "Ng\u00e2n H\u00e0ng Th\u01b0\u01a1ng M\u1ea1i C\u1ed5 Ph\u1ea7n B\u01b0u \u0110i\u1ec7n Li\u00ean Vi\u1ec7t<br>1,361.5 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_18",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_05_18",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_05_25",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_05_26",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh<br>252.0 k c\u1ed5 phi\u1ebfu<br>2022_06_02",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh<br>252.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh<br>252.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh<br>234.0 k c\u1ed5 phi\u1ebfu<br>2022_06_09",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_06_13",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh<br>153.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh<br>126.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "\u0110\u1eb7ng Th\u1ecb Ch\u00ednh<br>207.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng<br>253.5 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu<br>799.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>80.0 k c\u1ed5 phi\u1ebfu<br>2022_06_24",
          "Tr\u01b0\u01a1ng Ng\u1ecdc Xuy\u1ebfn<br>358.0 k c\u1ed5 phi\u1ebfu<br>2022_06_24",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>15.2 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>7.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>7.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>170.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>89.2 k c\u1ed5 phi\u1ebfu<br>2022_06_29",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_06_30",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>80.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "Tr\u1ea7n Qu\u1ed1c Vi\u1ec7t<br>1,190.5 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "Nguy\u1ec5n Qu\u1ed1c B\u1ea3o<br>620.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "NGUY\u1ec4N TH\u1eca THANH TUY\u1ec0N<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu<br>952.7 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "Tr\u1ea7n Qu\u1ed1c Vi\u1ec7t<br>29.7 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu<br>356.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Nguy\u1ec5n Th\u1ecb Mai Ph\u01b0\u1ee3ng<br>390.5 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "Nguy\u1ec5n Ph\u00f9ng C\u1ea9m Thu<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_07_08",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>0.2 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>0.1 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>130.0 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "Nguy\u1ec5n Huy\u1ec1n Tr\u00e2m<br>165.6 k c\u1ed5 phi\u1ebfu<br>2022_07_14",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>3.5 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>0.1 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>0.1 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>28.3 k c\u1ed5 phi\u1ebfu<br>2022_07_26",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>33.5 k c\u1ed5 phi\u1ebfu<br>2022_07_26",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>5.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "\u0110\u1eb7ng Kh\u1eafc C\u01b0\u1eddng<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19"
        ]
      },
      "vn30_index": {
        "close": [
          1541.25,
          1550.46,
          1552.44,
          1548.95,
          1545.92,
          1505.07,
          1527.36,
          1521.47,
          1540.51,
          1531.47,
          1533.4,
          1532.36,
          1538.83,
          1522.04,
          1526.5,
          1517.18,
          1520.12,
          1498.61,
          1522.49,
          1525.34,
          1509.12,
          1490.17,
          1489.25,
          1490.24,
          1477.14,
          1461.1,
          1468.89,
          1472.69,
          1469.92,
          1476.94,
          1502.67,
          1513.4,
          1505.59,
          1497.44,
          1498.36,
          1484.16,
          1500.57,
          1500.23,
          1508.53,
          1542.47,
          1548.04,
          1539.2,
          1557.11,
          1541.96,
          1524.31,
          1507.2,
          1525.39,
          1518.01,
          1493.74,
          1468.25,
          1440.61,
          1435.5,
          1426.87,
          1444.32,
          1366.39,
          1396.9,
          1402.03,
          1400.88,
          1417.31,
          1389.59,
          1404.88,
          1373.21,
          1314.04,
          1345.46,
          1349.82,
          1279.76,
          1223.76,
          1215.08,
          1279.55,
          1286.41,
          1283.55,
          1282.51,
          1255.35,
          1272.71,
          1310.7,
          1309.5,
          1335.68,
          1342.87,
          1332.59,
          1335.49,
          1325.49,
          1327.4,
          1327.04,
          1324.37,
          1342.03,
          1342.92,
          1325.69,
          1260.85,
          1261.16,
          1253.09,
          1280.37,
          1258.03,
          1225.56,
          1224.54,
          1227.18,
          1240.58,
          1235.47,
          1256.67,
          1273.41,
          1273.4,
          1248.92,
          1252.24,
          1248.37,
          1242.05,
          1211.94,
          1229.23,
          1231.54,
          1209.02,
          1219.44,
          1216.94,
          1221.94,
          1220.14,
          1214.28,
          1211.7,
          1225.62,
          1235.25,
          1228.84,
          1222.6,
          1218.49,
          1219.43,
          1236.14,
          1232.0,
          1256.25,
          1265.97,
          1269.83,
          1277.73,
          1272.06,
          1276.7,
          1281.44,
          1277.15,
          1272.33,
          1280.96,
          1293.79,
          1295.1,
          1300.4,
          1299.93,
          1294.93,
          1291.06
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ],
        "color": [
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index"
        ]
      }
    },
    "TCB": {
      "prices": {
        "t": [
          1644192000000000000,
          1644278400000000000,
          1644364800000000000,
          1644451200000000000,
          1644537600000000000,
          1644796800000000000,
          1644883200000000000,
          1644969600000000000,
          1645056000000000000,
          1645142400000000000,
          1645401600000000000,
          1645488000000000000,
          1645574400000000000,
          1645660800000000000,
          1645747200000000000,
          1646006400000000000,
          1646092800000000000,
          1646179200000000000,
          1646265600000000000,
          1646352000000000000,
          1646611200000000000,
          1646697600000000000,
          1646784000000000000,
          1646870400000000000,
          1646956800000000000,
          1647216000000000000,
          1647302400000000000,
          1647388800000000000,
          1647475200000000000,
          1647561600000000000,
          1647820800000000000,
          1647907200000000000,
          1647993600000000000,
          1648080000000000000,
          1648166400000000000,
          1648425600000000000,
          1648512000000000000,
          1648598400000000000,
          1648684800000000000,
          1648771200000000000,
          1649030400000000000,
          1649116800000000000,
          1649203200000000000,
          1649289600000000000,
          1649376000000000000,
          1649721600000000000,
          1649808000000000000,
          1649894400000000000,
          1649980800000000000,
          1650240000000000000,
          1650326400000000000,
          1650412800000000000,
          1650499200000000000,
          1650585600000000000,
          1650844800000000000,
          1650931200000000000,
          1651017600000000000,
          1651104000000000000,
          1651190400000000000,
          1651622400000000000,
          1651708800000000000,
          1651795200000000000,
          1652054400000000000,
          1652140800000000000,
          1652227200000000000,
          1652313600000000000,
          1652400000000000000,
          1652659200000000000,
          1652745600000000000,
          1652832000000000000,
          1652918400000000000,
          1653004800000000000,
          1653264000000000000,
          1653350400000000000,
          1653436800000000000,
          1653523200000000000,
          1653609600000000000,
          1653868800000000000,
          1653955200000000000,
          1654041600000000000,
          1654128000000000000,
          1654214400000000000,
          1654473600000000000,
          1654560000000000000,
          1654646400000000000,
          1654732800000000000,
          1654819200000000000,
          1655078400000000000,
          1655164800000000000,
          1655251200000000000,
          1655337600000000000,
          1655424000000000000,
          1655683200000000000,
          1655769600000000000,
          1655856000000000000,
          1655942400000000000,
          1656028800000000000,
          1656288000000000000,
          1656374400000000000,
          1656460800000000000,
          1656547200000000000,
          1656633600000000000,
          1656892800000000000,
          1656979200000000000,
          1657065600000000000,
          1657152000000000000,
          1657238400000000000,
          1657497600000000000,
          1657584000000000000,
          1657670400000000000,
          1657756800000000000,
          1657843200000000000,
          1658102400000000000,
          1658188800000000000,
          1658275200000000000,
          1658361600000000000,
          1658448000000000000,
          1658707200000000000,
          1658793600000000000,
          1658880000000000000,
          1658966400000000000,
          1659052800000000000,
          1659312000000000000,
          1659398400000000000,
          1659484800000000000,
          1659571200000000000,
          1659657600000000000,
          1659916800000000000,
          1660003200000000000,
          1660089600000000000,
          1660176000000000000,
          1660262400000000000,
          1660521600000000000,
          1660608000000000000,
          1660694400000000000,
          1660780800000000000,
          1660867200000000000,
          1661126400000000000
        ],
        "close": [
          52.8,
          53.8,
          53.6,
          53.6,
          53.7,
          51.5,
          51.8,
          51.6,
          52.2,
          51.6,
          51.5,
          51.4,
          51.7,
          50.5,
          50.7,
          50.4,
          50.3,
          49.2,
          49.65,
          49.95,
          49.25,
          49.0,
          49.1,
          49.25,
          48.9,
          48.5,
          48.6,
          49.05,
          49.0,
          49.2,
          49.45,
          49.9,
          49.6,
          49.3,
          49.25,
          48.8,
          48.9,
          49.15,
          49.55,
          50.3,
          49.65,
          49.05,
          49.9,
          49.3,
          48.85,
          48.0,
          48.0,
          47.3,
          45.6,
          44.85,
          43.7,
          43.05,
          43.6,
          44.1,
          41.05,
          41.5,
          41.6,
          42.3,
          44.0,
          42.0,
          42.6,
          41.5,
          38.6,
          38.7,
          39.0,
          36.3,
          33.8,
          32.6,
          34.85,
          36.05,
          35.8,
          35.7,
          34.85,
          35.25,
          36.5,
          36.7,
          37.45,
          37.75,
          37.15,
          36.85,
          36.4,
          36.45,
          36.05,
          36.2,
          37.25,
          37.4,
          37.95,
          36.0,
          35.75,
          35.7,
          36.05,
          34.5,
          33.0,
          33.0,
          34.8,
          35.95,
          35.5,
          35.55,
          36.7,
          36.75,
          35.55,
          36.0,
          36.35,
          37.75,
          37.6,
          37.8,
          37.55,
          35.6,
          36.1,
          36.1,
          35.95,
          36.1,
          36.3,
          36.1,
          36.3,
          36.85,
          37.1,
          36.8,
          36.6,
          36.6,
          37.3,
          37.9,
          38.6,
          38.3,
          38.15,
          38.4,
          38.4,
          39.2,
          39.15,
          38.8,
          38.5,
          38.8,
          39.1,
          39.0,
          39.3,
          39.1,
          39.0,
          38.95
        ],
        "open": [
          53.7,
          52.8,
          54.0,
          53.6,
          53.5,
          53.2,
          51.6,
          52.2,
          51.6,
          51.7,
          51.6,
          51.0,
          51.7,
          51.5,
          51.0,
          50.5,
          50.7,
          50.1,
          49.5,
          49.75,
          49.5,
          49.0,
          49.0,
          49.8,
          49.2,
          48.8,
          48.6,
          48.8,
          49.45,
          49.15,
          49.25,
          49.55,
          49.9,
          49.6,
          49.4,
          49.15,
          48.9,
          48.9,
          49.5,
          49.55,
          50.4,
          49.4,
          48.9,
          49.9,
          49.4,
          48.7,
          48.4,
          48.05,
          47.3,
          46.0,
          45.0,
          43.7,
          43.0,
          44.0,
          44.2,
          40.6,
          41.5,
          41.65,
          42.3,
          43.5,
          42.85,
          41.7,
          41.2,
          37.45,
          39.0,
          39.0,
          36.3,
          35.1,
          32.6,
          35.7,
          35.4,
          35.8,
          35.8,
          34.8,
          35.85,
          36.7,
          36.9,
          38.0,
          37.45,
          37.0,
          36.9,
          36.35,
          36.45,
          36.0,
          36.3,
          37.25,
          37.0,
          37.0,
          35.3,
          35.7,
          35.9,
          35.05,
          34.5,
          33.1,
          33.75,
          34.8,
          35.95,
          35.55,
          35.5,
          36.2,
          36.55,
          35.6,
          36.25,
          36.6,
          37.5,
          37.45,
          37.95,
          37.05,
          35.8,
          36.15,
          35.9,
          36.1,
          36.3,
          36.25,
          36.5,
          36.6,
          37.3,
          36.85,
          36.8,
          36.4,
          36.9,
          37.5,
          37.85,
          38.4,
          38.3,
          38.25,
          38.4,
          38.5,
          39.4,
          38.85,
          39.1,
          38.5,
          39.0,
          39.05,
          39.05,
          39.1,
          39.05,
          38.85
        ],
        "high": [
          53.8,
          54.3,
          54.9,
          54.2,
          53.8,
          53.2,
          52.1,
          52.2,
          52.5,
          52.0,
          51.7,
          51.7,
          52.2,
          51.9,
          51.3,
          50.9,
          50.7,
          50.1,
          49.9,
          50.4,
          49.6,
          49.5,
          49.35,
          49.8,
          49.2,
          48.9,
          49.0,
          49.45,
          49.5,
          49.4,
          49.55,
          50.3,
          50.2,
          49.8,
          49.45,
          49.15,
          49.15,
          49.9,
          49.7,
          50.4,
          50.6,
          49.5,
          49.9,
          50.3,
          49.4,
          48.9,
          48.45,
          48.35,
          47.3,
          46.25,
          45.45,
          44.2,
          44.0,
          44.75,
          44.3,
          42.0,
          42.2,
          42.8,
          44.0,
          43.65,
          42.85,
          42.3,
          41.4,
          38.85,
          39.45,
          39.0,
          36.8,
          35.8,
          34.85,
          36.9,
          36.4,
          36.45,
          36.2,
          35.25,
          36.65,
          37.25,
          37.45,
          38.0,
          37.6,
          37.7,
          37.0,
          36.85,
          36.8,
          36.2,
          37.45,
          37.55,
          38.9,
          37.25,
          36.3,
          36.15,
          36.5,
          35.4,
          35.2,
          33.8,
          35.3,
          36.4,
          36.1,
          35.95,
          36.95,
          37.15,
          37.1,
          36.25,
          36.7,
          38.25,
          38.4,
          38.0,
          38.0,
          37.3,
          36.1,
          36.8,
          36.3,
          36.55,
          36.85,
          36.3,
          36.6,
          36.9,
          37.9,
          37.1,
          37.05,
          36.6,
          37.5,
          37.9,
          38.8,
          38.6,
          38.4,
          38.85,
          38.8,
          39.65,
          39.65,
          39.05,
          39.3,
          38.8,
          39.35,
          39.2,
          39.35,
          39.5,
          39.2,
          39.1
        ],
        "low": [
          52.8,
          52.8,
          53.6,
          53.1,
          53.0,
          51.5,
          51.3,
          51.5,
          51.6,
          51.5,
          50.8,
          50.6,
          51.4,
          49.8,
          50.5,
          50.2,
          50.1,
          49.2,
          49.15,
          49.5,
          49.1,
          48.8,
          48.9,
          49.25,
          48.7,
          48.3,
          48.5,
          48.8,
          49.0,
          49.1,
          49.1,
          49.5,
          49.6,
          49.3,
          49.15,
          48.35,
          48.65,
          48.9,
          49.3,
          49.4,
          49.65,
          49.05,
          48.7,
          49.3,
          48.8,
          47.9,
          47.9,
          47.15,
          45.6,
          44.8,
          43.5,
          43.05,
          42.9,
          43.55,
          41.05,
          38.85,
          40.9,
          41.65,
          42.2,
          42.0,
          41.4,
          41.5,
          38.6,
          37.45,
          38.45,
          36.3,
          33.8,
          32.6,
          32.55,
          35.35,
          35.2,
          35.35,
          34.85,
          34.4,
          35.5,
          36.55,
          36.85,
          37.45,
          37.15,
          36.65,
          36.0,
          36.05,
          36.0,
          35.0,
          36.15,
          37.05,
          36.95,
          35.95,
          35.2,
          35.05,
          35.6,
          33.85,
          33.0,
          32.8,
          33.45,
          34.55,
          35.5,
          35.5,
          35.35,
          36.2,
          35.55,
          34.85,
          36.05,
          36.35,
          37.2,
          37.0,
          37.35,
          35.5,
          35.65,
          36.0,
          35.85,
          36.1,
          36.1,
          35.75,
          36.2,
          36.3,
          37.05,
          36.75,
          36.55,
          36.4,
          36.8,
          37.15,
          37.6,
          38.05,
          37.85,
          38.2,
          38.3,
          38.5,
          39.0,
          38.7,
          38.35,
          38.25,
          38.85,
          38.95,
          39.0,
          38.95,
          38.95,
          38.8
        ],
        "volume": [
          9809900,
          16481000,
          17391000,
          11224900,
          7847800,
          18771500,
          8584300,
          6174400,
          7301000,
          6543700,
          8654200,
          10561000,
          6188300,
          15534300,
          6665900,
          7106100,
          6945900,
          17893700,
          7391900,
          7022700,
          8122400,
          8766400,
          6957800,
          4387100,
          6422600,
          5161200,
          3829700,
          4542000,
          3249200,
          2909700,
          3921900,
          5953800,
          3409900,
          3214000,
          3175400,
          6597500,
          4272400,
          5598900,
          5248700,
          9575500,
          9775600,
          5803000,
          9012200,
          5971000,
          7030100,
          8278800,
          6722300,
          8324100,
          18618800,
          11505300,
          7232900,
          7909800,
          10019500,
          6684800,
          12317000,
          11120700,
          6154800,
          4729300,
          9795500,
          6384600,
          4804200,
          4889500,
          12361700,
          10236300,
          4071500,
          7306300,
          15203300,
          11689600,
          9447600,
          8689300,
          5692200,
          5443200,
          5290600,
          4270100,
          6709700,
          4687800,
          5777300,
          4224300,
          5119800,
          3829000,
          3533700,
          3040200,
          3845100,
          4640800,
          6456800,
          5776000,
          8194200,
          6673500,
          3740800,
          5449100,
          5916100,
          4994900,
          4004000,
          4900200,
          6208200,
          4708100,
          2969100,
          4282300,
          5342500,
          3048300,
          3566500,
          3473300,
          3148000,
          10888900,
          6055300,
          3567400,
          4058400,
          8452700,
          3916500,
          3156800,
          2902000,
          4094300,
          2990600,
          2566900,
          4923800,
          5488700,
          4424700,
          2340300,
          2794900,
          1654100,
          5604300,
          5486300,
          6875400,
          4609900,
          4944700,
          4630800,
          3914900,
          7998700,
          4306400,
          3001500,
          4920200,
          2817400,
          4366600,
          3042300,
          4459300,
          5337200,
          2566900,
          1005400
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "stock": [
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ]
      },
      "insider": {
        "id": [
          "068C009318",
          "081C065178",
          "068C009318",
          "081C125886",
          "068C009318",
          "105C648947",
          "068C008778",
          "081C009639",
          "068C008778",
          "057C809912",
          "105C648947",
          "081C125886",
          "105C648947",
          "068C005250",
          "068C022188",
          "105C648947",
          "081C125886",
          "081C009639",
          "105C648947",
          "081C001973",
          "068C022188",
          "081C065178",
          "068C005959",
          "105C648947",
          "105C648947",
          "068C005959",
          "105C648947",
          "068C005250",
          "068C005250",
          "068C008984",
          "068C008984",
          "068C005097",
          "068C005097",
          "068C010768",
          "068C010768",
          "048C059808",
          "048C057709",
          "048C050211",
          "048C057580",
          "068C005250",
          "105C648947",
          "068C005250",
          "068C008984",
          "105C648947",
          "068C008984",
          "058C476866",
          "011C390773",
          "011C390773",
          "011C391081",
          "011C391081",
          "011C399986",
          "011C399986",
          "039C521023",
          "057C778780",
          "039C521024",
          "039C521024",
          "057C010916",
          "057C013997",
          "039C521023",
          "039C541154",
          "048C050211",
          "077C280581",
          "048C057709",
          "011C390773",
          "010C012288",
          "011C109300",
          "068C009318",
          "057C010916",
          "068C009318",
          "077C280750",
          "057C809912",
          "077C280581",
          "057C013997",
          "057C778780",
          "068C009318",
          "068C008778",
          "021C457564",
          "021C457564",
          "021C842011",
          "021C842011",
          "079C326747",
          "079C326747",
          "079C126748",
          "079C126748",
          "068C008778",
          "079C126747",
          "079C126747",
          "068C005959",
          "068C005959",
          "068C022188",
          "003C326222",
          "068C022188",
          "022C009755",
          "022C009755",
          "077C280581",
          "048C059808",
          "081C001973",
          "058C476866",
          "081C001973",
          "077C280581",
          "081C009639",
          "003C880814",
          "011C109300",
          "093C032390",
          "093C032390",
          "068C005097",
          "093C040750",
          "093C040750",
          "077C280581",
          "077C280581",
          "093C048780",
          "093C048780",
          "068C005097",
          "011C399986",
          "011C390773",
          "011C391081",
          "011C399986",
          "011C390773",
          "068C005097",
          "011C391081",
          "093C048780",
          "011C064814",
          "093C032390",
          "068C005959",
          "093C040750",
          "081C125886",
          "081C009639",
          "081C001973",
          "081C065178",
          "068C005250",
          "048C059808",
          "068C005250",
          "003C326222",
          "011C011583",
          "011C011583",
          "068C005250",
          "011C064814",
          "011C064814",
          "068C008984",
          "068C008984",
          "068C009318",
          "068C009318",
          "068C008778",
          "068C008778",
          "011C367145",
          "068C008778",
          "068C005959",
          "068C106198",
          "068C005959",
          "081C001973",
          "093C040750",
          "081C065178",
          "022C025643",
          "022C009755",
          "093C048780",
          "022C009755",
          "093C032390",
          "068C106198",
          "010C012288",
          "010C004818",
          "006C196879",
          "081C125886",
          "068C022188",
          "057C778780",
          "068C022188",
          "068C005097",
          "081C065178",
          "081C125886",
          "081C009639",
          "081C001973",
          "068C005097",
          "081C001973",
          "081C009639",
          "081C065178",
          "081C125886"
        ],
        "day": [
          "2022_05_11",
          "2022_05_12",
          "2022_05_12",
          "2022_05_12",
          "2022_05_13",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_17",
          "2022_05_17",
          "2022_05_17",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_19",
          "2022_05_19",
          "2022_05_20",
          "2022_05_20",
          "2022_05_20",
          "2022_05_20",
          "2022_05_20",
          "2022_05_20",
          "2022_05_23",
          "2022_05_23",
          "2022_05_23",
          "2022_05_24",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_31",
          "2022_06_01",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_10",
          "2022_06_10",
          "2022_06_10",
          "2022_06_14",
          "2022_06_15",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_17",
          "2022_06_17",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_21",
          "2022_06_21",
          "2022_06_21",
          "2022_06_21",
          "2022_06_21",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_23",
          "2022_06_23",
          "2022_06_23",
          "2022_06_23",
          "2022_06_23",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_27",
          "2022_06_27",
          "2022_06_27",
          "2022_06_27",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_05",
          "2022_07_05",
          "2022_07_06",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_13",
          "2022_07_13",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_20",
          "2022_07_20",
          "2022_07_20",
          "2022_07_20",
          "2022_07_20",
          "2022_07_20",
          "2022_07_21",
          "2022_07_21",
          "2022_07_21",
          "2022_07_21",
          "2022_07_21",
          "2022_07_25",
          "2022_07_25",
          "2022_07_25",
          "2022_07_25",
          "2022_07_25",
          "2022_07_25",
          "2022_07_26",
          "2022_07_26",
          "2022_07_27",
          "2022_07_27",
          "2022_07_27",
          "2022_07_27",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_10",
          "2022_08_10",
          "2022_08_10",
          "2022_08_10",
          "2022_08_10",
          "2022_08_10",
          "2022_08_10",
          "2022_08_11",
          "2022_08_11",
          "2022_08_11",
          "2022_08_11",
          "2022_08_12",
          "2022_08_12",
          "2022_08_12",
          "2022_08_15",
          "2022_08_18",
          "2022_08_18",
          "2022_08_18",
          "2022_08_18",
          "2022_08_18",
          "2022_08_19",
          "2022_08_19",
          "2022_08_19",
          "2022_08_19",
          "2022_08_19"
        ],
        "stock": [
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB",
          "TCB"
        ],
        "side": [
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell"
        ],
        "TYPES": [
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider"
        ],
        "TradeValue": [
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          99.0,
          -99.0,
          99.0,
          -99.0,
          99.0,
          -99.0,
          -3.3,
          7.6,
          29.7,
          -28.7,
          -3.8,
          -3.8,
          5.6,
          -32.0,
          -3.6,
          9.9,
          -1.6,
          -9.9,
          5.3,
          -4.0,
          23.3,
          -25.2,
          22.3,
          -10.8,
          -41.7,
          10.8,
          -26.5,
          93.5,
          17.0,
          24.9,
          82.9,
          -84.2,
          84.2,
          -82.9,
          -37.3,
          36.7,
          -36.7,
          37.1,
          29.0,
          37.3,
          -37.1,
          17.6,
          20.9,
          37.8,
          -3.4,
          33.8,
          9.4,
          -9.4,
          -9.4,
          -6.4,
          1.8,
          1.8,
          -0.8,
          9.4,
          0.8,
          0.0,
          -0.7,
          23.4,
          -15.6,
          16.0,
          7.8,
          -23.4,
          -7.8,
          7.8,
          -7.8,
          15.6,
          15.5,
          -38.1,
          38.1,
          -38.1,
          38.1,
          -38.1,
          25.4,
          38.1,
          -7.9,
          56.7,
          -7.9,
          -56.7,
          15.8,
          10.2,
          7.2,
          9.3,
          7.2,
          12.5,
          -34.0,
          15.7,
          0.2,
          -96.8,
          96.8,
          8.3,
          96.8,
          -96.8,
          28.6,
          19.6,
          28.6,
          22.2,
          23.7,
          25.3,
          75.3,
          21.5,
          30.6,
          -41.3,
          18.8,
          3.0,
          -0.8,
          -3.0,
          1.6,
          -28.7,
          -0.8,
          0.8,
          -0.8,
          40.6,
          13.9,
          3.1,
          96.2,
          16.4,
          14.7,
          -16.4,
          22.7,
          35.2,
          -0.5,
          -0.5,
          -0.1,
          0.1,
          19.5,
          0.0,
          -0.1,
          1.7,
          -0.5
        ],
        "volume": [
          390000,
          61000,
          1000000,
          61000,
          1800000,
          57000,
          1300000,
          353100,
          719700,
          650200,
          6000,
          297100,
          48000,
          277000,
          310000,
          18000,
          6000,
          4000,
          17900,
          4000,
          800000,
          6000,
          950000,
          11000,
          1000,
          830000,
          9000,
          970000,
          540000,
          750000,
          600000,
          580000,
          870000,
          933400,
          800000,
          375000,
          200000,
          100000,
          75000,
          519700,
          2000,
          790000,
          875800,
          2500,
          800000,
          31500,
          3000000,
          3000000,
          3000000,
          3000000,
          3000000,
          3000000,
          100000,
          230000,
          900000,
          870700,
          115000,
          115000,
          170000,
          970000,
          110000,
          300000,
          50000,
          300000,
          160000,
          120000,
          669600,
          702000,
          620000,
          300000,
          1160200,
          300000,
          738000,
          2600200,
          480000,
          700000,
          2333000,
          2369000,
          2369000,
          2333000,
          1015000,
          1000000,
          1000000,
          1012000,
          790300,
          1015000,
          1012000,
          478700,
          587000,
          1000000,
          90000,
          900000,
          263400,
          263400,
          263400,
          178800,
          50000,
          50000,
          22500,
          263400,
          22500,
          100,
          20000,
          645000,
          430000,
          440000,
          215000,
          645000,
          215000,
          215000,
          215000,
          430000,
          430000,
          1050000,
          1050000,
          1050000,
          1050000,
          1050000,
          700000,
          1050000,
          215000,
          1540000,
          215000,
          1540000,
          430000,
          278000,
          196500,
          254000,
          196500,
          340000,
          925000,
          430000,
          5000,
          2645000,
          2645000,
          227200,
          2645000,
          2645000,
          766900,
          520000,
          740000,
          580000,
          620000,
          660000,
          1960000,
          560000,
          780000,
          1054000,
          480000,
          78000,
          20000,
          78000,
          40000,
          740000,
          20000,
          20000,
          20000,
          1054000,
          360000,
          80000,
          2500000,
          422000,
          380000,
          422000,
          580000,
          900000,
          13500,
          13500,
          2000,
          2000,
          500000,
          1000,
          2000,
          43000,
          13000
        ],
        "name": [
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "Nguy\u1ec5n Th\u00fay Mai",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean",
          "Nguy\u1ec5n Th\u00fay Mai",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "Nguy\u1ec5n Th\u00fay Mai",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Tr\u1ea7n \u0110\u1ee9c Anh",
          "Nguy\u1ec5n Th\u00fay Mai",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O",
          "Nguy\u1ec5n Th\u00fay Mai",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "Tr\u1ea7n \u0110\u1ee9c Anh",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH",
          "Th\u00e1i Quang Huy",
          "Nguy\u1ec5n Th\u00fay Mai",
          "Nguy\u1ec5n Th\u00fay Mai",
          "Th\u00e1i Quang Huy",
          "Nguy\u1ec5n Th\u00fay Mai",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Nguy\u1ec5n Th\u00fay Mai",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "Nguy\u1ec5n Th\u00fay Mai",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Ho\u00e0ng Trung Th\u00e0nh",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "Th\u00e1i Quang Huy",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "Ho\u00e0ng Trung Th\u00e0nh",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "Ho\u00e0ng Trung Th\u00e0nh",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Th\u00e1i Quang Huy",
          "Th\u00e1i Quang Huy",
          "TR\u00c2\u0300N \u0110\u01af\u0301C ANH",
          "TR\u00c2\u0300N \u0110\u01af\u0301C ANH",
          "TR\u00c2\u0300N V\u0102N THI\u0323NH",
          "TR\u00c2\u0300N V\u0102N THI\u0323NH",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "NGUY\u00ca\u0303N L\u00ca \u0110\u01af\u0301C MINH",
          "NGUY\u00ca\u0303N L\u00ca \u0110\u01af\u0301C MINH",
          "Th\u00e1i Quang Huy",
          "Th\u00e1i Quang Huy",
          "Tr\u1ea7n \u0110\u1ee9c Anh",
          "Ph\u1ea1m Hi\u1ec1n Trang",
          "Tr\u1ea7n \u0110\u1ee9c Anh",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O",
          "Ho\u00e0ng Trung Th\u00e0nh",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "Ho\u00e0ng Trung Th\u00e0nh",
          "Ho\u00e0ng Trung Th\u00e0nh",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh",
          "TR\u1ea6N V\u0102N TH\u1ecaNH",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean",
          "Th\u00e1i Quang Huy",
          "Ho\u00e0ng Trung Th\u00e0nh",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "Ph\u1ea1m Hi\u1ec1n Trang",
          "TR\u1ea6N QU\u1ed0C ANH",
          "TR\u1ea6N QU\u1ed0C ANH",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh",
          "TR\u1ea6N V\u0102N TH\u1ecaNH",
          "TR\u1ea6N V\u0102N TH\u1ecaNH",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "TR\u1ea6N \u0110\u1ee8C ANH",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t",
          "Th\u00e1i Quang Huy",
          "Tr\u1ea7n Th\u1ecb Thanh Hi\u00ean",
          "Th\u00e1i Quang Huy",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "Ho\u00e0ng Trung Th\u00e0nh",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean",
          "Tr\u1ea7n Th\u1ecb Thanh Hi\u00ean",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m",
          null,
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "Tr\u1ea7n \u0110\u1ee9c Anh",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh",
          "Tr\u1ea7n \u0110\u1ee9c Anh",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH",
          "HO\u00c0NG TRUNG TH\u00c0NH",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "Tr\u1ea7n Qu\u1ed1c Anh",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH",
          "HO\u00c0NG TRUNG TH\u00c0NH"
        ],
        "Value": [
          15.16,
          2.31,
          37.64,
          2.31,
          63.39,
          1.96,
          44.78,
          11.51,
          24.12,
          21.3,
          0.2,
          9.69,
          1.76,
          9.89,
          11.18,
          0.64,
          0.21,
          0.14,
          0.64,
          0.14,
          28.79,
          0.21,
          33.65,
          0.39,
          0.04,
          28.95,
          0.31,
          35.18,
          19.92,
          27.86,
          22.3,
          20.91,
          30.87,
          34.77,
          29.92,
          14.23,
          7.59,
          3.8,
          2.85,
          18.58,
          0.07,
          28.2,
          31.57,
          0.09,
          27.6,
          1.09,
          99.0,
          99.0,
          99.0,
          99.0,
          99.0,
          99.0,
          3.3,
          7.59,
          29.7,
          28.73,
          3.79,
          3.79,
          5.61,
          32.01,
          3.63,
          9.9,
          1.65,
          9.9,
          5.28,
          3.96,
          23.3,
          25.24,
          22.29,
          10.79,
          41.71,
          10.79,
          26.53,
          93.48,
          17.04,
          24.88,
          82.94,
          84.22,
          84.22,
          82.94,
          37.25,
          36.7,
          36.7,
          37.14,
          29.0,
          37.25,
          37.14,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN
        ],
        "i": [
          5949,
          5945,
          5941,
          5937,
          5929,
          5934,
          5920,
          5905,
          5913,
          5914,
          5917,
          5906,
          5902,
          5890,
          5889,
          5894,
          5883,
          5884,
          5882,
          5875,
          5878,
          5874,
          5864,
          5871,
          5863,
          5853,
          5858,
          5844,
          5151,
          5138,
          5131,
          5113,
          5102,
          5090,
          5081,
          5828,
          5832,
          5834,
          5836,
          5815,
          5812,
          5803,
          5792,
          5786,
          5778,
          5784,
          5761,
          5744,
          5762,
          5745,
          5763,
          5746,
          5757,
          5768,
          5765,
          5750,
          5755,
          5756,
          5769,
          5747,
          5733,
          5740,
          5736,
          5731,
          5742,
          5732,
          5728,
          5713,
          5719,
          5714,
          5710,
          5721,
          5712,
          5718,
          5709,
          3945,
          3944,
          3933,
          3943,
          3934,
          3911,
          3927,
          3913,
          3926,
          3928,
          3925,
          3912,
          3000,
          2873,
          4553,
          4547,
          4535,
          5603,
          5594,
          5595,
          5597,
          5606,
          5605,
          5600,
          5604,
          5607,
          5580,
          5578,
          5553,
          5547,
          5555,
          5558,
          5546,
          5548,
          5557,
          5549,
          5556,
          5542,
          5527,
          5533,
          5526,
          5535,
          5525,
          5536,
          5534,
          5517,
          5520,
          5516,
          5513,
          5522,
          5497,
          5500,
          5498,
          5501,
          5496,
          5490,
          5483,
          5486,
          4240,
          4247,
          4249,
          4248,
          4241,
          738,
          687,
          3309,
          3303,
          3293,
          3284,
          3283,
          3276,
          5702,
          5695,
          5691,
          5682,
          5678,
          5676,
          5683,
          5674,
          5679,
          5684,
          5677,
          5665,
          5666,
          5669,
          5664,
          5654,
          5655,
          5650,
          5646,
          5627,
          5624,
          5625,
          5626,
          5629,
          5617,
          5616,
          5615,
          5618,
          5613
        ],
        "x": [
          298,
          299,
          299,
          299,
          300,
          300,
          301,
          302,
          302,
          302,
          302,
          302,
          303,
          304,
          304,
          304,
          305,
          305,
          305,
          305,
          305,
          305,
          306,
          306,
          306,
          307,
          307,
          308,
          309,
          312,
          313,
          316,
          317,
          318,
          319,
          320,
          320,
          320,
          320,
          322,
          323,
          323,
          324,
          325,
          325,
          325,
          326,
          326,
          326,
          326,
          326,
          326,
          326,
          326,
          326,
          326,
          326,
          326,
          326,
          326,
          327,
          327,
          327,
          327,
          327,
          327,
          328,
          329,
          329,
          329,
          329,
          329,
          329,
          329,
          330,
          331,
          331,
          331,
          331,
          331,
          332,
          332,
          332,
          332,
          332,
          332,
          332,
          333,
          334,
          337,
          337,
          338,
          341,
          341,
          341,
          341,
          341,
          341,
          341,
          341,
          341,
          343,
          343,
          346,
          346,
          346,
          346,
          346,
          346,
          346,
          346,
          346,
          347,
          348,
          348,
          348,
          348,
          348,
          348,
          348,
          349,
          349,
          349,
          349,
          349,
          351,
          351,
          351,
          351,
          351,
          351,
          352,
          352,
          353,
          353,
          353,
          353,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          359,
          360,
          361,
          361,
          362,
          363,
          363,
          363,
          363,
          363,
          363,
          363,
          363,
          364,
          364,
          364,
          364,
          365,
          365,
          365,
          366,
          369,
          369,
          369,
          369,
          369,
          370,
          370,
          370,
          370,
          370
        ],
        "size": [
          8998,
          3037,
          16648,
          3037,
          24716,
          2933,
          19844,
          8447,
          13389,
          12525,
          1241,
          7576,
          2691,
          7252,
          7781,
          1752,
          1241,
          1132,
          1748,
          1132,
          14356,
          1241,
          16089,
          1474,
          925,
          14710,
          1386,
          16314,
          11096,
          13758,
          11884,
          11624,
          15176,
          15902,
          14356,
          8776,
          5937,
          3962,
          3385,
          10823,
          1004,
          14238,
          15243,
          1039,
          14356,
          2208,
          34996,
          34996,
          34996,
          34996,
          34996,
          34996,
          3962,
          6465,
          15522,
          15184,
          4287,
          4287,
          5384,
          16314,
          4180,
          7623,
          2746,
          7623,
          5194,
          4392,
          12769,
          13171,
          12141,
          7623,
          18386,
          7623,
          13612,
          31738,
          10281,
          13147,
          29477,
          29786,
          29786,
          29477,
          16814,
          16648,
          16648,
          16781,
          14241,
          16814,
          16781,
          10263,
          11715,
          16648,
          3737,
          15522,
          7029,
          7029,
          7029,
          5549,
          2746,
          2746,
          1913,
          7029,
          1913,
          825,
          1824,
          12459,
          9578,
          9721,
          6203,
          12459,
          6203,
          6203,
          6203,
          9578,
          9578,
          17199,
          17199,
          17199,
          17199,
          17199,
          13147,
          17199,
          6203,
          22241,
          6203,
          22241,
          9578,
          7268,
          5874,
          6872,
          5874,
          8247,
          15807,
          9578,
          1188,
          32111,
          32111,
          6416,
          32111,
          32111,
          13961,
          10827,
          13636,
          11624,
          12141,
          12648,
          26185,
          11361,
          14118,
          17243,
          10281,
          3457,
          1824,
          3457,
          2465,
          13636,
          1824,
          1824,
          1824,
          17243,
          8551,
          3504,
          30899,
          9464,
          8850,
          9464,
          11624,
          15522,
          1578,
          1578,
          1004,
          1004,
          10556,
          925,
          1004,
          2551,
          1558
        ],
        "hover_name": [
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>390.0 k c\u1ed5 phi\u1ebfu<br>2022_05_11",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH<br>61.0 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>1,000.0 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>61.0 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>1,800.0 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "Nguy\u1ec5n Th\u00fay Mai<br>57.0 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>1,300.0 k c\u1ed5 phi\u1ebfu<br>2022_05_16",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O<br>353.1 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>719.7 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean<br>650.2 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "Nguy\u1ec5n Th\u00fay Mai<br>6.0 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>297.1 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "Nguy\u1ec5n Th\u00fay Mai<br>48.0 k c\u1ed5 phi\u1ebfu<br>2022_05_18",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>277.0 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "Tr\u1ea7n \u0110\u1ee9c Anh<br>310.0 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "Nguy\u1ec5n Th\u00fay Mai<br>18.0 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>6.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O<br>4.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "Nguy\u1ec5n Th\u00fay Mai<br>17.9 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>4.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "Tr\u1ea7n \u0110\u1ee9c Anh<br>800.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH<br>6.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "Th\u00e1i Quang Huy<br>950.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "Nguy\u1ec5n Th\u00fay Mai<br>11.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "Nguy\u1ec5n Th\u00fay Mai<br>1.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "Th\u00e1i Quang Huy<br>830.0 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "Nguy\u1ec5n Th\u00fay Mai<br>9.0 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>970.0 k c\u1ed5 phi\u1ebfu<br>2022_05_25",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>540.0 k c\u1ed5 phi\u1ebfu<br>2022_05_26",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>750.0 k c\u1ed5 phi\u1ebfu<br>2022_05_31",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>600.0 k c\u1ed5 phi\u1ebfu<br>2022_06_01",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>580.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>870.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh<br>933.4 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "Nguy\u1ec5n L\u00ea \u0110\u1ee9c Minh<br>800.0 k c\u1ed5 phi\u1ebfu<br>2022_06_09",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN<br>375.0 k c\u1ed5 phi\u1ebfu<br>2022_06_10",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_06_10",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_10",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>75.0 k c\u1ed5 phi\u1ebfu<br>2022_06_10",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>519.7 k c\u1ed5 phi\u1ebfu<br>2022_06_14",
          "Nguy\u1ec5n Th\u00fay Mai<br>2.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>790.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>875.8 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "Nguy\u1ec5n Th\u00fay Mai<br>2.5 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>800.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>31.5 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>3,000.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>3,000.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>3,000.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>3,000.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN<br>3,000.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN<br>3,000.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh<br>230.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>900.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>870.7 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Ho\u00e0ng Trung Th\u00e0nh<br>115.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m<br>115.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>170.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Th\u00e1i Quang Huy<br>970.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH<br>110.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean<br>160.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O<br>120.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>669.6 k c\u1ed5 phi\u1ebfu<br>2022_06_22",
          "Ho\u00e0ng Trung Th\u00e0nh<br>702.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>620.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "Ho\u00e0ng Trung Th\u00e0nh<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean<br>1,160.2 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m<br>738.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh<br>2,600.2 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>480.0 k c\u1ed5 phi\u1ebfu<br>2022_06_24",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>700.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>2,333.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>2,369.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "Th\u00e1i Quang Huy<br>2,369.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "Th\u00e1i Quang Huy<br>2,333.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "TR\u00c2\u0300N \u0110\u01af\u0301C ANH<br>1,015.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "TR\u00c2\u0300N \u0110\u01af\u0301C ANH<br>1,000.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "TR\u00c2\u0300N V\u0102N THI\u0323NH<br>1,000.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "TR\u00c2\u0300N V\u0102N THI\u0323NH<br>1,012.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>790.3 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "NGUY\u00ca\u0303N L\u00ca \u0110\u01af\u0301C MINH<br>1,015.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "NGUY\u00ca\u0303N L\u00ca \u0110\u01af\u0301C MINH<br>1,012.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Th\u00e1i Quang Huy<br>478.7 k c\u1ed5 phi\u1ebfu<br>2022_06_29",
          "Th\u00e1i Quang Huy<br>587.0 k c\u1ed5 phi\u1ebfu<br>2022_06_30",
          "Tr\u1ea7n \u0110\u1ee9c Anh<br>1,000.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Ph\u1ea1m Hi\u1ec1n Trang<br>90.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Tr\u1ea7n \u0110\u1ee9c Anh<br>900.0 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>263.4 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>263.4 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m<br>263.4 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN<br>178.8 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>22.5 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m<br>263.4 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O<br>22.5 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "Ho\u00e0ng Trung Th\u00e0nh<br>0.1 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean<br>645.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean<br>430.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>440.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Ho\u00e0ng Trung Th\u00e0nh<br>215.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Ho\u00e0ng Trung Th\u00e0nh<br>645.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m<br>215.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m<br>215.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh<br>215.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh<br>430.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>430.0 k c\u1ed5 phi\u1ebfu<br>2022_07_19",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN<br>1,050.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>1,050.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>1,050.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN<br>1,050.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>1,050.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>700.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>1,050.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh<br>215.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "TR\u1ea6N V\u0102N TH\u1ecaNH<br>1,540.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean<br>215.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "Th\u00e1i Quang Huy<br>1,540.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "Ho\u00e0ng Trung Th\u00e0nh<br>430.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>278.0 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O<br>196.5 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>254.0 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH<br>196.5 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>340.0 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "NGUY\u1ec4N TH\u1eca KIM LI\u00caN<br>925.0 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>430.0 k c\u1ed5 phi\u1ebfu<br>2022_07_26",
          "Ph\u1ea1m Hi\u1ec1n Trang<br>5.0 k c\u1ed5 phi\u1ebfu<br>2022_07_26",
          "TR\u1ea6N QU\u1ed0C ANH<br>2,645.0 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "TR\u1ea6N QU\u1ed0C ANH<br>2,645.0 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "Tr\u1ea7n V\u0103n Th\u1ecbnh<br>227.2 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "TR\u1ea6N V\u0102N TH\u1ecaNH<br>2,645.0 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "TR\u1ea6N V\u0102N TH\u1ecaNH<br>2,645.0 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>766.9 k c\u1ed5 phi\u1ebfu<br>2022_07_28",
          "\u0110\u00e0o Th\u1ecb Thanh H\u01b0\u01a1ng<br>520.0 k c\u1ed5 phi\u1ebfu<br>2022_07_29",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>740.0 k c\u1ed5 phi\u1ebfu<br>2022_08_01",
          "\u0110\u1ed7 Th\u1ecb Ho\u00e0ng Y\u1ebfn<br>580.0 k c\u1ed5 phi\u1ebfu<br>2022_08_02",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>620.0 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>660.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "TR\u1ea6N \u0110\u1ee8C ANH<br>1,960.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "Tr\u1ea7n Th\u1ecb Minh Nguy\u1ec7t<br>560.0 k c\u1ed5 phi\u1ebfu<br>2022_08_05",
          "Th\u00e1i Quang Huy<br>780.0 k c\u1ed5 phi\u1ebfu<br>2022_08_08",
          "Tr\u1ea7n Th\u1ecb Thanh Hi\u00ean<br>1,054.0 k c\u1ed5 phi\u1ebfu<br>2022_08_08",
          "Th\u00e1i Quang Huy<br>480.0 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>78.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "Ho\u00e0ng Trung Th\u00e0nh<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH<br>78.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>40.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>740.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "Tr\u1ea7n Th\u1ecb Thanh Hi\u00ean<br>1,054.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "Nguy\u1ec5n Th\u1ecb Kim Li\u00ean<br>360.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "\u0110\u1eb7ng \u0110\u00ecnh Nh\u00e2m<br>80.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          NaN,
          "HO\u00c0NG TRUNG TH\u00c0NH<br>422.0 k c\u1ed5 phi\u1ebfu<br>2022_08_12",
          "Tr\u1ea7n \u0110\u1ee9c Anh<br>380.0 k c\u1ed5 phi\u1ebfu<br>2022_08_12",
          "Ho\u00e0ng \u0110\u00ecnh B\u00ecnh<br>422.0 k c\u1ed5 phi\u1ebfu<br>2022_08_12",
          "Tr\u1ea7n \u0110\u1ee9c Anh<br>580.0 k c\u1ed5 phi\u1ebfu<br>2022_08_15",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>900.0 k c\u1ed5 phi\u1ebfu<br>2022_08_18",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH<br>13.5 k c\u1ed5 phi\u1ebfu<br>2022_08_18",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>13.5 k c\u1ed5 phi\u1ebfu<br>2022_08_18",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O<br>2.0 k c\u1ed5 phi\u1ebfu<br>2022_08_18",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>2.0 k c\u1ed5 phi\u1ebfu<br>2022_08_18",
          "Tr\u1ea7n Qu\u1ed1c Anh<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19",
          "\u0110\u1eb6NG \u0110\u00ccNH NH\u00c2M<br>1.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19",
          "NGUY\u1ec4N C\u1ea2NH M\u00c3O<br>2.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19",
          "HO\u00c0NG \u0110\u00ccNH B\u00ccNH<br>43.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19",
          "HO\u00c0NG TRUNG TH\u00c0NH<br>13.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19"
        ]
      },
      "vn30_index": {
        "close": [
          1541.25,
          1550.46,
          1552.44,
          1548.95,
          1545.92,
          1505.07,
          1527.36,
          1521.47,
          1540.51,
          1531.47,
          1533.4,
          1532.36,
          1538.83,
          1522.04,
          1526.5,
          1517.18,
          1520.12,
          1498.61,
          1522.49,
          1525.34,
          1509.12,
          1490.17,
          1489.25,
          1490.24,
          1477.14,
          1461.1,
          1468.89,
          1472.69,
          1469.92,
          1476.94,
          1502.67,
          1513.4,
          1505.59,
          1497.44,
          1498.36,
          1484.16,
          1500.57,
          1500.23,
          1508.53,
          1542.47,
          1548.04,
          1539.2,
          1557.11,
          1541.96,
          1524.31,
          1507.2,
          1525.39,
          1518.01,
          1493.74,
          1468.25,
          1440.61,
          1435.5,
          1426.87,
          1444.32,
          1366.39,
          1396.9,
          1402.03,
          1400.88,
          1417.31,
          1389.59,
          1404.88,
          1373.21,
          1314.04,
          1345.46,
          1349.82,
          1279.76,
          1223.76,
          1215.08,
          1279.55,
          1286.41,
          1283.55,
          1282.51,
          1255.35,
          1272.71,
          1310.7,
          1309.5,
          1335.68,
          1342.87,
          1332.59,
          1335.49,
          1325.49,
          1327.4,
          1327.04,
          1324.37,
          1342.03,
          1342.92,
          1325.69,
          1260.85,
          1261.16,
          1253.09,
          1280.37,
          1258.03,
          1225.56,
          1224.54,
          1227.18,
          1240.58,
          1235.47,
          1256.67,
          1273.41,
          1273.4,
          1248.92,
          1252.24,
          1248.37,
          1242.05,
          1211.94,
          1229.23,
          1231.54,
          1209.02,
          1219.44,
          1216.94,
          1221.94,
          1220.14,
          1214.28,
          1211.7,
          1225.62,
          1235.25,
          1228.84,
          1222.6,
          1218.49,
          1219.43,
          1236.14,
          1232.0,
          1256.25,
          1265.97,
          1269.83,
          1277.73,
          1272.06,
          1276.7,
          1281.44,
          1277.15,
          1272.33,
          1280.96,
          1293.79,
          1295.1,
          1300.4,
          1299.93,
          1294.93,
          1291.06
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ],
        "color": [
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index"
        ]
      }
    },
    "VCI": {
      "prices": {
        "t": [
          1644192000000000000,
          1644278400000000000,
          1644364800000000000,
          1644451200000000000,
          1644537600000000000,
          1644796800000000000,
          1644883200000000000,
          1644969600000000000,
          1645056000000000000,
          1645142400000000000,
          1645401600000000000,
          1645488000000000000,
          1645574400000000000,
          1645660800000000000,
          1645747200000000000,
          1646006400000000000,
          1646092800000000000,
          1646179200000000000,
          1646265600000000000,
          1646352000000000000,
          1646611200000000000,
          1646697600000000000,
          1646784000000000000,
          1646870400000000000,
          1646956800000000000,
          1647216000000000000,
          1647302400000000000,
          1647388800000000000,
          1647475200000000000,
          1647561600000000000,
          1647820800000000000,
          1647907200000000000,
          1647993600000000000,
          1648080000000000000,
          1648166400000000000,
          1648425600000000000,
          1648512000000000000,
          1648598400000000000,
          1648684800000000000,
          1648771200000000000,
          1649030400000000000,
          1649116800000000000,
          1649203200000000000,
          1649289600000000000,
          1649376000000000000,
          1649721600000000000,
          1649808000000000000,
          1649894400000000000,
          1649980800000000000,
          1650240000000000000,
          1650326400000000000,
          1650412800000000000,
          1650499200000000000,
          1650585600000000000,
          1650844800000000000,
          1650931200000000000,
          1651017600000000000,
          1651104000000000000,
          1651190400000000000,
          1651622400000000000,
          1651708800000000000,
          1651795200000000000,
          1652054400000000000,
          1652140800000000000,
          1652227200000000000,
          1652313600000000000,
          1652400000000000000,
          1652659200000000000,
          1652745600000000000,
          1652832000000000000,
          1652918400000000000,
          1653004800000000000,
          1653264000000000000,
          1653350400000000000,
          1653436800000000000,
          1653523200000000000,
          1653609600000000000,
          1653868800000000000,
          1653955200000000000,
          1654041600000000000,
          1654128000000000000,
          1654214400000000000,
          1654473600000000000,
          1654560000000000000,
          1654646400000000000,
          1654732800000000000,
          1654819200000000000,
          1655078400000000000,
          1655164800000000000,
          1655251200000000000,
          1655337600000000000,
          1655424000000000000,
          1655683200000000000,
          1655769600000000000,
          1655856000000000000,
          1655942400000000000,
          1656028800000000000,
          1656288000000000000,
          1656374400000000000,
          1656460800000000000,
          1656547200000000000,
          1656633600000000000,
          1656892800000000000,
          1656979200000000000,
          1657065600000000000,
          1657152000000000000,
          1657238400000000000,
          1657497600000000000,
          1657584000000000000,
          1657670400000000000,
          1657756800000000000,
          1657843200000000000,
          1658102400000000000,
          1658188800000000000,
          1658275200000000000,
          1658361600000000000,
          1658448000000000000,
          1658707200000000000,
          1658793600000000000,
          1658880000000000000,
          1658966400000000000,
          1659052800000000000,
          1659312000000000000,
          1659398400000000000,
          1659484800000000000,
          1659571200000000000,
          1659657600000000000,
          1659916800000000000,
          1660003200000000000,
          1660089600000000000,
          1660176000000000000,
          1660262400000000000,
          1660521600000000000,
          1660608000000000000,
          1660694400000000000,
          1660780800000000000,
          1660867200000000000,
          1661126400000000000
        ],
        "close": [
          45.075,
          45.767,
          46.536,
          46.382,
          46.998,
          43.767,
          44.613,
          45.613,
          45.459,
          46.536,
          48.075,
          46.921,
          47.151,
          46.536,
          47.921,
          47.998,
          48.844,
          48.305,
          48.382,
          48.844,
          48.151,
          48.151,
          48.767,
          48.536,
          47.69,
          44.998,
          44.767,
          45.152,
          45.305,
          44.998,
          45.767,
          45.998,
          45.459,
          44.998,
          44.305,
          43.459,
          44.229,
          43.998,
          43.152,
          44.229,
          45.536,
          44.998,
          44.767,
          43.998,
          43.075,
          41.075,
          41.536,
          40.921,
          38.46,
          35.767,
          34.614,
          33.46,
          34.614,
          34.306,
          31.921,
          32.306,
          31.691,
          30.768,
          32.344,
          31.537,
          30.729,
          28.614,
          26.614,
          27.075,
          26.691,
          24.845,
          23.114,
          23.883,
          25.537,
          27.306,
          28.691,
          28.537,
          27.152,
          28.268,
          29.998,
          30.152,
          30.383,
          30.191,
          29.998,
          30.191,
          30.152,
          30.114,
          30.306,
          30.114,
          32.191,
          31.921,
          29.998,
          27.921,
          26.306,
          24.768,
          24.614,
          23.806,
          22.152,
          23.614,
          25.229,
          25.998,
          25.922,
          27.691,
          26.922,
          27.306,
          25.422,
          27.191,
          28.152,
          27.921,
          26.96,
          27.46,
          28.537,
          27.46,
          28.421,
          27.96,
          29.883,
          29.229,
          29.575,
          29.229,
          30.46,
          29.883,
          29.229,
          29.691,
          28.998,
          29.537,
          29.921,
          31.152,
          33.075,
          32.844,
          33.844,
          33.537,
          34.96,
          35.383,
          36.306,
          36.69,
          34.69,
          36.844,
          35.69,
          35.998,
          36.5,
          37.0,
          36.8,
          36.25
        ],
        "open": [
          43.767,
          44.767,
          46.152,
          46.152,
          46.305,
          45.921,
          44.382,
          44.998,
          45.998,
          44.921,
          46.844,
          46.998,
          47.151,
          46.844,
          47.536,
          47.921,
          48.305,
          48.228,
          48.459,
          48.767,
          47.998,
          47.69,
          48.613,
          49.998,
          48.536,
          47.305,
          44.459,
          44.998,
          45.767,
          45.382,
          45.536,
          46.075,
          46.69,
          45.459,
          44.998,
          43.844,
          43.921,
          43.844,
          44.152,
          43.075,
          44.767,
          45.767,
          44.613,
          44.844,
          43.998,
          43.075,
          41.229,
          41.998,
          40.767,
          37.536,
          36.306,
          34.998,
          32.767,
          35.075,
          33.883,
          31.383,
          32.229,
          31.614,
          30.691,
          32.344,
          32.152,
          29.844,
          27.075,
          24.922,
          27.191,
          26.229,
          24.845,
          24.652,
          24.075,
          26.537,
          26.537,
          29.075,
          28.537,
          26.768,
          28.268,
          29.691,
          29.768,
          30.46,
          29.921,
          29.691,
          30.229,
          30.152,
          29.921,
          29.844,
          30.383,
          32.306,
          31.383,
          28.46,
          27.152,
          26.306,
          26.075,
          23.46,
          24.229,
          21.768,
          24.306,
          24.999,
          25.537,
          26.268,
          27.537,
          26.652,
          27.383,
          25.537,
          28.075,
          27.921,
          27.306,
          26.998,
          27.691,
          28.152,
          27.614,
          28.498,
          27.845,
          30.383,
          29.614,
          29.46,
          29.768,
          29.998,
          29.883,
          28.845,
          29.691,
          28.845,
          29.998,
          30.075,
          31.306,
          32.844,
          32.537,
          33.844,
          33.383,
          34.921,
          35.383,
          35.921,
          37.075,
          34.614,
          37.306,
          35.383,
          38.2,
          36.4,
          37.15,
          36.55
        ],
        "high": [
          46.075,
          46.459,
          47.305,
          47.613,
          47.536,
          46.459,
          44.844,
          46.152,
          45.998,
          46.844,
          48.228,
          47.69,
          47.921,
          48.075,
          48.921,
          48.69,
          49.613,
          49.228,
          48.613,
          49.844,
          48.844,
          49.844,
          49.151,
          50.536,
          48.536,
          47.305,
          45.459,
          45.844,
          46.382,
          45.69,
          45.767,
          46.305,
          46.69,
          45.69,
          45.075,
          44.305,
          44.613,
          44.998,
          44.229,
          44.229,
          46.767,
          46.305,
          45.536,
          45.69,
          44.613,
          43.305,
          42.306,
          42.69,
          41.152,
          37.536,
          36.998,
          35.267,
          35.537,
          35.613,
          34.614,
          32.614,
          32.575,
          32.267,
          32.46,
          32.806,
          32.152,
          30.152,
          27.498,
          27.306,
          27.268,
          26.537,
          24.922,
          24.729,
          25.537,
          27.306,
          28.845,
          29.46,
          28.537,
          28.268,
          29.998,
          30.768,
          30.691,
          30.921,
          30.498,
          30.537,
          31.383,
          30.768,
          31.306,
          30.229,
          32.191,
          32.921,
          32.037,
          29.152,
          27.921,
          26.614,
          26.075,
          23.999,
          24.537,
          23.691,
          25.229,
          25.998,
          26.498,
          27.691,
          27.691,
          28.075,
          27.614,
          27.191,
          29.075,
          28.806,
          28.537,
          27.46,
          29.075,
          28.691,
          28.575,
          29.075,
          29.883,
          30.383,
          30.191,
          29.844,
          31.075,
          30.652,
          30.152,
          30.344,
          29.883,
          29.768,
          30.768,
          31.383,
          33.075,
          33.537,
          33.844,
          34.075,
          34.96,
          35.383,
          36.498,
          36.998,
          37.498,
          36.844,
          37.306,
          36.113,
          38.2,
          37.6,
          37.15,
          36.85
        ],
        "low": [
          43.69,
          44.459,
          45.69,
          45.998,
          45.767,
          43.767,
          43.459,
          44.844,
          45.075,
          44.921,
          46.767,
          45.921,
          46.921,
          44.613,
          46.998,
          47.69,
          47.69,
          47.536,
          47.767,
          48.613,
          47.767,
          47.305,
          47.998,
          48.536,
          47.382,
          44.536,
          44.459,
          44.844,
          45.305,
          44.998,
          44.921,
          45.382,
          45.382,
          44.613,
          44.152,
          42.075,
          43.844,
          43.844,
          43.152,
          42.69,
          44.767,
          44.998,
          44.305,
          43.921,
          43.075,
          41.075,
          40.383,
          40.921,
          38.46,
          35.767,
          34.306,
          33.46,
          32.767,
          33.152,
          31.921,
          29.691,
          31.383,
          30.768,
          30.306,
          31.537,
          30.344,
          28.614,
          26.614,
          24.922,
          26.152,
          24.845,
          23.114,
          23.883,
          23.922,
          26.383,
          26.306,
          27.96,
          26.575,
          25.46,
          28.075,
          29.46,
          29.498,
          29.921,
          29.498,
          28.96,
          30.114,
          29.537,
          29.806,
          28.691,
          30.191,
          31.806,
          29.998,
          27.921,
          26.191,
          24.499,
          23.46,
          23.076,
          22.152,
          21.729,
          24.229,
          24.383,
          25.383,
          26.191,
          26.922,
          26.537,
          25.422,
          24.999,
          27.921,
          27.652,
          26.96,
          26.652,
          27.614,
          26.652,
          27.114,
          27.921,
          27.845,
          29.229,
          29.152,
          28.46,
          29.614,
          29.768,
          29.229,
          28.537,
          28.998,
          28.729,
          29.883,
          30.075,
          31.306,
          32.46,
          32.46,
          33.114,
          33.229,
          34.806,
          35.037,
          35.883,
          34.306,
          34.46,
          35.613,
          35.383,
          36.35,
          36.0,
          36.25,
          36.25
        ],
        "volume": [
          2641100,
          2560900,
          2231900,
          2016300,
          2418800,
          3309000,
          2170300,
          1136400,
          1337700,
          2963600,
          2835300,
          3721800,
          2218200,
          3898500,
          4092800,
          2157200,
          3464200,
          3559600,
          1931500,
          2947500,
          2980600,
          5363600,
          2610000,
          3166800,
          3509700,
          4849600,
          1955400,
          869200,
          1557500,
          2389900,
          1269100,
          1484200,
          2050600,
          2254500,
          3561200,
          4455000,
          1291900,
          1646100,
          1747200,
          1867600,
          4261300,
          1460700,
          1842800,
          2076900,
          2152200,
          2226500,
          1922900,
          1350100,
          3092300,
          3608400,
          2395800,
          2558000,
          3134300,
          1678200,
          2407500,
          3851700,
          1218500,
          1555100,
          3116100,
          1258400,
          1541900,
          4006700,
          2973400,
          3532600,
          1181500,
          3083200,
          6020000,
          3833000,
          2200700,
          6488500,
          5370500,
          3755300,
          6221200,
          5151200,
          6219000,
          5454000,
          4433700,
          4072200,
          5333100,
          4294200,
          5278000,
          3348800,
          4794500,
          4617100,
          6819500,
          3162800,
          4725600,
          6817000,
          3327900,
          5121900,
          3201100,
          4459600,
          5013000,
          5969200,
          3425000,
          3843800,
          4092400,
          3225100,
          3137600,
          3309400,
          3204000,
          4291000,
          3838100,
          3771200,
          4198100,
          2266900,
          4204000,
          4049900,
          3364000,
          3307800,
          6008000,
          3692600,
          3762600,
          6500400,
          6277100,
          3307700,
          3539800,
          5304600,
          2133200,
          2419600,
          5701300,
          8041100,
          6370700,
          5901400,
          6689400,
          5057100,
          7298700,
          4908400,
          4602100,
          4464900,
          14334400,
          5954000,
          7099200,
          7593000,
          7479800,
          4466300,
          4134600,
          1788600
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "stock": [
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI",
          "VCI"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ]
      },
      "insider": {
        "id": [
          "068C011515",
          "077C389595"
        ],
        "day": [
          "2022_03_04",
          "2022_03_09"
        ],
        "stock": [
          "VCI",
          "VCI"
        ],
        "side": [
          "sell",
          "buy"
        ],
        "TYPES": [
          "Insider",
          "Insider"
        ],
        "TradeValue": [
          NaN,
          NaN
        ],
        "volume": [
          50000,
          43700
        ],
        "name": [
          "Nguy\u1ec5n Thanh T\u00fa",
          "Nguy\u1ec5n Phan Minh Kh\u00f4i"
        ],
        "Value": [
          3.2,
          2.76
        ],
        "i": [
          5445,
          5432
        ],
        "x": [
          253,
          256
        ],
        "size": [
          2746,
          2571
        ],
        "hover_name": [
          "Nguy\u1ec5n Thanh T\u00fa<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_03_04",
          "Nguy\u1ec5n Phan Minh Kh\u00f4i<br>43.7 k c\u1ed5 phi\u1ebfu<br>2022_03_09"
        ]
      },
      "vn30_index": {
        "close": [
          1541.25,
          1550.46,
          1552.44,
          1548.95,
          1545.92,
          1505.07,
          1527.36,
          1521.47,
          1540.51,
          1531.47,
          1533.4,
          1532.36,
          1538.83,
          1522.04,
          1526.5,
          1517.18,
          1520.12,
          1498.61,
          1522.49,
          1525.34,
          1509.12,
          1490.17,
          1489.25,
          1490.24,
          1477.14,
          1461.1,
          1468.89,
          1472.69,
          1469.92,
          1476.94,
          1502.67,
          1513.4,
          1505.59,
          1497.44,
          1498.36,
          1484.16,
          1500.57,
          1500.23,
          1508.53,
          1542.47,
          1548.04,
          1539.2,
          1557.11,
          1541.96,
          1524.31,
          1507.2,
          1525.39,
          1518.01,
          1493.74,
          1468.25,
          1440.61,
          1435.5,
          1426.87,
          1444.32,
          1366.39,
          1396.9,
          1402.03,
          1400.88,
          1417.31,
          1389.59,
          1404.88,
          1373.21,
          1314.04,
          1345.46,
          1349.82,
          1279.76,
          1223.76,
          1215.08,
          1279.55,
          1286.41,
          1283.55,
          1282.51,
          1255.35,
          1272.71,
          1310.7,
          1309.5,
          1335.68,
          1342.87,
          1332.59,
          1335.49,
          1325.49,
          1327.4,
          1327.04,
          1324.37,
          1342.03,
          1342.92,
          1325.69,
          1260.85,
          1261.16,
          1253.09,
          1280.37,
          1258.03,
          1225.56,
          1224.54,
          1227.18,
          1240.58,
          1235.47,
          1256.67,
          1273.41,
          1273.4,
          1248.92,
          1252.24,
          1248.37,
          1242.05,
          1211.94,
          1229.23,
          1231.54,
          1209.02,
          1219.44,
          1216.94,
          1221.94,
          1220.14,
          1214.28,
          1211.7,
          1225.62,
          1235.25,
          1228.84,
          1222.6,
          1218.49,
          1219.43,
          1236.14,
          1232.0,
          1256.25,
          1265.97,
          1269.83,
          1277.73,
          1272.06,
          1276.7,
          1281.44,
          1277.15,
          1272.33,
          1280.96,
          1293.79,
          1295.1,
          1300.4,
          1299.93,
          1294.93,
          1291.06
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ],
        "color": [
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index"
        ]
      }
    },
    "VIC": {
      "prices": {
        "t": [
          1644192000000000000,
          1644278400000000000,
          1644364800000000000,
          1644451200000000000,
          1644537600000000000,
          1644796800000000000,
          1644883200000000000,
          1644969600000000000,
          1645056000000000000,
          1645142400000000000,
          1645401600000000000,
          1645488000000000000,
          1645574400000000000,
          1645660800000000000,
          1645747200000000000,
          1646006400000000000,
          1646092800000000000,
          1646179200000000000,
          1646265600000000000,
          1646352000000000000,
          1646611200000000000,
          1646697600000000000,
          1646784000000000000,
          1646870400000000000,
          1646956800000000000,
          1647216000000000000,
          1647302400000000000,
          1647388800000000000,
          1647475200000000000,
          1647561600000000000,
          1647820800000000000,
          1647907200000000000,
          1647993600000000000,
          1648080000000000000,
          1648166400000000000,
          1648425600000000000,
          1648512000000000000,
          1648598400000000000,
          1648684800000000000,
          1648771200000000000,
          1649030400000000000,
          1649116800000000000,
          1649203200000000000,
          1649289600000000000,
          1649376000000000000,
          1649721600000000000,
          1649808000000000000,
          1649894400000000000,
          1649980800000000000,
          1650240000000000000,
          1650326400000000000,
          1650412800000000000,
          1650499200000000000,
          1650585600000000000,
          1650844800000000000,
          1650931200000000000,
          1651017600000000000,
          1651104000000000000,
          1651190400000000000,
          1651622400000000000,
          1651708800000000000,
          1651795200000000000,
          1652054400000000000,
          1652140800000000000,
          1652227200000000000,
          1652313600000000000,
          1652400000000000000,
          1652659200000000000,
          1652745600000000000,
          1652832000000000000,
          1652918400000000000,
          1653004800000000000,
          1653264000000000000,
          1653350400000000000,
          1653436800000000000,
          1653523200000000000,
          1653609600000000000,
          1653868800000000000,
          1653955200000000000,
          1654041600000000000,
          1654128000000000000,
          1654214400000000000,
          1654473600000000000,
          1654560000000000000,
          1654646400000000000,
          1654732800000000000,
          1654819200000000000,
          1655078400000000000,
          1655164800000000000,
          1655251200000000000,
          1655337600000000000,
          1655424000000000000,
          1655683200000000000,
          1655769600000000000,
          1655856000000000000,
          1655942400000000000,
          1656028800000000000,
          1656288000000000000,
          1656374400000000000,
          1656460800000000000,
          1656547200000000000,
          1656633600000000000,
          1656892800000000000,
          1656979200000000000,
          1657065600000000000,
          1657152000000000000,
          1657238400000000000,
          1657497600000000000,
          1657584000000000000,
          1657670400000000000,
          1657756800000000000,
          1657843200000000000,
          1658102400000000000,
          1658188800000000000,
          1658275200000000000,
          1658361600000000000,
          1658448000000000000,
          1658707200000000000,
          1658793600000000000,
          1658880000000000000,
          1658966400000000000,
          1659052800000000000,
          1659312000000000000,
          1659398400000000000,
          1659484800000000000,
          1659571200000000000,
          1659657600000000000,
          1659916800000000000,
          1660003200000000000,
          1660089600000000000,
          1660176000000000000,
          1660262400000000000,
          1660521600000000000,
          1660608000000000000,
          1660694400000000000,
          1660780800000000000,
          1660867200000000000,
          1661126400000000000
        ],
        "close": [
          91.2,
          87.3,
          85.7,
          84.0,
          81.7,
          81.8,
          83.7,
          82.9,
          83.1,
          82.2,
          83.6,
          82.0,
          82.5,
          80.1,
          79.1,
          77.0,
          79.2,
          78.9,
          79.0,
          79.0,
          78.5,
          77.9,
          78.0,
          79.1,
          79.0,
          78.3,
          78.3,
          78.1,
          78.2,
          78.8,
          81.0,
          82.2,
          81.4,
          81.0,
          81.0,
          80.5,
          80.8,
          81.1,
          81.2,
          82.1,
          82.7,
          82.3,
          80.5,
          79.5,
          81.7,
          81.3,
          82.2,
          81.7,
          80.7,
          79.5,
          78.8,
          79.3,
          77.5,
          77.7,
          76.2,
          78.0,
          78.0,
          77.9,
          80.0,
          79.7,
          80.0,
          79.8,
          79.1,
          80.4,
          80.5,
          79.0,
          78.0,
          77.0,
          78.0,
          78.0,
          77.9,
          77.8,
          77.4,
          77.6,
          77.7,
          77.7,
          78.0,
          78.5,
          78.8,
          79.3,
          78.9,
          78.8,
          78.5,
          78.0,
          78.1,
          78.1,
          78.0,
          76.4,
          76.6,
          76.7,
          76.6,
          76.6,
          75.6,
          75.0,
          75.0,
          74.2,
          74.6,
          73.8,
          73.6,
          73.4,
          73.5,
          72.6,
          72.7,
          72.3,
          67.5,
          69.8,
          70.0,
          70.0,
          69.9,
          69.5,
          69.5,
          69.6,
          68.0,
          68.8,
          68.2,
          68.5,
          66.5,
          67.3,
          66.1,
          66.5,
          67.0,
          64.0,
          64.7,
          66.6,
          66.0,
          66.4,
          65.4,
          64.6,
          65.3,
          64.8,
          65.8,
          66.2,
          66.4,
          65.9,
          67.3,
          68.1,
          67.9,
          67.3
        ],
        "open": [
          96.4,
          91.3,
          87.6,
          85.7,
          83.0,
          81.7,
          82.0,
          83.9,
          82.8,
          82.5,
          82.1,
          83.0,
          82.1,
          82.3,
          80.9,
          79.5,
          77.1,
          79.2,
          78.9,
          79.0,
          78.4,
          78.0,
          78.1,
          78.5,
          78.8,
          78.8,
          78.0,
          78.3,
          78.3,
          78.3,
          78.8,
          81.0,
          82.1,
          81.4,
          80.6,
          79.9,
          80.0,
          82.5,
          81.1,
          81.4,
          82.5,
          82.5,
          80.5,
          80.2,
          80.0,
          81.4,
          81.0,
          81.6,
          81.5,
          80.0,
          78.6,
          78.0,
          79.0,
          77.7,
          77.8,
          73.5,
          77.5,
          78.0,
          77.5,
          79.6,
          79.6,
          78.5,
          78.7,
          78.9,
          80.2,
          80.0,
          79.0,
          79.0,
          76.0,
          78.0,
          76.4,
          77.9,
          77.6,
          77.0,
          77.6,
          77.7,
          77.4,
          78.0,
          78.0,
          78.6,
          79.2,
          78.1,
          78.7,
          78.5,
          77.8,
          78.0,
          77.8,
          76.6,
          76.2,
          76.6,
          76.0,
          75.8,
          76.6,
          75.2,
          74.7,
          74.1,
          73.5,
          73.8,
          73.0,
          72.8,
          72.7,
          73.2,
          72.4,
          72.7,
          72.0,
          68.0,
          70.0,
          67.7,
          68.6,
          68.6,
          69.5,
          69.1,
          69.6,
          68.0,
          68.8,
          68.2,
          68.1,
          66.9,
          66.1,
          66.2,
          66.6,
          67.8,
          64.9,
          64.8,
          65.8,
          66.0,
          65.9,
          65.4,
          64.8,
          65.0,
          65.6,
          65.8,
          66.3,
          66.4,
          66.2,
          67.2,
          68.0,
          67.2
        ],
        "high": [
          97.5,
          92.3,
          88.7,
          86.0,
          83.6,
          84.0,
          83.8,
          83.9,
          83.5,
          82.8,
          83.8,
          83.3,
          82.5,
          82.4,
          80.9,
          79.5,
          79.9,
          79.2,
          80.4,
          79.3,
          79.0,
          78.9,
          78.7,
          80.6,
          79.0,
          78.8,
          78.6,
          78.4,
          78.5,
          79.5,
          81.0,
          82.4,
          82.4,
          81.4,
          81.0,
          81.2,
          81.8,
          82.7,
          82.3,
          82.5,
          82.8,
          83.1,
          82.3,
          80.3,
          82.4,
          81.4,
          82.3,
          82.2,
          81.8,
          80.1,
          79.8,
          79.8,
          79.5,
          79.0,
          79.2,
          78.5,
          78.0,
          78.2,
          80.0,
          80.0,
          80.0,
          80.0,
          79.2,
          80.4,
          80.5,
          80.0,
          79.0,
          79.0,
          78.0,
          78.0,
          79.0,
          77.9,
          77.7,
          77.6,
          78.1,
          77.7,
          78.0,
          78.5,
          79.5,
          79.4,
          79.2,
          79.2,
          78.9,
          78.5,
          78.7,
          78.2,
          78.2,
          77.4,
          76.6,
          76.9,
          77.7,
          76.6,
          76.6,
          75.2,
          75.0,
          74.2,
          74.6,
          74.8,
          73.8,
          73.4,
          73.5,
          73.4,
          72.7,
          72.8,
          72.1,
          69.8,
          70.7,
          70.0,
          70.2,
          70.0,
          69.8,
          69.8,
          69.6,
          69.0,
          69.0,
          68.8,
          68.5,
          67.4,
          67.6,
          66.8,
          67.1,
          67.8,
          65.1,
          66.6,
          66.4,
          66.4,
          66.2,
          65.7,
          65.4,
          65.6,
          66.4,
          66.2,
          66.5,
          66.4,
          67.8,
          69.3,
          68.4,
          68.0
        ],
        "low": [
          91.2,
          86.7,
          84.9,
          81.7,
          81.5,
          80.7,
          81.8,
          82.7,
          82.0,
          82.0,
          82.0,
          81.8,
          82.0,
          79.3,
          79.1,
          77.0,
          77.0,
          77.2,
          78.3,
          77.8,
          77.3,
          77.1,
          77.3,
          78.0,
          77.8,
          77.4,
          77.0,
          77.2,
          77.3,
          77.4,
          78.3,
          81.0,
          81.4,
          80.4,
          79.7,
          79.7,
          79.8,
          80.4,
          81.0,
          80.9,
          81.5,
          81.7,
          79.5,
          77.6,
          80.0,
          80.3,
          80.7,
          81.1,
          80.6,
          78.1,
          77.8,
          78.0,
          77.5,
          77.6,
          72.7,
          73.5,
          75.0,
          76.3,
          76.6,
          77.7,
          78.5,
          78.0,
          77.9,
          78.1,
          78.7,
          77.1,
          77.2,
          76.2,
          75.2,
          76.0,
          76.2,
          76.5,
          76.2,
          76.4,
          76.7,
          76.8,
          77.0,
          77.2,
          77.7,
          78.3,
          77.6,
          78.0,
          77.9,
          77.7,
          77.6,
          77.3,
          77.5,
          76.2,
          75.2,
          75.3,
          75.8,
          75.1,
          75.3,
          73.8,
          73.6,
          73.0,
          72.0,
          73.5,
          72.4,
          72.3,
          72.4,
          72.1,
          72.2,
          72.0,
          67.5,
          66.5,
          67.5,
          66.2,
          68.0,
          68.3,
          68.5,
          68.6,
          68.0,
          68.0,
          68.2,
          68.1,
          66.5,
          66.5,
          66.1,
          66.0,
          66.0,
          64.0,
          64.1,
          64.5,
          65.1,
          65.4,
          65.2,
          64.6,
          64.7,
          64.7,
          65.0,
          65.5,
          66.1,
          65.7,
          66.0,
          67.2,
          66.8,
          67.1
        ],
        "volume": [
          7413400,
          8010800,
          8812500,
          11088500,
          7846400,
          4973500,
          3001500,
          1986000,
          2865600,
          2914400,
          2513700,
          2975200,
          1439000,
          6367500,
          3385500,
          4678600,
          3467600,
          2401000,
          2610400,
          3817400,
          2442600,
          4290200,
          3049900,
          4366600,
          2222400,
          2622700,
          2733900,
          2950000,
          2565900,
          5520700,
          3705300,
          3631800,
          1529200,
          1995200,
          2193500,
          2339900,
          2798300,
          3088400,
          1372000,
          2609900,
          2730500,
          3591100,
          5679100,
          3704800,
          5192000,
          2356500,
          3146300,
          1763700,
          1678400,
          2984700,
          1816000,
          1713900,
          2684300,
          1867300,
          2738000,
          3661700,
          2793500,
          1386100,
          2148700,
          1436000,
          1858600,
          1690000,
          2428900,
          2617900,
          1983600,
          2474100,
          3134600,
          1994100,
          1857400,
          2964900,
          2650300,
          2331100,
          2644700,
          1568300,
          1360400,
          2076700,
          1575800,
          1390900,
          1776600,
          1539200,
          2935600,
          1249100,
          1503500,
          1562200,
          1441700,
          1542100,
          979600,
          1795500,
          2058500,
          2037200,
          1597800,
          3725300,
          2132800,
          1956200,
          1418900,
          1548900,
          1514800,
          1822400,
          2228300,
          1429300,
          1878800,
          1866600,
          758300,
          1478700,
          2171700,
          2174100,
          1413800,
          2385900,
          1212400,
          2004600,
          1414300,
          1582500,
          1088000,
          655400,
          626100,
          930600,
          674700,
          755800,
          592700,
          581400,
          1152200,
          1908500,
          1261800,
          1372600,
          1250700,
          1146000,
          1134900,
          1010300,
          792000,
          967900,
          1674000,
          811200,
          802100,
          862200,
          2201000,
          2769100,
          1221600,
          473400
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "stock": [
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ]
      },
      "insider": {
        "id": [
          "003C122408",
          "003C122408",
          "003C122408",
          "003C122392",
          "003C122392",
          "003C122392",
          "003C122392",
          "003C122408",
          "003C122392",
          "003C122392",
          "003C122408",
          "003C122392",
          "003C122392",
          "003C122445",
          "003C122445",
          "003C122392",
          "003C122445",
          "003C208628",
          "003C122445",
          "003C122445",
          "003C122445",
          "003C122445",
          "003C208628"
        ],
        "day": [
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_01",
          "2022_07_04",
          "2022_07_04",
          "2022_07_05",
          "2022_07_05",
          "2022_07_06",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15"
        ],
        "stock": [
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC",
          "VIC"
        ],
        "side": [
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy"
        ],
        "TYPES": [
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider"
        ],
        "TradeValue": [
          19.7,
          32.9,
          14.0,
          40.8,
          50.0,
          71.6,
          42.1,
          38.3,
          64.4,
          91.9,
          0.5,
          19.9,
          1.1,
          26.4,
          21.7,
          28.9,
          27.0,
          12.6,
          94.4,
          42.5,
          54.4,
          60.0,
          41.7
        ],
        "volume": [
          260500,
          439300,
          186500,
          550000,
          669800,
          970000,
          572000,
          520000,
          877400,
          1250500,
          6500,
          274000,
          15000,
          363800,
          300200,
          400000,
          400000,
          180000,
          1348200,
          608300,
          783000,
          862700,
          599200
        ],
        "name": [
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng",
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng",
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n",
          "L\u00ea Thanh Hi\u1ec1n",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n",
          "L\u00ea Thanh Hi\u1ec1n"
        ],
        "Value": [
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN
        ],
        "i": [
          3110,
          3079,
          3069,
          3058,
          3047,
          3038,
          3016,
          3017,
          2999,
          2872,
          2868,
          2866,
          4571,
          4569,
          4555,
          4554,
          4536,
          1439,
          5601,
          5590,
          5581,
          5571,
          5564
        ],
        "x": [
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          332,
          333,
          334,
          335,
          335,
          336,
          336,
          337,
          337,
          338,
          340,
          341,
          342,
          343,
          344,
          345
        ],
        "size": [
          6981,
          9711,
          5691,
          11229,
          12771,
          16314,
          11519,
          10827,
          15262,
          19333,
          1266,
          7203,
          1638,
          8609,
          7626,
          9145,
          9145,
          5571,
          20335,
          11991,
          14154,
          15092,
          11873
        ],
        "hover_name": [
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng<br>260.5 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng<br>439.3 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng<br>186.5 k c\u1ed5 phi\u1ebfu<br>2022_06_22",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n<br>550.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n<br>669.8 k c\u1ed5 phi\u1ebfu<br>2022_06_24",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n<br>970.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n<br>572.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng<br>520.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n<br>877.4 k c\u1ed5 phi\u1ebfu<br>2022_06_29",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n<br>1,250.5 k c\u1ed5 phi\u1ebfu<br>2022_06_30",
          "Ph\u1ea1m Kh\u1eafc Ph\u01b0\u01a1ng<br>6.5 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n<br>274.0 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n<br>15.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n<br>363.8 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n<br>300.2 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "L\u00ea Thanh Hi\u1ec1n<br>180.0 k c\u1ed5 phi\u1ebfu<br>2022_07_08",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n<br>1,348.2 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n<br>608.3 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n<br>783.0 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "Nguy\u1ec5n Th\u1ecb Huy\u1ec1n Tr\u00e2n<br>862.7 k c\u1ed5 phi\u1ebfu<br>2022_07_14",
          "L\u00ea Thanh Hi\u1ec1n<br>599.2 k c\u1ed5 phi\u1ebfu<br>2022_07_15"
        ]
      },
      "vn30_index": {
        "close": [
          1541.25,
          1550.46,
          1552.44,
          1548.95,
          1545.92,
          1505.07,
          1527.36,
          1521.47,
          1540.51,
          1531.47,
          1533.4,
          1532.36,
          1538.83,
          1522.04,
          1526.5,
          1517.18,
          1520.12,
          1498.61,
          1522.49,
          1525.34,
          1509.12,
          1490.17,
          1489.25,
          1490.24,
          1477.14,
          1461.1,
          1468.89,
          1472.69,
          1469.92,
          1476.94,
          1502.67,
          1513.4,
          1505.59,
          1497.44,
          1498.36,
          1484.16,
          1500.57,
          1500.23,
          1508.53,
          1542.47,
          1548.04,
          1539.2,
          1557.11,
          1541.96,
          1524.31,
          1507.2,
          1525.39,
          1518.01,
          1493.74,
          1468.25,
          1440.61,
          1435.5,
          1426.87,
          1444.32,
          1366.39,
          1396.9,
          1402.03,
          1400.88,
          1417.31,
          1389.59,
          1404.88,
          1373.21,
          1314.04,
          1345.46,
          1349.82,
          1279.76,
          1223.76,
          1215.08,
          1279.55,
          1286.41,
          1283.55,
          1282.51,
          1255.35,
          1272.71,
          1310.7,
          1309.5,
          1335.68,
          1342.87,
          1332.59,
          1335.49,
          1325.49,
          1327.4,
          1327.04,
          1324.37,
          1342.03,
          1342.92,
          1325.69,
          1260.85,
          1261.16,
          1253.09,
          1280.37,
          1258.03,
          1225.56,
          1224.54,
          1227.18,
          1240.58,
          1235.47,
          1256.67,
          1273.41,
          1273.4,
          1248.92,
          1252.24,
          1248.37,
          1242.05,
          1211.94,
          1229.23,
          1231.54,
          1209.02,
          1219.44,
          1216.94,
          1221.94,
          1220.14,
          1214.28,
          1211.7,
          1225.62,
          1235.25,
          1228.84,
          1222.6,
          1218.49,
          1219.43,
          1236.14,
          1232.0,
          1256.25,
          1265.97,
          1269.83,
          1277.73,
          1272.06,
          1276.7,
          1281.44,
          1277.15,
          1272.33,
          1280.96,
          1293.79,
          1295.1,
          1300.4,
          1299.93,
          1294.93,
          1291.06
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ],
        "color": [
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index"
        ]
      }
    },
    "VPB": {
      "prices": {
        "t": [
          1644192000000000000,
          1644278400000000000,
          1644364800000000000,
          1644451200000000000,
          1644537600000000000,
          1644796800000000000,
          1644883200000000000,
          1644969600000000000,
          1645056000000000000,
          1645142400000000000,
          1645401600000000000,
          1645488000000000000,
          1645574400000000000,
          1645660800000000000,
          1645747200000000000,
          1646006400000000000,
          1646092800000000000,
          1646179200000000000,
          1646265600000000000,
          1646352000000000000,
          1646611200000000000,
          1646697600000000000,
          1646784000000000000,
          1646870400000000000,
          1646956800000000000,
          1647216000000000000,
          1647302400000000000,
          1647388800000000000,
          1647475200000000000,
          1647561600000000000,
          1647820800000000000,
          1647907200000000000,
          1647993600000000000,
          1648080000000000000,
          1648166400000000000,
          1648425600000000000,
          1648512000000000000,
          1648598400000000000,
          1648684800000000000,
          1648771200000000000,
          1649030400000000000,
          1649116800000000000,
          1649203200000000000,
          1649289600000000000,
          1649376000000000000,
          1649721600000000000,
          1649808000000000000,
          1649894400000000000,
          1649980800000000000,
          1650240000000000000,
          1650326400000000000,
          1650412800000000000,
          1650499200000000000,
          1650585600000000000,
          1650844800000000000,
          1650931200000000000,
          1651017600000000000,
          1651104000000000000,
          1651190400000000000,
          1651622400000000000,
          1651708800000000000,
          1651795200000000000,
          1652054400000000000,
          1652140800000000000,
          1652227200000000000,
          1652313600000000000,
          1652400000000000000,
          1652659200000000000,
          1652745600000000000,
          1652832000000000000,
          1652918400000000000,
          1653004800000000000,
          1653264000000000000,
          1653350400000000000,
          1653436800000000000,
          1653523200000000000,
          1653609600000000000,
          1653868800000000000,
          1653955200000000000,
          1654041600000000000,
          1654128000000000000,
          1654214400000000000,
          1654473600000000000,
          1654560000000000000,
          1654646400000000000,
          1654732800000000000,
          1654819200000000000,
          1655078400000000000,
          1655164800000000000,
          1655251200000000000,
          1655337600000000000,
          1655424000000000000,
          1655683200000000000,
          1655769600000000000,
          1655856000000000000,
          1655942400000000000,
          1656028800000000000,
          1656288000000000000,
          1656374400000000000,
          1656460800000000000,
          1656547200000000000,
          1656633600000000000,
          1656892800000000000,
          1656979200000000000,
          1657065600000000000,
          1657152000000000000,
          1657238400000000000,
          1657497600000000000,
          1657584000000000000,
          1657670400000000000,
          1657756800000000000,
          1657843200000000000,
          1658102400000000000,
          1658188800000000000,
          1658275200000000000,
          1658361600000000000,
          1658448000000000000,
          1658707200000000000,
          1658793600000000000,
          1658880000000000000,
          1658966400000000000,
          1659052800000000000,
          1659312000000000000,
          1659398400000000000,
          1659484800000000000,
          1659571200000000000,
          1659657600000000000,
          1659916800000000000,
          1660003200000000000,
          1660089600000000000,
          1660176000000000000,
          1660262400000000000,
          1660521600000000000,
          1660608000000000000,
          1660694400000000000,
          1660780800000000000,
          1660867200000000000,
          1661126400000000000
        ],
        "close": [
          36.3,
          37.3,
          37.1,
          36.7,
          36.6,
          35.0,
          35.95,
          35.65,
          36.3,
          35.9,
          35.9,
          35.55,
          35.9,
          36.9,
          38.2,
          38.0,
          37.75,
          36.5,
          37.45,
          38.1,
          37.7,
          37.0,
          36.85,
          36.75,
          36.4,
          36.0,
          36.9,
          36.8,
          36.45,
          36.25,
          36.8,
          36.85,
          36.65,
          36.35,
          36.75,
          36.25,
          36.4,
          36.8,
          37.2,
          38.6,
          39.0,
          38.3,
          39.95,
          39.8,
          38.8,
          39.0,
          39.0,
          39.5,
          38.5,
          36.65,
          35.8,
          35.2,
          35.5,
          36.8,
          34.25,
          36.4,
          35.95,
          36.5,
          36.7,
          35.8,
          35.55,
          34.15,
          31.8,
          33.3,
          33.4,
          31.1,
          29.0,
          29.0,
          31.0,
          30.95,
          30.45,
          30.6,
          29.3,
          30.0,
          31.5,
          31.05,
          31.1,
          31.6,
          31.0,
          30.85,
          30.25,
          30.75,
          30.6,
          30.45,
          31.8,
          31.5,
          30.9,
          28.75,
          28.85,
          28.85,
          29.0,
          27.9,
          27.1,
          27.2,
          28.4,
          28.45,
          28.15,
          28.9,
          30.0,
          30.0,
          29.0,
          29.1,
          29.2,
          29.5,
          28.8,
          28.9,
          28.6,
          27.5,
          27.75,
          27.75,
          28.05,
          28.25,
          28.15,
          28.0,
          28.15,
          28.2,
          28.15,
          27.65,
          27.7,
          27.7,
          28.4,
          28.7,
          29.1,
          29.05,
          29.0,
          29.4,
          29.35,
          30.0,
          30.25,
          30.1,
          29.6,
          29.9,
          30.35,
          30.15,
          30.15,
          30.05,
          31.25,
          31.25
        ],
        "open": [
          37.0,
          36.15,
          37.5,
          37.05,
          36.8,
          36.2,
          35.2,
          36.1,
          35.65,
          35.9,
          35.8,
          35.5,
          35.7,
          35.85,
          37.3,
          38.2,
          37.9,
          37.4,
          36.8,
          37.5,
          38.0,
          37.5,
          37.15,
          37.5,
          36.65,
          36.1,
          36.0,
          37.0,
          36.95,
          36.55,
          36.5,
          36.8,
          37.05,
          36.65,
          36.35,
          36.65,
          36.25,
          36.3,
          36.9,
          37.1,
          39.6,
          38.7,
          38.1,
          40.1,
          39.8,
          39.1,
          39.4,
          39.0,
          39.3,
          38.5,
          36.85,
          35.5,
          35.1,
          36.2,
          36.65,
          33.6,
          36.0,
          36.1,
          36.5,
          36.75,
          36.35,
          35.0,
          33.7,
          31.15,
          33.3,
          32.95,
          31.2,
          30.0,
          29.0,
          31.5,
          30.1,
          30.6,
          30.8,
          29.6,
          30.35,
          31.7,
          31.1,
          31.45,
          31.5,
          30.9,
          30.9,
          30.2,
          30.7,
          30.55,
          30.6,
          31.9,
          31.3,
          30.0,
          28.2,
          28.85,
          29.2,
          28.3,
          27.9,
          27.35,
          27.85,
          28.0,
          28.3,
          28.3,
          28.5,
          29.6,
          29.9,
          29.1,
          29.2,
          29.25,
          29.3,
          28.85,
          29.0,
          28.4,
          27.65,
          27.8,
          27.65,
          28.1,
          28.5,
          28.15,
          28.3,
          28.2,
          28.3,
          28.15,
          27.6,
          27.6,
          28.0,
          28.5,
          28.7,
          29.3,
          28.85,
          29.25,
          29.4,
          29.4,
          30.2,
          30.3,
          30.4,
          29.65,
          30.2,
          30.35,
          30.2,
          30.3,
          30.2,
          31.25
        ],
        "high": [
          37.2,
          37.9,
          38.0,
          37.25,
          37.2,
          36.45,
          36.2,
          36.4,
          36.3,
          36.0,
          36.15,
          35.9,
          36.15,
          37.2,
          38.8,
          38.7,
          38.2,
          37.4,
          37.45,
          38.95,
          38.3,
          37.7,
          37.25,
          37.55,
          37.05,
          36.3,
          36.95,
          37.25,
          37.1,
          36.7,
          36.9,
          37.6,
          37.05,
          36.7,
          36.9,
          36.65,
          36.5,
          37.45,
          37.5,
          38.6,
          39.9,
          39.2,
          40.2,
          41.05,
          40.05,
          40.2,
          39.45,
          40.6,
          39.7,
          38.6,
          37.45,
          36.25,
          36.5,
          37.2,
          36.95,
          36.6,
          36.45,
          36.7,
          36.9,
          36.75,
          36.35,
          35.15,
          33.95,
          33.3,
          33.9,
          33.0,
          31.6,
          30.55,
          31.0,
          32.15,
          30.9,
          30.95,
          30.8,
          30.2,
          31.5,
          31.75,
          31.3,
          31.6,
          31.6,
          31.2,
          30.95,
          30.75,
          31.2,
          30.7,
          31.95,
          31.95,
          31.75,
          30.45,
          29.2,
          29.4,
          29.4,
          28.5,
          28.0,
          27.95,
          28.95,
          28.55,
          28.75,
          29.1,
          30.35,
          30.25,
          30.2,
          29.35,
          29.5,
          29.85,
          29.55,
          29.05,
          29.15,
          28.5,
          28.2,
          28.2,
          28.1,
          28.35,
          28.65,
          28.15,
          28.45,
          28.35,
          28.5,
          28.15,
          28.05,
          27.75,
          28.8,
          28.7,
          29.1,
          29.3,
          29.15,
          29.95,
          29.5,
          30.0,
          30.8,
          30.35,
          30.5,
          29.9,
          30.45,
          30.35,
          30.5,
          30.35,
          31.8,
          31.6
        ],
        "low": [
          36.3,
          36.1,
          37.1,
          36.15,
          36.5,
          35.0,
          35.2,
          35.65,
          35.55,
          35.7,
          35.4,
          35.2,
          35.5,
          35.6,
          37.0,
          37.5,
          37.45,
          36.5,
          36.5,
          37.45,
          37.35,
          36.75,
          36.5,
          36.75,
          36.2,
          35.6,
          36.0,
          36.8,
          36.45,
          36.2,
          36.1,
          36.8,
          36.65,
          36.35,
          36.3,
          36.0,
          36.25,
          36.2,
          36.8,
          37.0,
          38.9,
          38.15,
          37.85,
          39.8,
          38.8,
          39.0,
          38.0,
          38.9,
          37.7,
          36.65,
          35.7,
          35.2,
          34.5,
          35.4,
          34.25,
          33.1,
          35.4,
          35.85,
          36.3,
          35.8,
          34.75,
          34.15,
          31.8,
          31.15,
          32.55,
          31.1,
          29.0,
          29.0,
          28.95,
          30.7,
          29.95,
          30.3,
          29.2,
          29.2,
          30.2,
          31.05,
          30.75,
          31.05,
          30.8,
          30.4,
          30.1,
          30.15,
          30.35,
          29.8,
          30.25,
          31.5,
          30.9,
          28.75,
          28.2,
          27.6,
          28.95,
          27.3,
          26.5,
          26.8,
          27.5,
          27.95,
          28.15,
          28.25,
          28.5,
          29.5,
          29.0,
          28.1,
          28.85,
          29.2,
          28.8,
          28.45,
          28.55,
          27.5,
          27.45,
          27.55,
          27.5,
          28.05,
          28.15,
          27.75,
          28.1,
          28.0,
          28.1,
          27.65,
          27.55,
          27.45,
          27.8,
          28.3,
          28.55,
          28.75,
          28.65,
          29.05,
          28.95,
          29.2,
          30.0,
          29.95,
          29.6,
          29.4,
          30.0,
          29.95,
          30.05,
          29.95,
          30.05,
          31.05
        ],
        "volume": [
          11233200,
          22298900,
          18041900,
          15375100,
          11792400,
          19972100,
          12555400,
          6911100,
          10943800,
          9693800,
          10170500,
          10692300,
          13643600,
          35730400,
          49379700,
          21335600,
          16869400,
          29095300,
          20145000,
          47246400,
          19257100,
          16269800,
          13754700,
          10978100,
          10846400,
          14189800,
          11626100,
          8577500,
          8245000,
          9468400,
          6580000,
          10463600,
          9286100,
          12673000,
          9600800,
          12230200,
          7423300,
          16622400,
          9162300,
          36857900,
          24503700,
          15772700,
          41068500,
          31583800,
          23996600,
          25203900,
          25270100,
          23375700,
          29772100,
          28756000,
          24120700,
          21334600,
          18919400,
          22705300,
          32739600,
          22646100,
          12469900,
          11125800,
          13253000,
          12017800,
          10625200,
          12789500,
          23931200,
          15091000,
          7613600,
          17904700,
          20466800,
          12267800,
          10387900,
          11877200,
          9190800,
          7415500,
          11050700,
          9892000,
          12839500,
          8627400,
          10558700,
          11854000,
          10284800,
          10029300,
          9005500,
          7124300,
          7703100,
          7824600,
          19013900,
          9402900,
          9656300,
          20960500,
          11517000,
          12843800,
          5252400,
          12547400,
          9859300,
          7203800,
          7054900,
          4754900,
          3607300,
          5872800,
          12179100,
          8388900,
          7880400,
          7028900,
          8848600,
          11408500,
          5589500,
          7855100,
          7340400,
          8474400,
          8383200,
          10535300,
          9794300,
          8732200,
          7537400,
          6233900,
          9698300,
          9258800,
          7270700,
          7813800,
          7418900,
          6395800,
          18936300,
          12985400,
          12729400,
          11967000,
          13033100,
          18168400,
          12186100,
          17182600,
          20344900,
          10950100,
          15022600,
          8700500,
          11208300,
          10232300,
          10393600,
          11851100,
          31776400,
          7516600
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "stock": [
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ]
      },
      "insider": {
        "id": [
          "003C553158",
          "026C005097",
          "004C022949",
          "069C689999",
          "026C090046",
          "026C041229",
          "003C553158",
          "003C553158",
          "077C680379",
          "011C112961",
          "003C086988",
          "077C680379",
          "011C112961",
          "003C086988",
          "077C282826",
          "010C771111",
          "004C022949",
          "105C359169",
          "011C137826",
          "039C100218",
          "003C553158",
          "039C100154",
          "003C553158",
          "077C287588",
          "003C051499",
          "026C041229",
          "077C285973",
          "026C041229",
          "003C086599",
          "026C041229",
          "003C553158",
          "026C006891",
          "003C128322",
          "026C041229",
          "003C553158",
          "026C041229",
          "010C771111",
          "026C041229",
          "026C004516",
          "026C041229",
          "091C912666",
          "026C041229",
          "011C112961",
          "010C771111",
          "010C771111",
          "091C912666",
          "003C553158",
          "010C771111",
          "004C057979",
          "026C041229",
          "004C057979",
          "010C771111",
          "010C771111",
          "005C266485",
          "026C041229",
          "006C222988",
          "005C266485",
          "077C680379",
          "077C282966",
          "026C041229",
          "005C266485",
          "077C680379",
          "003C086988",
          "077C282826",
          "077C282966",
          "003C553158",
          "006C222988",
          "077C282826",
          "026C041229",
          "005C266485",
          "010C771111",
          "010C771111",
          "006C222988",
          "006C222988",
          "077C282966",
          "004C022949",
          "003C553158",
          "005C266485",
          "005C266485",
          "058C026348",
          "026C041229",
          "022C014841",
          "068C600992",
          "010C771111",
          "058C026348",
          "068C600992",
          "004C022949",
          "003C553158",
          "068C600992",
          "003C553158",
          "068C600992",
          "026C041229",
          "077C287588",
          "077C680379",
          "077C287588",
          "026C041229",
          "003C553158",
          "068C600992",
          "057C014068",
          "010C771111",
          "026C041229",
          "077C285973",
          "005C266485",
          "010C771111",
          "010C771111",
          "068C600992",
          "010C771111",
          "010C771111",
          "077C282826",
          "022C014841",
          "003C553158",
          "077C285973",
          "004C022949",
          "005C266485",
          "003C553158",
          "077C282966",
          "005C266485",
          "077C282966",
          "022C014841",
          "058C026348",
          "003C553158",
          "068C600992",
          "010C771111",
          "068C600992",
          "058C026348",
          "077C282966",
          "026C041229",
          "010C771111",
          "022C014841",
          "068C600992",
          "026C041229",
          "010C771111",
          "003C051499",
          "005C258821",
          "068C600992",
          "026C041229",
          "003C553158",
          "005C258821",
          "057C014068",
          "010C771111",
          "010C771111",
          "058C026348",
          "058C026348",
          "003C051499",
          "011C119993",
          "068C600992",
          "010C771111",
          "010C771111",
          "005C258821",
          "058C026348",
          "010C771111",
          "077C282966",
          "026C041229",
          "004C057979",
          "068C600992",
          "003C051499",
          "058C026348",
          "026C041229",
          "007C007693",
          "003C051499",
          "003C086988",
          "091C580003",
          "058C026348",
          "026C041229",
          "010C771111",
          "003C086988",
          "091C580003",
          "077C680379",
          "026C041229",
          "010C771111",
          "003C553158",
          "077C680379",
          "077C680379",
          "058C026348",
          "003C553158",
          "026C041229",
          "058C026348",
          "010C771111",
          "058C026348",
          "003C553158",
          "026C041229",
          "026C006891",
          "010C771111",
          "091C912666",
          "058C026348",
          "011C119993",
          "077C680379",
          "003C553158",
          "026C006891",
          "079C296213",
          "026C041229",
          "005C266485",
          "010C771111",
          "003C553158",
          "026C006891",
          "077C285973",
          "010C771111",
          "026C006891",
          "057C014068",
          "003C086988",
          "077C285973",
          "003C553158",
          "058C026348",
          "026C006891",
          "003C086988",
          "003C128322",
          "022C014838",
          "003C553158",
          "010C771111",
          "077C282966",
          "022C014838",
          "039C100154",
          "003C553158",
          "058C026348",
          "026C006891",
          "077C285973",
          "010C771111",
          "026C041229",
          "077C282966",
          "058C026348",
          "003C553158",
          "077C282966",
          "058C026348",
          "010C771111",
          "003C553158",
          "057C014068",
          "077C285973",
          "064C800855",
          "004C022970",
          "077C282966",
          "003C051499",
          "010C771111",
          "064C800855",
          "003C051499",
          "058C026348",
          "068C018516",
          "003C128322",
          "010C771111",
          "004C022970",
          "003C553158",
          "010C771111",
          "003C051499",
          "004C022970",
          "064C800855",
          "058C026348",
          "003C553158",
          "077C285973",
          "003C553158",
          "010C771111",
          "091C580003",
          "026C041229",
          "077C282966",
          "077C285973",
          "077C285973",
          "011C119993",
          "003C086988",
          "077C282966",
          "003C553158",
          "010C771111",
          "077C282966",
          "003C051499",
          "003C086988",
          "010C771111",
          "068C018516",
          "003C086988",
          "003C553158",
          "003C051499",
          "003C128322",
          "004C022970",
          "081C001308",
          "007C007707",
          "003C051499",
          "003C128322",
          "003C128322",
          "003C553158",
          "077C282966",
          "026C006891",
          "007C007693",
          "003C051499",
          "026C006891",
          "026C041229",
          "007C007693",
          "003C553158",
          "057C014068",
          "007C007693",
          "077C282966",
          "003C553158",
          "007C007693",
          "077C282966",
          "003C553158",
          "007C007693",
          "057C014068",
          "064C800855",
          "003C553158",
          "026C006891",
          "057C014068",
          "011C680010",
          "064C800855",
          "077C282826",
          "010C771111",
          "003C553158",
          "091C912666",
          "003C553158",
          "010C771111",
          "057C014068",
          "010C771111",
          "007C007707",
          "064C800855",
          "026C006891",
          "026C006891",
          "026C006891",
          "058C026348",
          "003C553158",
          "010C771111",
          "064C800855",
          "003C553158",
          "011C119993",
          "026C006891",
          "010C771111",
          "077C282966",
          "026C006891",
          "003C553158",
          "011C119993",
          "057C014068",
          "077C282966",
          "011C118525",
          "058C026348",
          "011C118525",
          "026C041229",
          "003C553158",
          "077C680379",
          "058C026348",
          "010C771111",
          "011C118525",
          "026C006891",
          "077C285973",
          "058C026348",
          "026C256699",
          "077C282966",
          "003C553158",
          "026C006891",
          "077C282966",
          "010C771111",
          "081C001461",
          "081C001461",
          "026C256699",
          "026C006891",
          "003C553158",
          "091C580003",
          "003C553158",
          "058C026348",
          "026C018828",
          "010C771111",
          "003C553158",
          "026C006891"
        ],
        "day": [
          "2022_02_17",
          "2022_02_23",
          "2022_02_23",
          "2022_02_23",
          "2022_02_23",
          "2022_02_24",
          "2022_02_24",
          "2022_02_25",
          "2022_02_25",
          "2022_02_25",
          "2022_02_25",
          "2022_02_28",
          "2022_02_28",
          "2022_02_28",
          "2022_02_28",
          "2022_02_28",
          "2022_03_01",
          "2022_03_01",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_03",
          "2022_03_04",
          "2022_03_04",
          "2022_03_04",
          "2022_03_07",
          "2022_03_10",
          "2022_03_10",
          "2022_03_15",
          "2022_03_15",
          "2022_03_15",
          "2022_03_29",
          "2022_03_29",
          "2022_04_01",
          "2022_04_01",
          "2022_04_04",
          "2022_04_14",
          "2022_04_15",
          "2022_05_11",
          "2022_05_11",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_13",
          "2022_05_13",
          "2022_05_17",
          "2022_05_17",
          "2022_05_17",
          "2022_05_17",
          "2022_05_18",
          "2022_05_18",
          "2022_05_18",
          "2022_05_19",
          "2022_05_19",
          "2022_05_19",
          "2022_05_19",
          "2022_05_20",
          "2022_05_20",
          "2022_05_20",
          "2022_05_20",
          "2022_05_23",
          "2022_05_23",
          "2022_05_23",
          "2022_05_23",
          "2022_05_23",
          "2022_05_24",
          "2022_05_24",
          "2022_05_24",
          "2022_05_24",
          "2022_05_24",
          "2022_05_25",
          "2022_05_25",
          "2022_05_25",
          "2022_05_26",
          "2022_05_26",
          "2022_05_27",
          "2022_05_27",
          "2022_05_27",
          "2022_05_30",
          "2022_05_30",
          "2022_05_31",
          "2022_05_31",
          "2022_05_31",
          "2022_05_31",
          "2022_05_31",
          "2022_06_01",
          "2022_06_01",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_03",
          "2022_06_03",
          "2022_06_03",
          "2022_06_03",
          "2022_06_06",
          "2022_06_06",
          "2022_06_06",
          "2022_06_06",
          "2022_06_06",
          "2022_06_06",
          "2022_06_07",
          "2022_06_07",
          "2022_06_07",
          "2022_06_07",
          "2022_06_07",
          "2022_06_07",
          "2022_06_08",
          "2022_06_08",
          "2022_06_08",
          "2022_06_08",
          "2022_06_08",
          "2022_06_08",
          "2022_06_08",
          "2022_06_08",
          "2022_06_09",
          "2022_06_09",
          "2022_06_09",
          "2022_06_10",
          "2022_06_10",
          "2022_06_10",
          "2022_06_10",
          "2022_06_10",
          "2022_06_13",
          "2022_06_13",
          "2022_06_13",
          "2022_06_13",
          "2022_06_13",
          "2022_06_13",
          "2022_06_13",
          "2022_06_14",
          "2022_06_14",
          "2022_06_14",
          "2022_06_14",
          "2022_06_14",
          "2022_06_15",
          "2022_06_15",
          "2022_06_15",
          "2022_06_15",
          "2022_06_15",
          "2022_06_15",
          "2022_06_15",
          "2022_06_15",
          "2022_06_16",
          "2022_06_16",
          "2022_06_16",
          "2022_06_16",
          "2022_06_16",
          "2022_06_16",
          "2022_06_16",
          "2022_06_17",
          "2022_06_17",
          "2022_06_17",
          "2022_06_17",
          "2022_06_17",
          "2022_06_17",
          "2022_06_17",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_20",
          "2022_06_21",
          "2022_06_21",
          "2022_06_21",
          "2022_06_21",
          "2022_06_21",
          "2022_06_21",
          "2022_06_22",
          "2022_06_22",
          "2022_06_22",
          "2022_06_22",
          "2022_06_23",
          "2022_06_23",
          "2022_06_23",
          "2022_06_23",
          "2022_06_24",
          "2022_06_24",
          "2022_06_27",
          "2022_06_27",
          "2022_06_27",
          "2022_06_27",
          "2022_06_27",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_28",
          "2022_06_29",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_01",
          "2022_07_01",
          "2022_07_01",
          "2022_07_01",
          "2022_07_01",
          "2022_07_04",
          "2022_07_04",
          "2022_07_04",
          "2022_07_04",
          "2022_07_04",
          "2022_07_04",
          "2022_07_05",
          "2022_07_05",
          "2022_07_05",
          "2022_07_05",
          "2022_07_05",
          "2022_07_05",
          "2022_07_05",
          "2022_07_05",
          "2022_07_06",
          "2022_07_06",
          "2022_07_06",
          "2022_07_06",
          "2022_07_06",
          "2022_07_07",
          "2022_07_07",
          "2022_07_07",
          "2022_07_07",
          "2022_07_08",
          "2022_07_08",
          "2022_07_08",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_11",
          "2022_07_12",
          "2022_07_12",
          "2022_07_12",
          "2022_07_12",
          "2022_07_12",
          "2022_07_12",
          "2022_07_12",
          "2022_07_13",
          "2022_07_13",
          "2022_07_13",
          "2022_07_13",
          "2022_07_13",
          "2022_07_13",
          "2022_07_14",
          "2022_07_14",
          "2022_07_14",
          "2022_07_14",
          "2022_07_15",
          "2022_07_15",
          "2022_07_15",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_18",
          "2022_07_19",
          "2022_07_19",
          "2022_07_19",
          "2022_07_20",
          "2022_07_20",
          "2022_07_20",
          "2022_07_20",
          "2022_07_20",
          "2022_07_20",
          "2022_07_21",
          "2022_07_21",
          "2022_07_21",
          "2022_07_21",
          "2022_07_21",
          "2022_07_22",
          "2022_07_22",
          "2022_07_22",
          "2022_07_22",
          "2022_07_22",
          "2022_07_25",
          "2022_07_25",
          "2022_07_25",
          "2022_07_26",
          "2022_07_26",
          "2022_07_26",
          "2022_07_27",
          "2022_07_27",
          "2022_07_27",
          "2022_07_28",
          "2022_07_28",
          "2022_07_28",
          "2022_07_29",
          "2022_07_29",
          "2022_07_29",
          "2022_07_29",
          "2022_08_01",
          "2022_08_01",
          "2022_08_01",
          "2022_08_02",
          "2022_08_02",
          "2022_08_02",
          "2022_08_02",
          "2022_08_02",
          "2022_08_03",
          "2022_08_03",
          "2022_08_03",
          "2022_08_03",
          "2022_08_03",
          "2022_08_03",
          "2022_08_03",
          "2022_08_04",
          "2022_08_04",
          "2022_08_04",
          "2022_08_04",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_08",
          "2022_08_08",
          "2022_08_08",
          "2022_08_08",
          "2022_08_09",
          "2022_08_09",
          "2022_08_09",
          "2022_08_09",
          "2022_08_09",
          "2022_08_09",
          "2022_08_10",
          "2022_08_10",
          "2022_08_11",
          "2022_08_11",
          "2022_08_11",
          "2022_08_11",
          "2022_08_11",
          "2022_08_11",
          "2022_08_11",
          "2022_08_12",
          "2022_08_12",
          "2022_08_12",
          "2022_08_12",
          "2022_08_15",
          "2022_08_15",
          "2022_08_15",
          "2022_08_15",
          "2022_08_15",
          "2022_08_17",
          "2022_08_17",
          "2022_08_17",
          "2022_08_17",
          "2022_08_18",
          "2022_08_18",
          "2022_08_18",
          "2022_08_19",
          "2022_08_19",
          "2022_08_19",
          "2022_08_19"
        ],
        "stock": [
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB",
          "VPB"
        ],
        "side": [
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "buy",
          "sell",
          "buy",
          "buy",
          "sell",
          "sell",
          "sell",
          "buy",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell",
          "sell"
        ],
        "TYPES": [
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider",
          "Insider"
        ],
        "TradeValue": [
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          -29.3,
          -1.5,
          0.0,
          5.4,
          1.6,
          29.3,
          -10.3,
          -2.8,
          -1.7,
          8.2,
          10.3,
          18.8,
          -1.4,
          -3.1,
          -2.8,
          11.4,
          11.9,
          -37.3,
          -1.4,
          -0.3,
          -24.8,
          -0.1,
          -47.1,
          -13.9,
          -0.6,
          -2.4,
          -2.9,
          1.5,
          -25.5,
          -12.9,
          12.4,
          -5.7,
          -14.2,
          25.5,
          -2.2,
          -19.8,
          -3.0,
          -1.5,
          -3.1,
          -20.0,
          -3.3,
          -0.6,
          32.0,
          69.8,
          -7.9,
          -0.2,
          -5.8,
          -0.2,
          0.3,
          -3.5,
          7.4,
          -2.9,
          -1.8,
          21.0,
          1.2,
          5.3,
          -1.5,
          -17.7,
          -2.4,
          -9.1,
          -0.4,
          -0.7,
          14.5,
          -15.4,
          -1.4,
          3.8,
          -20.1,
          -0.4,
          -4.3,
          10.9,
          -21.4,
          -17.2,
          13.0,
          0.0,
          -3.1,
          -2.5,
          -7.4,
          -19.7,
          -21.1,
          21.1,
          -4.2,
          -0.3,
          6.9,
          -1.4,
          -0.1,
          -4.7,
          15.1,
          -15.2,
          -14.6,
          -2.8,
          -16.3,
          -1.0,
          -0.3,
          0.0,
          -0.6,
          4.5,
          -18.4,
          -2.1,
          5.6,
          22.6,
          4.4,
          -0.8,
          -1.7,
          9.5,
          -16.8,
          3.4,
          -0.8,
          23.4,
          2.3,
          -5.6,
          -7.3,
          -6.1,
          4.2,
          11.4,
          16.9,
          -16.6,
          -16.1,
          -3.6,
          -1.4,
          -16.9,
          -5.3,
          21.1,
          3.2,
          -1.1,
          8.7,
          -15.8,
          -1.4,
          1.9,
          -9.7,
          -26.9,
          -1.6,
          26.4,
          -12.1,
          -38.7,
          2.9,
          -22.4,
          -14.7,
          -7.2,
          -6.9,
          17.2,
          -2.0,
          -24.0,
          -0.4,
          2.9,
          -0.9,
          -1.5,
          -1.4,
          2.9,
          4.3,
          -1.7,
          7.2,
          -24.4,
          -0.5,
          0.2,
          -0.9,
          -48.2,
          -5.3,
          -2.4,
          0.0,
          -1.5,
          -16.1,
          -0.8,
          -5.7,
          -15.9,
          -10.0,
          -12.1,
          -26.5,
          5.5,
          -9.5,
          15.1,
          -21.1,
          25.5,
          -8.9,
          -0.6,
          5.5,
          -7.1,
          -0.3,
          1.5,
          9.8,
          -0.2,
          -17.3,
          2.8,
          16.5,
          -3.0,
          -2.1,
          15.9,
          -1.6,
          12.1,
          12.1,
          -2.9,
          -6.3,
          -3.0,
          7.6,
          -3.7,
          -19.6,
          -7.5,
          -6.1,
          -19.0,
          -37.2
        ],
        "volume": [
          100000,
          331800,
          250000,
          148100,
          200000,
          700000,
          350000,
          447300,
          376000,
          1056100,
          1000000,
          200000,
          950000,
          420000,
          600000,
          20000,
          400000,
          896200,
          200000,
          500000,
          250000,
          400000,
          555400,
          790000,
          755600,
          779400,
          250000,
          300000,
          401000,
          450000,
          500000,
          173100,
          1500000,
          860000,
          360600,
          345000,
          169800,
          4000,
          4000,
          65000,
          50000,
          30000,
          360000,
          5000,
          80000,
          100000,
          800,
          60000,
          400000,
          75000,
          600000,
          53500,
          13000,
          300000,
          62300,
          701000,
          318000,
          100000,
          400000,
          160000,
          200000,
          270000,
          270000,
          437700,
          250100,
          45000,
          400000,
          100000,
          20000,
          400000,
          15000,
          8000,
          467000,
          250000,
          465000,
          195200,
          120000,
          550000,
          250000,
          150000,
          189400,
          500000,
          100000,
          70000,
          861500,
          100000,
          590000,
          88100,
          60000,
          50000,
          40000,
          150000,
          701000,
          100000,
          350000,
          20000,
          100000,
          10000,
          200100,
          20000,
          50000,
          300000,
          700000,
          440000,
          100000,
          65000,
          30000,
          460000,
          650000,
          100100,
          550100,
          700000,
          500100,
          800000,
          100000,
          300000,
          687000,
          300000,
          180000,
          400000,
          100000,
          100000,
          2000,
          265000,
          449100,
          213900,
          50000,
          10000,
          505000,
          100000,
          25000,
          10000,
          635000,
          701000,
          90000,
          150000,
          268000,
          741000,
          450000,
          10000,
          48100,
          910000,
          730000,
          410000,
          165000,
          20000,
          10200,
          30200,
          615000,
          750000,
          30000,
          750000,
          20000,
          335000,
          50000,
          940000,
          1080000,
          55000,
          1000,
          200000,
          60000,
          1080000,
          380000,
          102300,
          63800,
          300000,
          380000,
          690000,
          47700,
          110000,
          100000,
          400000,
          418000,
          1310000,
          50000,
          10000,
          880000,
          5000,
          1630000,
          480000,
          20000,
          83000,
          100000,
          50000,
          850000,
          430000,
          411800,
          190600,
          473000,
          850000,
          75000,
          660000,
          100000,
          50000,
          105000,
          690200,
          115000,
          21000,
          1100000,
          2400000,
          270000,
          5200,
          200000,
          7000,
          10000,
          121000,
          255100,
          100000,
          60000,
          713400,
          41000,
          179000,
          50000,
          600000,
          80000,
          310000,
          15000,
          25000,
          503400,
          535500,
          50000,
          130000,
          695400,
          12900,
          150000,
          382300,
          748600,
          600000,
          472000,
          100,
          112800,
          90000,
          268400,
          710000,
          760000,
          760000,
          150400,
          10000,
          250000,
          50000,
          5000,
          170000,
          545000,
          548200,
          527900,
          100000,
          580000,
          36900,
          10000,
          200,
          20000,
          158500,
          650000,
          75000,
          200000,
          803000,
          156000,
          30000,
          60000,
          340000,
          600000,
          120000,
          30000,
          830000,
          80000,
          200000,
          260000,
          215000,
          150000,
          406000,
          600000,
          590000,
          570900,
          129100,
          50000,
          602000,
          190000,
          751100,
          115100,
          40000,
          315000,
          570000,
          50000,
          70000,
          350000,
          970100,
          57300,
          930000,
          425600,
          1363700,
          100000,
          783200,
          515100,
          250000,
          236000,
          591500,
          70000,
          825000,
          15000,
          100000,
          30000,
          50000,
          50000,
          100000,
          150000,
          60000,
          250000,
          842700,
          18000,
          8000,
          32000,
          1640000,
          180000,
          80000,
          100,
          50000,
          535100,
          27000,
          190000,
          530000,
          330800,
          400000,
          875000,
          180300,
          315000,
          500000,
          701000,
          846000,
          300000,
          20000,
          185100,
          240000,
          10200,
          50000,
          332500,
          6900,
          580000,
          95000,
          550300,
          100000,
          70000,
          525000,
          52000,
          400000,
          400000,
          95000,
          208000,
          100000,
          251000,
          121700,
          650000,
          240000,
          195900,
          615000,
          1200000
        ],
        "name": [
          "L\u00ea Th\u1ecb Lan",
          "\u0110\u1eb7ng Th\u1ecb Thanh T\u00e2m ",
          "L\u00ca VI\u1ec6T ANH",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng",
          "C\u00d4NG \u0110O\u00c0N NH TMCP VI\u1ec6T NAM TH\u1ecaNH V\u01af\u1ee2NG-H\u1ed8I S\u1ede",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ea Th\u1ecb Lan",
          "L\u00ea Th\u1ecb Lan",
          "L\u00ca VI\u1ec6T ANH",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ca VI\u1ec6T ANH",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ca VI\u1ec6T ANH",
          "\u0110\u1eb7ng Th\u1ecb Thanh T\u00e2m",
          "NGUY\u1ec4N TH\u1eca HOA",
          "L\u00ea Vi\u1ec7t Anh",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Lan",
          "L\u00ea Vi\u1ec7t Anh",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Lan",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ea Th\u1ecb H\u1eadu",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng",
          "L\u00ea Th\u1ecb Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "L\u00ca VI\u1ec6T ANH",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "L\u00ca VI\u1ec6T ANH",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ca VI\u1ec6T ANH",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "TR\u1ea6N NG\u1eccC LAN",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ca VI\u1ec6T ANH",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "TR\u1ea6N NG\u1eccC LAN",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "L\u00ca VI\u1ec6T ANH",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "Tr\u1ea7n Ng\u1ecdc Lan",
          "L\u00ca VI\u1ec6T ANH",
          "Tr\u1ea7n Ng\u1ecdc Lan",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "L\u00ca VI\u1ec6T ANH",
          "L\u00ea Th\u1ecb Lan",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "L\u00ca VI\u1ec6T ANH",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ca VI\u1ec6T ANH",
          "TR\u1ea6N NG\u1eccC LAN",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "TR\u1ea6N NG\u1eccC LAN",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ca VI\u1ec6T ANH",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ea Vi\u1ec7t Anh",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "TR\u1ea6N NG\u1eccC LAN",
          "TR\u1ea6N NG\u1eccC LAN",
          "L\u00ea Vi\u1ec7t Anh",
          "TR\u1ea6N NG\u1eccC LAN",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "TR\u1ea6N NG\u1eccC LAN",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng",
          "L\u00ea Vi\u1ec7t Anh",
          "TR\u1ea6N NG\u1eccC LAN",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ea Vi\u1ec7t Anh",
          "L\u00ea Vi\u1ec7t Anh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "TR\u1ea6N NG\u1eccC LAN",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ca VI\u1ec6T ANH",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ea Th\u1ecb Lan",
          "L\u00ca VI\u1ec6T ANH",
          "L\u00ca VI\u1ec6T ANH",
          "TR\u1ea6N NG\u1eccC LAN",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "TR\u1ea6N NG\u1eccC LAN",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "TR\u1ea6N NG\u1eccC LAN",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng",
          "TR\u1ea6N NG\u1eccC LAN",
          "TR\u1ea6N NG\u1eccC LAN",
          "L\u00ca VI\u1ec6T ANH",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "Tr\u00e2\u0300n Ngo\u0323c Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "L\u00ea Th\u1ecb Lan",
          "TR\u1ea6N NG\u1eccC LAN",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Lan",
          "NGUY\u1ec4N TH\u1eca HOA",
          "L\u00ea Th\u1ecb Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "NGUY\u1ec4N TH\u1eca HOA",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "TR\u1ea6N NG\u1eccC LAN",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "TR\u1ea6N NG\u1eccC LAN",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "TR\u1ea6N NG\u1eccC LAN",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "V\u0168 \u0110\u00ccNH CHI\u1ebeN",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Vi\u1ec7t Anh",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Vi\u1ec7t Anh",
          "TR\u1ea6N NG\u1eccC LAN",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "V\u0168 \u0110\u00ccNH CHI\u1ebeN",
          "L\u00ea Th\u1ecb Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ea Vi\u1ec7t Anh",
          "V\u0168 \u0110\u00ccNH CHI\u1ebeN",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "TR\u1ea6N NG\u1eccC LAN",
          "L\u00ea Th\u1ecb Lan",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "L\u00ea Th\u1ecb Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "TR\u1ea6N NG\u1eccC LAN",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Vi\u1ec7t Anh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "L\u00ea Vi\u1ec7t Anh",
          "Tr\u1ea7n Ng\u1ecdc Lan",
          "V\u0168 \u0110\u00ccNH CHI\u1ebeN",
          "TR\u1ea6N NG\u1eccC TRUNG",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "L\u00ea Vi\u1ec7t Anh",
          "Tr\u1ea7n Ng\u1ecdc Lan",
          "Tr\u1ea7n Ng\u1ecdc Lan",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Vi\u1ec7t Anh",
          "L\u00ea Vi\u1ec7t Anh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ea Vi\u1ec7t Anh",
          "L\u00ea Th\u1ecb Lan",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "L\u00ea Vi\u1ec7t Anh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "L\u00ea Vi\u1ec7t Anh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "L\u00ea Vi\u1ec7t Anh",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "L\u00ca MINH ANH",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ea Th\u1ecb Lan",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng",
          "L\u00ea Th\u1ecb Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "TR\u1ea6N NG\u1eccC LAN",
          "L\u00ea Th\u1ecb Lan",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "TR\u1ea6N NG\u1eccC LAN",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "TR\u1ea6N NG\u1eccC LAN",
          "Nguy\u1ec5n Th\u1ecb Hoa",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ca VI\u1ec6T ANH",
          "TR\u1ea6N NG\u1eccC LAN",
          "L\u00ca VI\u1ec6T ANH",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng",
          "L\u00ea Th\u1ecb Lan",
          "L\u00ca VI\u1ec6T ANH",
          "TR\u1ea6N NG\u1eccC LAN",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ca VI\u1ec6T ANH",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn",
          "TR\u1ea6N NG\u1eccC LAN",
          "Nguy\u1ec5n Th\u1ecb Thu H\u01b0\u01a1ng",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "NGUY\u1ec4N TH\u1eca HOA",
          "NGUY\u1ec4N TH\u1eca HOA",
          "Nguy\u1ec5n Th\u1ecb Thu H\u01b0\u01a1ng",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung",
          "L\u00ea Th\u1ecb Lan",
          "TR\u1ea6N NG\u1eccC LAN",
          "V\u00f5 Th\u1ecb Th\u00fay H\u00e0",
          "B\u00f9i \u0110\u00ecnh D\u0129nh",
          "L\u00ea Th\u1ecb Lan",
          "Tr\u1ea7n Ng\u1ecdc Trung"
        ],
        "Value": [
          3.61,
          11.86,
          8.97,
          5.29,
          7.19,
          25.74,
          12.83,
          17.24,
          14.32,
          39.46,
          38.63,
          7.6,
          36.33,
          16.06,
          22.99,
          0.76,
          15.18,
          34.01,
          7.59,
          18.36,
          9.29,
          14.69,
          21.3,
          30.21,
          29.01,
          29.73,
          9.29,
          11.16,
          14.5,
          16.4,
          18.36,
          6.3,
          54.6,
          33.2,
          13.92,
          13.62,
          6.8,
          0.16,
          0.13,
          2.15,
          1.66,
          0.98,
          10.72,
          0.15,
          2.44,
          2.98,
          0.02,
          1.8,
          11.86,
          2.34,
          19.02,
          1.67,
          0.4,
          9.16,
          1.89,
          21.42,
          9.74,
          3.07,
          12.24,
          4.9,
          5.93,
          8.06,
          8.06,
          13.04,
          7.5,
          1.35,
          11.97,
          2.99,
          0.6,
          11.99,
          0.46,
          0.24,
          14.38,
          7.86,
          14.62,
          6.04,
          3.75,
          17.02,
          7.82,
          4.67,
          5.87,
          15.5,
          3.1,
          2.17,
          26.71,
          3.08,
          18.12,
          2.74,
          1.81,
          1.54,
          1.23,
          4.61,
          21.56,
          3.08,
          10.71,
          0.61,
          3.06,
          0.31,
          6.12,
          0.61,
          1.52,
          9.08,
          21.19,
          13.28,
          3.04,
          1.96,
          0.95,
          14.63,
          20.67,
          3.18,
          17.49,
          22.26,
          15.9,
          25.44,
          3.15,
          9.45,
          21.64,
          9.27,
          5.56,
          12.36,
          3.09,
          3.09,
          0.06,
          7.62,
          12.91,
          6.15,
          1.44,
          0.29,
          14.52,
          2.86,
          0.72,
          0.28,
          18.2,
          20.31,
          2.6,
          4.33,
          7.73,
          21.38,
          12.98,
          0.29,
          1.39,
          26.25,
          21.17,
          11.89,
          4.79,
          0.58,
          0.3,
          0.88,
          17.84,
          20.93,
          0.84,
          20.93,
          0.56,
          9.35,
          1.4,
          26.23,
          29.27,
          1.49,
          0.03,
          5.42,
          1.63,
          29.27,
          10.34,
          2.78,
          1.74,
          8.16,
          10.34,
          18.77,
          1.35,
          3.12,
          2.84,
          11.36,
          11.89,
          37.27,
          1.42,
          0.28,
          24.77,
          0.14,
          47.11,
          13.87,
          0.58,
          2.4,
          2.89,
          1.5,
          25.5,
          12.9,
          12.35,
          5.72,
          14.19,
          25.5,
          2.25,
          19.8,
          3.0,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
          NaN
        ],
        "i": [
          5479,
          5475,
          5477,
          5478,
          5476,
          5470,
          5471,
          5466,
          5469,
          5467,
          5468,
          5465,
          5462,
          5464,
          5463,
          5461,
          5458,
          5457,
          5459,
          5454,
          5450,
          5452,
          5443,
          5446,
          5447,
          5435,
          5428,
          5427,
          5421,
          5420,
          5419,
          5408,
          5407,
          5401,
          5402,
          5320,
          5308,
          5304,
          5951,
          5947,
          5948,
          5939,
          5931,
          5926,
          5933,
          5908,
          5911,
          5910,
          5915,
          5897,
          5898,
          5903,
          5895,
          5885,
          5886,
          5888,
          5872,
          5880,
          5879,
          5873,
          5860,
          5859,
          5867,
          5866,
          5868,
          5851,
          5854,
          5857,
          5852,
          5849,
          5843,
          5848,
          5845,
          5153,
          5152,
          5149,
          5147,
          5146,
          5143,
          5144,
          5136,
          5139,
          5140,
          5141,
          5135,
          5134,
          5132,
          5130,
          5129,
          5122,
          5124,
          5120,
          5119,
          5123,
          5108,
          5112,
          5110,
          5118,
          5114,
          5117,
          5100,
          5097,
          5096,
          5103,
          5099,
          5106,
          5094,
          5087,
          5091,
          5093,
          5086,
          5085,
          5092,
          5084,
          5079,
          5077,
          5076,
          5830,
          5833,
          5829,
          5831,
          5835,
          5823,
          5825,
          5819,
          5820,
          5822,
          5827,
          5824,
          5817,
          5813,
          5818,
          5816,
          5814,
          5809,
          5800,
          5799,
          5804,
          5807,
          5811,
          5802,
          5798,
          5787,
          5794,
          5788,
          5796,
          5791,
          5795,
          5793,
          5774,
          5785,
          5780,
          5776,
          5781,
          5783,
          5779,
          5749,
          5759,
          5773,
          5770,
          5772,
          5766,
          5730,
          5734,
          5735,
          5741,
          5739,
          5738,
          5727,
          5724,
          5725,
          5729,
          5720,
          5711,
          5715,
          5717,
          5703,
          5708,
          3936,
          3937,
          3941,
          3940,
          3939,
          3931,
          3914,
          3917,
          3930,
          3918,
          3916,
          3929,
          3922,
          3915,
          3921,
          2878,
          2876,
          2869,
          2860,
          2862,
          2865,
          2864,
          2859,
          2863,
          4563,
          4568,
          4572,
          4564,
          4570,
          4565,
          4549,
          4556,
          4559,
          4557,
          4550,
          4542,
          4548,
          4543,
          4533,
          4532,
          4537,
          4530,
          4531,
          4526,
          4520,
          4523,
          4521,
          4519,
          4514,
          4515,
          5602,
          5608,
          5598,
          5599,
          5596,
          5585,
          5584,
          5591,
          5586,
          5588,
          5592,
          5587,
          5579,
          5574,
          5582,
          5572,
          5573,
          5576,
          5566,
          5568,
          5569,
          5570,
          5563,
          5565,
          5562,
          5550,
          5559,
          5554,
          5560,
          5552,
          5551,
          5543,
          5539,
          5544,
          5532,
          5537,
          5538,
          5531,
          5528,
          5530,
          5524,
          5523,
          5521,
          5514,
          5515,
          5507,
          5508,
          5505,
          5506,
          5509,
          5502,
          5491,
          5499,
          5480,
          5481,
          5484,
          4243,
          4242,
          4244,
          739,
          734,
          733,
          688,
          681,
          683,
          684,
          3305,
          3310,
          3307,
          3298,
          3301,
          3304,
          3300,
          3299,
          3289,
          3296,
          3295,
          3288,
          3294,
          3287,
          3291,
          3286,
          3281,
          3278,
          3279,
          3280,
          3275,
          5699,
          5696,
          5700,
          5698,
          5697,
          5687,
          5686,
          5685,
          5693,
          5688,
          5692,
          5675,
          5681,
          5658,
          5661,
          5668,
          5659,
          5662,
          5670,
          5667,
          5652,
          5649,
          5656,
          5653,
          5642,
          5643,
          5647,
          5644,
          5648,
          5635,
          5634,
          5632,
          5633,
          5628,
          5622,
          5621,
          5611,
          5612,
          5610,
          5609
        ],
        "x": [
          242,
          246,
          246,
          246,
          246,
          247,
          247,
          248,
          248,
          248,
          248,
          249,
          249,
          249,
          249,
          249,
          250,
          250,
          250,
          251,
          252,
          252,
          253,
          253,
          253,
          254,
          257,
          257,
          260,
          260,
          260,
          270,
          270,
          273,
          273,
          274,
          281,
          282,
          298,
          298,
          298,
          299,
          300,
          300,
          300,
          302,
          302,
          302,
          302,
          303,
          303,
          303,
          304,
          304,
          304,
          304,
          305,
          305,
          305,
          305,
          306,
          306,
          306,
          306,
          306,
          307,
          307,
          307,
          307,
          307,
          308,
          308,
          308,
          309,
          309,
          310,
          310,
          310,
          311,
          311,
          312,
          312,
          312,
          312,
          312,
          313,
          313,
          313,
          314,
          315,
          315,
          315,
          315,
          315,
          316,
          316,
          316,
          316,
          316,
          316,
          317,
          317,
          317,
          317,
          317,
          317,
          318,
          318,
          318,
          318,
          318,
          318,
          318,
          318,
          319,
          319,
          319,
          320,
          320,
          320,
          320,
          320,
          321,
          321,
          321,
          321,
          321,
          321,
          321,
          322,
          322,
          322,
          322,
          322,
          323,
          323,
          323,
          323,
          323,
          323,
          323,
          323,
          324,
          324,
          324,
          324,
          324,
          324,
          324,
          325,
          325,
          325,
          325,
          325,
          325,
          325,
          326,
          326,
          326,
          326,
          326,
          326,
          327,
          327,
          327,
          327,
          327,
          327,
          328,
          328,
          328,
          328,
          329,
          329,
          329,
          329,
          330,
          330,
          331,
          331,
          331,
          331,
          331,
          332,
          332,
          332,
          332,
          332,
          332,
          332,
          332,
          332,
          332,
          333,
          333,
          334,
          335,
          335,
          335,
          335,
          335,
          335,
          336,
          336,
          336,
          336,
          336,
          336,
          337,
          337,
          337,
          337,
          337,
          337,
          337,
          337,
          338,
          338,
          338,
          338,
          338,
          339,
          339,
          339,
          339,
          340,
          340,
          340,
          341,
          341,
          341,
          341,
          341,
          342,
          342,
          342,
          342,
          342,
          342,
          342,
          343,
          343,
          343,
          343,
          343,
          343,
          344,
          344,
          344,
          344,
          345,
          345,
          345,
          346,
          346,
          346,
          346,
          346,
          346,
          347,
          347,
          347,
          348,
          348,
          348,
          348,
          348,
          348,
          349,
          349,
          349,
          349,
          349,
          350,
          350,
          350,
          350,
          350,
          351,
          351,
          351,
          352,
          352,
          352,
          353,
          353,
          353,
          354,
          354,
          354,
          355,
          355,
          355,
          355,
          356,
          356,
          356,
          357,
          357,
          357,
          357,
          357,
          358,
          358,
          358,
          358,
          358,
          358,
          358,
          359,
          359,
          359,
          359,
          359,
          360,
          361,
          361,
          361,
          361,
          361,
          362,
          362,
          362,
          362,
          362,
          362,
          363,
          363,
          364,
          364,
          364,
          364,
          364,
          364,
          364,
          365,
          365,
          365,
          365,
          366,
          366,
          366,
          366,
          366,
          368,
          368,
          368,
          368,
          369,
          369,
          369,
          370,
          370,
          370,
          370
        ],
        "size": [
          3962,
          8121,
          6805,
          4962,
          5937,
          13147,
          8400,
          9824,
          8791,
          17266,
          16648,
          5937,
          16089,
          9435,
          11884,
          1824,
          9145,
          15478,
          5937,
          10556,
          6805,
          9145,
          11300,
          14238,
          13825,
          14111,
          6805,
          7623,
          9159,
          9862,
          10556,
          5443,
          21850,
          15060,
          8561,
          8324,
          5380,
          1132,
          1132,
          3139,
          2746,
          2161,
          8551,
          1188,
          3504,
          3962,
          907,
          3011,
          9145,
          3385,
          11884,
          2841,
          1558,
          7623,
          3070,
          13159,
          7907,
          3962,
          9145,
          5194,
          5937,
          7138,
          7138,
          9688,
          6807,
          2608,
          9145,
          3962,
          1824,
          9145,
          1638,
          1339,
          10100,
          6805,
          10072,
          5850,
          4392,
          11229,
          6805,
          5000,
          5744,
          10556,
          3962,
          3263,
          15078,
          3962,
          11754,
          3693,
          3011,
          2746,
          2465,
          5000,
          13159,
          3962,
          8400,
          1824,
          3962,
          1430,
          5938,
          1824,
          2746,
          7623,
          13147,
          9721,
          3962,
          3139,
          2161,
          10003,
          12523,
          3964,
          11230,
          13147,
          10557,
          14356,
          3962,
          7623,
          12986,
          7623,
          5571,
          9145,
          3962,
          3962,
          1004,
          7055,
          9849,
          6184,
          2746,
          1430,
          10624,
          3962,
          1998,
          1430,
          12332,
          13159,
          3737,
          5000,
          7105,
          13649,
          9862,
          1430,
          2694,
          15636,
          13515,
          9290,
          5289,
          1824,
          1439,
          2167,
          12077,
          13758,
          2161,
          13758,
          1824,
          8171,
          2746,
          15977,
          17526,
          2880,
          925,
          5937,
          3011,
          17526,
          8850,
          4013,
          3108,
          7623,
          8850,
          13023,
          2683,
          4180,
          3962,
          9145,
          9406,
          19946,
          2746,
          1430,
          15292,
          1188,
          23111,
          10281,
          1824,
          3575,
          3962,
          2746,
          14944,
          9578,
          9316,
          5766,
          10184,
          14944,
          3385,
          12648,
          3962,
          2746,
          4072,
          13025,
          4287,
          1860,
          17742,
          30051,
          7138,
          1199,
          5937,
          1291,
          1430,
          4413,
          6891,
          3962,
          3011,
          13312,
          2494,
          5553,
          2746,
          11884,
          3504,
          7781,
          1638,
          1998,
          10602,
          11036,
          2746,
          4599,
          13090,
          1554,
          5000,
          8885,
          13741,
          11884,
          10170,
          825,
          4240,
          3737,
          7111,
          13270,
          13878,
          13878,
          5007,
          1430,
          6805,
          2746,
          1188,
          5384,
          11162,
          11205,
          10934,
          3962,
          11624,
          2373,
          1430,
          840,
          1824,
          5165,
          12523,
          3385,
          5937,
          14392,
          5117,
          2161,
          3011,
          8247,
          11884,
          4392,
          2161,
          14710,
          3504,
          5937,
          6972,
          6203,
          5000,
          9232,
          11884,
          11754,
          11505,
          4581,
          2746,
          11910,
          5755,
          13771,
          4289,
          2465,
          7860,
          11493,
          2746,
          3263,
          8400,
          16315,
          2941,
          15863,
          9515,
          20492,
          3962,
          14157,
          10761,
          6805,
          6568,
          11774,
          3263,
          14652,
          1638,
          3962,
          2161,
          2746,
          2746,
          3962,
          5000,
          3011,
          6805,
          14859,
          1752,
          1339,
          2224,
          23207,
          5571,
          3504,
          825,
          2746,
          11030,
          2064,
          5755,
          10962,
          8106,
          9145,
          15234,
          5577,
          7860,
          10556,
          13159,
          14898,
          7623,
          1824,
          5666,
          6636,
          1439,
          2746,
          8132,
          1286,
          11624,
          3850,
          11233,
          3962,
          3263,
          10895,
          2800,
          9145,
          9145,
          3850,
          6080,
          3962,
          6822,
          4428,
          12523,
          6636,
          5863,
          12077,
          18806
        ],
        "hover_name": [
          "L\u00ea Th\u1ecb Lan<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_02_17",
          "\u0110\u1eb7ng Th\u1ecb Thanh T\u00e2m <br>331.8 k c\u1ed5 phi\u1ebfu<br>2022_02_23",
          "L\u00ca VI\u1ec6T ANH<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_02_23",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng<br>148.1 k c\u1ed5 phi\u1ebfu<br>2022_02_23",
          "C\u00d4NG \u0110O\u00c0N NH TMCP VI\u1ec6T NAM TH\u1ecaNH V\u01af\u1ee2NG-H\u1ed8I S\u1ede<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_02_23",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>700.0 k c\u1ed5 phi\u1ebfu<br>2022_02_24",
          "L\u00ea Th\u1ecb Lan<br>350.0 k c\u1ed5 phi\u1ebfu<br>2022_02_24",
          "L\u00ea Th\u1ecb Lan<br>447.3 k c\u1ed5 phi\u1ebfu<br>2022_02_25",
          "L\u00ca VI\u1ec6T ANH<br>376.0 k c\u1ed5 phi\u1ebfu<br>2022_02_25",
          "TR\u1ea6N NG\u1eccC TRUNG<br>1,056.1 k c\u1ed5 phi\u1ebfu<br>2022_02_25",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>1,000.0 k c\u1ed5 phi\u1ebfu<br>2022_02_25",
          "L\u00ca VI\u1ec6T ANH<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_02_28",
          "TR\u1ea6N NG\u1eccC TRUNG<br>950.0 k c\u1ed5 phi\u1ebfu<br>2022_02_28",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>420.0 k c\u1ed5 phi\u1ebfu<br>2022_02_28",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>600.0 k c\u1ed5 phi\u1ebfu<br>2022_02_28",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_02_28",
          "L\u00ca VI\u1ec6T ANH<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_03_01",
          "\u0110\u1eb7ng Th\u1ecb Thanh T\u00e2m<br>896.2 k c\u1ed5 phi\u1ebfu<br>2022_03_01",
          "NGUY\u1ec4N TH\u1eca HOA<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_03_01",
          "L\u00ea Vi\u1ec7t Anh<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_03_02",
          "L\u00ea Th\u1ecb Lan<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_03_03",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_03_03",
          "L\u00ea Th\u1ecb Lan<br>555.4 k c\u1ed5 phi\u1ebfu<br>2022_03_04",
          "Tr\u1ea7n Ng\u1ecdc Lan<br>790.0 k c\u1ed5 phi\u1ebfu<br>2022_03_04",
          "L\u00ea Vi\u1ec7t Anh<br>755.6 k c\u1ed5 phi\u1ebfu<br>2022_03_04",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>779.4 k c\u1ed5 phi\u1ebfu<br>2022_03_07",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_03_10",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_03_10",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>401.0 k c\u1ed5 phi\u1ebfu<br>2022_03_15",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>450.0 k c\u1ed5 phi\u1ebfu<br>2022_03_15",
          "L\u00ea Th\u1ecb Lan<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_03_15",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>173.1 k c\u1ed5 phi\u1ebfu<br>2022_03_29",
          "Tr\u1ea7n Ng\u1ecdc Lan<br>1,500.0 k c\u1ed5 phi\u1ebfu<br>2022_03_29",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>860.0 k c\u1ed5 phi\u1ebfu<br>2022_04_01",
          "L\u00ea Th\u1ecb Lan<br>360.6 k c\u1ed5 phi\u1ebfu<br>2022_04_01",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>345.0 k c\u1ed5 phi\u1ebfu<br>2022_04_04",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>169.8 k c\u1ed5 phi\u1ebfu<br>2022_04_14",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>4.0 k c\u1ed5 phi\u1ebfu<br>2022_04_15",
          "L\u00ea Th\u1ecb H\u1eadu<br>4.0 k c\u1ed5 phi\u1ebfu<br>2022_05_11",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>65.0 k c\u1ed5 phi\u1ebfu<br>2022_05_11",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_05_11",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_05_12",
          "TR\u1ea6N NG\u1eccC TRUNG<br>360.0 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>5.0 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>80.0 k c\u1ed5 phi\u1ebfu<br>2022_05_13",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "L\u00ea Th\u1ecb Lan<br>0.8 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "TR\u1ea6N NG\u1eccC TRUNG<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_05_17",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>75.0 k c\u1ed5 phi\u1ebfu<br>2022_05_18",
          "TR\u1ea6N NG\u1eccC TRUNG<br>600.0 k c\u1ed5 phi\u1ebfu<br>2022_05_18",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>53.5 k c\u1ed5 phi\u1ebfu<br>2022_05_18",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>13.0 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>62.3 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "TR\u1ea6N NG\u1eccC TRUNG<br>701.0 k c\u1ed5 phi\u1ebfu<br>2022_05_19",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>318.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "L\u00ca VI\u1ec6T ANH<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>160.0 k c\u1ed5 phi\u1ebfu<br>2022_05_20",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "L\u00ca VI\u1ec6T ANH<br>270.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>270.0 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>437.7 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>250.1 k c\u1ed5 phi\u1ebfu<br>2022_05_23",
          "L\u00ea Th\u1ecb Lan<br>45.0 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "TR\u1ea6N NG\u1eccC TRUNG<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_05_24",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>15.0 k c\u1ed5 phi\u1ebfu<br>2022_05_25",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>8.0 k c\u1ed5 phi\u1ebfu<br>2022_05_25",
          "TR\u1ea6N NG\u1eccC TRUNG<br>467.0 k c\u1ed5 phi\u1ebfu<br>2022_05_25",
          "TR\u1ea6N NG\u1eccC TRUNG<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_05_26",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>465.0 k c\u1ed5 phi\u1ebfu<br>2022_05_26",
          "L\u00ca VI\u1ec6T ANH<br>195.2 k c\u1ed5 phi\u1ebfu<br>2022_05_27",
          "L\u00ea Th\u1ecb Lan<br>120.0 k c\u1ed5 phi\u1ebfu<br>2022_05_27",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>550.0 k c\u1ed5 phi\u1ebfu<br>2022_05_27",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_05_30",
          "TR\u1ea6N NG\u1eccC LAN<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_05_30",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>189.4 k c\u1ed5 phi\u1ebfu<br>2022_05_31",
          "L\u00ca VI\u1ec6T ANH<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_05_31",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_05_31",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>70.0 k c\u1ed5 phi\u1ebfu<br>2022_05_31",
          "TR\u1ea6N NG\u1eccC LAN<br>861.5 k c\u1ed5 phi\u1ebfu<br>2022_05_31",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_01",
          "L\u00ca VI\u1ec6T ANH<br>590.0 k c\u1ed5 phi\u1ebfu<br>2022_06_01",
          "L\u00ea Th\u1ecb Lan<br>88.1 k c\u1ed5 phi\u1ebfu<br>2022_06_01",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_06_02",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_03",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>40.0 k c\u1ed5 phi\u1ebfu<br>2022_06_03",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_06_03",
          "Tr\u1ea7n Ng\u1ecdc Lan<br>701.0 k c\u1ed5 phi\u1ebfu<br>2022_06_03",
          "L\u00ca VI\u1ec6T ANH<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_03",
          "Tr\u1ea7n Ng\u1ecdc Lan<br>350.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "L\u00ea Th\u1ecb Lan<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>200.1 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_06_06",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>700.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>440.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>65.0 k c\u1ed5 phi\u1ebfu<br>2022_06_07",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>460.0 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>650.0 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "L\u00ca VI\u1ec6T ANH<br>100.1 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "L\u00ea Th\u1ecb Lan<br>550.1 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>700.0 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "L\u00ca VI\u1ec6T ANH<br>500.1 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>800.0 k c\u1ed5 phi\u1ebfu<br>2022_06_08",
          "L\u00ea Th\u1ecb Lan<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_09",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_06_09",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>687.0 k c\u1ed5 phi\u1ebfu<br>2022_06_09",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_06_10",
          "L\u00ca VI\u1ec6T ANH<br>180.0 k c\u1ed5 phi\u1ebfu<br>2022_06_10",
          "TR\u1ea6N NG\u1eccC LAN<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_06_10",
          "L\u00ea Th\u1ecb Lan<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_10",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_10",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>2.0 k c\u1ed5 phi\u1ebfu<br>2022_06_13",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>265.0 k c\u1ed5 phi\u1ebfu<br>2022_06_13",
          "TR\u1ea6N NG\u1eccC LAN<br>449.1 k c\u1ed5 phi\u1ebfu<br>2022_06_13",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>213.9 k c\u1ed5 phi\u1ebfu<br>2022_06_13",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_13",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_06_13",
          "L\u00ca VI\u1ec6T ANH<br>505.0 k c\u1ed5 phi\u1ebfu<br>2022_06_13",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_14",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>25.0 k c\u1ed5 phi\u1ebfu<br>2022_06_14",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_06_14",
          "L\u00ea Vi\u1ec7t Anh<br>635.0 k c\u1ed5 phi\u1ebfu<br>2022_06_14",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>701.0 k c\u1ed5 phi\u1ebfu<br>2022_06_14",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>90.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "L\u00ea Th\u1ecb Lan<br>268.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>741.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>450.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>48.1 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "TR\u1ea6N NG\u1eccC LAN<br>910.0 k c\u1ed5 phi\u1ebfu<br>2022_06_15",
          "TR\u1ea6N NG\u1eccC LAN<br>730.0 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "L\u00ea Vi\u1ec7t Anh<br>410.0 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "TR\u1ea6N NG\u1eccC LAN<br>165.0 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>10.2 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>30.2 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>615.0 k c\u1ed5 phi\u1ebfu<br>2022_06_16",
          "TR\u1ea6N NG\u1eccC LAN<br>750.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>750.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "TR\u1ea6N NG\u1eccC TRUNG<br>335.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "Nguy\u1ec5n Th\u1ecb Minh Ph\u01b0\u1ee3ng<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "L\u00ea Vi\u1ec7t Anh<br>940.0 k c\u1ed5 phi\u1ebfu<br>2022_06_17",
          "TR\u1ea6N NG\u1eccC LAN<br>1,080.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>55.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "L\u00ea Vi\u1ec7t Anh<br>1.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "L\u00ea Vi\u1ec7t Anh<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>1,080.0 k c\u1ed5 phi\u1ebfu<br>2022_06_20",
          "TR\u1ea6N NG\u1eccC LAN<br>380.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>102.3 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>63.8 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>380.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "L\u00ca VI\u1ec6T ANH<br>690.0 k c\u1ed5 phi\u1ebfu<br>2022_06_21",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>47.7 k c\u1ed5 phi\u1ebfu<br>2022_06_22",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>110.0 k c\u1ed5 phi\u1ebfu<br>2022_06_22",
          "L\u00ea Th\u1ecb Lan<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_22",
          "L\u00ca VI\u1ec6T ANH<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_06_22",
          "L\u00ca VI\u1ec6T ANH<br>418.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "TR\u1ea6N NG\u1eccC LAN<br>1,310.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_06_23",
          "TR\u1ea6N NG\u1eccC LAN<br>880.0 k c\u1ed5 phi\u1ebfu<br>2022_06_24",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>5.0 k c\u1ed5 phi\u1ebfu<br>2022_06_24",
          "TR\u1ea6N NG\u1eccC LAN<br>1,630.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "L\u00ea Th\u1ecb Lan<br>480.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>83.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_27",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "TR\u1ea6N NG\u1eccC LAN<br>850.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "TR\u1ea6N NG\u1eccC LAN<br>430.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "L\u00ca VI\u1ec6T ANH<br>411.8 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "L\u00ea Th\u1ecb Lan<br>190.6 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>473.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>850.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>75.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "Tr\u00e2\u0300n Ngo\u0323c Lan<br>660.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_06_28",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_06_29",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>105.0 k c\u1ed5 phi\u1ebfu<br>2022_06_29",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>690.2 k c\u1ed5 phi\u1ebfu<br>2022_06_30",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>115.0 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>21.0 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>1,100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>2,400.0 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>270.0 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "L\u00ea Th\u1ecb Lan<br>5.2 k c\u1ed5 phi\u1ebfu<br>2022_07_01",
          "TR\u1ea6N NG\u1eccC LAN<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>7.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "Tr\u1ea7n Ng\u1ecdc Lan<br>121.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "NGUY\u1ec4N TH\u1eca HOA<br>255.1 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "L\u00ea Th\u1ecb Lan<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_04",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>713.4 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "NGUY\u1ec4N TH\u1eca HOA<br>41.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>179.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "TR\u1ea6N NG\u1eccC LAN<br>600.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>80.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>310.0 k c\u1ed5 phi\u1ebfu<br>2022_07_05",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>15.0 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>25.0 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>503.4 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "TR\u1ea6N NG\u1eccC LAN<br>535.5 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_06",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>130.0 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "TR\u1ea6N NG\u1eccC LAN<br>695.4 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>12.9 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "L\u00ea Th\u1ecb Lan<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_07_07",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>382.3 k c\u1ed5 phi\u1ebfu<br>2022_07_08",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>748.6 k c\u1ed5 phi\u1ebfu<br>2022_07_08",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>600.0 k c\u1ed5 phi\u1ebfu<br>2022_07_08",
          "V\u0168 \u0110\u00ccNH CHI\u1ebeN<br>472.0 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>0.1 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "L\u00ea Vi\u1ec7t Anh<br>112.8 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>90.0 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>268.4 k c\u1ed5 phi\u1ebfu<br>2022_07_11",
          "L\u00ea Vi\u1ec7t Anh<br>710.0 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "TR\u1ea6N NG\u1eccC LAN<br>760.0 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>760.0 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "Tr\u1ea7n Ng\u1ecdc Lan<br>150.4 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "V\u0168 \u0110\u00ccNH CHI\u1ebeN<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_12",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>5.0 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "L\u00ea Vi\u1ec7t Anh<br>170.0 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "V\u0168 \u0110\u00ccNH CHI\u1ebeN<br>545.0 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>548.2 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "TR\u1ea6N NG\u1eccC LAN<br>527.9 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "L\u00ea Th\u1ecb Lan<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_13",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>580.0 k c\u1ed5 phi\u1ebfu<br>2022_07_14",
          "L\u00ea Th\u1ecb Lan<br>36.9 k c\u1ed5 phi\u1ebfu<br>2022_07_14",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>10.0 k c\u1ed5 phi\u1ebfu<br>2022_07_14",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>0.2 k c\u1ed5 phi\u1ebfu<br>2022_07_14",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_07_15",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>158.5 k c\u1ed5 phi\u1ebfu<br>2022_07_15",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>650.0 k c\u1ed5 phi\u1ebfu<br>2022_07_15",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>75.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "TR\u1ea6N NG\u1eccC LAN<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>803.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>156.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "L\u00ea Th\u1ecb Lan<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_07_18",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>340.0 k c\u1ed5 phi\u1ebfu<br>2022_07_19",
          "L\u00ea Vi\u1ec7t Anh<br>600.0 k c\u1ed5 phi\u1ebfu<br>2022_07_19",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>120.0 k c\u1ed5 phi\u1ebfu<br>2022_07_19",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>830.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>80.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "L\u00ea Th\u1ecb Lan<br>200.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "L\u00ea Vi\u1ec7t Anh<br>260.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "Tr\u1ea7n Ng\u1ecdc Lan<br>215.0 k c\u1ed5 phi\u1ebfu<br>2022_07_20",
          "V\u0168 \u0110\u00ccNH CHI\u1ebeN<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "TR\u1ea6N NG\u1eccC TRUNG<br>406.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>600.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "L\u00ea Vi\u1ec7t Anh<br>590.0 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "Tr\u1ea7n Ng\u1ecdc Lan<br>570.9 k c\u1ed5 phi\u1ebfu<br>2022_07_21",
          "Tr\u1ea7n Ng\u1ecdc Lan<br>129.1 k c\u1ed5 phi\u1ebfu<br>2022_07_22",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_22",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>602.0 k c\u1ed5 phi\u1ebfu<br>2022_07_22",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>190.0 k c\u1ed5 phi\u1ebfu<br>2022_07_22",
          "L\u00ea Vi\u1ec7t Anh<br>751.1 k c\u1ed5 phi\u1ebfu<br>2022_07_22",
          "L\u00ea Vi\u1ec7t Anh<br>115.1 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>40.0 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>315.0 k c\u1ed5 phi\u1ebfu<br>2022_07_25",
          "L\u00ea Vi\u1ec7t Anh<br>570.0 k c\u1ed5 phi\u1ebfu<br>2022_07_26",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_07_26",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>70.0 k c\u1ed5 phi\u1ebfu<br>2022_07_26",
          "L\u00ea Vi\u1ec7t Anh<br>350.0 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>970.1 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "L\u00ea Th\u1ecb Lan<br>57.3 k c\u1ed5 phi\u1ebfu<br>2022_07_27",
          "L\u00ea Vi\u1ec7t Anh<br>930.0 k c\u1ed5 phi\u1ebfu<br>2022_07_28",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>425.6 k c\u1ed5 phi\u1ebfu<br>2022_07_28",
          "L\u00ea Th\u1ecb Lan<br>1,363.7 k c\u1ed5 phi\u1ebfu<br>2022_07_28",
          "L\u00ea Vi\u1ec7t Anh<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_07_29",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>783.2 k c\u1ed5 phi\u1ebfu<br>2022_07_29",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>515.1 k c\u1ed5 phi\u1ebfu<br>2022_07_29",
          "L\u00ea Th\u1ecb Lan<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_07_29",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>236.0 k c\u1ed5 phi\u1ebfu<br>2022_08_01",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>591.5 k c\u1ed5 phi\u1ebfu<br>2022_08_01",
          "L\u00ca MINH ANH<br>70.0 k c\u1ed5 phi\u1ebfu<br>2022_08_01",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>825.0 k c\u1ed5 phi\u1ebfu<br>2022_08_02",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>15.0 k c\u1ed5 phi\u1ebfu<br>2022_08_02",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_08_02",
          "L\u00ea Th\u1ecb Lan<br>30.0 k c\u1ed5 phi\u1ebfu<br>2022_08_02",
          "Ph\u1ea1m Ng\u1ecdc Qu\u1ed1c C\u01b0\u1eddng<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_08_02",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>150.0 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>60.0 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>250.0 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>842.7 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>18.0 k c\u1ed5 phi\u1ebfu<br>2022_08_03",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>8.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>32.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "TR\u1ea6N NG\u1eccC LAN<br>1,640.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "L\u00ea Th\u1ecb Lan<br>180.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>80.0 k c\u1ed5 phi\u1ebfu<br>2022_08_04",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>0.1 k c\u1ed5 phi\u1ebfu<br>2022_08_05",
          "L\u00ea Th\u1ecb Lan<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_08_08",
          "TR\u1ea6N NG\u1eccC LAN<br>535.1 k c\u1ed5 phi\u1ebfu<br>2022_08_08",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>27.0 k c\u1ed5 phi\u1ebfu<br>2022_08_08",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>190.0 k c\u1ed5 phi\u1ebfu<br>2022_08_08",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>530.0 k c\u1ed5 phi\u1ebfu<br>2022_08_08",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>330.8 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          "L\u00ea Th\u1ecb Lan<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          "TR\u1ea6N NG\u1eccC LAN<br>875.0 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          "Nguy\u1ec5n Th\u1ecb Hoa<br>180.3 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>315.0 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          "L\u00ca VI\u1ec6T ANH<br>500.0 k c\u1ed5 phi\u1ebfu<br>2022_08_09",
          "TR\u1ea6N NG\u1eccC LAN<br>701.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "L\u00ca VI\u1ec6T ANH<br>846.0 k c\u1ed5 phi\u1ebfu<br>2022_08_10",
          "Nguy\u1ec5n M\u1ea1nh C\u01b0\u1eddng<br>300.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "L\u00ea Th\u1ecb Lan<br>20.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "L\u00ca VI\u1ec6T ANH<br>185.1 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "TR\u1ea6N NG\u1eccC LAN<br>240.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>10.2 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "L\u00ca VI\u1ec6T ANH<br>50.0 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>332.5 k c\u1ed5 phi\u1ebfu<br>2022_08_11",
          "V\u0169 \u0110\u00ecnh Chi\u1ebfn<br>6.9 k c\u1ed5 phi\u1ebfu<br>2022_08_12",
          "TR\u1ea6N NG\u1eccC LAN<br>580.0 k c\u1ed5 phi\u1ebfu<br>2022_08_12",
          "Nguy\u1ec5n Th\u1ecb Thu H\u01b0\u01a1ng<br>95.0 k c\u1ed5 phi\u1ebfu<br>2022_08_12",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>550.3 k c\u1ed5 phi\u1ebfu<br>2022_08_12",
          "L\u00ea Th\u1ecb Lan<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_08_15",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>70.0 k c\u1ed5 phi\u1ebfu<br>2022_08_15",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>525.0 k c\u1ed5 phi\u1ebfu<br>2022_08_15",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>52.0 k c\u1ed5 phi\u1ebfu<br>2022_08_15",
          "NGUY\u1ec4N TH\u1eca HOA<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_08_15",
          "NGUY\u1ec4N TH\u1eca HOA<br>400.0 k c\u1ed5 phi\u1ebfu<br>2022_08_17",
          "Nguy\u1ec5n Th\u1ecb Thu H\u01b0\u01a1ng<br>95.0 k c\u1ed5 phi\u1ebfu<br>2022_08_17",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>208.0 k c\u1ed5 phi\u1ebfu<br>2022_08_17",
          "L\u00ea Th\u1ecb Lan<br>100.0 k c\u1ed5 phi\u1ebfu<br>2022_08_17",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>251.0 k c\u1ed5 phi\u1ebfu<br>2022_08_18",
          "L\u00ea Th\u1ecb Lan<br>121.7 k c\u1ed5 phi\u1ebfu<br>2022_08_18",
          "TR\u1ea6N NG\u1eccC LAN<br>650.0 k c\u1ed5 phi\u1ebfu<br>2022_08_18",
          "V\u00f5 Th\u1ecb Th\u00fay H\u00e0<br>240.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19",
          "B\u00f9i \u0110\u00ecnh D\u0129nh<br>195.9 k c\u1ed5 phi\u1ebfu<br>2022_08_19",
          "L\u00ea Th\u1ecb Lan<br>615.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19",
          "Tr\u1ea7n Ng\u1ecdc Trung<br>1,200.0 k c\u1ed5 phi\u1ebfu<br>2022_08_19"
        ]
      },
      "vn30_index": {
        "close": [
          1541.25,
          1550.46,
          1552.44,
          1548.95,
          1545.92,
          1505.07,
          1527.36,
          1521.47,
          1540.51,
          1531.47,
          1533.4,
          1532.36,
          1538.83,
          1522.04,
          1526.5,
          1517.18,
          1520.12,
          1498.61,
          1522.49,
          1525.34,
          1509.12,
          1490.17,
          1489.25,
          1490.24,
          1477.14,
          1461.1,
          1468.89,
          1472.69,
          1469.92,
          1476.94,
          1502.67,
          1513.4,
          1505.59,
          1497.44,
          1498.36,
          1484.16,
          1500.57,
          1500.23,
          1508.53,
          1542.47,
          1548.04,
          1539.2,
          1557.11,
          1541.96,
          1524.31,
          1507.2,
          1525.39,
          1518.01,
          1493.74,
          1468.25,
          1440.61,
          1435.5,
          1426.87,
          1444.32,
          1366.39,
          1396.9,
          1402.03,
          1400.88,
          1417.31,
          1389.59,
          1404.88,
          1373.21,
          1314.04,
          1345.46,
          1349.82,
          1279.76,
          1223.76,
          1215.08,
          1279.55,
          1286.41,
          1283.55,
          1282.51,
          1255.35,
          1272.71,
          1310.7,
          1309.5,
          1335.68,
          1342.87,
          1332.59,
          1335.49,
          1325.49,
          1327.4,
          1327.04,
          1324.37,
          1342.03,
          1342.92,
          1325.69,
          1260.85,
          1261.16,
          1253.09,
          1280.37,
          1258.03,
          1225.56,
          1224.54,
          1227.18,
          1240.58,
          1235.47,
          1256.67,
          1273.41,
          1273.4,
          1248.92,
          1252.24,
          1248.37,
          1242.05,
          1211.94,
          1229.23,
          1231.54,
          1209.02,
          1219.44,
          1216.94,
          1221.94,
          1220.14,
          1214.28,
          1211.7,
          1225.62,
          1235.25,
          1228.84,
          1222.6,
          1218.49,
          1219.43,
          1236.14,
          1232.0,
          1256.25,
          1265.97,
          1269.83,
          1277.73,
          1272.06,
          1276.7,
          1281.44,
          1277.15,
          1272.33,
          1280.96,
          1293.79,
          1295.1,
          1300.4,
          1299.93,
          1294.93,
          1291.06
        ],
        "day": [
          "2022_02_07",
          "2022_02_08",
          "2022_02_09",
          "2022_02_10",
          "2022_02_11",
          "2022_02_14",
          "2022_02_15",
          "2022_02_16",
          "2022_02_17",
          "2022_02_18",
          "2022_02_21",
          "2022_02_22",
          "2022_02_23",
          "2022_02_24",
          "2022_02_25",
          "2022_02_28",
          "2022_03_01",
          "2022_03_02",
          "2022_03_03",
          "2022_03_04",
          "2022_03_07",
          "2022_03_08",
          "2022_03_09",
          "2022_03_10",
          "2022_03_11",
          "2022_03_14",
          "2022_03_15",
          "2022_03_16",
          "2022_03_17",
          "2022_03_18",
          "2022_03_21",
          "2022_03_22",
          "2022_03_23",
          "2022_03_24",
          "2022_03_25",
          "2022_03_28",
          "2022_03_29",
          "2022_03_30",
          "2022_03_31",
          "2022_04_01",
          "2022_04_04",
          "2022_04_05",
          "2022_04_06",
          "2022_04_07",
          "2022_04_08",
          "2022_04_12",
          "2022_04_13",
          "2022_04_14",
          "2022_04_15",
          "2022_04_18",
          "2022_04_19",
          "2022_04_20",
          "2022_04_21",
          "2022_04_22",
          "2022_04_25",
          "2022_04_26",
          "2022_04_27",
          "2022_04_28",
          "2022_04_29",
          "2022_05_04",
          "2022_05_05",
          "2022_05_06",
          "2022_05_09",
          "2022_05_10",
          "2022_05_11",
          "2022_05_12",
          "2022_05_13",
          "2022_05_16",
          "2022_05_17",
          "2022_05_18",
          "2022_05_19",
          "2022_05_20",
          "2022_05_23",
          "2022_05_24",
          "2022_05_25",
          "2022_05_26",
          "2022_05_27",
          "2022_05_30",
          "2022_05_31",
          "2022_06_01",
          "2022_06_02",
          "2022_06_03",
          "2022_06_06",
          "2022_06_07",
          "2022_06_08",
          "2022_06_09",
          "2022_06_10",
          "2022_06_13",
          "2022_06_14",
          "2022_06_15",
          "2022_06_16",
          "2022_06_17",
          "2022_06_20",
          "2022_06_21",
          "2022_06_22",
          "2022_06_23",
          "2022_06_24",
          "2022_06_27",
          "2022_06_28",
          "2022_06_29",
          "2022_06_30",
          "2022_07_01",
          "2022_07_04",
          "2022_07_05",
          "2022_07_06",
          "2022_07_07",
          "2022_07_08",
          "2022_07_11",
          "2022_07_12",
          "2022_07_13",
          "2022_07_14",
          "2022_07_15",
          "2022_07_18",
          "2022_07_19",
          "2022_07_20",
          "2022_07_21",
          "2022_07_22",
          "2022_07_25",
          "2022_07_26",
          "2022_07_27",
          "2022_07_28",
          "2022_07_29",
          "2022_08_01",
          "2022_08_02",
          "2022_08_03",
          "2022_08_04",
          "2022_08_05",
          "2022_08_08",
          "2022_08_09",
          "2022_08_10",
          "2022_08_11",
          "2022_08_12",
          "2022_08_15",
          "2022_08_16",
          "2022_08_17",
          "2022_08_18",
          "2022_08_19",
          "2022_08_22"
        ],
        "x": [
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371
        ],
        "color": [
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index",
          "vn30_index"
        ]
      }
    }
  }