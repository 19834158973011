import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { res } from '../models/sample_transactions_by_group_json_1661492743';
import moment from 'moment';
import TranGroup from '../models/admin/TranGroup';
import Transaction from '../models/admin/Transaction';
import Banner from '../components/Banner';
import { RadioButton } from 'primereact/radiobutton';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
class TranByGroup extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            { "field": "index", "header": "Index", "body": true, style: { width: '2rem' } },
            { "field": "day", "header": "Day", "body": true, style: { width: '10rem' }  },
            { "field": "group_ah", "header": "Group Count", style: { width: '10rem' }, "body": true },
            { "field": "id", "header": "STK Count", style: { width: '8rem' }, "body": true },
            { "field": "buy", "header": "Total Buy", "body": true },
            { "field": "sell", "header": "Total Sell", "body": true },
            { "field": "val_buy", "header": "Total Val_buy", "body": true },
            { "field": "val_sell", "header": "Total Val_sell", "body": true },
            { "field": "val_buy_sell", "header": "Total Val_buy_sell", "body": true },
            { "field": "val_group", "header": "Total Val_group", "body": true }
        ];
        this.columnsStock = [
            { "field": "index", "header": "Index", "body": true, style: { width: '2rem' } },
            { "field": "stock", "header": "Stock", style: { width: '10rem' } },
            { "field": "group_ah", "header": "Group Count", style: { width: '10rem' }, "body": true },
            { "field": "id", "header": "STK Count", style: { width: '8rem' }, "body": true },
            { "field": "buy", "header": "Total Buy", "body": true },
            { "field": "sell", "header": "Total Sell", "body": true },
            { "field": "val_buy", "header": "Total Val_buy", "body": true },
            { "field": "val_sell", "header": "Total Val_sell", "body": true },
            { "field": "val_buy_sell", "header": "Total Val_buy_sell", "body": true },
            { "field": "val_group", "header": "Total Val_group", "body": true }
        ];
        this.columnsGroup = [
            { "field": "index", "header": "Index", "body": true, style: { width: '2rem' } },
            { "field": "group_ah", "header": "Group", style: { width: '10rem' } },
            { "field": "id", "header": "STK Count", style: { width: '8rem' }, "body": true },
            { "field": "buy", "header": "Total Buy", "body": true },
            { "field": "sell", "header": "Total Sell", "body": true },
            { "field": "val_buy", "header": "Total Val_buy", "body": true },
            { "field": "val_sell", "header": "Total Val_sell", "body": true },
            { "field": "val_buy_sell", "header": "Total Val_buy_sell", "body": true },
            { "field": "val_group", "header": "Total Val_group", "body": true }
        ];
        this.state = {
            data: [],
            loading: false,
            totalRecords: 0,
            first: 0,
            quantity: 25,
            page: 1,
            sortField: 'day',
            sortOrder: -1,
            filters: {

            },
            selectedColumns: this.columns,
            expandedRows: null,
            mode: 'Day',

            optionsStock: [],
            optionsGroup: [],

            endDay: null,
            fromDay: null,


            data_start: null,
            data_end: null,
            minDay: null,

            footerBuy: 0,
            footerSell: 0,
            footerVal_buy: 0,
            footerVal_sell: 0,
            footerVal_buy_sell: 0,
            footerVal_group: 0,






        }

        this.loadLazyTimeout = null;

        this.model = new TranGroup()

        this.DayOpt = [
            {
                'name': '1m',
                'day': 1,
                'type': 'months'
            },
            {
                'name': '2m',
                'day': 2,
                'type': 'months'
            },
            {
                'name': '3m',
                'day': 1,
                'type': 'months'
            },
            {
                'name': 'ALL',
            },
        ]


    }

    getData() {
        let parsed = global.parsed()
        if(parsed['group']){
            this.setState({
                filters :{
                    "group": {
                        "col_name": "group",
                        "operator": "in",
                        "value": [parsed['group']]
                    }
                },
                groupValue : [{name : parsed['group'] , code : parsed['group']}]
            });
        }

        clearTimeout(this.loadLazyTimeout);
        this.loadLazyTimeout = setTimeout(() => {
            this.setState({ loading: true });

            let filter = {
                data_filter: Object.values(this.state.filters),
            }

            this.model.getData(this.state.mode , filter ).then(res => {

                if(this.state.mode == 'Day'){
            
                    res.sort((a,b) => b.day > a.day ? 1 : -1);
             
                }
             
                let footerBuy = 0;
                let footerSell = 0;
                let footerVal_buy = 0;
                let footerVal_sell = 0;
                let footerVal_buy_sell = 0;
                let footerVal_group = 0;
                res.map(row => {
                    footerBuy += row['buy']
                    footerSell += row['sell']
                    footerVal_buy += row['val_buy']
                    footerVal_sell += row['val_sell']
                    footerVal_buy_sell += row['val_buy_sell']
                    footerVal_group += row['val_group']
                })
                let expandedRows = {}
                if(res.length > 0){
                    let datakey =  this.state.mode == 'Group' ? 'group_ah' : this.state.mode.toLowerCase()
                    expandedRows[res[0][datakey]] = true
                }


               
                
                this.setState({
                    data: res,
                    loading: false,
                    selectedColumns: this.state.mode == 'Day' ? this.columns : this.state.mode == 'Stock' ? this.columnsStock : this.columnsGroup,
                    footerBuy,
                    footerSell,
                    footerVal_buy,
                    footerVal_sell,
                    footerVal_buy_sell,
                    footerVal_group,
                    expandedRows : expandedRows,

                });
            })

        }, 500);

    }

    filterData() {
        clearTimeout(this.loadLazyTimeout);
        this.loadLazyTimeout = setTimeout(() => {
            this.setState({ loading: true });
            let filter = {
                data_filter: Object.values(this.state.filters),
            }


            this.model.filterData(filter, this.state.mode).then(res => {

                if(this.state.mode == 'Day'){
            
                    res.sort((a,b) => b.day > a.day ? 1 : -1);
             
                }

                let footerBuy = 0;
                let footerSell = 0;
                let footerVal_buy = 0;
                let footerVal_sell = 0;
                let footerVal_buy_sell = 0;
                let footerVal_group = 0;
                res.map(row => {
                    footerBuy += row['buy']
                    footerSell += row['sell']
                    footerVal_buy += row['val_buy']
                    footerVal_sell += row['val_sell']
                    footerVal_buy_sell += row['val_buy_sell']
                    footerVal_group += row['val_group']
                })
                let expandedRows = {}
                if(res.length > 0){
                    let datakey =  this.state.mode == 'Group' ? 'group_ah' : this.state.mode.toLowerCase()
                    expandedRows[res[0][datakey]] = true
                }

             
                

                this.setState({
                    data: res,
                    loading: false,
                    selectedColumns: this.state.mode == 'Day' ? this.columns : this.state.mode == 'Stock' ? this.columnsStock : this.columnsGroup,
                    footerBuy,
                    footerSell,
                    footerVal_buy,
                    footerVal_sell,
                    footerVal_buy_sell,
                    footerVal_group,
                    expandedRows : expandedRows,
                });
            })

        }, 500);

    }

    getQuerry() {
        let model = new Transaction()
        model.query().then(response => {
            let stockOpt = []
            response['categories']['stock'].map(item => {
                stockOpt.push({ name: item, code: item },)
            })
            let groupOpt = []
            response['categories']['label1_details'].map(item => {
                groupOpt.push({ name: item, code: item },)
            })

            let endDay = response['max_day']
            let startDay = moment(endDay).subtract(2, 'months').format('YYYY/MM/DD');

            // let out = []

            // for (let index = Number(moment(startDay).format('X')); index <= Number(moment(endDay).format('X')); index = index + 86400) {
            //     out.push(moment(index, 'X').format('YYYY/MM/DD'))

            // }

            // let valueDay = out.map(item => new Date(item))

            this.setState({
                optionsStock: stockOpt,
                optionsGroup: groupOpt,
                // dateValue: valueDay,
                data_end: new Date(endDay),
                data_start: new Date(startDay),
                minDay: response['min_day']
            }, () => this.getData());
        }).catch((error) => {
            console.log(error);
        })
    }

    componentDidMount() {

        this.getQuerry()

    }
    genDay(opt) {
        if (opt['name'] == 'ALL') {
            let endDay = this.state.data_end
            let startDay = this.state.minDay;
            this.setState({
                data_end: new Date(endDay),
                data_start: new Date(startDay),

            });
        } else {
            let endDay = this.state.data_end
            let startDay = moment().subtract(opt['day'], opt['type']).format('YYYY/MM/DD');

            this.setState({
                data_end: new Date(endDay),
                data_start: new Date(startDay),

            });
        }


    }
    change(value, col, filterkey, type) {
        var filter = this.state.filters;
        if (type == 'text') {
            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": value.trim()
                }
            }
        } else if (type == 'date') {
            if (value == null) {
                delete filter[filterkey];

            } else {
                let dateData = []
                value.map(item => {
                    dateData.push(moment(item).format("YYYY/MM/DD"))
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dateData,
                }
                this.setState({
                    fromDay: null,
                    endDay: null,
                })
            }
        } else if (type == 'multi') {
            if (value.length == 0) {
                delete filter[filterkey];
            } else {
                let dataMul = []
                value.map(item => {
                    dataMul.push(item['code'])
                })
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": dataMul
                }
            }
        }
        else {
            if (typeof (value) == 'string' && value != '') {

                this.setState({
                    [col]: value,
                });
                return
            }

            if (value == undefined) return

            if (value == '') {
                delete filter[filterkey];
            } else {

                let name = value['name']
                let check = name.indexOf('(Σ:')
                if (check > -1) {
                    name = name.substring(0, check)
                    name = name.trim()
                }
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": name
                }
                value['name'] = name
                value['code'] = name


            }

        }
        this.setState({
            [col]: value,
            filters: filter,
        }, () => this.filterData())


    }

    changeDate(col, value, filterkey) {
        var filter = this.state.filters;
        this.setState({
            [col]: value,
        });

        if (col == 'fromDay') {
            // debugger
            if (this.state.endDay !== null && value !== null) {
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "from",
                    "value": [moment(value).format("YYYY/MM/DD"), moment(this.state.endDay).format("YYYY/MM/DD")],
                }
                this.setState({
                    filters: filter,
                    dateValue: null
                }, () => {
                    this.filterData()
                });
            }

        } else {
            if (this.state.fromDay !== null && value !== null) {
                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "from",
                    "value": [moment(this.state.fromDay).format("YYYY/MM/DD"), moment(value).format("YYYY/MM/DD")],
                }
                this.setState({
                    filters: filter,
                    dateValue: null
                }, () => {
                    this.filterData()
                });
            }
        }



    }

    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }



    rowExpansionTemplate(data) {

        if (this.state.mode == 'Day') {
            return (
                <div className="sub_table">
                    <DataTable value={data.value} responsiveLayout="scroll" >
                        <Column style={{ width: '3em' }} />
                        <Column style={{ width: '13rem' }} field='highlight' body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                        <Column field='stock' header='Stock' sortable  ></Column>
                        <Column field='group_ah' header='Group' style={{ width: '10rem' }} sortable  ></Column>
                        <Column field='id' header='STK'  sortable ></Column>
                        <Column field='name' header='Name' style={{ width: '20rem' }} sortable  ></Column>
                        <Column field='buy' header='Buy' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='sell' header='Sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='val_buy' header='Val_buy' body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='val_sell' header='Val_sell' body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='val_buy_sell' header='Val_buy_sell' body={(data, props) => this.representativeTemplate(data, props)}  sortable ></Column>
                        <Column field='val_group' header='Val_group' body={(data, props) => this.representativeTemplate(data, props)}  sortable ></Column>
                        {/* <Column field='label1' header='Type' body={(data, props) => this.representativeTemplate(data, props)}   sortable ></Column> */}


                    </DataTable>
                </div>
            );
        }

        if (this.state.mode == 'Stock') {
            return (
                <div className="sub_table">
                    <DataTable value={data.value} responsiveLayout="scroll">
                        <Column style={{ width: '3em' }} />
                        <Column style={{ width: '13rem' }}  ></Column>
                        <Column field='day' header='Day' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        {/* <Column field='stock' header='Stock'  ></Column> */}
                        <Column field='group_ah' header='Group' style={{ width: '10rem' }} sortable  ></Column>
                        <Column field='id' header='STK' sortable  ></Column>
                        <Column field='name' header='Name' style={{ width: '20rem' }} sortable  ></Column>
                        <Column field='buy' header='Buy' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='sell' header='Sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='val_buy' header='Val_buy' body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='val_sell' header='Val_sell' body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field='val_buy_sell' header='Val_buy_sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='val_group' header='Val_group' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        {/* <Column field='label1' header='Type' body={(data, props) => this.representativeTemplate(data, props)}   sortable ></Column> */}


                    </DataTable>
                </div>
            );
        }
        if (this.state.mode == 'Group') {
            return (
                <div className="sub_table">
                    <DataTable value={data.value} responsiveLayout="scroll">
                        <Column style={{ width: '3em' }} />
                        <Column style={{ width: '13rem' }}  ></Column>
                        <Column field='group_ah' header='Group' style={{ width: '10rem' }} sortable  ></Column>
                        <Column field='day' header='Day' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='stock' header='Stock' sortable  ></Column>

                        <Column field='id' header='STK' sortable  ></Column>
                        <Column field='name' header='Name' style={{ width: '20rem' }} sortable  ></Column>
                        <Column field='buy' header='Buy' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='sell' header='Sell' body={(data, props) => this.representativeTemplate(data, props)} sortable></Column>
                        <Column field='val_buy' header='Val_buy' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='val_sell' header='Val_sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='val_buy_sell' header='Val_buy_sell' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field='val_group' header='Val_group' body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        {/* <Column field='label1' header='Type' body={(data, props) => this.representativeTemplate(data, props)}   sortable ></Column> */}


                    </DataTable>
                </div>
            );
        }


    }
    representativeTemplate = (data, props) => {

        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }
        if (props.field == "highlight") {

            if (data.label1 === 'Insider' && data.buy > 0) {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <i class="pi pi-star-fill" style={{ color: 'limegreen' }}></i>
                    </div>
                )
            }
            if (data.label1 === 'Insider' && data.sell > 0) {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <i class="pi pi-star-fill" style={{ color: 'orange' }}></i>
                    </div>
                )
            }

        }

        let col = ['val_buy', 'val_sell', 'val_buy_sell', 'val_group']

        if (col.includes(props.field)) {
            let result = Math.round(data[props.field] * 100) / 100
            if (result == 0) {
                result = '-'
            } else {
                result = global.formatNumber(result)
            }
            return (
                <span>{result}</span>
            )
        }

        if (props.field == "buy" || props.field == "sell") {
            if (data[props.field] == null) return 'NULL'
            let result = Math.round(data[props.field] * 100) / 100
            result = global.formatNumber(data[props.field])
            if (result == 0) result = '-'

            let color
            if (props.field == 'buy') {
                color = 'limegreen'
            }
            if (props.field == 'sell') {
                color = 'orange'
            }

            return (
                <span style={result != '-' ? { color: color } : {}}>{result}</span>
            )
        }

        if (props.field == "day") {
            let reuslt = data[props.field].replaceAll('_', '/')
            reuslt = moment(reuslt).format('DD - MMM')
            return (
                <span >{reuslt}</span>
            )
        }

        if (props.field == "group_ah" || props.field == 'id') {
            let group = []
            let id = []

            data['value'].map(item => {
                if (group.indexOf(item['group_ah']) < 0) {
                    group.push(item['group_ah'])
                }
                if (id.indexOf(item['id']) < 0) {
                    id.push(item['id'])
                }
            })

            if (props.field == "group_ah") {

                return (
                    <span >{group.length}</span>
                )
            } else {

                return (
                    <span >{id.length}</span>
                )
            }


        }
        if (props.field == "label1") {
            
            return (
                <span style={{cursor : 'pointer'}} onClick={() => window.open(`${window.location.origin}/#/types?type=${data[props.field]}`)} >{data[props.field]}</span>
            )
        }









    }

    clearFilter(col) {

        if (Object.keys(this.state.filters).length == 0) return
        if (col) {
            let value = `${col}Value`
            if (col == 'day') {
                value = `dateValue`
                this.setState({
                    [value]: [],
                    fromDay: [],
                    endDay: [],
                });
            }
            else {
                this.setState({
                    [value]: ''
                });
            }

            var filter = this.state.filters;
            delete filter[col];
            this.setState({
                filters: filter,
            }, () => this.filterData());
            return
        } else {
            Object.keys(this.state.filters).map(item => {
                if (item == 'day') {
                    let value = `dateValue`

                    this.setState({
                        [value]: [],
                        fromDay: [],
                        endDay: [],
                    });
                } else {
                    let value = `${item}Value`

                    this.setState({
                        [value]: ''
                    });
                }

            })
            this.setState({
                filters: {},
            }, () => this.filterData());
        }


    }

    expandAll() {
        let expandedRows = {};
        this.state.data.forEach((p, index) => {
            if (index < 5) {
                expandedRows[`${p.day}`] = true
            } else {
                return
            }
        });

        this.setState({
            expandedRows
        });
    }
    collapseAll() {
        this.setState({
            expandedRows: null
        });
    }
    onSort(event) {

        this.setState({
            sortField: event.sortField,
            sortOrder: event.sortOrder
        }, () => this.getData());


    }

    changeMode(mode) {
        this.setState({
            // filters : {},
            mode: mode
        }, () => this.filterData());
    }

    render() {
        const header = (
            <div style={{ textAlign: 'left' }}>
                {/* <h1 style={{ textAlign: 'center' }}>Groups</h1> */}
                <Banner text='Groups' icon='pi pi-slack'></Banner>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ marginTop: '10px' }}>
                        {Object.keys(this.state.filters).length} filter : {
                            Object.keys(this.state.filters).map(item => {
                                let value1 = this.state.filters[item]['value']
                                if (item == 'day') {
                                    value1 = value1.map(item => moment(item).format('DD - MMM'))

                                }
                                return (
                                    <b onClick={() => this.clearFilter(item)} style={{ cursor: 'pointer' }}>
                                        <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                        {`     ${this.state.filters[item]['operator']} '${item}' `}    |
                                    </b>)
                            }

                            )
                        }

                        {
                            Object.keys(this.state.filters).length > 0 && <Button onClick={() => this.clearFilter()} style={{ position: 'relative', top: '7px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                        }
                    </div>

                    <div>
                        <Button label="Expand Top 5" onClick={() => this.expandAll()} className="ml-2 mr-2" />
                        <Button label="Collapse All" onClick={() => this.collapseAll()} className="ml-2 mr-2" />
                    </div>

                </div>


            </div>
        );

        let footerGroup = (
            <ColumnGroup>
                <Row >
                    <Column colSpan={5} footer="Totals:" />
                    <Column footer={global.formatNumber(this.state.footerBuy.toFixed(2))} footerStyle={{color: 'red'}} />
                    <Column footer={global.formatNumber(this.state.footerSell.toFixed(2))}  footerStyle={{color: 'red'}} />
                    <Column footer={global.formatNumber(this.state.footerVal_buy.toFixed(2))}  footerStyle={{color: 'red'}} />
                    <Column footer={global.formatNumber(this.state.footerVal_sell.toFixed(2))}  footerStyle={{color: 'red'}} />
                    <Column footer={global.formatNumber(this.state.footerVal_buy_sell.toFixed(2))}  footerStyle={{color: 'red'}} />
                    <Column footer={global.formatNumber(this.state.footerVal_group.toFixed(2))}   footerStyle={{color: 'red'}}/>
                </Row>
            </ColumnGroup>
        );
        let headerGroup = (
            <ColumnGroup>
                <Row >
                    <Column header={'Index'} />
                    {
                        this.state.selectedColumns.map(item => {

                        
                            return(
                                <Column header={item['header']} />
                            )

                        })
                    }
                </Row>
                <Row >
                    <Column  colSpan={this.state.mode == 'Group' ? 4 : 5}  />
                    {
                        this.state.selectedColumns.map(item => {
                            let col = {
                                'Total Buy': 'footerBuy',
                                'Total Sell': 'footerSell',
                                'Total Val_buy': 'footerVal_buy',
                                'Total Val_sell': 'footerVal_sell',
                                'Total Val_buy_sell': 'footerVal_buy_sell',
                                'Total Val_group': 'footerVal_group',
                            }


                            if (col[item['header']]) {
                                return (
                                    <Column header={ <div style={{ color: '#72c2ff' }}>  {global.formatNumber(Math.floor(this.state[col[item['header']]]))}</div> } />
                                )
                            }

                        })
                    }
                </Row>
            </ColumnGroup>
        );
        const columnComponents = this.state.selectedColumns.map(col => {
            if (col.body) {
                if (col.filter) {
                    return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
                }
                return <Column style={{ ...col.style }} body={(data, props) => this.representativeTemplate(data, props)} field={col.field} header={col.header} sortable={col.sort} />;
            }
            if (col.filter) {
                return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} filter={col.filter} filterElement={(() => { return this[col.filterElement]() })()} showFilterMenu={false} />;
            }
            return <Column style={{ ...col.style }} field={col.field} header={col.header} sortable={col.sort} />;


        });
        return (

            <div className="p-col-12 p-md-12" style={{ display: 'flex' }}>
                <div className="card widget-table" style={{ width: '80%' }} >
                    <DataTable

                        className="p-datatable-customers" value={this.state.data}
                        dataKey={this.state.mode == 'Group' ? 'group_ah' : this.state.mode.toLowerCase()}
                        stripedRows
                        emptyMessage="No data."
                        lazy loading={this.state.loading}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        onFilter={(event) => this.onFilter(event)}
                        // onSort={(event) => this.onSort(event)}
                        responsiveLayout="scroll"
                        header={header}
                        // footerColumnGroup={footerGroup}
                        headerColumnGroup={headerGroup}
                        expandedRows={this.state.expandedRows}
                        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={(data) => this.rowExpansionTemplate(data)}
                    >
                        <Column expander style={{ width: '3em' }} />
                        {columnComponents}
                    </DataTable>

                </div>

                <div className="card" style={{ width: '20%', marginLeft: '25px' }}>
                    {/* <div style={{ marginBottom: '10px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>CACHED DATA</div>
                        <div style={{ display: 'flex' }}>
                            <Calendar style={{ width: '50%', marginRight: '5px' }} id="multiple-a" dateFormat="dd - M" value={this.state.data_start} onChange={(e) => this.setState({ data_start: e.value })} readOnlyInput showButtonBar />
                            <Calendar style={{ width: '50%' }} id="multiple-b" dateFormat="dd - M" value={this.state.data_end} onChange={(e) => this.setState({ data_end: e.value })} readOnlyInput showButtonBar />
                        </div>
                        <div style={{ marginTop: '10px' }}>

                            {
                                this.DayOpt.map(item => {

                                    return (
                                        <Button key={item['name']} label={item['name']} className="p-button-raised p-button-text mr-2" onClick={() => this.genDay(item)} />
                                    )
                                })
                            }




                        </div>
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            <Button label="Reload Cached" onClick={() => {this.getData()  }} />
                        </div>
                    </div>
                    <hr></hr> */}
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT COLUMN </div>
                        <div>
                            <MultiSelect value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={(e) => this.onColumnToggle(e)} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY STOCK </div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.stockValue} onChange={(e) => this.change(e.value, 'stockValue', 'stock', 'multi')} options={this.state.optionsStock} optionLabel="name" display="chip" />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY DATE</div>
                        <div>
                            <   Calendar style={{ width: '100%' }} dateFormat="dd - M" id="multiple" value={this.state.dateValue} onChange={(e) => this.change(e.value, 'dateValue', 'day', 'date')} selectionMode="multiple" readOnlyInput showButtonBar />
                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>FROM DATE</div>
                        <div>
                            <Calendar style={{ width: '100%' }} id="multiple5" dateFormat="dd - M" value={this.state.fromDay} onChange={(e) => this.changeDate('fromDay', e.value, 'day')} readOnlyInput showButtonBar />
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>TO DATE</div>
                        <div>
                            <Calendar style={{ width: '100%' }} id="multiple2" dateFormat="dd - M" value={this.state.endDay} onChange={(e) => this.changeDate('endDay', e.value, 'day')} readOnlyInput showButtonBar />
                        </div>
                    </div>


                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SEARCH BY GROUP </div>
                        <div>
                            <MultiSelect style={{ width: '100%' }} value={this.state.groupValue} onChange={(e) => this.change(e.value, 'groupValue', 'group', 'multi')} options={this.state.optionsGroup} optionLabel="name" display="chip" />
                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>SELECT LAYOUT </div>
                        <div style={{ display: 'flex', background: '#f1f1f5', borderRadius: '8px', padding: '8px' }}>
                            <div className="field-radiobutton" style={{ flex: 1, margin: 0 }} >
                                <RadioButton value="Day" onChange={(e) => this.changeMode(e.value)} checked={this.state.mode === 'Day'} />
                                <label htmlFor="city1">Day</label>
                            </div>
                            <div className="field-radiobutton" style={{ flex: 1, margin: 0 }}>
                                <RadioButton value="Stock" onChange={(e) => this.changeMode(e.value)} checked={this.state.mode === 'Stock'} />
                                <label htmlFor="city1">Stock</label>
                            </div>
                            <div className="field-radiobutton" style={{ flex: 1, margin: 0 }}>
                                <RadioButton value="Group" onChange={(e) => this.changeMode(e.value)} checked={this.state.mode === 'Group'} />
                                <label htmlFor="city1">Group</label>
                            </div>
                        </div>
                    </div>


                </div>


            </div>
        )
    }
}

export default TranByGroup;