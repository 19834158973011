import React, { Component } from 'react';

import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';

import OneStockModel from '../models/admin/OneStock';
import TransactionModel from '../models/admin/Transaction';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';
import OneStockBuy from '../components/OneStock/OneStockBuy';
import OneStockSell from '../components/OneStock/OneStockSell';
import { InputSwitch } from 'primereact/inputswitch';
import OneStockBuyGroup from '../components/OneStock/OneStockBuyGroup';
import OneStockSellGroup from '../components/OneStock/OneStockSellGroup';
class OneStockView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // mode: 'Day',
            mode: true,
            valueDay: [],
            fromDay: null,
            endDay: null,
            filters: {},
            sortField: '',
            sortOrder: 0,
            code: 'HPG*',
            top: '20',
            matchedOnly: true,
            rankByGroup: true,
            rankByCTCK: false,
            optionsCode: [],

            color_position : false
        };
        this.options = [true, false];
        this.model = new OneStockModel()





        this.loadLazyTimeout = null;
        this.DayOpt = [
            {
                'name': '1w',
                'day': 1,
                'type': 'weeks'
            },
            {
                'name': '2w',
                'day': 2,
                'type': 'weeks'
            },
            {
                'name': '1m',
                'day': 1,
                'type': 'months'
            },
            {
                'name': '2m',
                'day': 2,
                'type': 'months'
            },
            {
                'name': '3m',
                'day': 3,
                'type': 'months'
            },
        ]

        this.DayOpt2 = [
            {
                'name': '1D',
                'day': 1,
                'type': 'days'
            },
            {
                'name': '2D',
                'day': 2,
                'type': 'days'
            },
            {
                'name': '3D',
                'day': 3,
                'type': 'days'
            },
            {
                'name': '1W',
                'day': 1,
                'type': 'weeks'
            },
        ]

        this.stock_special = ['MWG', 'VNM', 'VPB','SSI', 'STB', 'TCB', 'VCB', 'VND', 'CTG','HPG','MSB','FRT','FPT']
    }


    componentDidMount() {
        this.getMaxday()



    }

    getMaxday() {
        let model = new TransactionModel()
        model.query().then(res => {
            let optCode = []
            res['categories']['stock'].map(item => {
                if(this.stock_special.includes(item) ){
                    item = item + '*'
             
                }
                optCode.push(item)
            })

            this.setState({
                valueDay: [new Date(res['max_day'])],
                filters: {
                    'code': {
                        "col_name": "code",
                        "operator": "in",
                        "value": "HPG"
                    },
                    'day': {
                        "col_name": 'day',
                        "operator": 'in',
                        "value": [res['max_day']]
                    },
                    'top': {
                        "col_name": 'top',
                        "operator": 'in',
                        "value": this.state.top
                    },
                },
                maxDay: res['max_day'],
                optionsCode: optCode

            }, () => this.getData());
        })
    }

    setSort(sortField, sortOrder) {
        this.setState({
            sortField: sortField,
            sortOrder: sortOrder
        }, () => this.getData());
    }

    getData() {
        clearTimeout(this.loadLazyTimeout);
        this.loadLazyTimeout = setTimeout(() => {

            if (this.state.rankByGroup) {
                this.OneStockBuyGroup.setLoading()
                this.OneStockSellGroup.setLoading()
            } else {
                this.OneStockBuy.setLoading()
                this.OneStockSell.setLoading()
            }

            let dataSort = this.state.sortField != '' ? { [this.state.sortField]: this.state.sortOrder == 1 ? 'asc' : 'desc' } : {}
            let filter = {
                data_filter: Object.values(this.state.filters),
                data_sort: dataSort,
                matchedOnly: this.state.matchedOnly,
                rankByGroup: this.state.rankByGroup,
                rankByCTCK: this.state.rankByCTCK,
            }
            this.model.getData2(filter).then(response => {

                if (this.state.rankByGroup) {
                    this.OneStockBuyGroup.setData(response['buy'])
                    this.OneStockSellGroup.setData(response['sell'])
                } else {
                    this.OneStockBuy.setData(response['buy'], this.state.rankByCTCK)
                    this.OneStockSell.setData(response['sell'], this.state.rankByCTCK)
                }

            })
                .catch((error) => {

                    if (this.state.rankByGroup) {
                        this.OneStockBuyGroup.setData(null)
                        this.OneStockSellGroup.setData(null)
                    } else {
                        this.OneStockBuy.setData(null)
                        this.OneStockSell.setData(null)
                        console.log(error);
                    }
                })
        }, 500);


    }

    change(col, value, filterkey, type) {

        var filter = this.state.filters;
        let isFilter = true
        if (type == 'text') {

            if (value == '' || value == null) {
                delete filter[filterkey];
            } else {

                let data1 = value.trim()
                if(col == 'code'){
                    if(value.includes('*')){
                        data1 = value.slice(0, -1)

                        this.setState({
                            color_position : true
                        });
                    }else{

                        this.setState({
                            color_position : false
                        });
                    }
                }

                filter[filterkey] = {
                    "col_name": filterkey,
                    "operator": "in",
                    "value": data1
                }
            }
        } else if (type == 'date') {

            this.setState({
                [col]: value,
            });
            if (col == 'valueDay') {
                if (value == null) {
                    delete filter[filterkey];

                } else {
                    let dateData = []
                    value.map(item => {
                        dateData.push(moment(item).format("YYYY/MM/DD"))
                    })
                    filter[filterkey] = {
                        "col_name": filterkey,
                        "operator": "in",
                        "value": dateData,
                    }
                    this.setState({
                        fromDay: null,
                        endDay: null,
                    })


                }

                this.setState({
                    [col]: value,
                    filters: filter,
                }, () => this.getData())

                return
            }

            if (col == 'fromDay') {
                // debugger
                if (this.state.endDay !== null && value !== null) {
                    filter[filterkey] = {
                        "col_name": filterkey,
                        "operator": "from",
                        "value": [moment(value).format("YYYY/MM/DD"), moment(this.state.endDay).format("YYYY/MM/DD")],
                    }
                    this.setState({
                        filters: filter,
                        valueDay: null
                    }, () => {
                        this.getData()
                    });
                }
                return

            }

            if (col == 'endDay') {
                if (this.state.fromDay !== null && value !== null) {
                    filter[filterkey] = {
                        "col_name": filterkey,
                        "operator": "from",
                        "value": [moment(this.state.fromDay).format("YYYY/MM/DD"), moment(value).format("YYYY/MM/DD")],
                    }
                    this.setState({
                        filters: filter,
                        valueDay: null
                    }, () => {
                        this.getData()
                    });
                }
                return
            }

        }


        this.setState({
            [col]: value,
            filters: filter
        }, () => {
            if (isFilter) {
                this.getData()
            }
        });
    }


    genDay(opt) {

        let endDay = this.state.maxDay
        let startDay = moment(this.state.maxDay).subtract(opt['day'], opt['type']).format('YYYY/MM/DD');


        var filter = this.state.filters;

        filter['day'] = {
            "col_name": 'day',
            "operator": "from",
            "value": [startDay, endDay],
        }


        this.setState({
            fromDay: new Date(startDay),
            endDay: new Date(endDay),
            filters: filter,
            valueDay: null

        }, () => this.getData());

    }

    genDay2(opt) {
        let endDay = this.state.maxDay
        let startDay = moment(this.state.maxDay).subtract(opt['day'], opt['type']).format('YYYY/MM/DD');

        let out = []

        for (let index = Number(moment(startDay).format('X')); index <= Number(moment(endDay).format('X')); index = index + 86400) {
            out.push(moment(index, 'X').format('YYYY/MM/DD'))

        }



        var filter = this.state.filters;

        filter['day'] = {
            "col_name": 'day',
            "operator": "in",
            "value": out,
        }

        let valueDay = out.map(item => new Date(item))
        this.setState({
            valueDay: valueDay,
            filters: filter,
            fromDay: null,
            endDay: null,

        }, () => this.getData());

    }



    clearFilter(col) {
        if (Object.keys(this.state.filters).length == 0) return
        if (col) {
            if (col['col_name'] != 'day') {
                var filter = this.state.filters;
                delete filter[col['col_name']];
                this.setState({
                    filters: filter,
                }, () => this.getData());
                return
            } else {
                if (col['operator'] == 'in') {
                    this.setState({
                        'valueDay': null
                    });

                } else {
                    this.setState({
                        'fromDay': null,
                        'endDay': null,
                    });
                }

                var filter = this.state.filters;
                delete filter[col['col_name']];
                this.setState({
                    filters: filter,
                }, () => this.getData());
                return
            }

        } else {
            this.setState({
                mode: true,
                valueDay: null,
                fromDay: null,
                endDay: null,
                filters: {},
                code: null,
            }, () => this.getData());
        }

    }

    render() {
        return (
            <>

                <div className="p-col-12 p-md-12" >

                    <div className="card widget-table" >
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '20%', marginRight: '20px' }}>
                                <div style={{ paddingBottom: '20px', textAlign: 'center' }}>SEARCH BY CODE</div>
                                <div>
                                    <Dropdown style={{ width: '100%' }} value={this.state.code} options={this.state.optionsCode} onChange={(e) => this.change('code', e.value, 'code', 'text')} placeholder="Select a code" showClear />

                                </div>
                            </div>
                            <div style={{ width: '26%', marginRight: '20px' }}>
                                <div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <div style={{ textAlign: 'center', paddingBottom: '20px', cursor: 'pointer' }} >
                                            SEARCH BY DATE

                                        </div>
                                        <div>
                                            <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.valueDay} onChange={(e) => this.change('valueDay', e.value, 'day', 'date')} selectionMode="multiple" readOnlyInput showButtonBar />
                                        </div>


                                        <div style={{ marginTop: '10px' }}>
                                            {
                                                this.DayOpt2.map(item => {

                                                    return (
                                                        <Button key={item['name']} label={item['name']} style={{ color: '#44486D' }} className="p-button-raised p-button-text mr-2" onClick={() => this.genDay2(item)} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>






                                </div>
                            </div>
                            <div style={{ width: '30%', marginRight: '20px' }}>
                                <div style={{ marginBottom: '20px' }}>
                                    <div style={{ textAlign: 'center', paddingBottom: '20px', cursor: 'pointer' }} >
                                        SEARCH BY DATE
                                    </div>


                                    <div className='grid'>

                                        <div className='col-6' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: 5 }}>
                                                From
                                            </div>

                                            <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.fromDay} onChange={(e) => this.change('fromDay', e.value, 'day', 'date')} readOnlyInput showButtonBar />


                                        </div>

                                        <div className='col-6' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: 5 }}>
                                                To
                                            </div>

                                            <Calendar style={{ width: '100%' }} id="multiple" dateFormat="dd - M" value={this.state.endDay} onChange={(e) => this.change('endDay', e.value, 'day', 'date')} readOnlyInput showButtonBar />


                                        </div>


                                    </div>

                                    <div style={{ marginTop: '10px' }}>

                                        {
                                            this.DayOpt.map(item => {

                                                return (
                                                    <Button key={item['name']} label={item['name']} style={{ color: '#44486D' }} className="p-button-raised p-button-text mr-2" onClick={() => this.genDay(item)} />
                                                )
                                            })
                                        }

                                    </div>

                                </div>
                            </div>

                            <div style={{ width: '20%' }}>
                                <div style={{ paddingBottom: '20px', textAlign: 'center' }}>SELECT TOP</div>
                                <div>


                                    <input style={{ width: '100%' }} className='p-inputtext p-component' list="browsers" value={this.state.top} onChange={(e) => this.change('top', e.target.value, 'top', 'text')} />

                                    <datalist id="browsers">
                                        <option value="20" />
                                        <option value="50" />
                                        <option value="100" />
                                    </datalist>

                                    {/* <Dropdown style={{ width: '100%' }} value={this.state.code} options={this.optionsCode} onChange={(e) => this.change('code', e.value, 'code', 'text')} placeholder="Select a code" showClear /> */}

                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div >
                                {Object.keys(this.state.filters).length} filter : {
                                    Object.keys(this.state.filters).map(item => {
                                        let value1 = this.state.filters[item]['value']
                                        if (item == 'day') {
                                            value1 = value1.map(item => moment(item).format('DD - MMM'))

                                        }
                                        return (
                                            <b onClick={() => this.clearFilter(this.state.filters[item])} style={{ cursor: 'pointer' }}>
                                                <span style={{ background: '#0F8BFD', borderRadius: '6px', padding: '0.2145rem 0.571rem', color: 'white' }}> {`${value1}`}</span>

                                                {`     ${this.state.filters[item]['operator']} '${item}' `}    |
                                            </b>)
                                    }

                                    )
                                }

                                {
                                    Object.keys(this.state.filters).length > 0 && <Button onClick={() => this.clearFilter()} style={{ position: 'relative', top: '7px' }} tooltip="Clear all filter" tooltipOptions={{ position: 'top' }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" />
                                }
                            </div>

                            <div style={{ display: 'flex' }}>

                                <InputSwitch checked={this.state.matchedOnly} onChange={(e) => this.setState({ matchedOnly: e.value }, () => this.getData())} />
                                <span style={{ marginLeft: '10px', marginRight: '10px' }}> Matched only</span>

                                <InputSwitch checked={this.state.rankByGroup} onChange={(e) => {
                                    if (e.value) {
                                        this.setState({
                                            rankByGroup: e.value,
                                            rankByCTCK: false
                                        }, () => this.getData())
                                    } else {
                                        this.setState({
                                            rankByGroup: e.value,

                                        }, () => this.getData())
                                    }

                                }} />
                                <span style={{ marginLeft: '10px', marginRight: '10px' }}> Rank By Group</span>

                                <InputSwitch checked={this.state.rankByCTCK} onChange={(e) => {

                                    if (e.value) {
                                        this.setState({
                                            rankByCTCK: e.value,
                                            rankByGroup: false
                                        }, () => this.getData())
                                    } else {
                                        this.setState({
                                            rankByCTCK: e.value,

                                        }, () => this.getData())
                                    }
                                }} />
                                <span style={{ marginLeft: '10px' }}> Rank By CTCK</span>
                            </div>

                        </div>

                    </div>






                </div>

                <div className="p-col-12 p-md-12" style={{ display: 'flex', gap: '20px' }}>
                    <div className="card widget-table" style={{ width: '50%' }} >


                        {

                            this.state.rankByGroup ?
                                <OneStockBuyGroup ref={c => this.OneStockBuyGroup = c} color_position={this.state.color_position}></OneStockBuyGroup> :
                                <OneStockBuy ref={c => this.OneStockBuy = c} color_position={this.state.color_position} setSort={(sortField, sortOrder) => this.setSort(sortField, sortOrder)}></OneStockBuy>
                        }

                  

                    </div>
                    <div className="card widget-table" style={{ width: '50%' }} >

                        {

                            this.state.rankByGroup ?
                                <OneStockSellGroup ref={c => this.OneStockSellGroup = c} color_position={this.state.color_position} ></OneStockSellGroup> :
                                <OneStockSell ref={c => this.OneStockSell = c} color_position={this.state.color_position} setSort={(sortField, sortOrder) => this.setSort(sortField, sortOrder)}></OneStockSell>

                        }


                    </div>


                </div>



            </>




        );
    }
}

export default OneStockView;