import model from "../model";
import axios from "axios";

import QueryInfo from "./QueryInfo";
import moment from 'moment';
import { domain_config } from "../../utilities/Domain";
class Type {
    constructor() {
        // this.url = 'https://d11.f5traders.com/api'
        this.url = domain_config(1)
        this.queryInfo = new QueryInfo()

        this.key = ''
    }



    arrayBufferToBase64 = (buffer) => {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return binary;
    }

    decode = (res) => {
        // Decode base64 (convert ascii to binary)
        var strData = atob(res);


        // Convert binary string to character-number array
        var charData = strData.split('').map(function (x) {
            return x.charCodeAt(0);
        });
        // Turn number array into byte-array
        var binData = new Uint8Array(charData);

        // Pako magic
        var data = global.pako.inflate(binData);
        data = this.arrayBufferToBase64(new Uint16Array(data))
        return JSON.parse(data);
    }
    getData(filter, mode , filterLocal) {
        if(Object.keys(filter) == 0){
            this.key = 'aaa'
        }else{
            this.key = global.genKey(filter)
        }
        if (global.data_type && global.data_type[this.key] ) {
            this.queryInfo.info({} , `${this.url}/db11-type-url`)
            return Promise.resolve(global.data_type[this.key])
        } else {
            return axios.request({
                url: `${this.url}/db11-type-url`,
                method: "POST",
                data: {...filter}
            })

                .then(response => {
                    let res = this.decode(response['data'])
                    console.log(res)
                    let out = this.handleData(res['data'], mode)

                    
					if (!global.data_type) {
						global.data_type = {}
					}

                    let maxDay = new Date(res['max_day'].replaceAll('_', '/'));
                    let minDay = new Date(res['min_day'].replaceAll('_', '/'));

                    global.data_type[this.key] = {
                        'data': Object.values(out),
                        'max_day' : maxDay,
                        'min_day' : minDay,
                        'res': res
                    };
                
                    if(filterLocal['data_filter'].length > 0){
                        return this.filterData(filterLocal,mode)
                    }
                    return {
                        'max_day' : maxDay,
                        'min_day' : minDay,
                        'data' : Object.values(out)
                    }

                })

                .catch((error) => {
                    if (error.response.status == 401) {
						window.location.href = `http://d11.f5traders.com/#/login?nexLink=${encodeURIComponent(window.location.href)}`
						return false;
					}

                    return false

                })
        }

    }

    handleData(res, mode) {
        let out = {}
        let col = Object.keys(res)

        let length = res['day'].length


        for (let index = 0; index < length; index++) {
            let dataCol = {}
            col.map(item => {
                if (!dataCol['value']) dataCol['value'] = {}
                dataCol['value'][item] = res[item][index]
                dataCol[item] = res[item][index]



            })

            if (mode == 'Type') {
                if (out[dataCol['label1']]) {
                    out[dataCol['label1']]['value'].push(dataCol['value'])

                    Object.keys(dataCol['value']).map(col => {
                        if (col == 'buy' || col == 'sell' || col == 'buy_order' || col == 'sell_order') {

                            out[dataCol['label1']][col] += Number(dataCol['value'][col])

                        }
                    })
                } else {
                    out[dataCol['label1']] = {
                        ...dataCol, value: [dataCol['value']]
                    }
                }
            } else if (mode == 'Day') {
                if (out[dataCol['day']]) {
                    out[dataCol['day']]['value'].push(dataCol['value'])

                    Object.keys(dataCol['value']).map(col => {
                        if (col == 'buy' || col == 'sell' || col == 'buy_order' || col == 'sell_order') {

                            out[dataCol['day']][col] += Number(dataCol['value'][col])

                        }
                    })
                } else {
                    out[dataCol['day']] = {
                        ...dataCol, value: [dataCol['value']]
                    }
                }
            }


        }

        return out
    }

    filterData(filter, mode) {

        this.queryInfo.info({} , `${this.url}/db11-type-url`)
        let dataTemp = structuredClone(global.data_type[this.key]['res']['data'])
        let leng = dataTemp['day'].length
        let accountTemp = [], addressTemp = [], buyTemp = [], buy_orderTemp = [], dayTemp = [], idTemp = [], insider_tradeTemp = [], label1Temp = [], label1_detailsTemp = [], nameTemp = [], percentileTemp = [], priceTemp = [], sellTemp = [], sell_orderTemp = [], stockTemp = [], type_ahTemp = []
        for (let index = 0; index < leng; index++) {
            // debugger
            let check = true

            for (let y = 0; y < filter['data_filter'].length; y++) {
                let col = filter['data_filter'][y]['col_name']

                let value = filter['data_filter'][y]['value']
                if (col == 'type') {
                    col = 'label1'
                }
                if (col == 'day') {
                    if (filter['data_filter'][y]['operator'] == 'from') {
                        let startDay = value[0]
                        let endDay = value[1]
                        let out = []

                        for (let index = Number(moment(startDay).format('X')); index <= Number(moment(endDay).format('X')); index = index + 86400) {
                            out.push(moment(index, 'X').format('YYYY_MM_DD'))

                        }

                        value = out
                    } else {
                        value = value.map(item => item.replaceAll('/', '_'))
                    }
                }

                if (!value.includes(dataTemp[col][index])) {
                    check = false
                    break
                }


            }

            if (check == true) {
                accountTemp.push(dataTemp['account'][index])
                addressTemp.push(dataTemp['address'][index])
                buyTemp.push(dataTemp['buy'][index])
                buy_orderTemp.push(dataTemp['buy_order'][index])
                dayTemp.push(dataTemp['day'][index])
                idTemp.push(dataTemp['id'][index])
                insider_tradeTemp.push(dataTemp['insider_trade'][index])
                label1Temp.push(dataTemp['label1'][index])
                label1_detailsTemp.push(dataTemp['label1'][index])
                nameTemp.push(dataTemp['name'][index])
                percentileTemp.push(dataTemp['percentile'][index])
                priceTemp.push(dataTemp['price'][index])
                sellTemp.push(dataTemp['sell'][index])
                sell_orderTemp.push(dataTemp['sell_order'][index])
                stockTemp.push(dataTemp['stock'][index])
                type_ahTemp.push(dataTemp['type_ah'][index])





            }
        }
        dataTemp['account'] = accountTemp
        dataTemp['address'] = addressTemp
        dataTemp['buy'] = buyTemp
        dataTemp['buy_order'] = buy_orderTemp
        dataTemp['day'] = dayTemp
        dataTemp['id'] = idTemp
        dataTemp['insider_trade'] = insider_tradeTemp
        dataTemp['label1'] = label1Temp
        dataTemp['label1'] = label1_detailsTemp
        dataTemp['name'] = nameTemp
        dataTemp['percentile'] = percentileTemp
        dataTemp['price'] = priceTemp
        dataTemp['sell'] = sellTemp
        dataTemp['sell_order'] = sell_orderTemp
        dataTemp['stock'] = stockTemp
        dataTemp['type_ah'] = type_ahTemp

        let out = this.handleData(dataTemp, mode)

        
        return Promise.resolve({
            'data' : Object.values(out),
            'max_day' :  global.data_type[this.key]['maxDay'],
            'min_day' :  global.data_type[this.key]['minDay'],
        })

    }


}

export default Type;